import { makeStyles } from '@material-ui/core/styles'; 
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  baseButton: {
    height: theme.spacing(6),
    borderRadius: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      height: '40px',
      '& .MuiButton-label': {
        fontSize: '14px',
        lineHeight: '16px'
      }
    }
  },
  label: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  }
}));

export default function BaseButton(props: any) {
  const classes = useStyles();
  const { className, ...otherProps } = props;

  return <Button {...otherProps} className={`${classes.baseButton} ${className}` } classes={{label: classes.label}}>{props.children}</Button>

}