import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Icon, Link, Typography } from '@material-ui/core';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import theme from '../theme';
import { FormValues, ReqField } from '../data/schema';

import { reqFields } from '../views/RequiredFieldsView';
import HighlightButton from '../buttons/HighlightButton';
import OverviewTag from './OverviewTag';
import { FaXmark } from 'react-icons/fa6';
import { ClipLoader } from 'react-spinners';

// needs to be imported from form.listflix.de to make it work in production
// -> relative imports in static/media do not work because on prod, app will search under listflix.de/form/static/media ...
const logos = [
  {
    img: "https://form.listflix.de/static/media/sepa_logo.png",
    alt: "SEPA",
    maxWidth: "90px"
  },
  {
    img: "https://form.listflix.de/static/media/paypal_logo.png",
    alt: "PayPal",
    maxWidth: "130px"
  },
  {
    img: "https://form.listflix.de/static/media/visa_logo.png",
    alt: "VISA",
    maxWidth: "90px"
  },
  {
    img: "https://form.listflix.de/static/media/mastercard_logo.png",
    alt: "Master Card",
    maxWidth: "90px"
  },
  {
    img: "https://form.listflix.de/static/media/american_express_logo.png",
    alt: "American Express",
    maxWidth: "60px"
  }
]

const useStyles = makeStyles((theme) => ({
  offerWidgetContainer: {
    backgroundColor: theme.palette.grey[100],
    height: '100%',
    width: '100%',
    padding: theme.padding.md,
    [theme.breakpoints.only('xs')]: {
      padding: theme.padding.xs,
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.padding.md,
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.padding.sm,
    },
    '& ul': {
      margin: '2px 0px 2px 20px'
    },
    '& #arrow-back': {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    '& #arrow-upward': {
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  },
  icon: {
    marginRight: theme.padding.xs,
    fontSize: '20px'
  },
  expandIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.padding.xs
  },
  priceIcon: {
    color: '#F58634',
    marginRight: theme.padding.xs,
    fontSize: '20px'
    //marginTop: '4px'
  },
  iconText: {
    marginRight: theme.padding.xs
  },
  offer: {
    padding: theme.padding.sm,
    backgroundColor: 'white',
    border: '2px solid ' + theme.palette.primary.main,
    borderRadius: '2px',
    marginTop: theme.padding.xs
  },
  buyImmOffer: {
    padding: theme.padding.md,
    backgroundColor: 'white',
    border: '2px solid ' + theme.palette.grey[300],
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '90%'
    },
    [theme.breakpoints.down('md')]: {
      width: '97%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      margin: theme.padding.sm
    }
  },
  countTooSmallWarning: {
    color: theme.palette.warning.main,
    fontWeight: 'bold'
  },
  ust: {
    textAlign: 'right',
    marginTop: theme.padding.xs,
    fontSize: '16px'
  },
  calculating: {
    color: theme.palette.primary.main,
    fontSize: '12px'
  },
  smallFont: {
    fontSize: '16px'
  },
  limitContainer: {
    marginTop: theme.padding.sm
  },
  limitIconText: {
    borderRadius: '2px',
    marginBottom: '4px',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.padding.xs,
    backgroundColor: theme.palette.grey[100],
    '& span': {
      color: theme.palette.primary.main,
      marginRight: '4px'
    },
    '& p': {
      fontSize: '14px',
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  },
  dotted: {
    border: 'none',
    borderTop: '2px dashed ' + theme.palette.primary.main,
    width: '100%',
    marginBottom: '-20px'
  },
  buyImmButton: {
    height: '40px',
    minWidth: '220px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  result: {
    borderTop: '2px double grey',
    marginTop: theme.padding.xs,
    paddingTop: theme.padding.xs
  },
  logo: {
    maxHeight: '80px',
    width: 'auto',
    height: 'auto',
    marginRight: theme.padding.sm,
    marginBottom: theme.padding.xs
  },
  moreDetails: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

interface OfferWidgetProps {
  formValues: FormValues,
  count: number | undefined,
  price: number | undefined,
  countTooSmallValue: number | undefined,
  activeStep: number,
  handleBuy: Function,
  orderError?: boolean,
  quotation?: any
}

const additionalDetails: Array<ReqField> = [
  {
    value: 'Firmennamen',
    displayValue: 'Firmennamen'
  },
  {
    value: 'Branche',
    displayValue: 'Branche'
  },
  {
    value: 'Unternehmensgegenstand',
    displayValue: 'Unternehmensgegenstand'
  },
  {
    value: 'Anrede',
    displayValue: 'Geschäftsführung'
  },
  {
    value: 'Anrede',
    displayValue: 'Prokura'
  },
  {
    value: 'Anrede',
    displayValue: 'Inhaber'
  },
  {
    value: 'Telefon',
    displayValue: 'Telefon'
  },
  {
    value: 'Fax',
    displayValue: 'Fax'
  },
  {
    value: 'Webseite',
    displayValue: 'Webseite'
  },
  {
    value: 'E-Mail',
    displayValue: 'allg. E-Mail'
  },
  {
    value: 'Firmengrößenklassen',
    displayValue: 'Firmengrößenklassen'
  },
  {
    value: 'Bilanzsumme',
    displayValue: 'Bilanzsumme'
  },
  {
    value: 'Umsatz',
    displayValue: 'Umsatz'
  },
  {
    value: 'Betriebsergebnis',
    displayValue: 'Betriebsergebnis'
  },
  {
    value: 'Mitarbeiterzahl',
    displayValue: 'Mitarbeiterzahl'
  }
]

export default function OfferWidget({ formValues, count, price, countTooSmallValue, activeStep, handleBuy, orderError, quotation }: OfferWidgetProps) {
  const classes = useStyles();
  const { verticalValues, regionValues } = formValues;
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const [expand, setExpand] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const suggestionsOpen = document.getElementById("suggestion-list-1") !== null || document.getElementById("suggestion-list-2") !== null;

  useEffect(() => {
    // push 'Deutschland' as region value if nothing was selected after 2nd step (region selection)
    if(activeStep >= 2 && regionValues.length === 0 && formValues.postCodeRange === undefined && formValues.postCodes.length === 0) {
      formik.setFieldValue('regionValues', [{
        category: 'Alle',
        value: 'Deutschland',
        alias: 'Deutschland',
        count: count
      }]);
    }
  }, [regionValues.length, formValues.postCodeRanges, activeStep]);

  function showPrice() {
    return regionValues.length > 0 || formValues.postCodeRanges != undefined || activeStep >= 2
  }

  const ChooseValues = () => (
    <Grid container item direction="row" alignItems="center" wrap="nowrap" style={{marginTop: '185px'}}>
      <Icon id="arrow-back" className={`${classes.icon}`}>arrow_back</Icon>
      <Icon id="arrow-upward" className={`${classes.icon}`}>arrow_upward</Icon>
      <Typography variant="body2">Bitte geben Sie Ihre gesuchten Branchen ein und konfigurieren Sie Ihre Wunschliste.</Typography>
    </Grid>
  )

  const RegionOverview = () => {
    const text = regionValues.length === 1 ? "In der Region" : "In den Regionen";
    
    return (
    <>
      <Typography variant="h4">{text}</Typography>
  
      {regionValues.length > 0 &&
      <Grid container item direction="row">
        {regionValues.map((item, i) => (
          <OverviewTag key={i} text={item.value} />
        ))}
      </Grid>}
    </>
    )
  };

  const PlzOverview = () => (
    <>
      <Typography variant="h4">{`Für die Postleitzahl${formValues.postCodes.length > 1 || formValues.postCodes[0].value.includes("-") ? 'en' : ''}`}</Typography>
      <Grid container item direction="row">
        {formValues.postCodes.map((pc, i) => (
          <OverviewTag key={i} text={pc.value} />
        ))}
      </Grid>
    </>
  )

  const RadiusOverview = () => (
    <>
      <Typography variant="h4">Im Umkreis</Typography>
      <OverviewTag text={`${formValues.postCodeRange} km um ${formValues.postCodeRangeInput}`} icon='map' />
    </>
  )

  const ReqFieldsOverview = () => (
    <>
      <Typography variant="h4">Diese Details müssen enthalten sein</Typography>
      <Grid container item direction="row">
        <OverviewTag key='postadresse-tag' text='Postadresse' icon='check' /> 
        {formValues.requiredFields.map((field, i) => {
          const text = field === 'Anrede' ? 'Ansprechpartner' : reqFields.filter(f => f.value === field).map(f => f.displayValue)[0];
          return (
            <OverviewTag key={i} text={text} icon='check' />
          )
        })}
      </Grid>
    </>
  )

  const AdditionalDetOverview = () => {
    const detToShow = additionalDetails.filter(det => !formik.values.requiredFields.includes(det.value));
    
    return (
      <>
        <Grid container direction="row" wrap="nowrap" alignItems='center' component={'a'} onClick={() => setExpand(!expand)} className={classes.moreDetails} >
          <Typography variant="h4">Weitere Details nach Verfügbarkeit</Typography>
          <Icon className={classes.expandIcon} color='primary'>{expand ? 'expand_less' : 'expand_more'}</Icon>
        </Grid>
        {expand &&
        <Grid container direction="row">
          {detToShow.map((d, i) => (
          <OverviewTag key={i} text={d.displayValue} />
          ))}
        </Grid>}
      </>
    )
  }

  const SizeClassesOverview = () => (
    <>
      <Typography variant="h4">Diese Unternehmensgrößen sind enthalten</Typography>
      <Grid container item direction="row">
        {formValues.sizeClasses.map((field, i) => (
          <OverviewTag key={i} text={field} icon='check' />
        ))}
      </Grid>
    </>
  )

  const LimitOverview = () => (
    <div className={classes.limitContainer}>
      <hr className={classes.dotted} />
      <Grid container item direction="row" wrap="nowrap" alignItems='center' justify='center' className={classes.limitIconText}>
        <Icon color='primary'>content_cut</Icon>
        <Typography variant="body1">Anzahl wird auf {formValues.limit?.toLocaleString('de-DE')} beschränkt.</Typography>
      </Grid>
    </div>
  )

  const Count = () => { 
    if(count === undefined || count === null){
      return <Typography variant="body2" className={classes.calculating}>... wird berechnet ...</Typography>
    } else {
      return <Typography variant="body2" className={classes.smallFont}>{count.toLocaleString('de-DE')}</Typography>
    }
  }

  const Price = () => {
    if(price === undefined || price === null || price === 0){
      return <Typography variant="body2" className={classes.calculating}>... wird berechnet ...</Typography>
    } else {
      return <Typography variant="body2" className={classes.smallFont}>{price.toLocaleString('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</Typography>
    }
  }

  const MwSt = () => {
    if(price === undefined || price === null || price === 0){
      return <Typography variant="body2" className={classes.calculating}>... wird berechnet ...</Typography>
    } else {
      return <Typography variant="body2" className={classes.smallFont}>{(price * 0.19).toLocaleString('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</Typography>
    }
  }

  const FullPrice = () => {
    if(price === undefined || price === null || price === 0){
      return <Typography variant="body2" className={classes.calculating}>... wird berechnet ...</Typography>
    } else {
      return <Typography variant="body2" className={classes.smallFont}>{(price * 1.19).toLocaleString('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</Typography>
    }
  }

  const CalculatedOffer = () => {
    return (
    <Grid id="calculated-offer" container item direction="column">
      
      <Grid item>
        <Typography variant='h4'>Sie suchen</Typography>

        <Grid container item direction="row">
          {verticalValues.map((item, i) => (
            <OverviewTag key={i} text={item.value} primary />
          ))}
        </Grid>
      </Grid>

      {(formValues.regionValues.length > 0 && formValues.postCodeRange === undefined) && <RegionOverview />}

      {formValues.postCodes.length > 0 && <PlzOverview />}

      {formValues.postCodeRange && formValues.postCodeRangeInput && <RadiusOverview />}

      {(formValues.requiredFields.length > 0 || activeStep >= 2) && <ReqFieldsOverview />}

      {(formValues.requiredFields.length > 0 || activeStep >= 2) && <AdditionalDetOverview />}

      {formValues.sizeClasses.length > 0 && activeStep >= 3 && <SizeClassesOverview />}

      {formValues.limit && <LimitOverview />}

      <Grid container item className={classes.offer}>        
        <Grid container item direction="row" alignItems="center">
          <Icon className={classes.icon}>numbers</Icon>
          <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Firmenanzahl:</Typography>
          <Count />
        </Grid>

        {showPrice() &&
        <Grid container item direction="row" alignItems="center">
          <Icon className={classes.priceIcon}>sell</Icon>
          <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Preis*:</Typography>
          <Price />
        </Grid>}
      </Grid>

      {showPrice() && 
      <Grid item>
        <Typography variant="body1" id="ust" className={classes.ust}>*zzgl. 19% USt.</Typography>
      </Grid>}

    </Grid>
  )}

  const OfferView = () => (
    <>
      <Typography variant="h2">Ihre Liste im Überblick</Typography>
      {verticalValues.length > 0 && <CalculatedOffer />}
    </>
  )

  const CountTooSmallWarning = () => {
    const numCompanies = count !== undefined && count === 1 ? `${count?.toLocaleString('de-DE')} Firma` : `${count?.toLocaleString('de-DE')} Firmen`;

    return (
    <Grid container item direction="row" alignItems="center" wrap="nowrap" style={{minHeight: '150px', marginBottom: suggestionsOpen ? '541px' : '65px'}}>
      <Icon id="arrow-back" className={`${classes.icon}`}>arrow_back</Icon>
      <Icon id="arrow-upward" className={`${classes.icon}`}>arrow_upward</Icon>
      <Typography variant="body1" className={classes.countTooSmallWarning}>
        {`Ihre Suchanfrage ergibt leider nur ${numCompanies}. Bitte ergänzen Sie weitere Branchen / Regionen, um die Mindestmenge von ${countTooSmallValue?.toLocaleString('de-DE')} Firmen zu erreichen.`}
      </Typography>
    </Grid>)
  }

  const BuyImmediately = () => {
    // function getHeaderText() {
    //   if (activeStep === 5) {
    //     return 'Ihre Auswahl'
    //   } else if(activeStep === 6) {
    //     return 'Listenübersicht';
    //   }
    // }

    // const regionText = 
    //   formValues.regionValues.length > 0 ? `${formValues.regionValues.map(r => r.alias).join(', ')}` :
    //   formValues.postCodeRange && formValues.postCodeRangeInput ? `${formValues.postCodeRange} km um ${formValues.postCodeRangeInput}` : ''

    return (
      <>

        <Grid container direction="column" alignItems='center' className={classes.buyImmOffer}>

          <Typography variant="h2" style={{marginBottom: theme.padding.md}} align='center'>Listenübersicht</Typography>

          {/* {activeStep === 5 &&
            <Grid container item direction="column" alignItems="flex-start"  style={{marginBottom: '20px'}}>
              <Typography variant="h4">Ihr Angebot enthält:</Typography>

              {formValues.verticalValues.map((v, i) => (
                <Typography  style={{marginBottom: '4px'}} className={classes.smallFont} key={i} variant="body1">{i+1}. Liste: {v.value} in {regionText}</Typography>
              ))}
            </Grid>
          } */}
          
          <Grid container item direction="column" alignItems='flex-start'>
            {/* {activeStep == 5 && <Typography variant="h4">Preisübersicht:</Typography>} */}

            <Grid container item direction="row" alignItems="center">
              <Icon color='secondary' className={classes.icon}>numbers</Icon>
              <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Firmenanzahl:</Typography>
              <Count />
            </Grid>

            {quotation?.total_tax ?
            <>
              <Grid container item direction="row" alignItems="center">
                <Icon className={classes.priceIcon}>sell</Icon>
                <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Gesamtpreis (netto):</Typography>
                <Typography variant="body1" className={`${classes.smallFont}`} style={{fontWeight: 'bold'}}>{quotation.total_net} {quotation.total_currency}</Typography>
              </Grid>

              <Grid container item direction="row" alignItems="center">
                <Icon style={{color: 'white'}} className={classes.icon}>account_balance</Icon>
                <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Mehrwertsteuer (19%):</Typography>
                <Typography variant="body1" className={`${classes.smallFont}`} style={{fontWeight: 'bold'}}>{quotation.total_tax} {quotation.total_currency}</Typography>
              </Grid>

              <Grid container item direction="row" alignItems="center" className={classes.result}>
                <Icon color='primary' className={classes.icon}>euro</Icon>
                <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Gesamtpreis (brutto):</Typography>
                <Typography variant="body1" className={`${classes.smallFont}`} style={{fontWeight: 'bold'}}>{quotation.total_gross} {quotation.total_currency}</Typography>
              </Grid>
            </>
            :
            <>
              <Grid container item direction="row" alignItems="center">
                <Icon color='primary' className={classes.icon}>euro</Icon>
                <Typography variant="body1" className={`${classes.iconText} ${classes.smallFont}`}>Gesamtpreis:</Typography>
                <Typography variant="body1" className={`${classes.smallFont}`} style={{fontWeight: 'bold'}}>{quotation.total_gross} {quotation.total_currency}</Typography>
              </Grid>

              <Typography variant="body1" style={{marginTop: '16px', fontSize: '12px'}}>Der Gesamtbetrag ist eine nicht im Inland steuerbare Leistung (Steuerschuldnerschaft des Leistungsempfängers/Reverse Charge).</Typography>
            </>}

            {/* {activeStep === 5 && <Typography variant="body1" style={{marginLeft: '34px', fontSize: '12px'}}>Zahlbar sofort (Vorkasse)</Typography>} */}
          </Grid>

          <Grid item style={{marginTop: theme.padding.md}}>
            {submitting ?
            <Grid container direction='row' justify='center' alignItems='center' style={{ marginTop: '16px', marginBottom: '16px'}}>
              <ClipLoader color={'#000'} loading={submitting} size={20} />
              <Typography variant='body1' style={{ marginLeft: '8px', fontWeight: 'bold' }} color='primary'>Bestellbestätigung wird versandt...</Typography>
            </Grid> :

            orderError ?
            <Grid container direction='row' justify='center' alignItems='center' wrap="nowrap">
              <FaXmark color={'#c41818'} size={20} style={{ marginRight: '8px' }} />
              <Typography variant='body1' className={classes.smallFont} style={{ marginLeft: '8px', fontWeight: 'bold', color: '#c41818' }}>Leider ist ein Fehler aufgetreten. Bitte wenden Sie sich direkt an: <Link style={{ fontWeight: 'bold', color: '#c41818', textDecoration: 'underline' }} href="mailto:anfrage@listflix.de?subject=&amp;body=">anfrage@listflix.de</Link></Typography>
            </Grid> :

            <HighlightButton onClick={(async () => {
              setSubmitting(true);
              handleBuy();
            })} className={classes.buyImmButton} style={{marginBottom: theme.padding.sm}} disabled={submitting}>Liste bestellen</HighlightButton>}
          </Grid>

        </Grid>

        <Grid container direction="row" style={{marginTop: theme.padding.md}} className={classes.buyImmOffer} alignItems="center" justify="center">
          
          <Grid item xs={12}>
            <Typography style={{marginBottom: theme.padding.md}} align="center" variant="h2">Wir akzeptieren</Typography>
          </Grid>
          
          {logos.map((l, i) => (
            <Grid item key={i}>
              <img style={{maxWidth: l.maxWidth}} className={classes.logo} src={l.img} alt={l.alt} />
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  const WidgetContent = () => {
    if (activeStep >= 6) {
      return <BuyImmediately />
    } else if (count === undefined){
      return <ChooseValues />;
    } else if(countTooSmallValue){
      return <CountTooSmallWarning />;
    } else {
      return <OfferView />;
    }
  }

  const justify = (activeStep === 0 && verticalValues.length === 0) || activeStep === 6 ? 'flex-start' : countTooSmallValue ? 'flex-end' : 'center';

  return (
    <Grid container item direction="column" className={classes.offerWidgetContainer} justify={justify}>
      <WidgetContent />
    </Grid>
  )
}