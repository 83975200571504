import React, { BaseSyntheticEvent, useEffect, useRef } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { Input, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  }
}));

interface InputFieldProps {
  fieldName: string,
  placeholder: string,
  blur: boolean,
  focusOnLoad: boolean
}

export default function InputField({ fieldName, placeholder, blur, focusOnLoad }: InputFieldProps) {
  const classes = useStyles();
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if(inputRef && inputRef.current && focusOnLoad) inputRef.current.focus();
  }, []);

  const handleBlur = (e: BaseSyntheticEvent) => {
    if(blur){
      formik.handleBlur(e);
    }
  }

  return (
    <Input
      inputRef={inputRef}
      name={fieldName}
      value={formik.values[fieldName]}
      fullWidth 
      placeholder={placeholder} 
      onChange={formik.handleChange}
      onBlur={handleBlur}
      autoComplete='off'
      classes={{
        input: classes.input
      }}
    />
  );
}
