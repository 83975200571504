import { FormikValues } from "formik";
import { QuoteRequest, VertQuery, FormValues, SuggestionValue, OfferPreviewItem, OfferPreviewDetail } from "../data/schema";
import { allPostCodes } from "../resources/data/all_post_codes";


export function createQuoteRequest(formValues: FormValues): QuoteRequest | any {
  let vertQueries = mapFormValuesToVertQueries(formValues);

  let quoteRequest: QuoteRequest = {
    per_salutation: formValues.salutation,
    per_given_name: formValues.firstName,
    per_last_name: formValues.lastName,

    com_name: formValues.firmName,
    com_house_number: formValues.number,
    com_street: formValues.street,
    com_city: formValues.city,
    com_post_code: formValues.postCode,
    com_email: formValues.email,
    com_country_code: formValues.country,

    vert_queries: vertQueries,
  }

  if (formValues.ustid) {
    quoteRequest.com_vatin = formValues.ustid;
  }

  if (formValues.phone) {
    quoteRequest.com_phone = formValues.phone;
  }

  return quoteRequest;
}

export function mapFormValuesToVertQueries(formValues: FormValues | FormikValues, removeLimits = false): Array<VertQuery> {
  let vertQueries: Array<VertQuery> = [];
  const postCodeSearchEmpty = formValues.postCodeRangeInput === '' || formValues.postCodeRange === undefined;
  const limit = removeLimits ? undefined : formValues.limit;
  
  formValues.verticalValues.forEach((item: SuggestionValue, i: number) => {
    if(item.vert_id){
      vertQueries.push({
        vert_id: item.vert_id,
        states: formValues.regionValues.filter((i: SuggestionValue) => i.category === 'Bundesland').map((i: SuggestionValue) => i.value),
        cities: formValues.regionValues.filter((i: SuggestionValue) => i.category === 'Stadt').map((i: SuggestionValue) => i.value),
        req_fields: formValues.requiredFields,
        post_codes: [],
        post_code_ranges: cleanPostCodeRanges(formValues.postCodes),
        size_classes: formValues.sizeClasses,
        limit: limit ? formValues.limitsByVerts[limit][i] : null,
        i_post_code_radius: postCodeSearchEmpty ? null : [formValues.postCodeRangeInput, formValues.postCodeRange]
      })
    }
  });

  return vertQueries;
}

function cleanPostCodeRanges(postCodes: Array<SuggestionValue>): Array<string> {
  const cleanPostCodes: Array<string> = [];

  postCodes.forEach((postCode: SuggestionValue) => {
    cleanPostCodes.push(postCode.value.split(" (")[0].replaceAll(" ", ""));
  });

 return cleanPostCodes;
}

// export function mapPostCodesToList(postCodes: Array<SuggestionValue>): Array<string> {
//   const postCodeList: Array<string> = [];

//   postCodes.forEach((pc: SuggestionValue, i: number) => {
//     if (pc.value.includes('-')) {
//       const startEnd = pc.value.split("-");
//       const start = allPostCodes.indexOf(startEnd[0]);
//       const end = allPostCodes.indexOf(startEnd[1]);

//       for (let i = start; i <= end; i++) {
//         postCodeList.push(allPostCodes[i])
//       }
//     } else {
//       postCodeList.push(pc.value);
//     }
//   });

//   return postCodeList;
// }

export function mapVertInfosToOfferPreview(vertInfos: Array<any>, price: any): Array<OfferPreviewItem> {
  let offerPreviewItems: Array<OfferPreviewItem> = [];
  
  vertInfos.forEach(vertInfo => {

    let basics: Array<OfferPreviewDetail> = [];
    let contactData: Array<OfferPreviewDetail> = [];
    let persons: Array<OfferPreviewDetail> = [];
    let financialData: Array<OfferPreviewDetail> = [];

    basics.push(
      {
        name: 'Firmenname',
        percent: 1,
        total: vertInfo.com_cnt
      },
      {
        name: 'Branche',
        percent: 1,
        total: vertInfo.com_cnt
      },
      {
        name: 'Unternehmensgegenstand',
        percent: vertInfo.subject_frc,
        total: vertInfo.subject_frc * vertInfo.com_cnt
      }
    );

    contactData.push(
      {
        name: 'Postadresse',
        percent: 1,
        total: vertInfo.com_cnt
      },
      {
        name: 'allg. Telefon',
        percent: vertInfo.phone_frc,
        total: vertInfo.phone_frc * vertInfo.com_cnt
      },
      {
        name: 'Fax',
        percent: vertInfo.fax_frc,
        total: vertInfo.fax_frc * vertInfo.com_cnt
      },
      {
        name: 'Webseite',
        percent: vertInfo.web_frc,
        total: vertInfo.web_frc * vertInfo.com_cnt
      },
      {
        name: 'allg. E-Mail',
        percent: vertInfo.email_frc,
        total: vertInfo.email_frc * vertInfo.com_cnt
      }
    );

    persons.push(
      {
        name: 'Geschäftsführung',
        percent: vertInfo.executive_frc,
        total: vertInfo.executive_frc * vertInfo.com_cnt
      },
      {
        name: 'Prokura',
        percent: vertInfo.proxy_frc,
        total: vertInfo.proxy_frc * vertInfo.com_cnt
      },
      {
        name: 'Inhaber',
        percent: vertInfo.owner_frc,
        total: vertInfo.owner_frc * vertInfo.com_cnt
      }
    );

    financialData.push(
      {
        name: 'Firmengrößenklasse',
        percent: 1,
        total: vertInfo.com_cnt
      },
      {
        name: 'Bilanzsumme',
        percent: vertInfo.balance_sheet_total_frc,
        total: vertInfo.balance_sheet_total_frc * vertInfo.com_cnt
      },
      {
        name: 'Umsatz',
        percent: vertInfo.revenue_frc,
        total: vertInfo.revenue_frc * vertInfo.com_cnt
      },
      {
        name: 'Betriebsergebnis',
        percent: vertInfo.gross_profit_frc,
        total: vertInfo.gross_profit_frc * vertInfo.com_cnt
      },
      {
        name: 'Mitarbeiterzahl',
        percent: vertInfo.employees_no_frc,
        total: vertInfo.employees_no_frc * vertInfo.com_cnt
      }
    );

    offerPreviewItems.push({
      name: vertInfo.name_short,
      nameLong: vertInfo.name_long,
      overallCount: vertInfo.com_cnt,
      price: price.ds_net * vertInfo.com_cnt,
      basics: basics,
      contactData: contactData,
      persons: persons,
      financialData: financialData
    });

  });

  return offerPreviewItems;
}