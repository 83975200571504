import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Icon } from '@material-ui/core';
import ClipLoader from 'react-spinners/ClipLoader';

const useStyles = makeStyles((theme) => ({
  searchField: {
    zIndex: 10,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    height: '48px',
    borderRadius: '24px',
    padding: '0px ' + theme.padding.sm,
    '& span': {
      color: theme.palette.text.disabled
    },
    '&:focus-within': {
      boxShadow: theme.shadows[2],
      border: 'none',
      '& span': {
        color: theme.palette.text.primary
      }
    }
  },
  inputItem: {
    flex: 1,
    marginLeft: theme.padding.xs,
    height: '100%'
  },
  input: {
    height: '100%',
    width: '100%',
    border: 'none',
    ...theme.typography.body1,
    '&::placeholder': {
      ...theme.typography.body1,
    },
    '&:focus': {
      outline: 'none'
    }
  }
}));

export default function SearchField (props: any) {
  const classes = useStyles();
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  let { focusOnLoad, loading, ...inputProps } = props;

  useEffect(() => {
    if(inputRef && inputRef.current && focusOnLoad) inputRef.current.focus();
  }, []);

  return (
  <Grid container direction="row" alignItems="center" justify="space-between" className={classes.searchField}>
    {!loading && <Icon>search</Icon>}
    <ClipLoader color={'#000'} loading={loading} size={20} />
    <Grid container item alignItems="center" className={classes.inputItem}>
      <input 
        className={`${classes.input} LoNotSensitive data-hj-allow`}
        ref={inputRef}
        {...inputProps}        
      />
    </Grid>
  </Grid>
  )
};