import { makeStyles } from '@material-ui/core/styles'; 
import BaseButton from './BaseButton';

const useStyles = makeStyles(theme => ({
  highlightButton: {
    lineHeight: '20px',
    background: 'linear-gradient(100deg, ' + theme.palette.primary.main + ' 10%, ' + theme.palette.secondary.main + ' 120%)',
    color: 'white!important'
  }

}));

export default function HighlightButton(props: any) {
  const classes = useStyles();
  const { className, ...otherProps } = props;
  const buttonProps = {
    className,
    ...otherProps,
    variant: 'contained',
    disableElevation: false
  }

  return <BaseButton {...buttonProps} className={`${classes.highlightButton} ${className}`}>{props.children}</BaseButton>

}