export const defaultValues = {
  lastName: 'Bickel',
  salutation: 'Herr',
  comName: 'Datamego GmbH',
  comStreet: 'Karlstraße',
  comHouseNumber: '9',
  comPostCode: '90403',
  comCity: 'Nürnberg',
  comCountry: 'Deutschland',
  comCountryCode: 'AT',
  quoteDate: '01.01.2021',
  orderDate: '01.01.2021',
  quoteNumber: '123456',
  validTo: '01.02.2021',
  totalCnt: '5.123',
  totalNet: '2.005',
  totalCurrency: '€',
  totalTax: '380,95',
  totalTaxRate: '19%',
  totalGross: '2.005',
  paymentTerm: 'Zahlbar nach Erhalt der Rechnung',
  prepayment: true,
  acceptLink: 'mailto:anfrage@listflix.de?subject=Bestellung%3A+Angebot+AG20221237&body=Hallo+Herr+Wechsler%2C%0A%0Ahiermit+nehme+ich+das+Angebot+%28Angebotsnummer%3A+AG20221237%29+vom+20.07.2022+an.%0A%0ABeste+Gr%C3%BC%C3%9Fe%0ADatamego+GmbH%0A%0A',
  positions: [
    {
      name_short: 'Immobilienmakler',
      name_long: 'Immobilienmakler und so',
      regions: 'Bayern',
      req_fields: 'Postadresse, Fax, E-Mail',
      size_classes: 'Kleinunternehmen, Mittelstand',
      cnt: '1.001',
      com_t: '1.001',
      com_pc: '100%',
      industry_t: '1.001',
      industry_pc: '100%',
      executive_t: '1.001',
      executive_pc: '100%',
      proxy_t : '1.001',
      proxy_pc: '100%',
      owner_t: '1.001',
      owner_pc: '100%',
      subject_t: '1.001',
      subject_pc: '100%',
      postal_t: '1.001',
      postal_pc: '100%',
      size_class_t: '1.001',
      size_class_pc: '100%',
      phone_t: '1.001',
      phone_pc: '100%',
      balance_sheet_total_t: '1.001',
      balance_sheet_total_pc: '100%',
      fax_t: '1.001',
      fax_pc: '100%',
      homepage_t: '1.001',
      homepage_pc: '100%',
      email_t: '1.001',
      email_pc: '100%',
      revenue_t: '1.001',
      revenue_pc: '100%',
      profit_t: '1.001',
      profit_pc: '100%',
      employees_no_t: '1.001',
      employees_no_pc: '100%',
      net: '2.005',
      currency: '€'
    },
    {
      name_short: 'Bauunternehmer',
      name_long: 'Bauunternehmer und so',
      regions: 'Bayern',
      size_classes: 'Kleinunternehmen, Mittelstand',
      req_fields: 'Postadresse, Fax, Webseite',
      cnt: '50.123',
      com_t: '1.001',
      com_pc: '100%',
      industry_t: '1.001',
      industry_pc: '100%',
      executive_t: '1.001',
      executive_pc: '100%',
      proxy_t : '1.001',
      proxy_pc: '100%',
      owner_t: '1.001',
      owner_pc: '100%',
      subject_t: '1.001',
      subject_pc: '100%',
      postal_t: '1.001',
      postal_pc: '100%',
      size_class_t: '1.001',
      size_class_pc: '100%',
      phone_t: '1.001',
      phone_pc: '100%',
      balance_sheet_total_t: '1.001',
      balance_sheet_total_pc: '100%',
      fax_t: '1.001',
      fax_pc: '100%',
      homepage_t: '1.001',
      homepage_pc: '100%',
      email_t: '1.001',
      email_pc: '100%',
      revenue_t: '1.001',
      revenue_pc: '100%',
      profit_t: '1.001',
      profit_pc: '100%',
      employees_no_t: '1.001',
      employees_no_pc: '100%',
      net: '2.005',
      currency: '€'
    }
  ]
}