import { useEffect, useRef, useState } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import { SuggestionValue, VerticalCount, VerticalSearchConfig, ViewConfig } from '../data/schema';
import { fetchIndustrySuggestionList, fetchVerticalCounts } from '../api/client';
import { addCountsToSuggestionList, sortSuggestionListByValue, filterSuggestionList } from '../utils/suggestion-list-utils';
import { mapViewToFormikFields } from '../utils/form-utils';
import { calculateOverallCount } from '../utils/helpers';

import TagField from '../inputs/TagField';
import ErrorMessage from '../form/ErrorMessage';
import Header from '../form/Header';
import DefaultView from './DefaultView';
import { Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold'
  }
}));

interface VerticalSearchViewProps {
  verticalSearchConfig: VerticalSearchConfig
  handleIntermediateChange: Function,
  setCount: Function,
  setFormValues: Function,
  views: Array<ViewConfig>
}

export default function VerticalSearchView({
  verticalSearchConfig,
  handleIntermediateChange,
  setCount,
  setFormValues,
  views
}: VerticalSearchViewProps) {
  const classes = useStyles();
  const [verticalCounts, setVerticalCounts] = useState<Array<VerticalCount>>([]);
  const [verticalSuggestions, setVerticalSuggestions] = useState<Array<SuggestionValue>>([]);
  const [loading, setLoading] = useState(verticalCounts.length === 0);

  const formik: FormikContextType<FormikValues> = useFormikContext();
  const formikFields = mapViewToFormikFields(verticalSearchConfig.id);
  const valueRef = useRef<Array<string>>();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      (async () => {
        const suggestions = await fetchIndustrySuggestionList(verticalSearchConfig.suggestionFile);
        setVerticalSuggestions(sortSuggestionListByValue(suggestions));
        
        const verticalCounts = await fetchVerticalCounts();
        setVerticalCounts(verticalCounts);
      })();
    }

    valueRef.current = formik.values[formikFields[0]].map((i: SuggestionValue) => i.value);
    return () => { mounted = false };
  }, []);

  useEffect(() => {
    const count = calculateOverallCount(formik.values[formikFields[0]]);
    setCount(count);
  }, [formik.values.verticalValues]);

  // Add counts to suggestions
  useEffect(() => {
    if(verticalCounts.length > 0) {
      setVerticalSuggestions((prev) => {
        return addCountsToSuggestionList(prev, verticalCounts);
      });
    }
    setLoading(verticalCounts.length === 0);
  }, [verticalCounts.length]);


  const InfoText = () => (
    <>
      <Typography variant="body1" paragraph>Bei Listflix können Sie aus über 600 Branchen und Firmenarten wählen. Tippen Sie einfach die gesuchte Branche ein und wählen Sie aus den Vorschlägen eine oder mehrere Branchen für Ihre Liste.</Typography>
      <Typography variant="body1" paragraph><span className={classes.bold}>Ideen zur Auswahl:</span></Typography>
      <ul>
        <li><Typography variant="body1"><span className={classes.bold}>Branche Ihrer Bestandskunden:</span> Finden Sie weitere Neukunden, indem Sie sich auf die Branchen Ihrer Bestandskunden konzentrieren. Auch ähnliche Branchen sind denkbar.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Trends:</span> Nutzen Sie gesellschaftliche und wirtschaftliche Trends, um Ihre Produkte möglichst passenden Zielgruppen anzubieten.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Neukunden vor Ort:</span> Herkunft verbindet. Nutzen Sie Regionalität, um Neukunden leichter in Ihrer Nähe zu finden.</Typography></li>
      </ul>
      <Typography variant="body1" paragraph><span className={classes.bold}>Ihre gesuchte Liste ist nicht dabei?</span></Typography>
      <Typography variant="body1">Schicken Sie uns jetzt Ihren Listenwunsch: <Link href="https://listflix.de/kontakt/" target="_blank">Anfrage senden</Link></Typography>
    </>
  )

  return (
      <DefaultView viewId={verticalSearchConfig.id} setCount={setCount} setFormValues={setFormValues} views={views} >
        <Header 
          headerConfig={verticalSearchConfig.header}
          infoModal={{
            title: 'Listenauswahl',
            content: <InfoText />,
            linkText: 'Welche Liste ist die richtige für mich?'
          }}
        />
        <TagField 
          key={formikFields[0]}
          fieldName={formikFields[0]}
          inputFieldName={formikFields[1]}
          placeholder={verticalSearchConfig.placeholder}
          suggestionList={verticalSuggestions}
          allowShowAll={false}
          filterSuggestionList={filterSuggestionList}
          handleIntermediateChange={handleIntermediateChange}
          focusOnLoad={true}
          openOnFocus={false}
          previewSuggestions={10}
          loading={loading}
        />
        <ErrorMessage padding={true} fieldName={formikFields[0]} />
      </DefaultView>
  );
};