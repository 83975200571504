import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const tagManagerConfig = {
  gtmId: process.env.REACT_APP_GTM_ID || ''
}

TagManager.initialize(tagManagerConfig);

const formConfig = document.getElementById('root')?.getAttribute('data-formConfigFile');

if(formConfig){
  ReactDOM.render(
    <App formConfigFile={formConfig} />,
    document.getElementById('root')
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
