import { makeStyles, Theme, withStyles, styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';

import { ViewConfig } from '../data/schema';
import theme from '../theme';

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 3,
    borderRadius: 1,
  }
})(StepConnector);

const CustomStepLabel = styled(StepLabel)({
  // only works by overwriting css classes directly. Does not work with withStyles or useStyles..
  "& .MuiStepLabel-label": {
    fontSize: '12px',
    fontWeight: 'normal',
    color: theme.palette.grey[400],
    marginTop: theme.padding.xs,
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px'
    }
  },
  "& .MuiStepLabel-active": {
    fontWeight: 'bold',
    color: theme.palette.secondary.main
  },
  "& .MuiStepLabel-completed": {
    color: theme.palette.text.primary
  }
});

const useCustomStepIconStyles = makeStyles({
  root: {
    color: theme.palette.grey[300],
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.secondary.main
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.secondary.main,
    zIndex: 1,
    fontSize: 18,
  },
});

const useStyles = makeStyles(({
  stepper: {
    padding: '0px',
    '& .MuiStep-root:first-child': {
      paddingLeft: '0px'
    },
    '& .MuiStep-root:last-child': {
      paddingRight: '0px'
    }
  }
}));

interface ProgressBarProps {
  activeStep: number,
  views: Array<ViewConfig>
}

export default function ProgressBar(props: ProgressBarProps) {
  const classes = useStyles();

  // make steps 5 and 6 (offer preview and contact input) look like one in the progress bar
  const newViews = [...props.views];
  newViews.splice(6, 1);
  const steps = newViews.map(v => v.teaser);
  
  if(props.activeStep == 6) {
    steps[5] = 'Bestellung';
  }

  const newActiveStep = props.activeStep === 6 ? 5 : props.activeStep;

  function CustomStepIcon(props: StepIconProps) {
    const classes = useCustomStepIconStyles();
    const { active, completed } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {!completed ? <div className={classes.circle} /> : <Check className={classes.completed} />}
      </div>
    );
  }

  return (
    <Stepper alternativeLabel activeStep={newActiveStep} connector={<CustomConnector />} className={classes.stepper}>
      {steps.map((label) => (
        <Step key={label}>
          <CustomStepLabel StepIconComponent={CustomStepIcon}>{label}</CustomStepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
