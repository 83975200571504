import { Column, Img, Row, Section, Text } from "@react-email/components";

export default function Contact() {
  return (
    <Section className="px-[8px] xl:px-[32px] pt-[32px]">
      <Text className="mt-[0px] mb-[0px] text-[14px]">Bei Fragen können Sie sich jederzeit gerne bei mir melden.</Text>
      <Row>
        <Column className="w-[76px]">
          <Img
            src='https://form.listflix.de/static/media/m_wechsler.jpg'
            height="60"
            width="60"
            alt="Michael Wechsler"
            className="rounded-full"
          />
        </Column>
        <Column>
          <Text className="text-[14px]">Beste Grüße<br/><span className="font-bold text-[14px]">Michael Wechsler</span><br/>Geschäftsführer</Text>
        </Column>
      </Row>
    </Section>
  )
}