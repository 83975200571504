import { makeStyles } from '@material-ui/core/styles';
import { Grid, Icon, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tag: (props: OverviewTagProps) => ({
    backgroundColor: props.primary ? theme.palette.primary.main : props.secondary ? theme.palette.secondary.main : 'white',
    borderRadius: '2px',
    border: props.primary || props.secondary ? '0px' : '1px solid ' + theme.palette.grey[300],
    marginRight: '4px',
    marginBottom: '4px',
    width: 'fit-content',
    padding: '0px 4px',
    '& p': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: props.primary || props.secondary ? 'white' : theme.palette.text.primary
    }
  }),
  icon: (props: OverviewTagProps) => ({
    color: props.primary || props.secondary ?  'white' : theme.palette.primary.main,
    marginRight: '4px',
    fontSize: '16px'
  })
}));

interface OverviewTagProps {
  text: string, 
  primary?: boolean,
  secondary?: boolean,
  icon?: string
}

export default function OverviewTag (props: OverviewTagProps) {
  const classes = useStyles(props);
  const { text, icon } = props;

  return (
    <Grid container item direction="row" wrap="nowrap" alignItems='center' className={classes.tag}>
      {icon && <Icon className={classes.icon} color='primary'>{icon}</Icon>}
      <Typography variant="body1">{text}</Typography>
    </Grid>
  );
}