import React from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.grey[600]
  },
  label: {
    marginRight: theme.padding.xs
  }
}));

interface CheckboxFieldProps {
  fieldName: string,
  value: string,
  preselected: boolean,
  disabled: boolean,
  label?: string
}

export default function CheckboxField({ fieldName, value, preselected, disabled, label }: CheckboxFieldProps) {
  const classes = useStyles();
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const checked = formik.values[fieldName].includes(value) || preselected;

  return (
    <div>
      <FormControlLabel
        classes={{root: classes.label}}
        control={
          <Checkbox 
            checked={checked} 
            value={value} 
            classes={{root: classes.checkbox}} 
            onChange={formik.handleChange}
            name={fieldName}
            disabled={disabled}
          />}
        
        // accept empty labels (for data security consent component)
        label={label || label === "" ? label : value}
      />
    </div>
  );
}
