import { FormikErrors, FormikTouched, FormikValues, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  error: (padding) => ({
    color: theme.palette.error.main,
    //fontWeight: 'bold',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: padding ? theme.padding.xs + ' ' + theme.padding.sm : '0px'
  })
}));

interface ErrorMessageProps {
  fieldName: string,
  padding?: boolean
}

export default function ErrorMessage({ fieldName, padding }: ErrorMessageProps) {
  const classes = useStyles(padding);
  const formik = useFormikContext();
  const errors: FormikErrors<FormikValues> = formik.errors;
  const touched: FormikTouched<FormikValues> = formik.touched;

  return (
    <div>
      {fieldName in errors && fieldName in touched ? (
        <Typography variant="body1" className={classes.error}>{errors[fieldName]}</Typography>
      ) : null}
    </div>
  );
}
