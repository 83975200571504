import { Column, Img, Row, Section } from "@react-email/components";

export default function Header() {
  return (
    <Section>
      <Row>
        <Column className="bg-listflix-yellow-main h-[80px] w-full" align="center">
          <Img
            src='https://form.listflix.de/static/media/listflix-logo.png'
            height="30"
            alt="Listflix Logo"
            className="my-[0px] mx-auto"
          />
        </Column>
      </Row>
    </Section>
  )
}