import { useEffect, useState } from 'react';
import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { ClipLoader } from 'react-spinners';

import { LimitConfig, ViewConfig, AdvMark } from '../data/schema';
import { mapViewToFormikFields } from '../utils/form-utils';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';
import { fetchGlobalCount, fetchLimits } from '../api/client';

import DefaultView from './DefaultView';
import Header from '../form/Header';
import CustomSlider from '../form/CustomSlider';

interface LimitViewProps {
  handleIntermediateChange: Function,
  limitConfig: LimitConfig,
  count: number | undefined,
  setCount: Function,
  setFormValues: Function,
  views: Array<ViewConfig>
}

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold'
  }
}));

export default function LimitView ({
  handleIntermediateChange,
  limitConfig,
  count,
  setCount,
  setFormValues,
  views
}: LimitViewProps) {
  const classes = useStyles();
  const [limits, setLimits] = useState<{ [key: number]: Array<number> } | undefined>();
  const [maxCount, setMaxCount] = useState(count);
  const [marks, setMarks] = useState<Array<AdvMark>>([]);
  const [loading, setLoading] = useState(false);

  const formikFields = mapViewToFormikFields(limitConfig.id)
  const formik: FormikContextType<FormikValues> = useFormikContext();

  function mapToAdvMarks(counts: { [key: number]: number[] }, maxCount: number) {
    let marks: Array<AdvMark> = [];
      
    Object.keys(counts).forEach((key, i) => {
      marks.push({
        value: i,
        origValue: parseInt(key),
        label: parseInt(key).toLocaleString('de-DE'),
        count: parseInt(key)
      });
    });

    marks.push({
      value: marks.length,
      origValue: undefined,
      label: `Alle`,
      count: maxCount
    })

    return marks;
  };

  useEffect(() => {
    (async() => {
      setLoading(true);
      const limits = await fetchLimits(mapFormValuesToVertQueries(formik.values, true));
      const maxCount = await fetchGlobalCount(mapFormValuesToVertQueries(formik.values, true));
      setMaxCount(maxCount);
      setLimits(limits);
      setMarks(mapToAdvMarks(limits, maxCount));
      setLoading(false);
      formik.setFieldValue(formikFields[1], limits);
    })();
  }, []);

  // update counts with change to limit slider
  useEffect(() => {
    const currentLimit = formik.values.limit;
    if (currentLimit) {
      setCount(currentLimit);
    } else {
      setCount(maxCount);
    }
  }, [formik.values.limit]);

  const InfoText = () => (
    <>
      <Typography variant="body1" paragraph>Vor dem Listenkauf gilt es herauszufinden, wie viele Firmen Ihre Liste enthalten soll.</Typography>
      <Typography variant="body1" paragraph><span className={classes.bold}>Folgende Faktoren sollten Sie berücksichtigen:</span></Typography>
      <ul>
        <li><Typography variant="body1"><span className={classes.bold}>Mehr Firmen, mehr Reichweite:</span> Je mehr Firmen Sie erreichen, desto größer sind Ihre Chancen, möglichst viele Neukunden zu finden.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Ausreichende Kapazitäten:</span> Überlegen Sie vorab, wie viele Firmen Sie mit Ihren Kapazitäten beziehungsweise Ihrem Budget ansprechen können.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Sparen beim Datensatzpreis:</span> Je mehr Firmen Ihre Listflix-Liste enthält, desto günstiger ist der Preis pro Datensatz. <Link href="https://listflix.de/preise/" target="_blank">Hier finden Sie unsere Preisstaffel</Link></Typography></li>
      </ul>
    </>
  )

  return (
    <DefaultView viewId={limitConfig.id} setCount={setCount} setFormValues={setFormValues} views={views} >
      <Header headerConfig={limitConfig.header}  infoModal={{
        title: 'Firmenanzahl',
        content: <InfoText />,
        linkText: 'Wie viele Firmen brauche ich?'
      }} />
      <Grid container direction="row" justify='center'>
        <ClipLoader color={'#000'} loading={loading} size={20} />
      </Grid>
      { limits && <CustomSlider formikField={formikFields[0]} marks={marks} />}
    </DefaultView>
  );
};