import { SuggestionValue } from "../../data/schema";

export const allPostCodes: Array<SuggestionValue> = [
  { category: "Postleitzahl", value: "01067", alias: "01067" },
  { category: "Postleitzahl", value: "01069", alias: "01069" },
  { category: "Postleitzahl", value: "01097", alias: "01097" },
  { category: "Postleitzahl", value: "01099", alias: "01099" },
  { category: "Postleitzahl", value: "01108", alias: "01108" },
  { category: "Postleitzahl", value: "01109", alias: "01109" },
  { category: "Postleitzahl", value: "01127", alias: "01127" },
  { category: "Postleitzahl", value: "01129", alias: "01129" },
  { category: "Postleitzahl", value: "01139", alias: "01139" },
  { category: "Postleitzahl", value: "01156", alias: "01156" },
  { category: "Postleitzahl", value: "01157", alias: "01157" },
  { category: "Postleitzahl", value: "01159", alias: "01159" },
  { category: "Postleitzahl", value: "01169", alias: "01169" },
  { category: "Postleitzahl", value: "01187", alias: "01187" },
  { category: "Postleitzahl", value: "01189", alias: "01189" },
  { category: "Postleitzahl", value: "01217", alias: "01217" },
  { category: "Postleitzahl", value: "01219", alias: "01219" },
  { category: "Postleitzahl", value: "01237", alias: "01237" },
  { category: "Postleitzahl", value: "01239", alias: "01239" },
  { category: "Postleitzahl", value: "01257", alias: "01257" },
  { category: "Postleitzahl", value: "01259", alias: "01259" },
  { category: "Postleitzahl", value: "01277", alias: "01277" },
  { category: "Postleitzahl", value: "01279", alias: "01279" },
  { category: "Postleitzahl", value: "01307", alias: "01307" },
  { category: "Postleitzahl", value: "01309", alias: "01309" },
  { category: "Postleitzahl", value: "01324", alias: "01324" },
  { category: "Postleitzahl", value: "01326", alias: "01326" },
  { category: "Postleitzahl", value: "01328", alias: "01328" },
  { category: "Postleitzahl", value: "01445", alias: "01445" },
  { category: "Postleitzahl", value: "01454", alias: "01454" },
  { category: "Postleitzahl", value: "01458", alias: "01458" },
  { category: "Postleitzahl", value: "01465", alias: "01465" },
  { category: "Postleitzahl", value: "01468", alias: "01468" },
  { category: "Postleitzahl", value: "01471", alias: "01471" },
  { category: "Postleitzahl", value: "01477", alias: "01477" },
  { category: "Postleitzahl", value: "01558", alias: "01558" },
  { category: "Postleitzahl", value: "01561", alias: "01561" },
  { category: "Postleitzahl", value: "01587", alias: "01587" },
  { category: "Postleitzahl", value: "01589", alias: "01589" },
  { category: "Postleitzahl", value: "01591", alias: "01591" },
  { category: "Postleitzahl", value: "01594", alias: "01594" },
  { category: "Postleitzahl", value: "01609", alias: "01609" },
  { category: "Postleitzahl", value: "01612", alias: "01612" },
  { category: "Postleitzahl", value: "01616", alias: "01616" },
  { category: "Postleitzahl", value: "01619", alias: "01619" },
  { category: "Postleitzahl", value: "01623", alias: "01623" },
  { category: "Postleitzahl", value: "01640", alias: "01640" },
  { category: "Postleitzahl", value: "01662", alias: "01662" },
  { category: "Postleitzahl", value: "01665", alias: "01665" },
  { category: "Postleitzahl", value: "01683", alias: "01683" },
  { category: "Postleitzahl", value: "01689", alias: "01689" },
  { category: "Postleitzahl", value: "01705", alias: "01705" },
  { category: "Postleitzahl", value: "01723", alias: "01723" },
  { category: "Postleitzahl", value: "01728", alias: "01728" },
  { category: "Postleitzahl", value: "01731", alias: "01731" },
  { category: "Postleitzahl", value: "01734", alias: "01734" },
  { category: "Postleitzahl", value: "01737", alias: "01737" },
  { category: "Postleitzahl", value: "01738", alias: "01738" },
  { category: "Postleitzahl", value: "01744", alias: "01744" },
  { category: "Postleitzahl", value: "01762", alias: "01762" },
  { category: "Postleitzahl", value: "01768", alias: "01768" },
  { category: "Postleitzahl", value: "01773", alias: "01773" },
  { category: "Postleitzahl", value: "01774", alias: "01774" },
  { category: "Postleitzahl", value: "01776", alias: "01776" },
  { category: "Postleitzahl", value: "01778", alias: "01778" },
  { category: "Postleitzahl", value: "01796", alias: "01796" },
  { category: "Postleitzahl", value: "01809", alias: "01809" },
  { category: "Postleitzahl", value: "01814", alias: "01814" },
  { category: "Postleitzahl", value: "01816", alias: "01816" },
  { category: "Postleitzahl", value: "01819", alias: "01819" },
  { category: "Postleitzahl", value: "01824", alias: "01824" },
  { category: "Postleitzahl", value: "01825", alias: "01825" },
  { category: "Postleitzahl", value: "01829", alias: "01829" },
  { category: "Postleitzahl", value: "01833", alias: "01833" },
  { category: "Postleitzahl", value: "01844", alias: "01844" },
  { category: "Postleitzahl", value: "01847", alias: "01847" },
  { category: "Postleitzahl", value: "01848", alias: "01848" },
  { category: "Postleitzahl", value: "01855", alias: "01855" },
  { category: "Postleitzahl", value: "01877", alias: "01877" },
  { category: "Postleitzahl", value: "01896", alias: "01896" },
  { category: "Postleitzahl", value: "01900", alias: "01900" },
  { category: "Postleitzahl", value: "01904", alias: "01904" },
  { category: "Postleitzahl", value: "01906", alias: "01906" },
  { category: "Postleitzahl", value: "01909", alias: "01909" },
  { category: "Postleitzahl", value: "01917", alias: "01917" },
  { category: "Postleitzahl", value: "01920", alias: "01920" },
  { category: "Postleitzahl", value: "01936", alias: "01936" },
  { category: "Postleitzahl", value: "01945", alias: "01945" },
  { category: "Postleitzahl", value: "01968", alias: "01968" },
  { category: "Postleitzahl", value: "01979", alias: "01979" },
  { category: "Postleitzahl", value: "01983", alias: "01983" },
  { category: "Postleitzahl", value: "01987", alias: "01987" },
  { category: "Postleitzahl", value: "01990", alias: "01990" },
  { category: "Postleitzahl", value: "01993", alias: "01993" },
  { category: "Postleitzahl", value: "01994", alias: "01994" },
  { category: "Postleitzahl", value: "01996", alias: "01996" },
  { category: "Postleitzahl", value: "01998", alias: "01998" },
  { category: "Postleitzahl", value: "02625", alias: "02625" },
  { category: "Postleitzahl", value: "02627", alias: "02627" },
  { category: "Postleitzahl", value: "02633", alias: "02633" },
  { category: "Postleitzahl", value: "02681", alias: "02681" },
  { category: "Postleitzahl", value: "02689", alias: "02689" },
  { category: "Postleitzahl", value: "02692", alias: "02692" },
  { category: "Postleitzahl", value: "02694", alias: "02694" },
  { category: "Postleitzahl", value: "02699", alias: "02699" },
  { category: "Postleitzahl", value: "02708", alias: "02708" },
  { category: "Postleitzahl", value: "02727", alias: "02727" },
  { category: "Postleitzahl", value: "02730", alias: "02730" },
  { category: "Postleitzahl", value: "02733", alias: "02733" },
  { category: "Postleitzahl", value: "02736", alias: "02736" },
  { category: "Postleitzahl", value: "02739", alias: "02739" },
  { category: "Postleitzahl", value: "02742", alias: "02742" },
  { category: "Postleitzahl", value: "02747", alias: "02747" },
  { category: "Postleitzahl", value: "02748", alias: "02748" },
  { category: "Postleitzahl", value: "02763", alias: "02763" },
  { category: "Postleitzahl", value: "02779", alias: "02779" },
  { category: "Postleitzahl", value: "02782", alias: "02782" },
  { category: "Postleitzahl", value: "02785", alias: "02785" },
  { category: "Postleitzahl", value: "02788", alias: "02788" },
  { category: "Postleitzahl", value: "02791", alias: "02791" },
  { category: "Postleitzahl", value: "02794", alias: "02794" },
  { category: "Postleitzahl", value: "02796", alias: "02796" },
  { category: "Postleitzahl", value: "02797", alias: "02797" },
  { category: "Postleitzahl", value: "02799", alias: "02799" },
  { category: "Postleitzahl", value: "02826", alias: "02826" },
  { category: "Postleitzahl", value: "02827", alias: "02827" },
  { category: "Postleitzahl", value: "02828", alias: "02828" },
  { category: "Postleitzahl", value: "02829", alias: "02829" },
  { category: "Postleitzahl", value: "02894", alias: "02894" },
  { category: "Postleitzahl", value: "02899", alias: "02899" },
  { category: "Postleitzahl", value: "02906", alias: "02906" },
  { category: "Postleitzahl", value: "02923", alias: "02923" },
  { category: "Postleitzahl", value: "02929", alias: "02929" },
  { category: "Postleitzahl", value: "02943", alias: "02943" },
  { category: "Postleitzahl", value: "02953", alias: "02953" },
  { category: "Postleitzahl", value: "02956", alias: "02956" },
  { category: "Postleitzahl", value: "02957", alias: "02957" },
  { category: "Postleitzahl", value: "02959", alias: "02959" },
  { category: "Postleitzahl", value: "02977", alias: "02977" },
  { category: "Postleitzahl", value: "02979", alias: "02979" },
  { category: "Postleitzahl", value: "02991", alias: "02991" },
  { category: "Postleitzahl", value: "02994", alias: "02994" },
  { category: "Postleitzahl", value: "02997", alias: "02997" },
  { category: "Postleitzahl", value: "02999", alias: "02999" },
  { category: "Postleitzahl", value: "03042", alias: "03042" },
  { category: "Postleitzahl", value: "03044", alias: "03044" },
  { category: "Postleitzahl", value: "03046", alias: "03046" },
  { category: "Postleitzahl", value: "03048", alias: "03048" },
  { category: "Postleitzahl", value: "03050", alias: "03050" },
  { category: "Postleitzahl", value: "03051", alias: "03051" },
  { category: "Postleitzahl", value: "03052", alias: "03052" },
  { category: "Postleitzahl", value: "03053", alias: "03053" },
  { category: "Postleitzahl", value: "03054", alias: "03054" },
  { category: "Postleitzahl", value: "03055", alias: "03055" },
  { category: "Postleitzahl", value: "03058", alias: "03058" },
  { category: "Postleitzahl", value: "03096", alias: "03096" },
  { category: "Postleitzahl", value: "03099", alias: "03099" },
  { category: "Postleitzahl", value: "03103", alias: "03103" },
  { category: "Postleitzahl", value: "03116", alias: "03116" },
  { category: "Postleitzahl", value: "03119", alias: "03119" },
  { category: "Postleitzahl", value: "03130", alias: "03130" },
  { category: "Postleitzahl", value: "03149", alias: "03149" },
  { category: "Postleitzahl", value: "03159", alias: "03159" },
  { category: "Postleitzahl", value: "03172", alias: "03172" },
  { category: "Postleitzahl", value: "03185", alias: "03185" },
  { category: "Postleitzahl", value: "03197", alias: "03197" },
  { category: "Postleitzahl", value: "03205", alias: "03205" },
  { category: "Postleitzahl", value: "03222", alias: "03222" },
  { category: "Postleitzahl", value: "03226", alias: "03226" },
  { category: "Postleitzahl", value: "03229", alias: "03229" },
  { category: "Postleitzahl", value: "03238", alias: "03238" },
  { category: "Postleitzahl", value: "03246", alias: "03246" },
  { category: "Postleitzahl", value: "03249", alias: "03249" },
  { category: "Postleitzahl", value: "03253", alias: "03253" },
  { category: "Postleitzahl", value: "04103", alias: "04103" },
  { category: "Postleitzahl", value: "04105", alias: "04105" },
  { category: "Postleitzahl", value: "04107", alias: "04107" },
  { category: "Postleitzahl", value: "04109", alias: "04109" },
  { category: "Postleitzahl", value: "04129", alias: "04129" },
  { category: "Postleitzahl", value: "04155", alias: "04155" },
  { category: "Postleitzahl", value: "04157", alias: "04157" },
  { category: "Postleitzahl", value: "04158", alias: "04158" },
  { category: "Postleitzahl", value: "04159", alias: "04159" },
  { category: "Postleitzahl", value: "04177", alias: "04177" },
  { category: "Postleitzahl", value: "04178", alias: "04178" },
  { category: "Postleitzahl", value: "04179", alias: "04179" },
  { category: "Postleitzahl", value: "04205", alias: "04205" },
  { category: "Postleitzahl", value: "04207", alias: "04207" },
  { category: "Postleitzahl", value: "04209", alias: "04209" },
  { category: "Postleitzahl", value: "04229", alias: "04229" },
  { category: "Postleitzahl", value: "04249", alias: "04249" },
  { category: "Postleitzahl", value: "04275", alias: "04275" },
  { category: "Postleitzahl", value: "04277", alias: "04277" },
  { category: "Postleitzahl", value: "04279", alias: "04279" },
  { category: "Postleitzahl", value: "04288", alias: "04288" },
  { category: "Postleitzahl", value: "04289", alias: "04289" },
  { category: "Postleitzahl", value: "04299", alias: "04299" },
  { category: "Postleitzahl", value: "04315", alias: "04315" },
  { category: "Postleitzahl", value: "04316", alias: "04316" },
  { category: "Postleitzahl", value: "04317", alias: "04317" },
  { category: "Postleitzahl", value: "04318", alias: "04318" },
  { category: "Postleitzahl", value: "04319", alias: "04319" },
  { category: "Postleitzahl", value: "04328", alias: "04328" },
  { category: "Postleitzahl", value: "04329", alias: "04329" },
  { category: "Postleitzahl", value: "04347", alias: "04347" },
  { category: "Postleitzahl", value: "04349", alias: "04349" },
  { category: "Postleitzahl", value: "04356", alias: "04356" },
  { category: "Postleitzahl", value: "04357", alias: "04357" },
  { category: "Postleitzahl", value: "04416", alias: "04416" },
  { category: "Postleitzahl", value: "04420", alias: "04420" },
  { category: "Postleitzahl", value: "04425", alias: "04425" },
  { category: "Postleitzahl", value: "04435", alias: "04435" },
  { category: "Postleitzahl", value: "04442", alias: "04442" },
  { category: "Postleitzahl", value: "04451", alias: "04451" },
  { category: "Postleitzahl", value: "04463", alias: "04463" },
  { category: "Postleitzahl", value: "04509", alias: "04509" },
  { category: "Postleitzahl", value: "04519", alias: "04519" },
  { category: "Postleitzahl", value: "04523", alias: "04523" },
  { category: "Postleitzahl", value: "04539", alias: "04539" },
  { category: "Postleitzahl", value: "04552", alias: "04552" },
  { category: "Postleitzahl", value: "04564", alias: "04564" },
  { category: "Postleitzahl", value: "04565", alias: "04565" },
  { category: "Postleitzahl", value: "04567", alias: "04567" },
  { category: "Postleitzahl", value: "04571", alias: "04571" },
  { category: "Postleitzahl", value: "04575", alias: "04575" },
  { category: "Postleitzahl", value: "04600", alias: "04600" },
  { category: "Postleitzahl", value: "04603", alias: "04603" },
  { category: "Postleitzahl", value: "04610", alias: "04610" },
  { category: "Postleitzahl", value: "04613", alias: "04613" },
  { category: "Postleitzahl", value: "04617", alias: "04617" },
  { category: "Postleitzahl", value: "04618", alias: "04618" },
  { category: "Postleitzahl", value: "04626", alias: "04626" },
  { category: "Postleitzahl", value: "04639", alias: "04639" },
  { category: "Postleitzahl", value: "04643", alias: "04643" },
  { category: "Postleitzahl", value: "04651", alias: "04651" },
  { category: "Postleitzahl", value: "04654", alias: "04654" },
  { category: "Postleitzahl", value: "04668", alias: "04668" },
  { category: "Postleitzahl", value: "04680", alias: "04680" },
  { category: "Postleitzahl", value: "04683", alias: "04683" },
  { category: "Postleitzahl", value: "04687", alias: "04687" },
  { category: "Postleitzahl", value: "04703", alias: "04703" },
  { category: "Postleitzahl", value: "04720", alias: "04720" },
  { category: "Postleitzahl", value: "04736", alias: "04736" },
  { category: "Postleitzahl", value: "04741", alias: "04741" },
  { category: "Postleitzahl", value: "04746", alias: "04746" },
  { category: "Postleitzahl", value: "04749", alias: "04749" },
  { category: "Postleitzahl", value: "04758", alias: "04758" },
  { category: "Postleitzahl", value: "04769", alias: "04769" },
  { category: "Postleitzahl", value: "04774", alias: "04774" },
  { category: "Postleitzahl", value: "04779", alias: "04779" },
  { category: "Postleitzahl", value: "04808", alias: "04808" },
  { category: "Postleitzahl", value: "04821", alias: "04821" },
  { category: "Postleitzahl", value: "04824", alias: "04824" },
  { category: "Postleitzahl", value: "04827", alias: "04827" },
  { category: "Postleitzahl", value: "04828", alias: "04828" },
  { category: "Postleitzahl", value: "04838", alias: "04838" },
  { category: "Postleitzahl", value: "04849", alias: "04849" },
  { category: "Postleitzahl", value: "04860", alias: "04860" },
  { category: "Postleitzahl", value: "04861", alias: "04861" },
  { category: "Postleitzahl", value: "04862", alias: "04862" },
  { category: "Postleitzahl", value: "04874", alias: "04874" },
  { category: "Postleitzahl", value: "04880", alias: "04880" },
  { category: "Postleitzahl", value: "04886", alias: "04886" },
  { category: "Postleitzahl", value: "04889", alias: "04889" },
  { category: "Postleitzahl", value: "04895", alias: "04895" },
  { category: "Postleitzahl", value: "04910", alias: "04910" },
  { category: "Postleitzahl", value: "04916", alias: "04916" },
  { category: "Postleitzahl", value: "04924", alias: "04924" },
  { category: "Postleitzahl", value: "04928", alias: "04928" },
  { category: "Postleitzahl", value: "04931", alias: "04931" },
  { category: "Postleitzahl", value: "04932", alias: "04932" },
  { category: "Postleitzahl", value: "04934", alias: "04934" },
  { category: "Postleitzahl", value: "04936", alias: "04936" },
  { category: "Postleitzahl", value: "04938", alias: "04938" },
  { category: "Postleitzahl", value: "06108", alias: "06108" },
  { category: "Postleitzahl", value: "06110", alias: "06110" },
  { category: "Postleitzahl", value: "06112", alias: "06112" },
  { category: "Postleitzahl", value: "06114", alias: "06114" },
  { category: "Postleitzahl", value: "06116", alias: "06116" },
  { category: "Postleitzahl", value: "06118", alias: "06118" },
  { category: "Postleitzahl", value: "06120", alias: "06120" },
  { category: "Postleitzahl", value: "06122", alias: "06122" },
  { category: "Postleitzahl", value: "06124", alias: "06124" },
  { category: "Postleitzahl", value: "06126", alias: "06126" },
  { category: "Postleitzahl", value: "06128", alias: "06128" },
  { category: "Postleitzahl", value: "06130", alias: "06130" },
  { category: "Postleitzahl", value: "06132", alias: "06132" },
  { category: "Postleitzahl", value: "06179", alias: "06179" },
  { category: "Postleitzahl", value: "06184", alias: "06184" },
  { category: "Postleitzahl", value: "06188", alias: "06188" },
  { category: "Postleitzahl", value: "06193", alias: "06193" },
  { category: "Postleitzahl", value: "06198", alias: "06198" },
  { category: "Postleitzahl", value: "06217", alias: "06217" },
  { category: "Postleitzahl", value: "06231", alias: "06231" },
  { category: "Postleitzahl", value: "06237", alias: "06237" },
  { category: "Postleitzahl", value: "06242", alias: "06242" },
  { category: "Postleitzahl", value: "06246", alias: "06246" },
  { category: "Postleitzahl", value: "06249", alias: "06249" },
  { category: "Postleitzahl", value: "06255", alias: "06255" },
  { category: "Postleitzahl", value: "06258", alias: "06258" },
  { category: "Postleitzahl", value: "06259", alias: "06259" },
  { category: "Postleitzahl", value: "06268", alias: "06268" },
  { category: "Postleitzahl", value: "06279", alias: "06279" },
  { category: "Postleitzahl", value: "06295", alias: "06295" },
  { category: "Postleitzahl", value: "06308", alias: "06308" },
  { category: "Postleitzahl", value: "06311", alias: "06311" },
  { category: "Postleitzahl", value: "06313", alias: "06313" },
  { category: "Postleitzahl", value: "06317", alias: "06317" },
  { category: "Postleitzahl", value: "06333", alias: "06333" },
  { category: "Postleitzahl", value: "06343", alias: "06343" },
  { category: "Postleitzahl", value: "06347", alias: "06347" },
  { category: "Postleitzahl", value: "06366", alias: "06366" },
  { category: "Postleitzahl", value: "06369", alias: "06369" },
  { category: "Postleitzahl", value: "06385", alias: "06385" },
  { category: "Postleitzahl", value: "06386", alias: "06386" },
  { category: "Postleitzahl", value: "06388", alias: "06388" },
  { category: "Postleitzahl", value: "06406", alias: "06406" },
  { category: "Postleitzahl", value: "06408", alias: "06408" },
  { category: "Postleitzahl", value: "06420", alias: "06420" },
  { category: "Postleitzahl", value: "06425", alias: "06425" },
  { category: "Postleitzahl", value: "06429", alias: "06429" },
  { category: "Postleitzahl", value: "06449", alias: "06449" },
  { category: "Postleitzahl", value: "06456", alias: "06456" },
  { category: "Postleitzahl", value: "06458", alias: "06458" },
  { category: "Postleitzahl", value: "06463", alias: "06463" },
  { category: "Postleitzahl", value: "06464", alias: "06464" },
  { category: "Postleitzahl", value: "06466", alias: "06466" },
  { category: "Postleitzahl", value: "06467", alias: "06467" },
  { category: "Postleitzahl", value: "06469", alias: "06469" },
  { category: "Postleitzahl", value: "06484", alias: "06484" },
  { category: "Postleitzahl", value: "06485", alias: "06485" },
  { category: "Postleitzahl", value: "06493", alias: "06493" },
  { category: "Postleitzahl", value: "06502", alias: "06502" },
  { category: "Postleitzahl", value: "06526", alias: "06526" },
  { category: "Postleitzahl", value: "06528", alias: "06528" },
  { category: "Postleitzahl", value: "06536", alias: "06536" },
  { category: "Postleitzahl", value: "06537", alias: "06537" },
  { category: "Postleitzahl", value: "06542", alias: "06542" },
  { category: "Postleitzahl", value: "06543", alias: "06543" },
  { category: "Postleitzahl", value: "06556", alias: "06556" },
  { category: "Postleitzahl", value: "06567", alias: "06567" },
  { category: "Postleitzahl", value: "06571", alias: "06571" },
  { category: "Postleitzahl", value: "06577", alias: "06577" },
  { category: "Postleitzahl", value: "06618", alias: "06618" },
  { category: "Postleitzahl", value: "06628", alias: "06628" },
  { category: "Postleitzahl", value: "06632", alias: "06632" },
  { category: "Postleitzahl", value: "06636", alias: "06636" },
  { category: "Postleitzahl", value: "06638", alias: "06638" },
  { category: "Postleitzahl", value: "06642", alias: "06642" },
  { category: "Postleitzahl", value: "06647", alias: "06647" },
  { category: "Postleitzahl", value: "06648", alias: "06648" },
  { category: "Postleitzahl", value: "06667", alias: "06667" },
  { category: "Postleitzahl", value: "06679", alias: "06679" },
  { category: "Postleitzahl", value: "06682", alias: "06682" },
  { category: "Postleitzahl", value: "06686", alias: "06686" },
  { category: "Postleitzahl", value: "06688", alias: "06688" },
  { category: "Postleitzahl", value: "06711", alias: "06711" },
  { category: "Postleitzahl", value: "06712", alias: "06712" },
  { category: "Postleitzahl", value: "06721", alias: "06721" },
  { category: "Postleitzahl", value: "06722", alias: "06722" },
  { category: "Postleitzahl", value: "06729", alias: "06729" },
  { category: "Postleitzahl", value: "06749", alias: "06749" },
  { category: "Postleitzahl", value: "06766", alias: "06766" },
  { category: "Postleitzahl", value: "06772", alias: "06772" },
  { category: "Postleitzahl", value: "06773", alias: "06773" },
  { category: "Postleitzahl", value: "06774", alias: "06774" },
  { category: "Postleitzahl", value: "06779", alias: "06779" },
  { category: "Postleitzahl", value: "06780", alias: "06780" },
  { category: "Postleitzahl", value: "06785", alias: "06785" },
  { category: "Postleitzahl", value: "06792", alias: "06792" },
  { category: "Postleitzahl", value: "06794", alias: "06794" },
  { category: "Postleitzahl", value: "06796", alias: "06796" },
  { category: "Postleitzahl", value: "06800", alias: "06800" },
  { category: "Postleitzahl", value: "06803", alias: "06803" },
  { category: "Postleitzahl", value: "06808", alias: "06808" },
  { category: "Postleitzahl", value: "06809", alias: "06809" },
  { category: "Postleitzahl", value: "06842", alias: "06842" },
  { category: "Postleitzahl", value: "06844", alias: "06844" },
  { category: "Postleitzahl", value: "06846", alias: "06846" },
  { category: "Postleitzahl", value: "06847", alias: "06847" },
  { category: "Postleitzahl", value: "06849", alias: "06849" },
  { category: "Postleitzahl", value: "06861", alias: "06861" },
  { category: "Postleitzahl", value: "06862", alias: "06862" },
  { category: "Postleitzahl", value: "06868", alias: "06868" },
  { category: "Postleitzahl", value: "06869", alias: "06869" },
  { category: "Postleitzahl", value: "06886", alias: "06886" },
  { category: "Postleitzahl", value: "06888", alias: "06888" },
  { category: "Postleitzahl", value: "06889", alias: "06889" },
  { category: "Postleitzahl", value: "06895", alias: "06895" },
  { category: "Postleitzahl", value: "06901", alias: "06901" },
  { category: "Postleitzahl", value: "06905", alias: "06905" },
  { category: "Postleitzahl", value: "06917", alias: "06917" },
  { category: "Postleitzahl", value: "06925", alias: "06925" },
  { category: "Postleitzahl", value: "07318", alias: "07318" },
  { category: "Postleitzahl", value: "07330", alias: "07330" },
  { category: "Postleitzahl", value: "07333", alias: "07333" },
  { category: "Postleitzahl", value: "07338", alias: "07338" },
  { category: "Postleitzahl", value: "07343", alias: "07343" },
  { category: "Postleitzahl", value: "07349", alias: "07349" },
  { category: "Postleitzahl", value: "07356", alias: "07356" },
  { category: "Postleitzahl", value: "07366", alias: "07366" },
  { category: "Postleitzahl", value: "07368", alias: "07368" },
  { category: "Postleitzahl", value: "07381", alias: "07381" },
  { category: "Postleitzahl", value: "07387", alias: "07387" },
  { category: "Postleitzahl", value: "07389", alias: "07389" },
  { category: "Postleitzahl", value: "07407", alias: "07407" },
  { category: "Postleitzahl", value: "07422", alias: "07422" },
  { category: "Postleitzahl", value: "07426", alias: "07426" },
  { category: "Postleitzahl", value: "07427", alias: "07427" },
  { category: "Postleitzahl", value: "07429", alias: "07429" },
  { category: "Postleitzahl", value: "07545", alias: "07545" },
  { category: "Postleitzahl", value: "07546", alias: "07546" },
  { category: "Postleitzahl", value: "07548", alias: "07548" },
  { category: "Postleitzahl", value: "07549", alias: "07549" },
  { category: "Postleitzahl", value: "07551", alias: "07551" },
  { category: "Postleitzahl", value: "07552", alias: "07552" },
  { category: "Postleitzahl", value: "07554", alias: "07554" },
  { category: "Postleitzahl", value: "07557", alias: "07557" },
  { category: "Postleitzahl", value: "07570", alias: "07570" },
  { category: "Postleitzahl", value: "07580", alias: "07580" },
  { category: "Postleitzahl", value: "07586", alias: "07586" },
  { category: "Postleitzahl", value: "07589", alias: "07589" },
  { category: "Postleitzahl", value: "07607", alias: "07607" },
  { category: "Postleitzahl", value: "07613", alias: "07613" },
  { category: "Postleitzahl", value: "07616", alias: "07616" },
  { category: "Postleitzahl", value: "07619", alias: "07619" },
  { category: "Postleitzahl", value: "07629", alias: "07629" },
  { category: "Postleitzahl", value: "07639", alias: "07639" },
  { category: "Postleitzahl", value: "07646", alias: "07646" },
  { category: "Postleitzahl", value: "07743", alias: "07743" },
  { category: "Postleitzahl", value: "07745", alias: "07745" },
  { category: "Postleitzahl", value: "07747", alias: "07747" },
  { category: "Postleitzahl", value: "07749", alias: "07749" },
  { category: "Postleitzahl", value: "07751", alias: "07751" },
  { category: "Postleitzahl", value: "07768", alias: "07768" },
  { category: "Postleitzahl", value: "07774", alias: "07774" },
  { category: "Postleitzahl", value: "07778", alias: "07778" },
  { category: "Postleitzahl", value: "07806", alias: "07806" },
  { category: "Postleitzahl", value: "07819", alias: "07819" },
  { category: "Postleitzahl", value: "07907", alias: "07907" },
  { category: "Postleitzahl", value: "07919", alias: "07919" },
  { category: "Postleitzahl", value: "07922", alias: "07922" },
  { category: "Postleitzahl", value: "07924", alias: "07924" },
  { category: "Postleitzahl", value: "07926", alias: "07926" },
  { category: "Postleitzahl", value: "07927", alias: "07927" },
  { category: "Postleitzahl", value: "07929", alias: "07929" },
  { category: "Postleitzahl", value: "07937", alias: "07937" },
  { category: "Postleitzahl", value: "07950", alias: "07950" },
  { category: "Postleitzahl", value: "07952", alias: "07952" },
  { category: "Postleitzahl", value: "07955", alias: "07955" },
  { category: "Postleitzahl", value: "07957", alias: "07957" },
  { category: "Postleitzahl", value: "07958", alias: "07958" },
  { category: "Postleitzahl", value: "07973", alias: "07973" },
  { category: "Postleitzahl", value: "07980", alias: "07980" },
  { category: "Postleitzahl", value: "07985", alias: "07985" },
  { category: "Postleitzahl", value: "07987", alias: "07987" },
  { category: "Postleitzahl", value: "08056", alias: "08056" },
  { category: "Postleitzahl", value: "08058", alias: "08058" },
  { category: "Postleitzahl", value: "08060", alias: "08060" },
  { category: "Postleitzahl", value: "08062", alias: "08062" },
  { category: "Postleitzahl", value: "08064", alias: "08064" },
  { category: "Postleitzahl", value: "08066", alias: "08066" },
  { category: "Postleitzahl", value: "08107", alias: "08107" },
  { category: "Postleitzahl", value: "08112", alias: "08112" },
  { category: "Postleitzahl", value: "08115", alias: "08115" },
  { category: "Postleitzahl", value: "08118", alias: "08118" },
  { category: "Postleitzahl", value: "08132", alias: "08132" },
  { category: "Postleitzahl", value: "08134", alias: "08134" },
  { category: "Postleitzahl", value: "08141", alias: "08141" },
  { category: "Postleitzahl", value: "08144", alias: "08144" },
  { category: "Postleitzahl", value: "08147", alias: "08147" },
  { category: "Postleitzahl", value: "08209", alias: "08209" },
  { category: "Postleitzahl", value: "08223", alias: "08223" },
  { category: "Postleitzahl", value: "08228", alias: "08228" },
  { category: "Postleitzahl", value: "08233", alias: "08233" },
  { category: "Postleitzahl", value: "08236", alias: "08236" },
  { category: "Postleitzahl", value: "08237", alias: "08237" },
  { category: "Postleitzahl", value: "08239", alias: "08239" },
  { category: "Postleitzahl", value: "08248", alias: "08248" },
  { category: "Postleitzahl", value: "08258", alias: "08258" },
  { category: "Postleitzahl", value: "08261", alias: "08261" },
  { category: "Postleitzahl", value: "08262", alias: "08262" },
  { category: "Postleitzahl", value: "08267", alias: "08267" },
  { category: "Postleitzahl", value: "08280", alias: "08280" },
  { category: "Postleitzahl", value: "08289", alias: "08289" },
  { category: "Postleitzahl", value: "08294", alias: "08294" },
  { category: "Postleitzahl", value: "08297", alias: "08297" },
  { category: "Postleitzahl", value: "08301", alias: "08301" },
  { category: "Postleitzahl", value: "08304", alias: "08304" },
  { category: "Postleitzahl", value: "08309", alias: "08309" },
  { category: "Postleitzahl", value: "08315", alias: "08315" },
  { category: "Postleitzahl", value: "08321", alias: "08321" },
  { category: "Postleitzahl", value: "08324", alias: "08324" },
  { category: "Postleitzahl", value: "08328", alias: "08328" },
  { category: "Postleitzahl", value: "08340", alias: "08340" },
  { category: "Postleitzahl", value: "08344", alias: "08344" },
  { category: "Postleitzahl", value: "08349", alias: "08349" },
  { category: "Postleitzahl", value: "08352", alias: "08352" },
  { category: "Postleitzahl", value: "08359", alias: "08359" },
  { category: "Postleitzahl", value: "08371", alias: "08371" },
  { category: "Postleitzahl", value: "08373", alias: "08373" },
  { category: "Postleitzahl", value: "08393", alias: "08393" },
  { category: "Postleitzahl", value: "08396", alias: "08396" },
  { category: "Postleitzahl", value: "08412", alias: "08412" },
  { category: "Postleitzahl", value: "08427", alias: "08427" },
  { category: "Postleitzahl", value: "08428", alias: "08428" },
  { category: "Postleitzahl", value: "08451", alias: "08451" },
  { category: "Postleitzahl", value: "08459", alias: "08459" },
  { category: "Postleitzahl", value: "08468", alias: "08468" },
  { category: "Postleitzahl", value: "08485", alias: "08485" },
  { category: "Postleitzahl", value: "08491", alias: "08491" },
  { category: "Postleitzahl", value: "08496", alias: "08496" },
  { category: "Postleitzahl", value: "08499", alias: "08499" },
  { category: "Postleitzahl", value: "08523", alias: "08523" },
  { category: "Postleitzahl", value: "08525", alias: "08525" },
  { category: "Postleitzahl", value: "08527", alias: "08527" },
  { category: "Postleitzahl", value: "08529", alias: "08529" },
  { category: "Postleitzahl", value: "08538", alias: "08538" },
  { category: "Postleitzahl", value: "08539", alias: "08539" },
  { category: "Postleitzahl", value: "08541", alias: "08541" },
  { category: "Postleitzahl", value: "08543", alias: "08543" },
  { category: "Postleitzahl", value: "08547", alias: "08547" },
  { category: "Postleitzahl", value: "08548", alias: "08548" },
  { category: "Postleitzahl", value: "08606", alias: "08606" },
  { category: "Postleitzahl", value: "08626", alias: "08626" },
  { category: "Postleitzahl", value: "08645", alias: "08645" },
  { category: "Postleitzahl", value: "08648", alias: "08648" },
  { category: "Postleitzahl", value: "09111", alias: "09111" },
  { category: "Postleitzahl", value: "09112", alias: "09112" },
  { category: "Postleitzahl", value: "09113", alias: "09113" },
  { category: "Postleitzahl", value: "09114", alias: "09114" },
  { category: "Postleitzahl", value: "09116", alias: "09116" },
  { category: "Postleitzahl", value: "09117", alias: "09117" },
  { category: "Postleitzahl", value: "09119", alias: "09119" },
  { category: "Postleitzahl", value: "09120", alias: "09120" },
  { category: "Postleitzahl", value: "09122", alias: "09122" },
  { category: "Postleitzahl", value: "09123", alias: "09123" },
  { category: "Postleitzahl", value: "09125", alias: "09125" },
  { category: "Postleitzahl", value: "09126", alias: "09126" },
  { category: "Postleitzahl", value: "09127", alias: "09127" },
  { category: "Postleitzahl", value: "09128", alias: "09128" },
  { category: "Postleitzahl", value: "09130", alias: "09130" },
  { category: "Postleitzahl", value: "09131", alias: "09131" },
  { category: "Postleitzahl", value: "09212", alias: "09212" },
  { category: "Postleitzahl", value: "09217", alias: "09217" },
  { category: "Postleitzahl", value: "09221", alias: "09221" },
  { category: "Postleitzahl", value: "09224", alias: "09224" },
  { category: "Postleitzahl", value: "09228", alias: "09228" },
  { category: "Postleitzahl", value: "09232", alias: "09232" },
  { category: "Postleitzahl", value: "09235", alias: "09235" },
  { category: "Postleitzahl", value: "09236", alias: "09236" },
  { category: "Postleitzahl", value: "09241", alias: "09241" },
  { category: "Postleitzahl", value: "09243", alias: "09243" },
  { category: "Postleitzahl", value: "09244", alias: "09244" },
  { category: "Postleitzahl", value: "09247", alias: "09247" },
  { category: "Postleitzahl", value: "09249", alias: "09249" },
  { category: "Postleitzahl", value: "09306", alias: "09306" },
  { category: "Postleitzahl", value: "09322", alias: "09322" },
  { category: "Postleitzahl", value: "09326", alias: "09326" },
  { category: "Postleitzahl", value: "09328", alias: "09328" },
  { category: "Postleitzahl", value: "09337", alias: "09337" },
  { category: "Postleitzahl", value: "09350", alias: "09350" },
  { category: "Postleitzahl", value: "09353", alias: "09353" },
  { category: "Postleitzahl", value: "09355", alias: "09355" },
  { category: "Postleitzahl", value: "09356", alias: "09356" },
  { category: "Postleitzahl", value: "09366", alias: "09366" },
  { category: "Postleitzahl", value: "09376", alias: "09376" },
  { category: "Postleitzahl", value: "09380", alias: "09380" },
  { category: "Postleitzahl", value: "09385", alias: "09385" },
  { category: "Postleitzahl", value: "09387", alias: "09387" },
  { category: "Postleitzahl", value: "09390", alias: "09390" },
  { category: "Postleitzahl", value: "09392", alias: "09392" },
  { category: "Postleitzahl", value: "09394", alias: "09394" },
  { category: "Postleitzahl", value: "09399", alias: "09399" },
  { category: "Postleitzahl", value: "09405", alias: "09405" },
  { category: "Postleitzahl", value: "09419", alias: "09419" },
  { category: "Postleitzahl", value: "09423", alias: "09423" },
  { category: "Postleitzahl", value: "09427", alias: "09427" },
  { category: "Postleitzahl", value: "09429", alias: "09429" },
  { category: "Postleitzahl", value: "09430", alias: "09430" },
  { category: "Postleitzahl", value: "09432", alias: "09432" },
  { category: "Postleitzahl", value: "09434", alias: "09434" },
  { category: "Postleitzahl", value: "09437", alias: "09437" },
  { category: "Postleitzahl", value: "09439", alias: "09439" },
  { category: "Postleitzahl", value: "09456", alias: "09456" },
  { category: "Postleitzahl", value: "09465", alias: "09465" },
  { category: "Postleitzahl", value: "09468", alias: "09468" },
  { category: "Postleitzahl", value: "09471", alias: "09471" },
  { category: "Postleitzahl", value: "09474", alias: "09474" },
  { category: "Postleitzahl", value: "09477", alias: "09477" },
  { category: "Postleitzahl", value: "09481", alias: "09481" },
  { category: "Postleitzahl", value: "09484", alias: "09484" },
  { category: "Postleitzahl", value: "09487", alias: "09487" },
  { category: "Postleitzahl", value: "09488", alias: "09488" },
  { category: "Postleitzahl", value: "09496", alias: "09496" },
  { category: "Postleitzahl", value: "09509", alias: "09509" },
  { category: "Postleitzahl", value: "09514", alias: "09514" },
  { category: "Postleitzahl", value: "09518", alias: "09518" },
  { category: "Postleitzahl", value: "09526", alias: "09526" },
  { category: "Postleitzahl", value: "09544", alias: "09544" },
  { category: "Postleitzahl", value: "09548", alias: "09548" },
  { category: "Postleitzahl", value: "09557", alias: "09557" },
  { category: "Postleitzahl", value: "09569", alias: "09569" },
  { category: "Postleitzahl", value: "09573", alias: "09573" },
  { category: "Postleitzahl", value: "09575", alias: "09575" },
  { category: "Postleitzahl", value: "09577", alias: "09577" },
  { category: "Postleitzahl", value: "09579", alias: "09579" },
  { category: "Postleitzahl", value: "09599", alias: "09599" },
  { category: "Postleitzahl", value: "09600", alias: "09600" },
  { category: "Postleitzahl", value: "09603", alias: "09603" },
  { category: "Postleitzahl", value: "09618", alias: "09618" },
  { category: "Postleitzahl", value: "09619", alias: "09619" },
  { category: "Postleitzahl", value: "09623", alias: "09623" },
  { category: "Postleitzahl", value: "09627", alias: "09627" },
  { category: "Postleitzahl", value: "09629", alias: "09629" },
  { category: "Postleitzahl", value: "09633", alias: "09633" },
  { category: "Postleitzahl", value: "09634", alias: "09634" },
  { category: "Postleitzahl", value: "09638", alias: "09638" },
  { category: "Postleitzahl", value: "09648", alias: "09648" },
  { category: "Postleitzahl", value: "09661", alias: "09661" },
  { category: "Postleitzahl", value: "09669", alias: "09669" },
  { category: "Postleitzahl", value: "10115", alias: "10115" },
  { category: "Postleitzahl", value: "10117", alias: "10117" },
  { category: "Postleitzahl", value: "10119", alias: "10119" },
  { category: "Postleitzahl", value: "10178", alias: "10178" },
  { category: "Postleitzahl", value: "10179", alias: "10179" },
  { category: "Postleitzahl", value: "10243", alias: "10243" },
  { category: "Postleitzahl", value: "10245", alias: "10245" },
  { category: "Postleitzahl", value: "10247", alias: "10247" },
  { category: "Postleitzahl", value: "10249", alias: "10249" },
  { category: "Postleitzahl", value: "10315", alias: "10315" },
  { category: "Postleitzahl", value: "10317", alias: "10317" },
  { category: "Postleitzahl", value: "10318", alias: "10318" },
  { category: "Postleitzahl", value: "10319", alias: "10319" },
  { category: "Postleitzahl", value: "10365", alias: "10365" },
  { category: "Postleitzahl", value: "10367", alias: "10367" },
  { category: "Postleitzahl", value: "10369", alias: "10369" },
  { category: "Postleitzahl", value: "10405", alias: "10405" },
  { category: "Postleitzahl", value: "10407", alias: "10407" },
  { category: "Postleitzahl", value: "10409", alias: "10409" },
  { category: "Postleitzahl", value: "10435", alias: "10435" },
  { category: "Postleitzahl", value: "10437", alias: "10437" },
  { category: "Postleitzahl", value: "10439", alias: "10439" },
  { category: "Postleitzahl", value: "10551", alias: "10551" },
  { category: "Postleitzahl", value: "10553", alias: "10553" },
  { category: "Postleitzahl", value: "10555", alias: "10555" },
  { category: "Postleitzahl", value: "10557", alias: "10557" },
  { category: "Postleitzahl", value: "10559", alias: "10559" },
  { category: "Postleitzahl", value: "10585", alias: "10585" },
  { category: "Postleitzahl", value: "10587", alias: "10587" },
  { category: "Postleitzahl", value: "10589", alias: "10589" },
  { category: "Postleitzahl", value: "10623", alias: "10623" },
  { category: "Postleitzahl", value: "10625", alias: "10625" },
  { category: "Postleitzahl", value: "10627", alias: "10627" },
  { category: "Postleitzahl", value: "10629", alias: "10629" },
  { category: "Postleitzahl", value: "10707", alias: "10707" },
  { category: "Postleitzahl", value: "10709", alias: "10709" },
  { category: "Postleitzahl", value: "10711", alias: "10711" },
  { category: "Postleitzahl", value: "10713", alias: "10713" },
  { category: "Postleitzahl", value: "10715", alias: "10715" },
  { category: "Postleitzahl", value: "10717", alias: "10717" },
  { category: "Postleitzahl", value: "10719", alias: "10719" },
  { category: "Postleitzahl", value: "10777", alias: "10777" },
  { category: "Postleitzahl", value: "10779", alias: "10779" },
  { category: "Postleitzahl", value: "10781", alias: "10781" },
  { category: "Postleitzahl", value: "10783", alias: "10783" },
  { category: "Postleitzahl", value: "10785", alias: "10785" },
  { category: "Postleitzahl", value: "10787", alias: "10787" },
  { category: "Postleitzahl", value: "10789", alias: "10789" },
  { category: "Postleitzahl", value: "10823", alias: "10823" },
  { category: "Postleitzahl", value: "10825", alias: "10825" },
  { category: "Postleitzahl", value: "10827", alias: "10827" },
  { category: "Postleitzahl", value: "10829", alias: "10829" },
  { category: "Postleitzahl", value: "10961", alias: "10961" },
  { category: "Postleitzahl", value: "10963", alias: "10963" },
  { category: "Postleitzahl", value: "10965", alias: "10965" },
  { category: "Postleitzahl", value: "10967", alias: "10967" },
  { category: "Postleitzahl", value: "10969", alias: "10969" },
  { category: "Postleitzahl", value: "10997", alias: "10997" },
  { category: "Postleitzahl", value: "10999", alias: "10999" },
  { category: "Postleitzahl", value: "12043", alias: "12043" },
  { category: "Postleitzahl", value: "12045", alias: "12045" },
  { category: "Postleitzahl", value: "12047", alias: "12047" },
  { category: "Postleitzahl", value: "12049", alias: "12049" },
  { category: "Postleitzahl", value: "12051", alias: "12051" },
  { category: "Postleitzahl", value: "12053", alias: "12053" },
  { category: "Postleitzahl", value: "12055", alias: "12055" },
  { category: "Postleitzahl", value: "12057", alias: "12057" },
  { category: "Postleitzahl", value: "12059", alias: "12059" },
  { category: "Postleitzahl", value: "12099", alias: "12099" },
  { category: "Postleitzahl", value: "12101", alias: "12101" },
  { category: "Postleitzahl", value: "12103", alias: "12103" },
  { category: "Postleitzahl", value: "12105", alias: "12105" },
  { category: "Postleitzahl", value: "12107", alias: "12107" },
  { category: "Postleitzahl", value: "12109", alias: "12109" },
  { category: "Postleitzahl", value: "12157", alias: "12157" },
  { category: "Postleitzahl", value: "12159", alias: "12159" },
  { category: "Postleitzahl", value: "12161", alias: "12161" },
  { category: "Postleitzahl", value: "12163", alias: "12163" },
  { category: "Postleitzahl", value: "12165", alias: "12165" },
  { category: "Postleitzahl", value: "12167", alias: "12167" },
  { category: "Postleitzahl", value: "12169", alias: "12169" },
  { category: "Postleitzahl", value: "12203", alias: "12203" },
  { category: "Postleitzahl", value: "12205", alias: "12205" },
  { category: "Postleitzahl", value: "12207", alias: "12207" },
  { category: "Postleitzahl", value: "12209", alias: "12209" },
  { category: "Postleitzahl", value: "12247", alias: "12247" },
  { category: "Postleitzahl", value: "12249", alias: "12249" },
  { category: "Postleitzahl", value: "12277", alias: "12277" },
  { category: "Postleitzahl", value: "12279", alias: "12279" },
  { category: "Postleitzahl", value: "12305", alias: "12305" },
  { category: "Postleitzahl", value: "12307", alias: "12307" },
  { category: "Postleitzahl", value: "12309", alias: "12309" },
  { category: "Postleitzahl", value: "12347", alias: "12347" },
  { category: "Postleitzahl", value: "12349", alias: "12349" },
  { category: "Postleitzahl", value: "12351", alias: "12351" },
  { category: "Postleitzahl", value: "12353", alias: "12353" },
  { category: "Postleitzahl", value: "12355", alias: "12355" },
  { category: "Postleitzahl", value: "12357", alias: "12357" },
  { category: "Postleitzahl", value: "12359", alias: "12359" },
  { category: "Postleitzahl", value: "12435", alias: "12435" },
  { category: "Postleitzahl", value: "12437", alias: "12437" },
  { category: "Postleitzahl", value: "12439", alias: "12439" },
  { category: "Postleitzahl", value: "12459", alias: "12459" },
  { category: "Postleitzahl", value: "12487", alias: "12487" },
  { category: "Postleitzahl", value: "12489", alias: "12489" },
  { category: "Postleitzahl", value: "12524", alias: "12524" },
  { category: "Postleitzahl", value: "12526", alias: "12526" },
  { category: "Postleitzahl", value: "12527", alias: "12527" },
  { category: "Postleitzahl", value: "12529", alias: "12529" },
  { category: "Postleitzahl", value: "12555", alias: "12555" },
  { category: "Postleitzahl", value: "12557", alias: "12557" },
  { category: "Postleitzahl", value: "12559", alias: "12559" },
  { category: "Postleitzahl", value: "12587", alias: "12587" },
  { category: "Postleitzahl", value: "12589", alias: "12589" },
  { category: "Postleitzahl", value: "12619", alias: "12619" },
  { category: "Postleitzahl", value: "12621", alias: "12621" },
  { category: "Postleitzahl", value: "12623", alias: "12623" },
  { category: "Postleitzahl", value: "12627", alias: "12627" },
  { category: "Postleitzahl", value: "12629", alias: "12629" },
  { category: "Postleitzahl", value: "12679", alias: "12679" },
  { category: "Postleitzahl", value: "12681", alias: "12681" },
  { category: "Postleitzahl", value: "12683", alias: "12683" },
  { category: "Postleitzahl", value: "12685", alias: "12685" },
  { category: "Postleitzahl", value: "12687", alias: "12687" },
  { category: "Postleitzahl", value: "12689", alias: "12689" },
  { category: "Postleitzahl", value: "13051", alias: "13051" },
  { category: "Postleitzahl", value: "13053", alias: "13053" },
  { category: "Postleitzahl", value: "13055", alias: "13055" },
  { category: "Postleitzahl", value: "13057", alias: "13057" },
  { category: "Postleitzahl", value: "13059", alias: "13059" },
  { category: "Postleitzahl", value: "13086", alias: "13086" },
  { category: "Postleitzahl", value: "13088", alias: "13088" },
  { category: "Postleitzahl", value: "13089", alias: "13089" },
  { category: "Postleitzahl", value: "13125", alias: "13125" },
  { category: "Postleitzahl", value: "13127", alias: "13127" },
  { category: "Postleitzahl", value: "13129", alias: "13129" },
  { category: "Postleitzahl", value: "13156", alias: "13156" },
  { category: "Postleitzahl", value: "13158", alias: "13158" },
  { category: "Postleitzahl", value: "13159", alias: "13159" },
  { category: "Postleitzahl", value: "13187", alias: "13187" },
  { category: "Postleitzahl", value: "13189", alias: "13189" },
  { category: "Postleitzahl", value: "13347", alias: "13347" },
  { category: "Postleitzahl", value: "13349", alias: "13349" },
  { category: "Postleitzahl", value: "13351", alias: "13351" },
  { category: "Postleitzahl", value: "13353", alias: "13353" },
  { category: "Postleitzahl", value: "13355", alias: "13355" },
  { category: "Postleitzahl", value: "13357", alias: "13357" },
  { category: "Postleitzahl", value: "13359", alias: "13359" },
  { category: "Postleitzahl", value: "13403", alias: "13403" },
  { category: "Postleitzahl", value: "13405", alias: "13405" },
  { category: "Postleitzahl", value: "13407", alias: "13407" },
  { category: "Postleitzahl", value: "13409", alias: "13409" },
  { category: "Postleitzahl", value: "13435", alias: "13435" },
  { category: "Postleitzahl", value: "13437", alias: "13437" },
  { category: "Postleitzahl", value: "13439", alias: "13439" },
  { category: "Postleitzahl", value: "13465", alias: "13465" },
  { category: "Postleitzahl", value: "13467", alias: "13467" },
  { category: "Postleitzahl", value: "13469", alias: "13469" },
  { category: "Postleitzahl", value: "13503", alias: "13503" },
  { category: "Postleitzahl", value: "13505", alias: "13505" },
  { category: "Postleitzahl", value: "13507", alias: "13507" },
  { category: "Postleitzahl", value: "13509", alias: "13509" },
  { category: "Postleitzahl", value: "13581", alias: "13581" },
  { category: "Postleitzahl", value: "13583", alias: "13583" },
  { category: "Postleitzahl", value: "13585", alias: "13585" },
  { category: "Postleitzahl", value: "13587", alias: "13587" },
  { category: "Postleitzahl", value: "13589", alias: "13589" },
  { category: "Postleitzahl", value: "13591", alias: "13591" },
  { category: "Postleitzahl", value: "13593", alias: "13593" },
  { category: "Postleitzahl", value: "13595", alias: "13595" },
  { category: "Postleitzahl", value: "13597", alias: "13597" },
  { category: "Postleitzahl", value: "13599", alias: "13599" },
  { category: "Postleitzahl", value: "13627", alias: "13627" },
  { category: "Postleitzahl", value: "13629", alias: "13629" },
  { category: "Postleitzahl", value: "14050", alias: "14050" },
  { category: "Postleitzahl", value: "14052", alias: "14052" },
  { category: "Postleitzahl", value: "14053", alias: "14053" },
  { category: "Postleitzahl", value: "14055", alias: "14055" },
  { category: "Postleitzahl", value: "14057", alias: "14057" },
  { category: "Postleitzahl", value: "14059", alias: "14059" },
  { category: "Postleitzahl", value: "14089", alias: "14089" },
  { category: "Postleitzahl", value: "14109", alias: "14109" },
  { category: "Postleitzahl", value: "14129", alias: "14129" },
  { category: "Postleitzahl", value: "14163", alias: "14163" },
  { category: "Postleitzahl", value: "14165", alias: "14165" },
  { category: "Postleitzahl", value: "14167", alias: "14167" },
  { category: "Postleitzahl", value: "14169", alias: "14169" },
  { category: "Postleitzahl", value: "14193", alias: "14193" },
  { category: "Postleitzahl", value: "14195", alias: "14195" },
  { category: "Postleitzahl", value: "14197", alias: "14197" },
  { category: "Postleitzahl", value: "14199", alias: "14199" },
  { category: "Postleitzahl", value: "14467", alias: "14467" },
  { category: "Postleitzahl", value: "14469", alias: "14469" },
  { category: "Postleitzahl", value: "14471", alias: "14471" },
  { category: "Postleitzahl", value: "14473", alias: "14473" },
  { category: "Postleitzahl", value: "14476", alias: "14476" },
  { category: "Postleitzahl", value: "14478", alias: "14478" },
  { category: "Postleitzahl", value: "14480", alias: "14480" },
  { category: "Postleitzahl", value: "14482", alias: "14482" },
  { category: "Postleitzahl", value: "14513", alias: "14513" },
  { category: "Postleitzahl", value: "14532", alias: "14532" },
  { category: "Postleitzahl", value: "14542", alias: "14542" },
  { category: "Postleitzahl", value: "14547", alias: "14547" },
  { category: "Postleitzahl", value: "14548", alias: "14548" },
  { category: "Postleitzahl", value: "14550", alias: "14550" },
  { category: "Postleitzahl", value: "14552", alias: "14552" },
  { category: "Postleitzahl", value: "14554", alias: "14554" },
  { category: "Postleitzahl", value: "14558", alias: "14558" },
  { category: "Postleitzahl", value: "14612", alias: "14612" },
  { category: "Postleitzahl", value: "14621", alias: "14621" },
  { category: "Postleitzahl", value: "14624", alias: "14624" },
  { category: "Postleitzahl", value: "14641", alias: "14641" },
  { category: "Postleitzahl", value: "14656", alias: "14656" },
  { category: "Postleitzahl", value: "14662", alias: "14662" },
  { category: "Postleitzahl", value: "14669", alias: "14669" },
  { category: "Postleitzahl", value: "14712", alias: "14712" },
  { category: "Postleitzahl", value: "14715", alias: "14715" },
  { category: "Postleitzahl", value: "14727", alias: "14727" },
  { category: "Postleitzahl", value: "14728", alias: "14728" },
  { category: "Postleitzahl", value: "14770", alias: "14770" },
  { category: "Postleitzahl", value: "14772", alias: "14772" },
  { category: "Postleitzahl", value: "14774", alias: "14774" },
  { category: "Postleitzahl", value: "14776", alias: "14776" },
  { category: "Postleitzahl", value: "14778", alias: "14778" },
  { category: "Postleitzahl", value: "14789", alias: "14789" },
  { category: "Postleitzahl", value: "14793", alias: "14793" },
  { category: "Postleitzahl", value: "14797", alias: "14797" },
  { category: "Postleitzahl", value: "14798", alias: "14798" },
  { category: "Postleitzahl", value: "14806", alias: "14806" },
  { category: "Postleitzahl", value: "14822", alias: "14822" },
  { category: "Postleitzahl", value: "14823", alias: "14823" },
  { category: "Postleitzahl", value: "14827", alias: "14827" },
  { category: "Postleitzahl", value: "14828", alias: "14828" },
  { category: "Postleitzahl", value: "14913", alias: "14913" },
  { category: "Postleitzahl", value: "14929", alias: "14929" },
  { category: "Postleitzahl", value: "14943", alias: "14943" },
  { category: "Postleitzahl", value: "14947", alias: "14947" },
  { category: "Postleitzahl", value: "14959", alias: "14959" },
  { category: "Postleitzahl", value: "14974", alias: "14974" },
  { category: "Postleitzahl", value: "14979", alias: "14979" },
  { category: "Postleitzahl", value: "15230", alias: "15230" },
  { category: "Postleitzahl", value: "15232", alias: "15232" },
  { category: "Postleitzahl", value: "15234", alias: "15234" },
  { category: "Postleitzahl", value: "15236", alias: "15236" },
  { category: "Postleitzahl", value: "15295", alias: "15295" },
  { category: "Postleitzahl", value: "15299", alias: "15299" },
  { category: "Postleitzahl", value: "15306", alias: "15306" },
  { category: "Postleitzahl", value: "15320", alias: "15320" },
  { category: "Postleitzahl", value: "15324", alias: "15324" },
  { category: "Postleitzahl", value: "15326", alias: "15326" },
  { category: "Postleitzahl", value: "15328", alias: "15328" },
  { category: "Postleitzahl", value: "15344", alias: "15344" },
  { category: "Postleitzahl", value: "15345", alias: "15345" },
  { category: "Postleitzahl", value: "15366", alias: "15366" },
  { category: "Postleitzahl", value: "15370", alias: "15370" },
  { category: "Postleitzahl", value: "15374", alias: "15374" },
  { category: "Postleitzahl", value: "15377", alias: "15377" },
  { category: "Postleitzahl", value: "15378", alias: "15378" },
  { category: "Postleitzahl", value: "15517", alias: "15517" },
  { category: "Postleitzahl", value: "15518", alias: "15518" },
  { category: "Postleitzahl", value: "15526", alias: "15526" },
  { category: "Postleitzahl", value: "15528", alias: "15528" },
  { category: "Postleitzahl", value: "15537", alias: "15537" },
  { category: "Postleitzahl", value: "15562", alias: "15562" },
  { category: "Postleitzahl", value: "15566", alias: "15566" },
  { category: "Postleitzahl", value: "15569", alias: "15569" },
  { category: "Postleitzahl", value: "15711", alias: "15711" },
  { category: "Postleitzahl", value: "15712", alias: "15712" },
  { category: "Postleitzahl", value: "15713", alias: "15713" },
  { category: "Postleitzahl", value: "15732", alias: "15732" },
  { category: "Postleitzahl", value: "15738", alias: "15738" },
  { category: "Postleitzahl", value: "15741", alias: "15741" },
  { category: "Postleitzahl", value: "15745", alias: "15745" },
  { category: "Postleitzahl", value: "15746", alias: "15746" },
  { category: "Postleitzahl", value: "15748", alias: "15748" },
  { category: "Postleitzahl", value: "15749", alias: "15749" },
  { category: "Postleitzahl", value: "15754", alias: "15754" },
  { category: "Postleitzahl", value: "15755", alias: "15755" },
  { category: "Postleitzahl", value: "15757", alias: "15757" },
  { category: "Postleitzahl", value: "15806", alias: "15806" },
  { category: "Postleitzahl", value: "15827", alias: "15827" },
  { category: "Postleitzahl", value: "15831", alias: "15831" },
  { category: "Postleitzahl", value: "15834", alias: "15834" },
  { category: "Postleitzahl", value: "15837", alias: "15837" },
  { category: "Postleitzahl", value: "15838", alias: "15838" },
  { category: "Postleitzahl", value: "15848", alias: "15848" },
  { category: "Postleitzahl", value: "15859", alias: "15859" },
  { category: "Postleitzahl", value: "15864", alias: "15864" },
  { category: "Postleitzahl", value: "15868", alias: "15868" },
  { category: "Postleitzahl", value: "15890", alias: "15890" },
  { category: "Postleitzahl", value: "15898", alias: "15898" },
  { category: "Postleitzahl", value: "15907", alias: "15907" },
  { category: "Postleitzahl", value: "15910", alias: "15910" },
  { category: "Postleitzahl", value: "15913", alias: "15913" },
  { category: "Postleitzahl", value: "15926", alias: "15926" },
  { category: "Postleitzahl", value: "15936", alias: "15936" },
  { category: "Postleitzahl", value: "15938", alias: "15938" },
  { category: "Postleitzahl", value: "16225", alias: "16225" },
  { category: "Postleitzahl", value: "16227", alias: "16227" },
  { category: "Postleitzahl", value: "16230", alias: "16230" },
  { category: "Postleitzahl", value: "16244", alias: "16244" },
  { category: "Postleitzahl", value: "16247", alias: "16247" },
  { category: "Postleitzahl", value: "16248", alias: "16248" },
  { category: "Postleitzahl", value: "16259", alias: "16259" },
  { category: "Postleitzahl", value: "16269", alias: "16269" },
  { category: "Postleitzahl", value: "16278", alias: "16278" },
  { category: "Postleitzahl", value: "16303", alias: "16303" },
  { category: "Postleitzahl", value: "16306", alias: "16306" },
  { category: "Postleitzahl", value: "16307", alias: "16307" },
  { category: "Postleitzahl", value: "16321", alias: "16321" },
  { category: "Postleitzahl", value: "16341", alias: "16341" },
  { category: "Postleitzahl", value: "16348", alias: "16348" },
  { category: "Postleitzahl", value: "16356", alias: "16356" },
  { category: "Postleitzahl", value: "16359", alias: "16359" },
  { category: "Postleitzahl", value: "16515", alias: "16515" },
  { category: "Postleitzahl", value: "16540", alias: "16540" },
  { category: "Postleitzahl", value: "16547", alias: "16547" },
  { category: "Postleitzahl", value: "16548", alias: "16548" },
  { category: "Postleitzahl", value: "16552", alias: "16552" },
  { category: "Postleitzahl", value: "16556", alias: "16556" },
  { category: "Postleitzahl", value: "16559", alias: "16559" },
  { category: "Postleitzahl", value: "16562", alias: "16562" },
  { category: "Postleitzahl", value: "16567", alias: "16567" },
  { category: "Postleitzahl", value: "16727", alias: "16727" },
  { category: "Postleitzahl", value: "16761", alias: "16761" },
  { category: "Postleitzahl", value: "16766", alias: "16766" },
  { category: "Postleitzahl", value: "16767", alias: "16767" },
  { category: "Postleitzahl", value: "16775", alias: "16775" },
  { category: "Postleitzahl", value: "16792", alias: "16792" },
  { category: "Postleitzahl", value: "16798", alias: "16798" },
  { category: "Postleitzahl", value: "16816", alias: "16816" },
  { category: "Postleitzahl", value: "16818", alias: "16818" },
  { category: "Postleitzahl", value: "16827", alias: "16827" },
  { category: "Postleitzahl", value: "16831", alias: "16831" },
  { category: "Postleitzahl", value: "16833", alias: "16833" },
  { category: "Postleitzahl", value: "16835", alias: "16835" },
  { category: "Postleitzahl", value: "16837", alias: "16837" },
  { category: "Postleitzahl", value: "16845", alias: "16845" },
  { category: "Postleitzahl", value: "16866", alias: "16866" },
  { category: "Postleitzahl", value: "16868", alias: "16868" },
  { category: "Postleitzahl", value: "16909", alias: "16909" },
  { category: "Postleitzahl", value: "16928", alias: "16928" },
  { category: "Postleitzahl", value: "16945", alias: "16945" },
  { category: "Postleitzahl", value: "16949", alias: "16949" },
  { category: "Postleitzahl", value: "17033", alias: "17033" },
  { category: "Postleitzahl", value: "17034", alias: "17034" },
  { category: "Postleitzahl", value: "17036", alias: "17036" },
  { category: "Postleitzahl", value: "17039", alias: "17039" },
  { category: "Postleitzahl", value: "17087", alias: "17087" },
  { category: "Postleitzahl", value: "17089", alias: "17089" },
  { category: "Postleitzahl", value: "17091", alias: "17091" },
  { category: "Postleitzahl", value: "17094", alias: "17094" },
  { category: "Postleitzahl", value: "17098", alias: "17098" },
  { category: "Postleitzahl", value: "17099", alias: "17099" },
  { category: "Postleitzahl", value: "17109", alias: "17109" },
  { category: "Postleitzahl", value: "17111", alias: "17111" },
  { category: "Postleitzahl", value: "17121", alias: "17121" },
  { category: "Postleitzahl", value: "17126", alias: "17126" },
  { category: "Postleitzahl", value: "17129", alias: "17129" },
  { category: "Postleitzahl", value: "17139", alias: "17139" },
  { category: "Postleitzahl", value: "17153", alias: "17153" },
  { category: "Postleitzahl", value: "17154", alias: "17154" },
  { category: "Postleitzahl", value: "17159", alias: "17159" },
  { category: "Postleitzahl", value: "17166", alias: "17166" },
  { category: "Postleitzahl", value: "17168", alias: "17168" },
  { category: "Postleitzahl", value: "17179", alias: "17179" },
  { category: "Postleitzahl", value: "17192", alias: "17192" },
  { category: "Postleitzahl", value: "17194", alias: "17194" },
  { category: "Postleitzahl", value: "17207", alias: "17207" },
  { category: "Postleitzahl", value: "17209", alias: "17209" },
  { category: "Postleitzahl", value: "17213", alias: "17213" },
  { category: "Postleitzahl", value: "17214", alias: "17214" },
  { category: "Postleitzahl", value: "17217", alias: "17217" },
  { category: "Postleitzahl", value: "17219", alias: "17219" },
  { category: "Postleitzahl", value: "17235", alias: "17235" },
  { category: "Postleitzahl", value: "17237", alias: "17237" },
  { category: "Postleitzahl", value: "17248", alias: "17248" },
  { category: "Postleitzahl", value: "17252", alias: "17252" },
  { category: "Postleitzahl", value: "17255", alias: "17255" },
  { category: "Postleitzahl", value: "17258", alias: "17258" },
  { category: "Postleitzahl", value: "17268", alias: "17268" },
  { category: "Postleitzahl", value: "17279", alias: "17279" },
  { category: "Postleitzahl", value: "17291", alias: "17291" },
  { category: "Postleitzahl", value: "17309", alias: "17309" },
  { category: "Postleitzahl", value: "17321", alias: "17321" },
  { category: "Postleitzahl", value: "17322", alias: "17322" },
  { category: "Postleitzahl", value: "17326", alias: "17326" },
  { category: "Postleitzahl", value: "17328", alias: "17328" },
  { category: "Postleitzahl", value: "17329", alias: "17329" },
  { category: "Postleitzahl", value: "17335", alias: "17335" },
  { category: "Postleitzahl", value: "17337", alias: "17337" },
  { category: "Postleitzahl", value: "17348", alias: "17348" },
  { category: "Postleitzahl", value: "17349", alias: "17349" },
  { category: "Postleitzahl", value: "17358", alias: "17358" },
  { category: "Postleitzahl", value: "17367", alias: "17367" },
  { category: "Postleitzahl", value: "17373", alias: "17373" },
  { category: "Postleitzahl", value: "17375", alias: "17375" },
  { category: "Postleitzahl", value: "17379", alias: "17379" },
  { category: "Postleitzahl", value: "17389", alias: "17389" },
  { category: "Postleitzahl", value: "17390", alias: "17390" },
  { category: "Postleitzahl", value: "17391", alias: "17391" },
  { category: "Postleitzahl", value: "17392", alias: "17392" },
  { category: "Postleitzahl", value: "17398", alias: "17398" },
  { category: "Postleitzahl", value: "17406", alias: "17406" },
  { category: "Postleitzahl", value: "17419", alias: "17419" },
  { category: "Postleitzahl", value: "17424", alias: "17424" },
  { category: "Postleitzahl", value: "17429", alias: "17429" },
  { category: "Postleitzahl", value: "17438", alias: "17438" },
  { category: "Postleitzahl", value: "17440", alias: "17440" },
  { category: "Postleitzahl", value: "17449", alias: "17449" },
  { category: "Postleitzahl", value: "17454", alias: "17454" },
  { category: "Postleitzahl", value: "17459", alias: "17459" },
  { category: "Postleitzahl", value: "17489", alias: "17489" },
  { category: "Postleitzahl", value: "17491", alias: "17491" },
  { category: "Postleitzahl", value: "17493", alias: "17493" },
  { category: "Postleitzahl", value: "17495", alias: "17495" },
  { category: "Postleitzahl", value: "17498", alias: "17498" },
  { category: "Postleitzahl", value: "17506", alias: "17506" },
  { category: "Postleitzahl", value: "17509", alias: "17509" },
  { category: "Postleitzahl", value: "18055", alias: "18055" },
  { category: "Postleitzahl", value: "18057", alias: "18057" },
  { category: "Postleitzahl", value: "18059", alias: "18059" },
  { category: "Postleitzahl", value: "18069", alias: "18069" },
  { category: "Postleitzahl", value: "18106", alias: "18106" },
  { category: "Postleitzahl", value: "18107", alias: "18107" },
  { category: "Postleitzahl", value: "18109", alias: "18109" },
  { category: "Postleitzahl", value: "18119", alias: "18119" },
  { category: "Postleitzahl", value: "18146", alias: "18146" },
  { category: "Postleitzahl", value: "18147", alias: "18147" },
  { category: "Postleitzahl", value: "18181", alias: "18181" },
  { category: "Postleitzahl", value: "18182", alias: "18182" },
  { category: "Postleitzahl", value: "18184", alias: "18184" },
  { category: "Postleitzahl", value: "18190", alias: "18190" },
  { category: "Postleitzahl", value: "18195", alias: "18195" },
  { category: "Postleitzahl", value: "18196", alias: "18196" },
  { category: "Postleitzahl", value: "18198", alias: "18198" },
  { category: "Postleitzahl", value: "18209", alias: "18209" },
  { category: "Postleitzahl", value: "18211", alias: "18211" },
  { category: "Postleitzahl", value: "18225", alias: "18225" },
  { category: "Postleitzahl", value: "18230", alias: "18230" },
  { category: "Postleitzahl", value: "18233", alias: "18233" },
  { category: "Postleitzahl", value: "18236", alias: "18236" },
  { category: "Postleitzahl", value: "18239", alias: "18239" },
  { category: "Postleitzahl", value: "18246", alias: "18246" },
  { category: "Postleitzahl", value: "18249", alias: "18249" },
  { category: "Postleitzahl", value: "18258", alias: "18258" },
  { category: "Postleitzahl", value: "18273", alias: "18273" },
  { category: "Postleitzahl", value: "18276", alias: "18276" },
  { category: "Postleitzahl", value: "18279", alias: "18279" },
  { category: "Postleitzahl", value: "18292", alias: "18292" },
  { category: "Postleitzahl", value: "18299", alias: "18299" },
  { category: "Postleitzahl", value: "18311", alias: "18311" },
  { category: "Postleitzahl", value: "18314", alias: "18314" },
  { category: "Postleitzahl", value: "18317", alias: "18317" },
  { category: "Postleitzahl", value: "18320", alias: "18320" },
  { category: "Postleitzahl", value: "18334", alias: "18334" },
  { category: "Postleitzahl", value: "18337", alias: "18337" },
  { category: "Postleitzahl", value: "18347", alias: "18347" },
  { category: "Postleitzahl", value: "18356", alias: "18356" },
  { category: "Postleitzahl", value: "18374", alias: "18374" },
  { category: "Postleitzahl", value: "18375", alias: "18375" },
  { category: "Postleitzahl", value: "18435", alias: "18435" },
  { category: "Postleitzahl", value: "18437", alias: "18437" },
  { category: "Postleitzahl", value: "18439", alias: "18439" },
  { category: "Postleitzahl", value: "18442", alias: "18442" },
  { category: "Postleitzahl", value: "18445", alias: "18445" },
  { category: "Postleitzahl", value: "18461", alias: "18461" },
  { category: "Postleitzahl", value: "18465", alias: "18465" },
  { category: "Postleitzahl", value: "18469", alias: "18469" },
  { category: "Postleitzahl", value: "18507", alias: "18507" },
  { category: "Postleitzahl", value: "18510", alias: "18510" },
  { category: "Postleitzahl", value: "18513", alias: "18513" },
  { category: "Postleitzahl", value: "18516", alias: "18516" },
  { category: "Postleitzahl", value: "18519", alias: "18519" },
  { category: "Postleitzahl", value: "18528", alias: "18528" },
  { category: "Postleitzahl", value: "18546", alias: "18546" },
  { category: "Postleitzahl", value: "18551", alias: "18551" },
  { category: "Postleitzahl", value: "18556", alias: "18556" },
  { category: "Postleitzahl", value: "18565", alias: "18565" },
  { category: "Postleitzahl", value: "18569", alias: "18569" },
  { category: "Postleitzahl", value: "18573", alias: "18573" },
  { category: "Postleitzahl", value: "18574", alias: "18574" },
  { category: "Postleitzahl", value: "18581", alias: "18581" },
  { category: "Postleitzahl", value: "18586", alias: "18586" },
  { category: "Postleitzahl", value: "18609", alias: "18609" },
  { category: "Postleitzahl", value: "19053", alias: "19053" },
  { category: "Postleitzahl", value: "19055", alias: "19055" },
  { category: "Postleitzahl", value: "19057", alias: "19057" },
  { category: "Postleitzahl", value: "19059", alias: "19059" },
  { category: "Postleitzahl", value: "19061", alias: "19061" },
  { category: "Postleitzahl", value: "19063", alias: "19063" },
  { category: "Postleitzahl", value: "19065", alias: "19065" },
  { category: "Postleitzahl", value: "19067", alias: "19067" },
  { category: "Postleitzahl", value: "19069", alias: "19069" },
  { category: "Postleitzahl", value: "19071", alias: "19071" },
  { category: "Postleitzahl", value: "19073", alias: "19073" },
  { category: "Postleitzahl", value: "19075", alias: "19075" },
  { category: "Postleitzahl", value: "19077", alias: "19077" },
  { category: "Postleitzahl", value: "19079", alias: "19079" },
  { category: "Postleitzahl", value: "19086", alias: "19086" },
  { category: "Postleitzahl", value: "19089", alias: "19089" },
  { category: "Postleitzahl", value: "19205", alias: "19205" },
  { category: "Postleitzahl", value: "19209", alias: "19209" },
  { category: "Postleitzahl", value: "19217", alias: "19217" },
  { category: "Postleitzahl", value: "19230", alias: "19230" },
  { category: "Postleitzahl", value: "19243", alias: "19243" },
  { category: "Postleitzahl", value: "19246", alias: "19246" },
  { category: "Postleitzahl", value: "19249", alias: "19249" },
  { category: "Postleitzahl", value: "19258", alias: "19258" },
  { category: "Postleitzahl", value: "19260", alias: "19260" },
  { category: "Postleitzahl", value: "19273", alias: "19273" },
  { category: "Postleitzahl", value: "19288", alias: "19288" },
  { category: "Postleitzahl", value: "19294", alias: "19294" },
  { category: "Postleitzahl", value: "19300", alias: "19300" },
  { category: "Postleitzahl", value: "19303", alias: "19303" },
  { category: "Postleitzahl", value: "19306", alias: "19306" },
  { category: "Postleitzahl", value: "19309", alias: "19309" },
  { category: "Postleitzahl", value: "19322", alias: "19322" },
  { category: "Postleitzahl", value: "19336", alias: "19336" },
  { category: "Postleitzahl", value: "19339", alias: "19339" },
  { category: "Postleitzahl", value: "19348", alias: "19348" },
  { category: "Postleitzahl", value: "19357", alias: "19357" },
  { category: "Postleitzahl", value: "19370", alias: "19370" },
  { category: "Postleitzahl", value: "19372", alias: "19372" },
  { category: "Postleitzahl", value: "19374", alias: "19374" },
  { category: "Postleitzahl", value: "19376", alias: "19376" },
  { category: "Postleitzahl", value: "19386", alias: "19386" },
  { category: "Postleitzahl", value: "19395", alias: "19395" },
  { category: "Postleitzahl", value: "19399", alias: "19399" },
  { category: "Postleitzahl", value: "19406", alias: "19406" },
  { category: "Postleitzahl", value: "19412", alias: "19412" },
  { category: "Postleitzahl", value: "19417", alias: "19417" },
  { category: "Postleitzahl", value: "20095", alias: "20095" },
  { category: "Postleitzahl", value: "20097", alias: "20097" },
  { category: "Postleitzahl", value: "20099", alias: "20099" },
  { category: "Postleitzahl", value: "20144", alias: "20144" },
  { category: "Postleitzahl", value: "20146", alias: "20146" },
  { category: "Postleitzahl", value: "20148", alias: "20148" },
  { category: "Postleitzahl", value: "20149", alias: "20149" },
  { category: "Postleitzahl", value: "20249", alias: "20249" },
  { category: "Postleitzahl", value: "20251", alias: "20251" },
  { category: "Postleitzahl", value: "20253", alias: "20253" },
  { category: "Postleitzahl", value: "20255", alias: "20255" },
  { category: "Postleitzahl", value: "20257", alias: "20257" },
  { category: "Postleitzahl", value: "20259", alias: "20259" },
  { category: "Postleitzahl", value: "20354", alias: "20354" },
  { category: "Postleitzahl", value: "20355", alias: "20355" },
  { category: "Postleitzahl", value: "20357", alias: "20357" },
  { category: "Postleitzahl", value: "20359", alias: "20359" },
  { category: "Postleitzahl", value: "20457", alias: "20457" },
  { category: "Postleitzahl", value: "20459", alias: "20459" },
  { category: "Postleitzahl", value: "20535", alias: "20535" },
  { category: "Postleitzahl", value: "20537", alias: "20537" },
  { category: "Postleitzahl", value: "20539", alias: "20539" },
  { category: "Postleitzahl", value: "21029", alias: "21029" },
  { category: "Postleitzahl", value: "21031", alias: "21031" },
  { category: "Postleitzahl", value: "21033", alias: "21033" },
  { category: "Postleitzahl", value: "21035", alias: "21035" },
  { category: "Postleitzahl", value: "21037", alias: "21037" },
  { category: "Postleitzahl", value: "21039", alias: "21039" },
  { category: "Postleitzahl", value: "21073", alias: "21073" },
  { category: "Postleitzahl", value: "21075", alias: "21075" },
  { category: "Postleitzahl", value: "21077", alias: "21077" },
  { category: "Postleitzahl", value: "21079", alias: "21079" },
  { category: "Postleitzahl", value: "21107", alias: "21107" },
  { category: "Postleitzahl", value: "21109", alias: "21109" },
  { category: "Postleitzahl", value: "21129", alias: "21129" },
  { category: "Postleitzahl", value: "21147", alias: "21147" },
  { category: "Postleitzahl", value: "21149", alias: "21149" },
  { category: "Postleitzahl", value: "21217", alias: "21217" },
  { category: "Postleitzahl", value: "21218", alias: "21218" },
  { category: "Postleitzahl", value: "21220", alias: "21220" },
  { category: "Postleitzahl", value: "21224", alias: "21224" },
  { category: "Postleitzahl", value: "21227", alias: "21227" },
  { category: "Postleitzahl", value: "21228", alias: "21228" },
  { category: "Postleitzahl", value: "21244", alias: "21244" },
  { category: "Postleitzahl", value: "21255", alias: "21255" },
  { category: "Postleitzahl", value: "21256", alias: "21256" },
  { category: "Postleitzahl", value: "21258", alias: "21258" },
  { category: "Postleitzahl", value: "21259", alias: "21259" },
  { category: "Postleitzahl", value: "21261", alias: "21261" },
  { category: "Postleitzahl", value: "21266", alias: "21266" },
  { category: "Postleitzahl", value: "21271", alias: "21271" },
  { category: "Postleitzahl", value: "21272", alias: "21272" },
  { category: "Postleitzahl", value: "21274", alias: "21274" },
  { category: "Postleitzahl", value: "21279", alias: "21279" },
  { category: "Postleitzahl", value: "21335", alias: "21335" },
  { category: "Postleitzahl", value: "21337", alias: "21337" },
  { category: "Postleitzahl", value: "21339", alias: "21339" },
  { category: "Postleitzahl", value: "21354", alias: "21354" },
  { category: "Postleitzahl", value: "21357", alias: "21357" },
  { category: "Postleitzahl", value: "21358", alias: "21358" },
  { category: "Postleitzahl", value: "21360", alias: "21360" },
  { category: "Postleitzahl", value: "21365", alias: "21365" },
  { category: "Postleitzahl", value: "21368", alias: "21368" },
  { category: "Postleitzahl", value: "21369", alias: "21369" },
  { category: "Postleitzahl", value: "21371", alias: "21371" },
  { category: "Postleitzahl", value: "21376", alias: "21376" },
  { category: "Postleitzahl", value: "21379", alias: "21379" },
  { category: "Postleitzahl", value: "21380", alias: "21380" },
  { category: "Postleitzahl", value: "21382", alias: "21382" },
  { category: "Postleitzahl", value: "21385", alias: "21385" },
  { category: "Postleitzahl", value: "21386", alias: "21386" },
  { category: "Postleitzahl", value: "21388", alias: "21388" },
  { category: "Postleitzahl", value: "21391", alias: "21391" },
  { category: "Postleitzahl", value: "21394", alias: "21394" },
  { category: "Postleitzahl", value: "21395", alias: "21395" },
  { category: "Postleitzahl", value: "21397", alias: "21397" },
  { category: "Postleitzahl", value: "21398", alias: "21398" },
  { category: "Postleitzahl", value: "21400", alias: "21400" },
  { category: "Postleitzahl", value: "21401", alias: "21401" },
  { category: "Postleitzahl", value: "21403", alias: "21403" },
  { category: "Postleitzahl", value: "21406", alias: "21406" },
  { category: "Postleitzahl", value: "21407", alias: "21407" },
  { category: "Postleitzahl", value: "21409", alias: "21409" },
  { category: "Postleitzahl", value: "21423", alias: "21423" },
  { category: "Postleitzahl", value: "21435", alias: "21435" },
  { category: "Postleitzahl", value: "21436", alias: "21436" },
  { category: "Postleitzahl", value: "21438", alias: "21438" },
  { category: "Postleitzahl", value: "21439", alias: "21439" },
  { category: "Postleitzahl", value: "21441", alias: "21441" },
  { category: "Postleitzahl", value: "21442", alias: "21442" },
  { category: "Postleitzahl", value: "21444", alias: "21444" },
  { category: "Postleitzahl", value: "21445", alias: "21445" },
  { category: "Postleitzahl", value: "21447", alias: "21447" },
  { category: "Postleitzahl", value: "21449", alias: "21449" },
  { category: "Postleitzahl", value: "21465", alias: "21465" },
  { category: "Postleitzahl", value: "21481", alias: "21481" },
  { category: "Postleitzahl", value: "21483", alias: "21483" },
  { category: "Postleitzahl", value: "21493", alias: "21493" },
  { category: "Postleitzahl", value: "21502", alias: "21502" },
  { category: "Postleitzahl", value: "21509", alias: "21509" },
  { category: "Postleitzahl", value: "21514", alias: "21514" },
  { category: "Postleitzahl", value: "21516", alias: "21516" },
  { category: "Postleitzahl", value: "21521", alias: "21521" },
  { category: "Postleitzahl", value: "21522", alias: "21522" },
  { category: "Postleitzahl", value: "21524", alias: "21524" },
  { category: "Postleitzahl", value: "21526", alias: "21526" },
  { category: "Postleitzahl", value: "21527", alias: "21527" },
  { category: "Postleitzahl", value: "21529", alias: "21529" },
  { category: "Postleitzahl", value: "21614", alias: "21614" },
  { category: "Postleitzahl", value: "21629", alias: "21629" },
  { category: "Postleitzahl", value: "21635", alias: "21635" },
  { category: "Postleitzahl", value: "21640", alias: "21640" },
  { category: "Postleitzahl", value: "21641", alias: "21641" },
  { category: "Postleitzahl", value: "21643", alias: "21643" },
  { category: "Postleitzahl", value: "21644", alias: "21644" },
  { category: "Postleitzahl", value: "21646", alias: "21646" },
  { category: "Postleitzahl", value: "21647", alias: "21647" },
  { category: "Postleitzahl", value: "21649", alias: "21649" },
  { category: "Postleitzahl", value: "21680", alias: "21680" },
  { category: "Postleitzahl", value: "21682", alias: "21682" },
  { category: "Postleitzahl", value: "21683", alias: "21683" },
  { category: "Postleitzahl", value: "21684", alias: "21684" },
  { category: "Postleitzahl", value: "21698", alias: "21698" },
  { category: "Postleitzahl", value: "21702", alias: "21702" },
  { category: "Postleitzahl", value: "21706", alias: "21706" },
  { category: "Postleitzahl", value: "21709", alias: "21709" },
  { category: "Postleitzahl", value: "21710", alias: "21710" },
  { category: "Postleitzahl", value: "21712", alias: "21712" },
  { category: "Postleitzahl", value: "21714", alias: "21714" },
  { category: "Postleitzahl", value: "21717", alias: "21717" },
  { category: "Postleitzahl", value: "21720", alias: "21720" },
  { category: "Postleitzahl", value: "21723", alias: "21723" },
  { category: "Postleitzahl", value: "21726", alias: "21726" },
  { category: "Postleitzahl", value: "21727", alias: "21727" },
  { category: "Postleitzahl", value: "21729", alias: "21729" },
  { category: "Postleitzahl", value: "21730", alias: "21730" },
  { category: "Postleitzahl", value: "21732", alias: "21732" },
  { category: "Postleitzahl", value: "21734", alias: "21734" },
  { category: "Postleitzahl", value: "21737", alias: "21737" },
  { category: "Postleitzahl", value: "21739", alias: "21739" },
  { category: "Postleitzahl", value: "21745", alias: "21745" },
  { category: "Postleitzahl", value: "21755", alias: "21755" },
  { category: "Postleitzahl", value: "21756", alias: "21756" },
  { category: "Postleitzahl", value: "21762", alias: "21762" },
  { category: "Postleitzahl", value: "21763", alias: "21763" },
  { category: "Postleitzahl", value: "21765", alias: "21765" },
  { category: "Postleitzahl", value: "21769", alias: "21769" },
  { category: "Postleitzahl", value: "21770", alias: "21770" },
  { category: "Postleitzahl", value: "21772", alias: "21772" },
  { category: "Postleitzahl", value: "21775", alias: "21775" },
  { category: "Postleitzahl", value: "21776", alias: "21776" },
  { category: "Postleitzahl", value: "21781", alias: "21781" },
  { category: "Postleitzahl", value: "21782", alias: "21782" },
  { category: "Postleitzahl", value: "21785", alias: "21785" },
  { category: "Postleitzahl", value: "21787", alias: "21787" },
  { category: "Postleitzahl", value: "21789", alias: "21789" },
  { category: "Postleitzahl", value: "22041", alias: "22041" },
  { category: "Postleitzahl", value: "22043", alias: "22043" },
  { category: "Postleitzahl", value: "22045", alias: "22045" },
  { category: "Postleitzahl", value: "22047", alias: "22047" },
  { category: "Postleitzahl", value: "22049", alias: "22049" },
  { category: "Postleitzahl", value: "22081", alias: "22081" },
  { category: "Postleitzahl", value: "22083", alias: "22083" },
  { category: "Postleitzahl", value: "22085", alias: "22085" },
  { category: "Postleitzahl", value: "22087", alias: "22087" },
  { category: "Postleitzahl", value: "22089", alias: "22089" },
  { category: "Postleitzahl", value: "22111", alias: "22111" },
  { category: "Postleitzahl", value: "22113", alias: "22113" },
  { category: "Postleitzahl", value: "22115", alias: "22115" },
  { category: "Postleitzahl", value: "22117", alias: "22117" },
  { category: "Postleitzahl", value: "22119", alias: "22119" },
  { category: "Postleitzahl", value: "22143", alias: "22143" },
  { category: "Postleitzahl", value: "22145", alias: "22145" },
  { category: "Postleitzahl", value: "22147", alias: "22147" },
  { category: "Postleitzahl", value: "22149", alias: "22149" },
  { category: "Postleitzahl", value: "22159", alias: "22159" },
  { category: "Postleitzahl", value: "22175", alias: "22175" },
  { category: "Postleitzahl", value: "22177", alias: "22177" },
  { category: "Postleitzahl", value: "22179", alias: "22179" },
  { category: "Postleitzahl", value: "22297", alias: "22297" },
  { category: "Postleitzahl", value: "22299", alias: "22299" },
  { category: "Postleitzahl", value: "22301", alias: "22301" },
  { category: "Postleitzahl", value: "22303", alias: "22303" },
  { category: "Postleitzahl", value: "22305", alias: "22305" },
  { category: "Postleitzahl", value: "22307", alias: "22307" },
  { category: "Postleitzahl", value: "22309", alias: "22309" },
  { category: "Postleitzahl", value: "22335", alias: "22335" },
  { category: "Postleitzahl", value: "22337", alias: "22337" },
  { category: "Postleitzahl", value: "22339", alias: "22339" },
  { category: "Postleitzahl", value: "22359", alias: "22359" },
  { category: "Postleitzahl", value: "22391", alias: "22391" },
  { category: "Postleitzahl", value: "22393", alias: "22393" },
  { category: "Postleitzahl", value: "22395", alias: "22395" },
  { category: "Postleitzahl", value: "22397", alias: "22397" },
  { category: "Postleitzahl", value: "22399", alias: "22399" },
  { category: "Postleitzahl", value: "22415", alias: "22415" },
  { category: "Postleitzahl", value: "22417", alias: "22417" },
  { category: "Postleitzahl", value: "22419", alias: "22419" },
  { category: "Postleitzahl", value: "22453", alias: "22453" },
  { category: "Postleitzahl", value: "22455", alias: "22455" },
  { category: "Postleitzahl", value: "22457", alias: "22457" },
  { category: "Postleitzahl", value: "22459", alias: "22459" },
  { category: "Postleitzahl", value: "22523", alias: "22523" },
  { category: "Postleitzahl", value: "22525", alias: "22525" },
  { category: "Postleitzahl", value: "22527", alias: "22527" },
  { category: "Postleitzahl", value: "22529", alias: "22529" },
  { category: "Postleitzahl", value: "22547", alias: "22547" },
  { category: "Postleitzahl", value: "22549", alias: "22549" },
  { category: "Postleitzahl", value: "22559", alias: "22559" },
  { category: "Postleitzahl", value: "22587", alias: "22587" },
  { category: "Postleitzahl", value: "22589", alias: "22589" },
  { category: "Postleitzahl", value: "22605", alias: "22605" },
  { category: "Postleitzahl", value: "22607", alias: "22607" },
  { category: "Postleitzahl", value: "22609", alias: "22609" },
  { category: "Postleitzahl", value: "22761", alias: "22761" },
  { category: "Postleitzahl", value: "22763", alias: "22763" },
  { category: "Postleitzahl", value: "22765", alias: "22765" },
  { category: "Postleitzahl", value: "22767", alias: "22767" },
  { category: "Postleitzahl", value: "22769", alias: "22769" },
  { category: "Postleitzahl", value: "22844", alias: "22844" },
  { category: "Postleitzahl", value: "22846", alias: "22846" },
  { category: "Postleitzahl", value: "22848", alias: "22848" },
  { category: "Postleitzahl", value: "22850", alias: "22850" },
  { category: "Postleitzahl", value: "22851", alias: "22851" },
  { category: "Postleitzahl", value: "22869", alias: "22869" },
  { category: "Postleitzahl", value: "22880", alias: "22880" },
  { category: "Postleitzahl", value: "22885", alias: "22885" },
  { category: "Postleitzahl", value: "22889", alias: "22889" },
  { category: "Postleitzahl", value: "22926", alias: "22926" },
  { category: "Postleitzahl", value: "22927", alias: "22927" },
  { category: "Postleitzahl", value: "22929", alias: "22929" },
  { category: "Postleitzahl", value: "22941", alias: "22941" },
  { category: "Postleitzahl", value: "22946", alias: "22946" },
  { category: "Postleitzahl", value: "22949", alias: "22949" },
  { category: "Postleitzahl", value: "22952", alias: "22952" },
  { category: "Postleitzahl", value: "22955", alias: "22955" },
  { category: "Postleitzahl", value: "22956", alias: "22956" },
  { category: "Postleitzahl", value: "22958", alias: "22958" },
  { category: "Postleitzahl", value: "22959", alias: "22959" },
  { category: "Postleitzahl", value: "22961", alias: "22961" },
  { category: "Postleitzahl", value: "22962", alias: "22962" },
  { category: "Postleitzahl", value: "22964", alias: "22964" },
  { category: "Postleitzahl", value: "22965", alias: "22965" },
  { category: "Postleitzahl", value: "22967", alias: "22967" },
  { category: "Postleitzahl", value: "22969", alias: "22969" },
  { category: "Postleitzahl", value: "23552", alias: "23552" },
  { category: "Postleitzahl", value: "23554", alias: "23554" },
  { category: "Postleitzahl", value: "23556", alias: "23556" },
  { category: "Postleitzahl", value: "23558", alias: "23558" },
  { category: "Postleitzahl", value: "23560", alias: "23560" },
  { category: "Postleitzahl", value: "23562", alias: "23562" },
  { category: "Postleitzahl", value: "23564", alias: "23564" },
  { category: "Postleitzahl", value: "23566", alias: "23566" },
  { category: "Postleitzahl", value: "23568", alias: "23568" },
  { category: "Postleitzahl", value: "23569", alias: "23569" },
  { category: "Postleitzahl", value: "23570", alias: "23570" },
  { category: "Postleitzahl", value: "23611", alias: "23611" },
  { category: "Postleitzahl", value: "23617", alias: "23617" },
  { category: "Postleitzahl", value: "23619", alias: "23619" },
  { category: "Postleitzahl", value: "23623", alias: "23623" },
  { category: "Postleitzahl", value: "23626", alias: "23626" },
  { category: "Postleitzahl", value: "23627", alias: "23627" },
  { category: "Postleitzahl", value: "23628", alias: "23628" },
  { category: "Postleitzahl", value: "23629", alias: "23629" },
  { category: "Postleitzahl", value: "23669", alias: "23669" },
  { category: "Postleitzahl", value: "23683", alias: "23683" },
  { category: "Postleitzahl", value: "23684", alias: "23684" },
  { category: "Postleitzahl", value: "23689", alias: "23689" },
  { category: "Postleitzahl", value: "23701", alias: "23701" },
  { category: "Postleitzahl", value: "23714", alias: "23714" },
  { category: "Postleitzahl", value: "23715", alias: "23715" },
  { category: "Postleitzahl", value: "23717", alias: "23717" },
  { category: "Postleitzahl", value: "23719", alias: "23719" },
  { category: "Postleitzahl", value: "23730", alias: "23730" },
  { category: "Postleitzahl", value: "23738", alias: "23738" },
  { category: "Postleitzahl", value: "23743", alias: "23743" },
  { category: "Postleitzahl", value: "23744", alias: "23744" },
  { category: "Postleitzahl", value: "23746", alias: "23746" },
  { category: "Postleitzahl", value: "23747", alias: "23747" },
  { category: "Postleitzahl", value: "23749", alias: "23749" },
  { category: "Postleitzahl", value: "23758", alias: "23758" },
  { category: "Postleitzahl", value: "23769", alias: "23769" },
  { category: "Postleitzahl", value: "23774", alias: "23774" },
  { category: "Postleitzahl", value: "23775", alias: "23775" },
  { category: "Postleitzahl", value: "23777", alias: "23777" },
  { category: "Postleitzahl", value: "23779", alias: "23779" },
  { category: "Postleitzahl", value: "23795", alias: "23795" },
  { category: "Postleitzahl", value: "23812", alias: "23812" },
  { category: "Postleitzahl", value: "23813", alias: "23813" },
  { category: "Postleitzahl", value: "23815", alias: "23815" },
  { category: "Postleitzahl", value: "23816", alias: "23816" },
  { category: "Postleitzahl", value: "23818", alias: "23818" },
  { category: "Postleitzahl", value: "23820", alias: "23820" },
  { category: "Postleitzahl", value: "23821", alias: "23821" },
  { category: "Postleitzahl", value: "23823", alias: "23823" },
  { category: "Postleitzahl", value: "23824", alias: "23824" },
  { category: "Postleitzahl", value: "23826", alias: "23826" },
  { category: "Postleitzahl", value: "23827", alias: "23827" },
  { category: "Postleitzahl", value: "23829", alias: "23829" },
  { category: "Postleitzahl", value: "23843", alias: "23843" },
  { category: "Postleitzahl", value: "23845", alias: "23845" },
  { category: "Postleitzahl", value: "23847", alias: "23847" },
  { category: "Postleitzahl", value: "23858", alias: "23858" },
  { category: "Postleitzahl", value: "23860", alias: "23860" },
  { category: "Postleitzahl", value: "23863", alias: "23863" },
  { category: "Postleitzahl", value: "23866", alias: "23866" },
  { category: "Postleitzahl", value: "23867", alias: "23867" },
  { category: "Postleitzahl", value: "23869", alias: "23869" },
  { category: "Postleitzahl", value: "23879", alias: "23879" },
  { category: "Postleitzahl", value: "23881", alias: "23881" },
  { category: "Postleitzahl", value: "23883", alias: "23883" },
  { category: "Postleitzahl", value: "23896", alias: "23896" },
  { category: "Postleitzahl", value: "23898", alias: "23898" },
  { category: "Postleitzahl", value: "23899", alias: "23899" },
  { category: "Postleitzahl", value: "23909", alias: "23909" },
  { category: "Postleitzahl", value: "23911", alias: "23911" },
  { category: "Postleitzahl", value: "23919", alias: "23919" },
  { category: "Postleitzahl", value: "23923", alias: "23923" },
  { category: "Postleitzahl", value: "23936", alias: "23936" },
  { category: "Postleitzahl", value: "23942", alias: "23942" },
  { category: "Postleitzahl", value: "23946", alias: "23946" },
  { category: "Postleitzahl", value: "23948", alias: "23948" },
  { category: "Postleitzahl", value: "23966", alias: "23966" },
  { category: "Postleitzahl", value: "23968", alias: "23968" },
  { category: "Postleitzahl", value: "23970", alias: "23970" },
  { category: "Postleitzahl", value: "23972", alias: "23972" },
  { category: "Postleitzahl", value: "23974", alias: "23974" },
  { category: "Postleitzahl", value: "23992", alias: "23992" },
  { category: "Postleitzahl", value: "23996", alias: "23996" },
  { category: "Postleitzahl", value: "23999", alias: "23999" },
  { category: "Postleitzahl", value: "24103", alias: "24103" },
  { category: "Postleitzahl", value: "24105", alias: "24105" },
  { category: "Postleitzahl", value: "24106", alias: "24106" },
  { category: "Postleitzahl", value: "24107", alias: "24107" },
  { category: "Postleitzahl", value: "24109", alias: "24109" },
  { category: "Postleitzahl", value: "24111", alias: "24111" },
  { category: "Postleitzahl", value: "24113", alias: "24113" },
  { category: "Postleitzahl", value: "24114", alias: "24114" },
  { category: "Postleitzahl", value: "24116", alias: "24116" },
  { category: "Postleitzahl", value: "24118", alias: "24118" },
  { category: "Postleitzahl", value: "24119", alias: "24119" },
  { category: "Postleitzahl", value: "24143", alias: "24143" },
  { category: "Postleitzahl", value: "24145", alias: "24145" },
  { category: "Postleitzahl", value: "24146", alias: "24146" },
  { category: "Postleitzahl", value: "24147", alias: "24147" },
  { category: "Postleitzahl", value: "24148", alias: "24148" },
  { category: "Postleitzahl", value: "24149", alias: "24149" },
  { category: "Postleitzahl", value: "24159", alias: "24159" },
  { category: "Postleitzahl", value: "24161", alias: "24161" },
  { category: "Postleitzahl", value: "24211", alias: "24211" },
  { category: "Postleitzahl", value: "24214", alias: "24214" },
  { category: "Postleitzahl", value: "24217", alias: "24217" },
  { category: "Postleitzahl", value: "24220", alias: "24220" },
  { category: "Postleitzahl", value: "24222", alias: "24222" },
  { category: "Postleitzahl", value: "24223", alias: "24223" },
  { category: "Postleitzahl", value: "24226", alias: "24226" },
  { category: "Postleitzahl", value: "24229", alias: "24229" },
  { category: "Postleitzahl", value: "24232", alias: "24232" },
  { category: "Postleitzahl", value: "24235", alias: "24235" },
  { category: "Postleitzahl", value: "24238", alias: "24238" },
  { category: "Postleitzahl", value: "24239", alias: "24239" },
  { category: "Postleitzahl", value: "24241", alias: "24241" },
  { category: "Postleitzahl", value: "24242", alias: "24242" },
  { category: "Postleitzahl", value: "24244", alias: "24244" },
  { category: "Postleitzahl", value: "24245", alias: "24245" },
  { category: "Postleitzahl", value: "24247", alias: "24247" },
  { category: "Postleitzahl", value: "24248", alias: "24248" },
  { category: "Postleitzahl", value: "24250", alias: "24250" },
  { category: "Postleitzahl", value: "24251", alias: "24251" },
  { category: "Postleitzahl", value: "24253", alias: "24253" },
  { category: "Postleitzahl", value: "24254", alias: "24254" },
  { category: "Postleitzahl", value: "24256", alias: "24256" },
  { category: "Postleitzahl", value: "24257", alias: "24257" },
  { category: "Postleitzahl", value: "24259", alias: "24259" },
  { category: "Postleitzahl", value: "24306", alias: "24306" },
  { category: "Postleitzahl", value: "24321", alias: "24321" },
  { category: "Postleitzahl", value: "24326", alias: "24326" },
  { category: "Postleitzahl", value: "24327", alias: "24327" },
  { category: "Postleitzahl", value: "24329", alias: "24329" },
  { category: "Postleitzahl", value: "24340", alias: "24340" },
  { category: "Postleitzahl", value: "24351", alias: "24351" },
  { category: "Postleitzahl", value: "24354", alias: "24354" },
  { category: "Postleitzahl", value: "24357", alias: "24357" },
  { category: "Postleitzahl", value: "24358", alias: "24358" },
  { category: "Postleitzahl", value: "24360", alias: "24360" },
  { category: "Postleitzahl", value: "24361", alias: "24361" },
  { category: "Postleitzahl", value: "24363", alias: "24363" },
  { category: "Postleitzahl", value: "24364", alias: "24364" },
  { category: "Postleitzahl", value: "24366", alias: "24366" },
  { category: "Postleitzahl", value: "24367", alias: "24367" },
  { category: "Postleitzahl", value: "24369", alias: "24369" },
  { category: "Postleitzahl", value: "24376", alias: "24376" },
  { category: "Postleitzahl", value: "24392", alias: "24392" },
  { category: "Postleitzahl", value: "24395", alias: "24395" },
  { category: "Postleitzahl", value: "24398", alias: "24398" },
  { category: "Postleitzahl", value: "24399", alias: "24399" },
  { category: "Postleitzahl", value: "24401", alias: "24401" },
  { category: "Postleitzahl", value: "24402", alias: "24402" },
  { category: "Postleitzahl", value: "24404", alias: "24404" },
  { category: "Postleitzahl", value: "24405", alias: "24405" },
  { category: "Postleitzahl", value: "24407", alias: "24407" },
  { category: "Postleitzahl", value: "24409", alias: "24409" },
  { category: "Postleitzahl", value: "24534", alias: "24534" },
  { category: "Postleitzahl", value: "24536", alias: "24536" },
  { category: "Postleitzahl", value: "24537", alias: "24537" },
  { category: "Postleitzahl", value: "24539", alias: "24539" },
  { category: "Postleitzahl", value: "24558", alias: "24558" },
  { category: "Postleitzahl", value: "24568", alias: "24568" },
  { category: "Postleitzahl", value: "24576", alias: "24576" },
  { category: "Postleitzahl", value: "24582", alias: "24582" },
  { category: "Postleitzahl", value: "24589", alias: "24589" },
  { category: "Postleitzahl", value: "24594", alias: "24594" },
  { category: "Postleitzahl", value: "24598", alias: "24598" },
  { category: "Postleitzahl", value: "24601", alias: "24601" },
  { category: "Postleitzahl", value: "24610", alias: "24610" },
  { category: "Postleitzahl", value: "24613", alias: "24613" },
  { category: "Postleitzahl", value: "24616", alias: "24616" },
  { category: "Postleitzahl", value: "24619", alias: "24619" },
  { category: "Postleitzahl", value: "24620", alias: "24620" },
  { category: "Postleitzahl", value: "24622", alias: "24622" },
  { category: "Postleitzahl", value: "24623", alias: "24623" },
  { category: "Postleitzahl", value: "24625", alias: "24625" },
  { category: "Postleitzahl", value: "24626", alias: "24626" },
  { category: "Postleitzahl", value: "24628", alias: "24628" },
  { category: "Postleitzahl", value: "24629", alias: "24629" },
  { category: "Postleitzahl", value: "24631", alias: "24631" },
  { category: "Postleitzahl", value: "24632", alias: "24632" },
  { category: "Postleitzahl", value: "24634", alias: "24634" },
  { category: "Postleitzahl", value: "24635", alias: "24635" },
  { category: "Postleitzahl", value: "24637", alias: "24637" },
  { category: "Postleitzahl", value: "24638", alias: "24638" },
  { category: "Postleitzahl", value: "24640", alias: "24640" },
  { category: "Postleitzahl", value: "24641", alias: "24641" },
  { category: "Postleitzahl", value: "24643", alias: "24643" },
  { category: "Postleitzahl", value: "24644", alias: "24644" },
  { category: "Postleitzahl", value: "24646", alias: "24646" },
  { category: "Postleitzahl", value: "24647", alias: "24647" },
  { category: "Postleitzahl", value: "24649", alias: "24649" },
  { category: "Postleitzahl", value: "24768", alias: "24768" },
  { category: "Postleitzahl", value: "24782", alias: "24782" },
  { category: "Postleitzahl", value: "24783", alias: "24783" },
  { category: "Postleitzahl", value: "24784", alias: "24784" },
  { category: "Postleitzahl", value: "24787", alias: "24787" },
  { category: "Postleitzahl", value: "24790", alias: "24790" },
  { category: "Postleitzahl", value: "24791", alias: "24791" },
  { category: "Postleitzahl", value: "24793", alias: "24793" },
  { category: "Postleitzahl", value: "24794", alias: "24794" },
  { category: "Postleitzahl", value: "24796", alias: "24796" },
  { category: "Postleitzahl", value: "24797", alias: "24797" },
  { category: "Postleitzahl", value: "24799", alias: "24799" },
  { category: "Postleitzahl", value: "24800", alias: "24800" },
  { category: "Postleitzahl", value: "24802", alias: "24802" },
  { category: "Postleitzahl", value: "24803", alias: "24803" },
  { category: "Postleitzahl", value: "24805", alias: "24805" },
  { category: "Postleitzahl", value: "24806", alias: "24806" },
  { category: "Postleitzahl", value: "24808", alias: "24808" },
  { category: "Postleitzahl", value: "24809", alias: "24809" },
  { category: "Postleitzahl", value: "24811", alias: "24811" },
  { category: "Postleitzahl", value: "24813", alias: "24813" },
  { category: "Postleitzahl", value: "24814", alias: "24814" },
  { category: "Postleitzahl", value: "24816", alias: "24816" },
  { category: "Postleitzahl", value: "24817", alias: "24817" },
  { category: "Postleitzahl", value: "24819", alias: "24819" },
  { category: "Postleitzahl", value: "24837", alias: "24837" },
  { category: "Postleitzahl", value: "24848", alias: "24848" },
  { category: "Postleitzahl", value: "24850", alias: "24850" },
  { category: "Postleitzahl", value: "24852", alias: "24852" },
  { category: "Postleitzahl", value: "24855", alias: "24855" },
  { category: "Postleitzahl", value: "24857", alias: "24857" },
  { category: "Postleitzahl", value: "24860", alias: "24860" },
  { category: "Postleitzahl", value: "24861", alias: "24861" },
  { category: "Postleitzahl", value: "24863", alias: "24863" },
  { category: "Postleitzahl", value: "24864", alias: "24864" },
  { category: "Postleitzahl", value: "24866", alias: "24866" },
  { category: "Postleitzahl", value: "24867", alias: "24867" },
  { category: "Postleitzahl", value: "24869", alias: "24869" },
  { category: "Postleitzahl", value: "24870", alias: "24870" },
  { category: "Postleitzahl", value: "24872", alias: "24872" },
  { category: "Postleitzahl", value: "24873", alias: "24873" },
  { category: "Postleitzahl", value: "24876", alias: "24876" },
  { category: "Postleitzahl", value: "24878", alias: "24878" },
  { category: "Postleitzahl", value: "24879", alias: "24879" },
  { category: "Postleitzahl", value: "24881", alias: "24881" },
  { category: "Postleitzahl", value: "24882", alias: "24882" },
  { category: "Postleitzahl", value: "24884", alias: "24884" },
  { category: "Postleitzahl", value: "24885", alias: "24885" },
  { category: "Postleitzahl", value: "24887", alias: "24887" },
  { category: "Postleitzahl", value: "24888", alias: "24888" },
  { category: "Postleitzahl", value: "24890", alias: "24890" },
  { category: "Postleitzahl", value: "24891", alias: "24891" },
  { category: "Postleitzahl", value: "24893", alias: "24893" },
  { category: "Postleitzahl", value: "24894", alias: "24894" },
  { category: "Postleitzahl", value: "24896", alias: "24896" },
  { category: "Postleitzahl", value: "24897", alias: "24897" },
  { category: "Postleitzahl", value: "24899", alias: "24899" },
  { category: "Postleitzahl", value: "24937", alias: "24937" },
  { category: "Postleitzahl", value: "24939", alias: "24939" },
  { category: "Postleitzahl", value: "24941", alias: "24941" },
  { category: "Postleitzahl", value: "24943", alias: "24943" },
  { category: "Postleitzahl", value: "24944", alias: "24944" },
  { category: "Postleitzahl", value: "24955", alias: "24955" },
  { category: "Postleitzahl", value: "24960", alias: "24960" },
  { category: "Postleitzahl", value: "24963", alias: "24963" },
  { category: "Postleitzahl", value: "24966", alias: "24966" },
  { category: "Postleitzahl", value: "24969", alias: "24969" },
  { category: "Postleitzahl", value: "24972", alias: "24972" },
  { category: "Postleitzahl", value: "24975", alias: "24975" },
  { category: "Postleitzahl", value: "24976", alias: "24976" },
  { category: "Postleitzahl", value: "24977", alias: "24977" },
  { category: "Postleitzahl", value: "24980", alias: "24980" },
  { category: "Postleitzahl", value: "24983", alias: "24983" },
  { category: "Postleitzahl", value: "24986", alias: "24986" },
  { category: "Postleitzahl", value: "24988", alias: "24988" },
  { category: "Postleitzahl", value: "24989", alias: "24989" },
  { category: "Postleitzahl", value: "24991", alias: "24991" },
  { category: "Postleitzahl", value: "24992", alias: "24992" },
  { category: "Postleitzahl", value: "24994", alias: "24994" },
  { category: "Postleitzahl", value: "24996", alias: "24996" },
  { category: "Postleitzahl", value: "24997", alias: "24997" },
  { category: "Postleitzahl", value: "24999", alias: "24999" },
  { category: "Postleitzahl", value: "25335", alias: "25335" },
  { category: "Postleitzahl", value: "25336", alias: "25336" },
  { category: "Postleitzahl", value: "25337", alias: "25337" },
  { category: "Postleitzahl", value: "25348", alias: "25348" },
  { category: "Postleitzahl", value: "25355", alias: "25355" },
  { category: "Postleitzahl", value: "25358", alias: "25358" },
  { category: "Postleitzahl", value: "25361", alias: "25361" },
  { category: "Postleitzahl", value: "25364", alias: "25364" },
  { category: "Postleitzahl", value: "25365", alias: "25365" },
  { category: "Postleitzahl", value: "25368", alias: "25368" },
  { category: "Postleitzahl", value: "25370", alias: "25370" },
  { category: "Postleitzahl", value: "25371", alias: "25371" },
  { category: "Postleitzahl", value: "25373", alias: "25373" },
  { category: "Postleitzahl", value: "25376", alias: "25376" },
  { category: "Postleitzahl", value: "25377", alias: "25377" },
  { category: "Postleitzahl", value: "25379", alias: "25379" },
  { category: "Postleitzahl", value: "25421", alias: "25421" },
  { category: "Postleitzahl", value: "25436", alias: "25436" },
  { category: "Postleitzahl", value: "25451", alias: "25451" },
  { category: "Postleitzahl", value: "25462", alias: "25462" },
  { category: "Postleitzahl", value: "25469", alias: "25469" },
  { category: "Postleitzahl", value: "25474", alias: "25474" },
  { category: "Postleitzahl", value: "25479", alias: "25479" },
  { category: "Postleitzahl", value: "25482", alias: "25482" },
  { category: "Postleitzahl", value: "25485", alias: "25485" },
  { category: "Postleitzahl", value: "25486", alias: "25486" },
  { category: "Postleitzahl", value: "25488", alias: "25488" },
  { category: "Postleitzahl", value: "25489", alias: "25489" },
  { category: "Postleitzahl", value: "25491", alias: "25491" },
  { category: "Postleitzahl", value: "25492", alias: "25492" },
  { category: "Postleitzahl", value: "25494", alias: "25494" },
  { category: "Postleitzahl", value: "25495", alias: "25495" },
  { category: "Postleitzahl", value: "25497", alias: "25497" },
  { category: "Postleitzahl", value: "25499", alias: "25499" },
  { category: "Postleitzahl", value: "25524", alias: "25524" },
  { category: "Postleitzahl", value: "25541", alias: "25541" },
  { category: "Postleitzahl", value: "25548", alias: "25548" },
  { category: "Postleitzahl", value: "25551", alias: "25551" },
  { category: "Postleitzahl", value: "25554", alias: "25554" },
  { category: "Postleitzahl", value: "25557", alias: "25557" },
  { category: "Postleitzahl", value: "25560", alias: "25560" },
  { category: "Postleitzahl", value: "25563", alias: "25563" },
  { category: "Postleitzahl", value: "25566", alias: "25566" },
  { category: "Postleitzahl", value: "25569", alias: "25569" },
  { category: "Postleitzahl", value: "25572", alias: "25572" },
  { category: "Postleitzahl", value: "25573", alias: "25573" },
  { category: "Postleitzahl", value: "25575", alias: "25575" },
  { category: "Postleitzahl", value: "25576", alias: "25576" },
  { category: "Postleitzahl", value: "25578", alias: "25578" },
  { category: "Postleitzahl", value: "25579", alias: "25579" },
  { category: "Postleitzahl", value: "25581", alias: "25581" },
  { category: "Postleitzahl", value: "25582", alias: "25582" },
  { category: "Postleitzahl", value: "25584", alias: "25584" },
  { category: "Postleitzahl", value: "25585", alias: "25585" },
  { category: "Postleitzahl", value: "25587", alias: "25587" },
  { category: "Postleitzahl", value: "25588", alias: "25588" },
  { category: "Postleitzahl", value: "25590", alias: "25590" },
  { category: "Postleitzahl", value: "25591", alias: "25591" },
  { category: "Postleitzahl", value: "25593", alias: "25593" },
  { category: "Postleitzahl", value: "25594", alias: "25594" },
  { category: "Postleitzahl", value: "25596", alias: "25596" },
  { category: "Postleitzahl", value: "25597", alias: "25597" },
  { category: "Postleitzahl", value: "25599", alias: "25599" },
  { category: "Postleitzahl", value: "25693", alias: "25693" },
  { category: "Postleitzahl", value: "25704", alias: "25704" },
  { category: "Postleitzahl", value: "25709", alias: "25709" },
  { category: "Postleitzahl", value: "25712", alias: "25712" },
  { category: "Postleitzahl", value: "25715", alias: "25715" },
  { category: "Postleitzahl", value: "25718", alias: "25718" },
  { category: "Postleitzahl", value: "25719", alias: "25719" },
  { category: "Postleitzahl", value: "25721", alias: "25721" },
  { category: "Postleitzahl", value: "25724", alias: "25724" },
  { category: "Postleitzahl", value: "25725", alias: "25725" },
  { category: "Postleitzahl", value: "25727", alias: "25727" },
  { category: "Postleitzahl", value: "25729", alias: "25729" },
  { category: "Postleitzahl", value: "25746", alias: "25746" },
  { category: "Postleitzahl", value: "25761", alias: "25761" },
  { category: "Postleitzahl", value: "25764", alias: "25764" },
  { category: "Postleitzahl", value: "25767", alias: "25767" },
  { category: "Postleitzahl", value: "25770", alias: "25770" },
  { category: "Postleitzahl", value: "25774", alias: "25774" },
  { category: "Postleitzahl", value: "25776", alias: "25776" },
  { category: "Postleitzahl", value: "25779", alias: "25779" },
  { category: "Postleitzahl", value: "25782", alias: "25782" },
  { category: "Postleitzahl", value: "25785", alias: "25785" },
  { category: "Postleitzahl", value: "25786", alias: "25786" },
  { category: "Postleitzahl", value: "25788", alias: "25788" },
  { category: "Postleitzahl", value: "25791", alias: "25791" },
  { category: "Postleitzahl", value: "25792", alias: "25792" },
  { category: "Postleitzahl", value: "25794", alias: "25794" },
  { category: "Postleitzahl", value: "25795", alias: "25795" },
  { category: "Postleitzahl", value: "25797", alias: "25797" },
  { category: "Postleitzahl", value: "25799", alias: "25799" },
  { category: "Postleitzahl", value: "25813", alias: "25813" },
  { category: "Postleitzahl", value: "25821", alias: "25821" },
  { category: "Postleitzahl", value: "25826", alias: "25826" },
  { category: "Postleitzahl", value: "25832", alias: "25832" },
  { category: "Postleitzahl", value: "25836", alias: "25836" },
  { category: "Postleitzahl", value: "25840", alias: "25840" },
  { category: "Postleitzahl", value: "25842", alias: "25842" },
  { category: "Postleitzahl", value: "25845", alias: "25845" },
  { category: "Postleitzahl", value: "25849", alias: "25849" },
  { category: "Postleitzahl", value: "25850", alias: "25850" },
  { category: "Postleitzahl", value: "25852", alias: "25852" },
  { category: "Postleitzahl", value: "25853", alias: "25853" },
  { category: "Postleitzahl", value: "25855", alias: "25855" },
  { category: "Postleitzahl", value: "25856", alias: "25856" },
  { category: "Postleitzahl", value: "25858", alias: "25858" },
  { category: "Postleitzahl", value: "25859", alias: "25859" },
  { category: "Postleitzahl", value: "25860", alias: "25860" },
  { category: "Postleitzahl", value: "25862", alias: "25862" },
  { category: "Postleitzahl", value: "25863", alias: "25863" },
  { category: "Postleitzahl", value: "25864", alias: "25864" },
  { category: "Postleitzahl", value: "25866", alias: "25866" },
  { category: "Postleitzahl", value: "25867", alias: "25867" },
  { category: "Postleitzahl", value: "25869", alias: "25869" },
  { category: "Postleitzahl", value: "25870", alias: "25870" },
  { category: "Postleitzahl", value: "25872", alias: "25872" },
  { category: "Postleitzahl", value: "25873", alias: "25873" },
  { category: "Postleitzahl", value: "25876", alias: "25876" },
  { category: "Postleitzahl", value: "25878", alias: "25878" },
  { category: "Postleitzahl", value: "25879", alias: "25879" },
  { category: "Postleitzahl", value: "25881", alias: "25881" },
  { category: "Postleitzahl", value: "25882", alias: "25882" },
  { category: "Postleitzahl", value: "25884", alias: "25884" },
  { category: "Postleitzahl", value: "25885", alias: "25885" },
  { category: "Postleitzahl", value: "25887", alias: "25887" },
  { category: "Postleitzahl", value: "25889", alias: "25889" },
  { category: "Postleitzahl", value: "25899", alias: "25899" },
  { category: "Postleitzahl", value: "25917", alias: "25917" },
  { category: "Postleitzahl", value: "25920", alias: "25920" },
  { category: "Postleitzahl", value: "25923", alias: "25923" },
  { category: "Postleitzahl", value: "25924", alias: "25924" },
  { category: "Postleitzahl", value: "25926", alias: "25926" },
  { category: "Postleitzahl", value: "25927", alias: "25927" },
  { category: "Postleitzahl", value: "25938", alias: "25938" },
  { category: "Postleitzahl", value: "25946", alias: "25946" },
  { category: "Postleitzahl", value: "25980", alias: "25980" },
  { category: "Postleitzahl", value: "25992", alias: "25992" },
  { category: "Postleitzahl", value: "25996", alias: "25996" },
  { category: "Postleitzahl", value: "25997", alias: "25997" },
  { category: "Postleitzahl", value: "25999", alias: "25999" },
  { category: "Postleitzahl", value: "26121", alias: "26121" },
  { category: "Postleitzahl", value: "26122", alias: "26122" },
  { category: "Postleitzahl", value: "26123", alias: "26123" },
  { category: "Postleitzahl", value: "26125", alias: "26125" },
  { category: "Postleitzahl", value: "26127", alias: "26127" },
  { category: "Postleitzahl", value: "26129", alias: "26129" },
  { category: "Postleitzahl", value: "26131", alias: "26131" },
  { category: "Postleitzahl", value: "26133", alias: "26133" },
  { category: "Postleitzahl", value: "26135", alias: "26135" },
  { category: "Postleitzahl", value: "26160", alias: "26160" },
  { category: "Postleitzahl", value: "26169", alias: "26169" },
  { category: "Postleitzahl", value: "26180", alias: "26180" },
  { category: "Postleitzahl", value: "26188", alias: "26188" },
  { category: "Postleitzahl", value: "26197", alias: "26197" },
  { category: "Postleitzahl", value: "26203", alias: "26203" },
  { category: "Postleitzahl", value: "26209", alias: "26209" },
  { category: "Postleitzahl", value: "26215", alias: "26215" },
  { category: "Postleitzahl", value: "26219", alias: "26219" },
  { category: "Postleitzahl", value: "26316", alias: "26316" },
  { category: "Postleitzahl", value: "26340", alias: "26340" },
  { category: "Postleitzahl", value: "26345", alias: "26345" },
  { category: "Postleitzahl", value: "26349", alias: "26349" },
  { category: "Postleitzahl", value: "26382", alias: "26382" },
  { category: "Postleitzahl", value: "26384", alias: "26384" },
  { category: "Postleitzahl", value: "26386", alias: "26386" },
  { category: "Postleitzahl", value: "26388", alias: "26388" },
  { category: "Postleitzahl", value: "26389", alias: "26389" },
  { category: "Postleitzahl", value: "26409", alias: "26409" },
  { category: "Postleitzahl", value: "26419", alias: "26419" },
  { category: "Postleitzahl", value: "26427", alias: "26427" },
  { category: "Postleitzahl", value: "26434", alias: "26434" },
  { category: "Postleitzahl", value: "26441", alias: "26441" },
  { category: "Postleitzahl", value: "26446", alias: "26446" },
  { category: "Postleitzahl", value: "26452", alias: "26452" },
  { category: "Postleitzahl", value: "26465", alias: "26465" },
  { category: "Postleitzahl", value: "26474", alias: "26474" },
  { category: "Postleitzahl", value: "26486", alias: "26486" },
  { category: "Postleitzahl", value: "26487", alias: "26487" },
  { category: "Postleitzahl", value: "26489", alias: "26489" },
  { category: "Postleitzahl", value: "26506", alias: "26506" },
  { category: "Postleitzahl", value: "26524", alias: "26524" },
  { category: "Postleitzahl", value: "26529", alias: "26529" },
  { category: "Postleitzahl", value: "26532", alias: "26532" },
  { category: "Postleitzahl", value: "26548", alias: "26548" },
  { category: "Postleitzahl", value: "26553", alias: "26553" },
  { category: "Postleitzahl", value: "26556", alias: "26556" },
  { category: "Postleitzahl", value: "26571", alias: "26571" },
  { category: "Postleitzahl", value: "26579", alias: "26579" },
  { category: "Postleitzahl", value: "26603", alias: "26603" },
  { category: "Postleitzahl", value: "26605", alias: "26605" },
  { category: "Postleitzahl", value: "26607", alias: "26607" },
  { category: "Postleitzahl", value: "26624", alias: "26624" },
  { category: "Postleitzahl", value: "26629", alias: "26629" },
  { category: "Postleitzahl", value: "26632", alias: "26632" },
  { category: "Postleitzahl", value: "26639", alias: "26639" },
  { category: "Postleitzahl", value: "26655", alias: "26655" },
  { category: "Postleitzahl", value: "26670", alias: "26670" },
  { category: "Postleitzahl", value: "26676", alias: "26676" },
  { category: "Postleitzahl", value: "26683", alias: "26683" },
  { category: "Postleitzahl", value: "26689", alias: "26689" },
  { category: "Postleitzahl", value: "26721", alias: "26721" },
  { category: "Postleitzahl", value: "26723", alias: "26723" },
  { category: "Postleitzahl", value: "26725", alias: "26725" },
  { category: "Postleitzahl", value: "26736", alias: "26736" },
  { category: "Postleitzahl", value: "26757", alias: "26757" },
  { category: "Postleitzahl", value: "26759", alias: "26759" },
  { category: "Postleitzahl", value: "26789", alias: "26789" },
  { category: "Postleitzahl", value: "26802", alias: "26802" },
  { category: "Postleitzahl", value: "26810", alias: "26810" },
  { category: "Postleitzahl", value: "26817", alias: "26817" },
  { category: "Postleitzahl", value: "26826", alias: "26826" },
  { category: "Postleitzahl", value: "26831", alias: "26831" },
  { category: "Postleitzahl", value: "26835", alias: "26835" },
  { category: "Postleitzahl", value: "26842", alias: "26842" },
  { category: "Postleitzahl", value: "26844", alias: "26844" },
  { category: "Postleitzahl", value: "26845", alias: "26845" },
  { category: "Postleitzahl", value: "26847", alias: "26847" },
  { category: "Postleitzahl", value: "26849", alias: "26849" },
  { category: "Postleitzahl", value: "26871", alias: "26871" },
  { category: "Postleitzahl", value: "26892", alias: "26892" },
  { category: "Postleitzahl", value: "26897", alias: "26897" },
  { category: "Postleitzahl", value: "26899", alias: "26899" },
  { category: "Postleitzahl", value: "26901", alias: "26901" },
  { category: "Postleitzahl", value: "26903", alias: "26903" },
  { category: "Postleitzahl", value: "26904", alias: "26904" },
  { category: "Postleitzahl", value: "26906", alias: "26906" },
  { category: "Postleitzahl", value: "26907", alias: "26907" },
  { category: "Postleitzahl", value: "26909", alias: "26909" },
  { category: "Postleitzahl", value: "26919", alias: "26919" },
  { category: "Postleitzahl", value: "26931", alias: "26931" },
  { category: "Postleitzahl", value: "26935", alias: "26935" },
  { category: "Postleitzahl", value: "26936", alias: "26936" },
  { category: "Postleitzahl", value: "26937", alias: "26937" },
  { category: "Postleitzahl", value: "26939", alias: "26939" },
  { category: "Postleitzahl", value: "26954", alias: "26954" },
  { category: "Postleitzahl", value: "26969", alias: "26969" },
  { category: "Postleitzahl", value: "27211", alias: "27211" },
  { category: "Postleitzahl", value: "27232", alias: "27232" },
  { category: "Postleitzahl", value: "27239", alias: "27239" },
  { category: "Postleitzahl", value: "27243", alias: "27243" },
  { category: "Postleitzahl", value: "27245", alias: "27245" },
  { category: "Postleitzahl", value: "27246", alias: "27246" },
  { category: "Postleitzahl", value: "27248", alias: "27248" },
  { category: "Postleitzahl", value: "27249", alias: "27249" },
  { category: "Postleitzahl", value: "27251", alias: "27251" },
  { category: "Postleitzahl", value: "27252", alias: "27252" },
  { category: "Postleitzahl", value: "27254", alias: "27254" },
  { category: "Postleitzahl", value: "27257", alias: "27257" },
  { category: "Postleitzahl", value: "27259", alias: "27259" },
  { category: "Postleitzahl", value: "27283", alias: "27283" },
  { category: "Postleitzahl", value: "27299", alias: "27299" },
  { category: "Postleitzahl", value: "27305", alias: "27305" },
  { category: "Postleitzahl", value: "27308", alias: "27308" },
  { category: "Postleitzahl", value: "27313", alias: "27313" },
  { category: "Postleitzahl", value: "27318", alias: "27318" },
  { category: "Postleitzahl", value: "27321", alias: "27321" },
  { category: "Postleitzahl", value: "27324", alias: "27324" },
  { category: "Postleitzahl", value: "27327", alias: "27327" },
  { category: "Postleitzahl", value: "27330", alias: "27330" },
  { category: "Postleitzahl", value: "27333", alias: "27333" },
  { category: "Postleitzahl", value: "27336", alias: "27336" },
  { category: "Postleitzahl", value: "27337", alias: "27337" },
  { category: "Postleitzahl", value: "27339", alias: "27339" },
  { category: "Postleitzahl", value: "27356", alias: "27356" },
  { category: "Postleitzahl", value: "27367", alias: "27367" },
  { category: "Postleitzahl", value: "27374", alias: "27374" },
  { category: "Postleitzahl", value: "27383", alias: "27383" },
  { category: "Postleitzahl", value: "27386", alias: "27386" },
  { category: "Postleitzahl", value: "27389", alias: "27389" },
  { category: "Postleitzahl", value: "27404", alias: "27404" },
  { category: "Postleitzahl", value: "27412", alias: "27412" },
  { category: "Postleitzahl", value: "27419", alias: "27419" },
  { category: "Postleitzahl", value: "27432", alias: "27432" },
  { category: "Postleitzahl", value: "27442", alias: "27442" },
  { category: "Postleitzahl", value: "27446", alias: "27446" },
  { category: "Postleitzahl", value: "27449", alias: "27449" },
  { category: "Postleitzahl", value: "27472", alias: "27472" },
  { category: "Postleitzahl", value: "27474", alias: "27474" },
  { category: "Postleitzahl", value: "27476", alias: "27476" },
  { category: "Postleitzahl", value: "27478", alias: "27478" },
  { category: "Postleitzahl", value: "27498", alias: "27498" },
  { category: "Postleitzahl", value: "27499", alias: "27499" },
  { category: "Postleitzahl", value: "27568", alias: "27568" },
  { category: "Postleitzahl", value: "27570", alias: "27570" },
  { category: "Postleitzahl", value: "27572", alias: "27572" },
  { category: "Postleitzahl", value: "27574", alias: "27574" },
  { category: "Postleitzahl", value: "27576", alias: "27576" },
  { category: "Postleitzahl", value: "27578", alias: "27578" },
  { category: "Postleitzahl", value: "27580", alias: "27580" },
  { category: "Postleitzahl", value: "27607", alias: "27607" },
  { category: "Postleitzahl", value: "27612", alias: "27612" },
  { category: "Postleitzahl", value: "27616", alias: "27616" },
  { category: "Postleitzahl", value: "27619", alias: "27619" },
  { category: "Postleitzahl", value: "27624", alias: "27624" },
  { category: "Postleitzahl", value: "27628", alias: "27628" },
  { category: "Postleitzahl", value: "27639", alias: "27639" },
  { category: "Postleitzahl", value: "27711", alias: "27711" },
  { category: "Postleitzahl", value: "27721", alias: "27721" },
  { category: "Postleitzahl", value: "27726", alias: "27726" },
  { category: "Postleitzahl", value: "27729", alias: "27729" },
  { category: "Postleitzahl", value: "27749", alias: "27749" },
  { category: "Postleitzahl", value: "27751", alias: "27751" },
  { category: "Postleitzahl", value: "27753", alias: "27753" },
  { category: "Postleitzahl", value: "27755", alias: "27755" },
  { category: "Postleitzahl", value: "27777", alias: "27777" },
  { category: "Postleitzahl", value: "27793", alias: "27793" },
  { category: "Postleitzahl", value: "27798", alias: "27798" },
  { category: "Postleitzahl", value: "27801", alias: "27801" },
  { category: "Postleitzahl", value: "27804", alias: "27804" },
  { category: "Postleitzahl", value: "27809", alias: "27809" },
  { category: "Postleitzahl", value: "28195", alias: "28195" },
  { category: "Postleitzahl", value: "28197", alias: "28197" },
  { category: "Postleitzahl", value: "28199", alias: "28199" },
  { category: "Postleitzahl", value: "28201", alias: "28201" },
  { category: "Postleitzahl", value: "28203", alias: "28203" },
  { category: "Postleitzahl", value: "28205", alias: "28205" },
  { category: "Postleitzahl", value: "28207", alias: "28207" },
  { category: "Postleitzahl", value: "28209", alias: "28209" },
  { category: "Postleitzahl", value: "28211", alias: "28211" },
  { category: "Postleitzahl", value: "28213", alias: "28213" },
  { category: "Postleitzahl", value: "28215", alias: "28215" },
  { category: "Postleitzahl", value: "28217", alias: "28217" },
  { category: "Postleitzahl", value: "28219", alias: "28219" },
  { category: "Postleitzahl", value: "28237", alias: "28237" },
  { category: "Postleitzahl", value: "28239", alias: "28239" },
  { category: "Postleitzahl", value: "28259", alias: "28259" },
  { category: "Postleitzahl", value: "28277", alias: "28277" },
  { category: "Postleitzahl", value: "28279", alias: "28279" },
  { category: "Postleitzahl", value: "28307", alias: "28307" },
  { category: "Postleitzahl", value: "28309", alias: "28309" },
  { category: "Postleitzahl", value: "28325", alias: "28325" },
  { category: "Postleitzahl", value: "28327", alias: "28327" },
  { category: "Postleitzahl", value: "28329", alias: "28329" },
  { category: "Postleitzahl", value: "28355", alias: "28355" },
  { category: "Postleitzahl", value: "28357", alias: "28357" },
  { category: "Postleitzahl", value: "28359", alias: "28359" },
  { category: "Postleitzahl", value: "28717", alias: "28717" },
  { category: "Postleitzahl", value: "28719", alias: "28719" },
  { category: "Postleitzahl", value: "28755", alias: "28755" },
  { category: "Postleitzahl", value: "28757", alias: "28757" },
  { category: "Postleitzahl", value: "28759", alias: "28759" },
  { category: "Postleitzahl", value: "28777", alias: "28777" },
  { category: "Postleitzahl", value: "28779", alias: "28779" },
  { category: "Postleitzahl", value: "28790", alias: "28790" },
  { category: "Postleitzahl", value: "28816", alias: "28816" },
  { category: "Postleitzahl", value: "28832", alias: "28832" },
  { category: "Postleitzahl", value: "28844", alias: "28844" },
  { category: "Postleitzahl", value: "28857", alias: "28857" },
  { category: "Postleitzahl", value: "28865", alias: "28865" },
  { category: "Postleitzahl", value: "28870", alias: "28870" },
  { category: "Postleitzahl", value: "28876", alias: "28876" },
  { category: "Postleitzahl", value: "28879", alias: "28879" },
  { category: "Postleitzahl", value: "29221", alias: "29221" },
  { category: "Postleitzahl", value: "29223", alias: "29223" },
  { category: "Postleitzahl", value: "29225", alias: "29225" },
  { category: "Postleitzahl", value: "29227", alias: "29227" },
  { category: "Postleitzahl", value: "29229", alias: "29229" },
  { category: "Postleitzahl", value: "29303", alias: "29303" },
  { category: "Postleitzahl", value: "29308", alias: "29308" },
  { category: "Postleitzahl", value: "29313", alias: "29313" },
  { category: "Postleitzahl", value: "29320", alias: "29320" },
  { category: "Postleitzahl", value: "29323", alias: "29323" },
  { category: "Postleitzahl", value: "29328", alias: "29328" },
  { category: "Postleitzahl", value: "29331", alias: "29331" },
  { category: "Postleitzahl", value: "29336", alias: "29336" },
  { category: "Postleitzahl", value: "29339", alias: "29339" },
  { category: "Postleitzahl", value: "29342", alias: "29342" },
  { category: "Postleitzahl", value: "29345", alias: "29345" },
  { category: "Postleitzahl", value: "29348", alias: "29348" },
  { category: "Postleitzahl", value: "29351", alias: "29351" },
  { category: "Postleitzahl", value: "29352", alias: "29352" },
  { category: "Postleitzahl", value: "29353", alias: "29353" },
  { category: "Postleitzahl", value: "29355", alias: "29355" },
  { category: "Postleitzahl", value: "29356", alias: "29356" },
  { category: "Postleitzahl", value: "29358", alias: "29358" },
  { category: "Postleitzahl", value: "29359", alias: "29359" },
  { category: "Postleitzahl", value: "29361", alias: "29361" },
  { category: "Postleitzahl", value: "29362", alias: "29362" },
  { category: "Postleitzahl", value: "29364", alias: "29364" },
  { category: "Postleitzahl", value: "29365", alias: "29365" },
  { category: "Postleitzahl", value: "29367", alias: "29367" },
  { category: "Postleitzahl", value: "29369", alias: "29369" },
  { category: "Postleitzahl", value: "29378", alias: "29378" },
  { category: "Postleitzahl", value: "29379", alias: "29379" },
  { category: "Postleitzahl", value: "29386", alias: "29386" },
  { category: "Postleitzahl", value: "29389", alias: "29389" },
  { category: "Postleitzahl", value: "29392", alias: "29392" },
  { category: "Postleitzahl", value: "29393", alias: "29393" },
  { category: "Postleitzahl", value: "29394", alias: "29394" },
  { category: "Postleitzahl", value: "29396", alias: "29396" },
  { category: "Postleitzahl", value: "29399", alias: "29399" },
  { category: "Postleitzahl", value: "29410", alias: "29410" },
  { category: "Postleitzahl", value: "29413", alias: "29413" },
  { category: "Postleitzahl", value: "29416", alias: "29416" },
  { category: "Postleitzahl", value: "29439", alias: "29439" },
  { category: "Postleitzahl", value: "29451", alias: "29451" },
  { category: "Postleitzahl", value: "29456", alias: "29456" },
  { category: "Postleitzahl", value: "29459", alias: "29459" },
  { category: "Postleitzahl", value: "29462", alias: "29462" },
  { category: "Postleitzahl", value: "29465", alias: "29465" },
  { category: "Postleitzahl", value: "29468", alias: "29468" },
  { category: "Postleitzahl", value: "29471", alias: "29471" },
  { category: "Postleitzahl", value: "29472", alias: "29472" },
  { category: "Postleitzahl", value: "29473", alias: "29473" },
  { category: "Postleitzahl", value: "29475", alias: "29475" },
  { category: "Postleitzahl", value: "29476", alias: "29476" },
  { category: "Postleitzahl", value: "29478", alias: "29478" },
  { category: "Postleitzahl", value: "29479", alias: "29479" },
  { category: "Postleitzahl", value: "29481", alias: "29481" },
  { category: "Postleitzahl", value: "29482", alias: "29482" },
  { category: "Postleitzahl", value: "29484", alias: "29484" },
  { category: "Postleitzahl", value: "29485", alias: "29485" },
  { category: "Postleitzahl", value: "29487", alias: "29487" },
  { category: "Postleitzahl", value: "29488", alias: "29488" },
  { category: "Postleitzahl", value: "29490", alias: "29490" },
  { category: "Postleitzahl", value: "29491", alias: "29491" },
  { category: "Postleitzahl", value: "29493", alias: "29493" },
  { category: "Postleitzahl", value: "29494", alias: "29494" },
  { category: "Postleitzahl", value: "29496", alias: "29496" },
  { category: "Postleitzahl", value: "29497", alias: "29497" },
  { category: "Postleitzahl", value: "29499", alias: "29499" },
  { category: "Postleitzahl", value: "29525", alias: "29525" },
  { category: "Postleitzahl", value: "29549", alias: "29549" },
  { category: "Postleitzahl", value: "29553", alias: "29553" },
  { category: "Postleitzahl", value: "29556", alias: "29556" },
  { category: "Postleitzahl", value: "29559", alias: "29559" },
  { category: "Postleitzahl", value: "29562", alias: "29562" },
  { category: "Postleitzahl", value: "29565", alias: "29565" },
  { category: "Postleitzahl", value: "29571", alias: "29571" },
  { category: "Postleitzahl", value: "29574", alias: "29574" },
  { category: "Postleitzahl", value: "29575", alias: "29575" },
  { category: "Postleitzahl", value: "29576", alias: "29576" },
  { category: "Postleitzahl", value: "29578", alias: "29578" },
  { category: "Postleitzahl", value: "29579", alias: "29579" },
  { category: "Postleitzahl", value: "29581", alias: "29581" },
  { category: "Postleitzahl", value: "29582", alias: "29582" },
  { category: "Postleitzahl", value: "29584", alias: "29584" },
  { category: "Postleitzahl", value: "29585", alias: "29585" },
  { category: "Postleitzahl", value: "29587", alias: "29587" },
  { category: "Postleitzahl", value: "29588", alias: "29588" },
  { category: "Postleitzahl", value: "29590", alias: "29590" },
  { category: "Postleitzahl", value: "29591", alias: "29591" },
  { category: "Postleitzahl", value: "29593", alias: "29593" },
  { category: "Postleitzahl", value: "29594", alias: "29594" },
  { category: "Postleitzahl", value: "29597", alias: "29597" },
  { category: "Postleitzahl", value: "29599", alias: "29599" },
  { category: "Postleitzahl", value: "29614", alias: "29614" },
  { category: "Postleitzahl", value: "29633", alias: "29633" },
  { category: "Postleitzahl", value: "29640", alias: "29640" },
  { category: "Postleitzahl", value: "29643", alias: "29643" },
  { category: "Postleitzahl", value: "29646", alias: "29646" },
  { category: "Postleitzahl", value: "29649", alias: "29649" },
  { category: "Postleitzahl", value: "29664", alias: "29664" },
  { category: "Postleitzahl", value: "29683", alias: "29683" },
  { category: "Postleitzahl", value: "29690", alias: "29690" },
  { category: "Postleitzahl", value: "29693", alias: "29693" },
  { category: "Postleitzahl", value: "29699", alias: "29699" },
  { category: "Postleitzahl", value: "30159", alias: "30159" },
  { category: "Postleitzahl", value: "30161", alias: "30161" },
  { category: "Postleitzahl", value: "30163", alias: "30163" },
  { category: "Postleitzahl", value: "30165", alias: "30165" },
  { category: "Postleitzahl", value: "30167", alias: "30167" },
  { category: "Postleitzahl", value: "30169", alias: "30169" },
  { category: "Postleitzahl", value: "30171", alias: "30171" },
  { category: "Postleitzahl", value: "30173", alias: "30173" },
  { category: "Postleitzahl", value: "30175", alias: "30175" },
  { category: "Postleitzahl", value: "30177", alias: "30177" },
  { category: "Postleitzahl", value: "30179", alias: "30179" },
  { category: "Postleitzahl", value: "30419", alias: "30419" },
  { category: "Postleitzahl", value: "30449", alias: "30449" },
  { category: "Postleitzahl", value: "30451", alias: "30451" },
  { category: "Postleitzahl", value: "30453", alias: "30453" },
  { category: "Postleitzahl", value: "30455", alias: "30455" },
  { category: "Postleitzahl", value: "30457", alias: "30457" },
  { category: "Postleitzahl", value: "30459", alias: "30459" },
  { category: "Postleitzahl", value: "30519", alias: "30519" },
  { category: "Postleitzahl", value: "30521", alias: "30521" },
  { category: "Postleitzahl", value: "30539", alias: "30539" },
  { category: "Postleitzahl", value: "30559", alias: "30559" },
  { category: "Postleitzahl", value: "30625", alias: "30625" },
  { category: "Postleitzahl", value: "30627", alias: "30627" },
  { category: "Postleitzahl", value: "30629", alias: "30629" },
  { category: "Postleitzahl", value: "30655", alias: "30655" },
  { category: "Postleitzahl", value: "30657", alias: "30657" },
  { category: "Postleitzahl", value: "30659", alias: "30659" },
  { category: "Postleitzahl", value: "30669", alias: "30669" },
  { category: "Postleitzahl", value: "30823", alias: "30823" },
  { category: "Postleitzahl", value: "30826", alias: "30826" },
  { category: "Postleitzahl", value: "30827", alias: "30827" },
  { category: "Postleitzahl", value: "30851", alias: "30851" },
  { category: "Postleitzahl", value: "30853", alias: "30853" },
  { category: "Postleitzahl", value: "30855", alias: "30855" },
  { category: "Postleitzahl", value: "30880", alias: "30880" },
  { category: "Postleitzahl", value: "30890", alias: "30890" },
  { category: "Postleitzahl", value: "30900", alias: "30900" },
  { category: "Postleitzahl", value: "30916", alias: "30916" },
  { category: "Postleitzahl", value: "30926", alias: "30926" },
  { category: "Postleitzahl", value: "30938", alias: "30938" },
  { category: "Postleitzahl", value: "30952", alias: "30952" },
  { category: "Postleitzahl", value: "30966", alias: "30966" },
  { category: "Postleitzahl", value: "30974", alias: "30974" },
  { category: "Postleitzahl", value: "30982", alias: "30982" },
  { category: "Postleitzahl", value: "30989", alias: "30989" },
  { category: "Postleitzahl", value: "31008", alias: "31008" },
  { category: "Postleitzahl", value: "31020", alias: "31020" },
  { category: "Postleitzahl", value: "31028", alias: "31028" },
  { category: "Postleitzahl", value: "31036", alias: "31036" },
  { category: "Postleitzahl", value: "31061", alias: "31061" },
  { category: "Postleitzahl", value: "31073", alias: "31073" },
  { category: "Postleitzahl", value: "31079", alias: "31079" },
  { category: "Postleitzahl", value: "31084", alias: "31084" },
  { category: "Postleitzahl", value: "31089", alias: "31089" },
  { category: "Postleitzahl", value: "31134", alias: "31134" },
  { category: "Postleitzahl", value: "31135", alias: "31135" },
  { category: "Postleitzahl", value: "31137", alias: "31137" },
  { category: "Postleitzahl", value: "31139", alias: "31139" },
  { category: "Postleitzahl", value: "31141", alias: "31141" },
  { category: "Postleitzahl", value: "31157", alias: "31157" },
  { category: "Postleitzahl", value: "31162", alias: "31162" },
  { category: "Postleitzahl", value: "31167", alias: "31167" },
  { category: "Postleitzahl", value: "31171", alias: "31171" },
  { category: "Postleitzahl", value: "31174", alias: "31174" },
  { category: "Postleitzahl", value: "31177", alias: "31177" },
  { category: "Postleitzahl", value: "31180", alias: "31180" },
  { category: "Postleitzahl", value: "31185", alias: "31185" },
  { category: "Postleitzahl", value: "31188", alias: "31188" },
  { category: "Postleitzahl", value: "31191", alias: "31191" },
  { category: "Postleitzahl", value: "31195", alias: "31195" },
  { category: "Postleitzahl", value: "31199", alias: "31199" },
  { category: "Postleitzahl", value: "31224", alias: "31224" },
  { category: "Postleitzahl", value: "31226", alias: "31226" },
  { category: "Postleitzahl", value: "31228", alias: "31228" },
  { category: "Postleitzahl", value: "31234", alias: "31234" },
  { category: "Postleitzahl", value: "31241", alias: "31241" },
  { category: "Postleitzahl", value: "31246", alias: "31246" },
  { category: "Postleitzahl", value: "31249", alias: "31249" },
  { category: "Postleitzahl", value: "31275", alias: "31275" },
  { category: "Postleitzahl", value: "31303", alias: "31303" },
  { category: "Postleitzahl", value: "31311", alias: "31311" },
  { category: "Postleitzahl", value: "31319", alias: "31319" },
  { category: "Postleitzahl", value: "31515", alias: "31515" },
  { category: "Postleitzahl", value: "31535", alias: "31535" },
  { category: "Postleitzahl", value: "31542", alias: "31542" },
  { category: "Postleitzahl", value: "31547", alias: "31547" },
  { category: "Postleitzahl", value: "31552", alias: "31552" },
  { category: "Postleitzahl", value: "31553", alias: "31553" },
  { category: "Postleitzahl", value: "31555", alias: "31555" },
  { category: "Postleitzahl", value: "31556", alias: "31556" },
  { category: "Postleitzahl", value: "31558", alias: "31558" },
  { category: "Postleitzahl", value: "31559", alias: "31559" },
  { category: "Postleitzahl", value: "31582", alias: "31582" },
  { category: "Postleitzahl", value: "31592", alias: "31592" },
  { category: "Postleitzahl", value: "31595", alias: "31595" },
  { category: "Postleitzahl", value: "31600", alias: "31600" },
  { category: "Postleitzahl", value: "31603", alias: "31603" },
  { category: "Postleitzahl", value: "31604", alias: "31604" },
  { category: "Postleitzahl", value: "31606", alias: "31606" },
  { category: "Postleitzahl", value: "31608", alias: "31608" },
  { category: "Postleitzahl", value: "31609", alias: "31609" },
  { category: "Postleitzahl", value: "31613", alias: "31613" },
  { category: "Postleitzahl", value: "31618", alias: "31618" },
  { category: "Postleitzahl", value: "31619", alias: "31619" },
  { category: "Postleitzahl", value: "31621", alias: "31621" },
  { category: "Postleitzahl", value: "31622", alias: "31622" },
  { category: "Postleitzahl", value: "31623", alias: "31623" },
  { category: "Postleitzahl", value: "31626", alias: "31626" },
  { category: "Postleitzahl", value: "31627", alias: "31627" },
  { category: "Postleitzahl", value: "31628", alias: "31628" },
  { category: "Postleitzahl", value: "31629", alias: "31629" },
  { category: "Postleitzahl", value: "31632", alias: "31632" },
  { category: "Postleitzahl", value: "31633", alias: "31633" },
  { category: "Postleitzahl", value: "31634", alias: "31634" },
  { category: "Postleitzahl", value: "31636", alias: "31636" },
  { category: "Postleitzahl", value: "31637", alias: "31637" },
  { category: "Postleitzahl", value: "31638", alias: "31638" },
  { category: "Postleitzahl", value: "31655", alias: "31655" },
  { category: "Postleitzahl", value: "31675", alias: "31675" },
  { category: "Postleitzahl", value: "31683", alias: "31683" },
  { category: "Postleitzahl", value: "31688", alias: "31688" },
  { category: "Postleitzahl", value: "31691", alias: "31691" },
  { category: "Postleitzahl", value: "31693", alias: "31693" },
  { category: "Postleitzahl", value: "31698", alias: "31698" },
  { category: "Postleitzahl", value: "31699", alias: "31699" },
  { category: "Postleitzahl", value: "31700", alias: "31700" },
  { category: "Postleitzahl", value: "31702", alias: "31702" },
  { category: "Postleitzahl", value: "31707", alias: "31707" },
  { category: "Postleitzahl", value: "31708", alias: "31708" },
  { category: "Postleitzahl", value: "31710", alias: "31710" },
  { category: "Postleitzahl", value: "31711", alias: "31711" },
  { category: "Postleitzahl", value: "31712", alias: "31712" },
  { category: "Postleitzahl", value: "31714", alias: "31714" },
  { category: "Postleitzahl", value: "31715", alias: "31715" },
  { category: "Postleitzahl", value: "31717", alias: "31717" },
  { category: "Postleitzahl", value: "31718", alias: "31718" },
  { category: "Postleitzahl", value: "31719", alias: "31719" },
  { category: "Postleitzahl", value: "31737", alias: "31737" },
  { category: "Postleitzahl", value: "31749", alias: "31749" },
  { category: "Postleitzahl", value: "31785", alias: "31785" },
  { category: "Postleitzahl", value: "31787", alias: "31787" },
  { category: "Postleitzahl", value: "31789", alias: "31789" },
  { category: "Postleitzahl", value: "31812", alias: "31812" },
  { category: "Postleitzahl", value: "31832", alias: "31832" },
  { category: "Postleitzahl", value: "31840", alias: "31840" },
  { category: "Postleitzahl", value: "31848", alias: "31848" },
  { category: "Postleitzahl", value: "31855", alias: "31855" },
  { category: "Postleitzahl", value: "31860", alias: "31860" },
  { category: "Postleitzahl", value: "31863", alias: "31863" },
  { category: "Postleitzahl", value: "31867", alias: "31867" },
  { category: "Postleitzahl", value: "31868", alias: "31868" },
  { category: "Postleitzahl", value: "32049", alias: "32049" },
  { category: "Postleitzahl", value: "32051", alias: "32051" },
  { category: "Postleitzahl", value: "32052", alias: "32052" },
  { category: "Postleitzahl", value: "32105", alias: "32105" },
  { category: "Postleitzahl", value: "32107", alias: "32107" },
  { category: "Postleitzahl", value: "32108", alias: "32108" },
  { category: "Postleitzahl", value: "32120", alias: "32120" },
  { category: "Postleitzahl", value: "32130", alias: "32130" },
  { category: "Postleitzahl", value: "32139", alias: "32139" },
  { category: "Postleitzahl", value: "32257", alias: "32257" },
  { category: "Postleitzahl", value: "32278", alias: "32278" },
  { category: "Postleitzahl", value: "32289", alias: "32289" },
  { category: "Postleitzahl", value: "32312", alias: "32312" },
  { category: "Postleitzahl", value: "32339", alias: "32339" },
  { category: "Postleitzahl", value: "32351", alias: "32351" },
  { category: "Postleitzahl", value: "32361", alias: "32361" },
  { category: "Postleitzahl", value: "32369", alias: "32369" },
  { category: "Postleitzahl", value: "32423", alias: "32423" },
  { category: "Postleitzahl", value: "32425", alias: "32425" },
  { category: "Postleitzahl", value: "32427", alias: "32427" },
  { category: "Postleitzahl", value: "32429", alias: "32429" },
  { category: "Postleitzahl", value: "32457", alias: "32457" },
  { category: "Postleitzahl", value: "32469", alias: "32469" },
  { category: "Postleitzahl", value: "32479", alias: "32479" },
  { category: "Postleitzahl", value: "32545", alias: "32545" },
  { category: "Postleitzahl", value: "32547", alias: "32547" },
  { category: "Postleitzahl", value: "32549", alias: "32549" },
  { category: "Postleitzahl", value: "32584", alias: "32584" },
  { category: "Postleitzahl", value: "32602", alias: "32602" },
  { category: "Postleitzahl", value: "32609", alias: "32609" },
  { category: "Postleitzahl", value: "32657", alias: "32657" },
  { category: "Postleitzahl", value: "32676", alias: "32676" },
  { category: "Postleitzahl", value: "32683", alias: "32683" },
  { category: "Postleitzahl", value: "32689", alias: "32689" },
  { category: "Postleitzahl", value: "32694", alias: "32694" },
  { category: "Postleitzahl", value: "32699", alias: "32699" },
  { category: "Postleitzahl", value: "32756", alias: "32756" },
  { category: "Postleitzahl", value: "32758", alias: "32758" },
  { category: "Postleitzahl", value: "32760", alias: "32760" },
  { category: "Postleitzahl", value: "32791", alias: "32791" },
  { category: "Postleitzahl", value: "32805", alias: "32805" },
  { category: "Postleitzahl", value: "32816", alias: "32816" },
  { category: "Postleitzahl", value: "32825", alias: "32825" },
  { category: "Postleitzahl", value: "32832", alias: "32832" },
  { category: "Postleitzahl", value: "32839", alias: "32839" },
  { category: "Postleitzahl", value: "33014", alias: "33014" },
  { category: "Postleitzahl", value: "33034", alias: "33034" },
  { category: "Postleitzahl", value: "33039", alias: "33039" },
  { category: "Postleitzahl", value: "33098", alias: "33098" },
  { category: "Postleitzahl", value: "33100", alias: "33100" },
  { category: "Postleitzahl", value: "33102", alias: "33102" },
  { category: "Postleitzahl", value: "33104", alias: "33104" },
  { category: "Postleitzahl", value: "33106", alias: "33106" },
  { category: "Postleitzahl", value: "33129", alias: "33129" },
  { category: "Postleitzahl", value: "33142", alias: "33142" },
  { category: "Postleitzahl", value: "33154", alias: "33154" },
  { category: "Postleitzahl", value: "33161", alias: "33161" },
  { category: "Postleitzahl", value: "33165", alias: "33165" },
  { category: "Postleitzahl", value: "33175", alias: "33175" },
  { category: "Postleitzahl", value: "33178", alias: "33178" },
  { category: "Postleitzahl", value: "33181", alias: "33181" },
  { category: "Postleitzahl", value: "33184", alias: "33184" },
  { category: "Postleitzahl", value: "33189", alias: "33189" },
  { category: "Postleitzahl", value: "33330", alias: "33330" },
  { category: "Postleitzahl", value: "33332", alias: "33332" },
  { category: "Postleitzahl", value: "33333", alias: "33333" },
  { category: "Postleitzahl", value: "33334", alias: "33334" },
  { category: "Postleitzahl", value: "33335", alias: "33335" },
  { category: "Postleitzahl", value: "33378", alias: "33378" },
  { category: "Postleitzahl", value: "33397", alias: "33397" },
  { category: "Postleitzahl", value: "33415", alias: "33415" },
  { category: "Postleitzahl", value: "33428", alias: "33428" },
  { category: "Postleitzahl", value: "33442", alias: "33442" },
  { category: "Postleitzahl", value: "33449", alias: "33449" },
  { category: "Postleitzahl", value: "33602", alias: "33602" },
  { category: "Postleitzahl", value: "33604", alias: "33604" },
  { category: "Postleitzahl", value: "33605", alias: "33605" },
  { category: "Postleitzahl", value: "33607", alias: "33607" },
  { category: "Postleitzahl", value: "33609", alias: "33609" },
  { category: "Postleitzahl", value: "33611", alias: "33611" },
  { category: "Postleitzahl", value: "33613", alias: "33613" },
  { category: "Postleitzahl", value: "33615", alias: "33615" },
  { category: "Postleitzahl", value: "33617", alias: "33617" },
  { category: "Postleitzahl", value: "33619", alias: "33619" },
  { category: "Postleitzahl", value: "33647", alias: "33647" },
  { category: "Postleitzahl", value: "33649", alias: "33649" },
  { category: "Postleitzahl", value: "33659", alias: "33659" },
  { category: "Postleitzahl", value: "33689", alias: "33689" },
  { category: "Postleitzahl", value: "33699", alias: "33699" },
  { category: "Postleitzahl", value: "33719", alias: "33719" },
  { category: "Postleitzahl", value: "33729", alias: "33729" },
  { category: "Postleitzahl", value: "33739", alias: "33739" },
  { category: "Postleitzahl", value: "33758", alias: "33758" },
  { category: "Postleitzahl", value: "33775", alias: "33775" },
  { category: "Postleitzahl", value: "33790", alias: "33790" },
  { category: "Postleitzahl", value: "33803", alias: "33803" },
  { category: "Postleitzahl", value: "33813", alias: "33813" },
  { category: "Postleitzahl", value: "33818", alias: "33818" },
  { category: "Postleitzahl", value: "33824", alias: "33824" },
  { category: "Postleitzahl", value: "33829", alias: "33829" },
  { category: "Postleitzahl", value: "34117", alias: "34117" },
  { category: "Postleitzahl", value: "34119", alias: "34119" },
  { category: "Postleitzahl", value: "34121", alias: "34121" },
  { category: "Postleitzahl", value: "34123", alias: "34123" },
  { category: "Postleitzahl", value: "34125", alias: "34125" },
  { category: "Postleitzahl", value: "34127", alias: "34127" },
  { category: "Postleitzahl", value: "34128", alias: "34128" },
  { category: "Postleitzahl", value: "34130", alias: "34130" },
  { category: "Postleitzahl", value: "34131", alias: "34131" },
  { category: "Postleitzahl", value: "34132", alias: "34132" },
  { category: "Postleitzahl", value: "34134", alias: "34134" },
  { category: "Postleitzahl", value: "34212", alias: "34212" },
  { category: "Postleitzahl", value: "34225", alias: "34225" },
  { category: "Postleitzahl", value: "34233", alias: "34233" },
  { category: "Postleitzahl", value: "34246", alias: "34246" },
  { category: "Postleitzahl", value: "34253", alias: "34253" },
  { category: "Postleitzahl", value: "34260", alias: "34260" },
  { category: "Postleitzahl", value: "34266", alias: "34266" },
  { category: "Postleitzahl", value: "34270", alias: "34270" },
  { category: "Postleitzahl", value: "34277", alias: "34277" },
  { category: "Postleitzahl", value: "34281", alias: "34281" },
  { category: "Postleitzahl", value: "34286", alias: "34286" },
  { category: "Postleitzahl", value: "34289", alias: "34289" },
  { category: "Postleitzahl", value: "34292", alias: "34292" },
  { category: "Postleitzahl", value: "34295", alias: "34295" },
  { category: "Postleitzahl", value: "34298", alias: "34298" },
  { category: "Postleitzahl", value: "34302", alias: "34302" },
  { category: "Postleitzahl", value: "34305", alias: "34305" },
  { category: "Postleitzahl", value: "34308", alias: "34308" },
  { category: "Postleitzahl", value: "34311", alias: "34311" },
  { category: "Postleitzahl", value: "34314", alias: "34314" },
  { category: "Postleitzahl", value: "34317", alias: "34317" },
  { category: "Postleitzahl", value: "34320", alias: "34320" },
  { category: "Postleitzahl", value: "34323", alias: "34323" },
  { category: "Postleitzahl", value: "34326", alias: "34326" },
  { category: "Postleitzahl", value: "34327", alias: "34327" },
  { category: "Postleitzahl", value: "34329", alias: "34329" },
  { category: "Postleitzahl", value: "34346", alias: "34346" },
  { category: "Postleitzahl", value: "34355", alias: "34355" },
  { category: "Postleitzahl", value: "34359", alias: "34359" },
  { category: "Postleitzahl", value: "34369", alias: "34369" },
  { category: "Postleitzahl", value: "34376", alias: "34376" },
  { category: "Postleitzahl", value: "34379", alias: "34379" },
  { category: "Postleitzahl", value: "34385", alias: "34385" },
  { category: "Postleitzahl", value: "34388", alias: "34388" },
  { category: "Postleitzahl", value: "34393", alias: "34393" },
  { category: "Postleitzahl", value: "34396", alias: "34396" },
  { category: "Postleitzahl", value: "34399", alias: "34399" },
  { category: "Postleitzahl", value: "34414", alias: "34414" },
  { category: "Postleitzahl", value: "34431", alias: "34431" },
  { category: "Postleitzahl", value: "34434", alias: "34434" },
  { category: "Postleitzahl", value: "34439", alias: "34439" },
  { category: "Postleitzahl", value: "34454", alias: "34454" },
  { category: "Postleitzahl", value: "34466", alias: "34466" },
  { category: "Postleitzahl", value: "34471", alias: "34471" },
  { category: "Postleitzahl", value: "34474", alias: "34474" },
  { category: "Postleitzahl", value: "34477", alias: "34477" },
  { category: "Postleitzahl", value: "34479", alias: "34479" },
  { category: "Postleitzahl", value: "34497", alias: "34497" },
  { category: "Postleitzahl", value: "34508", alias: "34508" },
  { category: "Postleitzahl", value: "34513", alias: "34513" },
  { category: "Postleitzahl", value: "34516", alias: "34516" },
  { category: "Postleitzahl", value: "34519", alias: "34519" },
  { category: "Postleitzahl", value: "34537", alias: "34537" },
  { category: "Postleitzahl", value: "34549", alias: "34549" },
  { category: "Postleitzahl", value: "34560", alias: "34560" },
  { category: "Postleitzahl", value: "34576", alias: "34576" },
  { category: "Postleitzahl", value: "34582", alias: "34582" },
  { category: "Postleitzahl", value: "34587", alias: "34587" },
  { category: "Postleitzahl", value: "34590", alias: "34590" },
  { category: "Postleitzahl", value: "34593", alias: "34593" },
  { category: "Postleitzahl", value: "34596", alias: "34596" },
  { category: "Postleitzahl", value: "34599", alias: "34599" },
  { category: "Postleitzahl", value: "34613", alias: "34613" },
  { category: "Postleitzahl", value: "34621", alias: "34621" },
  { category: "Postleitzahl", value: "34626", alias: "34626" },
  { category: "Postleitzahl", value: "34628", alias: "34628" },
  { category: "Postleitzahl", value: "34630", alias: "34630" },
  { category: "Postleitzahl", value: "34632", alias: "34632" },
  { category: "Postleitzahl", value: "34633", alias: "34633" },
  { category: "Postleitzahl", value: "34637", alias: "34637" },
  { category: "Postleitzahl", value: "34639", alias: "34639" },
  { category: "Postleitzahl", value: "35037", alias: "35037" },
  { category: "Postleitzahl", value: "35039", alias: "35039" },
  { category: "Postleitzahl", value: "35041", alias: "35041" },
  { category: "Postleitzahl", value: "35043", alias: "35043" },
  { category: "Postleitzahl", value: "35066", alias: "35066" },
  { category: "Postleitzahl", value: "35075", alias: "35075" },
  { category: "Postleitzahl", value: "35080", alias: "35080" },
  { category: "Postleitzahl", value: "35083", alias: "35083" },
  { category: "Postleitzahl", value: "35085", alias: "35085" },
  { category: "Postleitzahl", value: "35088", alias: "35088" },
  { category: "Postleitzahl", value: "35091", alias: "35091" },
  { category: "Postleitzahl", value: "35094", alias: "35094" },
  { category: "Postleitzahl", value: "35096", alias: "35096" },
  { category: "Postleitzahl", value: "35099", alias: "35099" },
  { category: "Postleitzahl", value: "35102", alias: "35102" },
  { category: "Postleitzahl", value: "35104", alias: "35104" },
  { category: "Postleitzahl", value: "35108", alias: "35108" },
  { category: "Postleitzahl", value: "35110", alias: "35110" },
  { category: "Postleitzahl", value: "35112", alias: "35112" },
  { category: "Postleitzahl", value: "35114", alias: "35114" },
  { category: "Postleitzahl", value: "35116", alias: "35116" },
  { category: "Postleitzahl", value: "35117", alias: "35117" },
  { category: "Postleitzahl", value: "35119", alias: "35119" },
  { category: "Postleitzahl", value: "35216", alias: "35216" },
  { category: "Postleitzahl", value: "35232", alias: "35232" },
  { category: "Postleitzahl", value: "35236", alias: "35236" },
  { category: "Postleitzahl", value: "35239", alias: "35239" },
  { category: "Postleitzahl", value: "35260", alias: "35260" },
  { category: "Postleitzahl", value: "35274", alias: "35274" },
  { category: "Postleitzahl", value: "35279", alias: "35279" },
  { category: "Postleitzahl", value: "35282", alias: "35282" },
  { category: "Postleitzahl", value: "35285", alias: "35285" },
  { category: "Postleitzahl", value: "35287", alias: "35287" },
  { category: "Postleitzahl", value: "35288", alias: "35288" },
  { category: "Postleitzahl", value: "35305", alias: "35305" },
  { category: "Postleitzahl", value: "35315", alias: "35315" },
  { category: "Postleitzahl", value: "35321", alias: "35321" },
  { category: "Postleitzahl", value: "35325", alias: "35325" },
  { category: "Postleitzahl", value: "35327", alias: "35327" },
  { category: "Postleitzahl", value: "35329", alias: "35329" },
  { category: "Postleitzahl", value: "35390", alias: "35390" },
  { category: "Postleitzahl", value: "35392", alias: "35392" },
  { category: "Postleitzahl", value: "35394", alias: "35394" },
  { category: "Postleitzahl", value: "35396", alias: "35396" },
  { category: "Postleitzahl", value: "35398", alias: "35398" },
  { category: "Postleitzahl", value: "35410", alias: "35410" },
  { category: "Postleitzahl", value: "35415", alias: "35415" },
  { category: "Postleitzahl", value: "35418", alias: "35418" },
  { category: "Postleitzahl", value: "35423", alias: "35423" },
  { category: "Postleitzahl", value: "35428", alias: "35428" },
  { category: "Postleitzahl", value: "35435", alias: "35435" },
  { category: "Postleitzahl", value: "35440", alias: "35440" },
  { category: "Postleitzahl", value: "35444", alias: "35444" },
  { category: "Postleitzahl", value: "35447", alias: "35447" },
  { category: "Postleitzahl", value: "35452", alias: "35452" },
  { category: "Postleitzahl", value: "35457", alias: "35457" },
  { category: "Postleitzahl", value: "35460", alias: "35460" },
  { category: "Postleitzahl", value: "35463", alias: "35463" },
  { category: "Postleitzahl", value: "35466", alias: "35466" },
  { category: "Postleitzahl", value: "35469", alias: "35469" },
  { category: "Postleitzahl", value: "35510", alias: "35510" },
  { category: "Postleitzahl", value: "35516", alias: "35516" },
  { category: "Postleitzahl", value: "35519", alias: "35519" },
  { category: "Postleitzahl", value: "35576", alias: "35576" },
  { category: "Postleitzahl", value: "35578", alias: "35578" },
  { category: "Postleitzahl", value: "35579", alias: "35579" },
  { category: "Postleitzahl", value: "35580", alias: "35580" },
  { category: "Postleitzahl", value: "35581", alias: "35581" },
  { category: "Postleitzahl", value: "35582", alias: "35582" },
  { category: "Postleitzahl", value: "35583", alias: "35583" },
  { category: "Postleitzahl", value: "35584", alias: "35584" },
  { category: "Postleitzahl", value: "35585", alias: "35585" },
  { category: "Postleitzahl", value: "35586", alias: "35586" },
  { category: "Postleitzahl", value: "35606", alias: "35606" },
  { category: "Postleitzahl", value: "35614", alias: "35614" },
  { category: "Postleitzahl", value: "35619", alias: "35619" },
  { category: "Postleitzahl", value: "35625", alias: "35625" },
  { category: "Postleitzahl", value: "35630", alias: "35630" },
  { category: "Postleitzahl", value: "35633", alias: "35633" },
  { category: "Postleitzahl", value: "35638", alias: "35638" },
  { category: "Postleitzahl", value: "35641", alias: "35641" },
  { category: "Postleitzahl", value: "35644", alias: "35644" },
  { category: "Postleitzahl", value: "35647", alias: "35647" },
  { category: "Postleitzahl", value: "35649", alias: "35649" },
  { category: "Postleitzahl", value: "35683", alias: "35683" },
  { category: "Postleitzahl", value: "35684", alias: "35684" },
  { category: "Postleitzahl", value: "35685", alias: "35685" },
  { category: "Postleitzahl", value: "35686", alias: "35686" },
  { category: "Postleitzahl", value: "35687", alias: "35687" },
  { category: "Postleitzahl", value: "35688", alias: "35688" },
  { category: "Postleitzahl", value: "35689", alias: "35689" },
  { category: "Postleitzahl", value: "35690", alias: "35690" },
  { category: "Postleitzahl", value: "35708", alias: "35708" },
  { category: "Postleitzahl", value: "35713", alias: "35713" },
  { category: "Postleitzahl", value: "35716", alias: "35716" },
  { category: "Postleitzahl", value: "35719", alias: "35719" },
  { category: "Postleitzahl", value: "35745", alias: "35745" },
  { category: "Postleitzahl", value: "35753", alias: "35753" },
  { category: "Postleitzahl", value: "35756", alias: "35756" },
  { category: "Postleitzahl", value: "35759", alias: "35759" },
  { category: "Postleitzahl", value: "35764", alias: "35764" },
  { category: "Postleitzahl", value: "35767", alias: "35767" },
  { category: "Postleitzahl", value: "35768", alias: "35768" },
  { category: "Postleitzahl", value: "35781", alias: "35781" },
  { category: "Postleitzahl", value: "35789", alias: "35789" },
  { category: "Postleitzahl", value: "35792", alias: "35792" },
  { category: "Postleitzahl", value: "35794", alias: "35794" },
  { category: "Postleitzahl", value: "35796", alias: "35796" },
  { category: "Postleitzahl", value: "35799", alias: "35799" },
  { category: "Postleitzahl", value: "36037", alias: "36037" },
  { category: "Postleitzahl", value: "36039", alias: "36039" },
  { category: "Postleitzahl", value: "36041", alias: "36041" },
  { category: "Postleitzahl", value: "36043", alias: "36043" },
  { category: "Postleitzahl", value: "36088", alias: "36088" },
  { category: "Postleitzahl", value: "36093", alias: "36093" },
  { category: "Postleitzahl", value: "36100", alias: "36100" },
  { category: "Postleitzahl", value: "36103", alias: "36103" },
  { category: "Postleitzahl", value: "36110", alias: "36110" },
  { category: "Postleitzahl", value: "36115", alias: "36115" },
  { category: "Postleitzahl", value: "36119", alias: "36119" },
  { category: "Postleitzahl", value: "36124", alias: "36124" },
  { category: "Postleitzahl", value: "36129", alias: "36129" },
  { category: "Postleitzahl", value: "36132", alias: "36132" },
  { category: "Postleitzahl", value: "36137", alias: "36137" },
  { category: "Postleitzahl", value: "36142", alias: "36142" },
  { category: "Postleitzahl", value: "36145", alias: "36145" },
  { category: "Postleitzahl", value: "36148", alias: "36148" },
  { category: "Postleitzahl", value: "36151", alias: "36151" },
  { category: "Postleitzahl", value: "36154", alias: "36154" },
  { category: "Postleitzahl", value: "36157", alias: "36157" },
  { category: "Postleitzahl", value: "36160", alias: "36160" },
  { category: "Postleitzahl", value: "36163", alias: "36163" },
  { category: "Postleitzahl", value: "36166", alias: "36166" },
  { category: "Postleitzahl", value: "36167", alias: "36167" },
  { category: "Postleitzahl", value: "36169", alias: "36169" },
  { category: "Postleitzahl", value: "36179", alias: "36179" },
  { category: "Postleitzahl", value: "36199", alias: "36199" },
  { category: "Postleitzahl", value: "36205", alias: "36205" },
  { category: "Postleitzahl", value: "36208", alias: "36208" },
  { category: "Postleitzahl", value: "36211", alias: "36211" },
  { category: "Postleitzahl", value: "36214", alias: "36214" },
  { category: "Postleitzahl", value: "36217", alias: "36217" },
  { category: "Postleitzahl", value: "36219", alias: "36219" },
  { category: "Postleitzahl", value: "36251", alias: "36251" },
  { category: "Postleitzahl", value: "36266", alias: "36266" },
  { category: "Postleitzahl", value: "36269", alias: "36269" },
  { category: "Postleitzahl", value: "36272", alias: "36272" },
  { category: "Postleitzahl", value: "36275", alias: "36275" },
  { category: "Postleitzahl", value: "36277", alias: "36277" },
  { category: "Postleitzahl", value: "36280", alias: "36280" },
  { category: "Postleitzahl", value: "36282", alias: "36282" },
  { category: "Postleitzahl", value: "36284", alias: "36284" },
  { category: "Postleitzahl", value: "36286", alias: "36286" },
  { category: "Postleitzahl", value: "36287", alias: "36287" },
  { category: "Postleitzahl", value: "36289", alias: "36289" },
  { category: "Postleitzahl", value: "36304", alias: "36304" },
  { category: "Postleitzahl", value: "36318", alias: "36318" },
  { category: "Postleitzahl", value: "36320", alias: "36320" },
  { category: "Postleitzahl", value: "36323", alias: "36323" },
  { category: "Postleitzahl", value: "36325", alias: "36325" },
  { category: "Postleitzahl", value: "36326", alias: "36326" },
  { category: "Postleitzahl", value: "36329", alias: "36329" },
  { category: "Postleitzahl", value: "36341", alias: "36341" },
  { category: "Postleitzahl", value: "36355", alias: "36355" },
  { category: "Postleitzahl", value: "36358", alias: "36358" },
  { category: "Postleitzahl", value: "36364", alias: "36364" },
  { category: "Postleitzahl", value: "36367", alias: "36367" },
  { category: "Postleitzahl", value: "36369", alias: "36369" },
  { category: "Postleitzahl", value: "36381", alias: "36381" },
  { category: "Postleitzahl", value: "36391", alias: "36391" },
  { category: "Postleitzahl", value: "36396", alias: "36396" },
  { category: "Postleitzahl", value: "36399", alias: "36399" },
  { category: "Postleitzahl", value: "36404", alias: "36404" },
  { category: "Postleitzahl", value: "36414", alias: "36414" },
  { category: "Postleitzahl", value: "36419", alias: "36419" },
  { category: "Postleitzahl", value: "36433", alias: "36433" },
  { category: "Postleitzahl", value: "36448", alias: "36448" },
  { category: "Postleitzahl", value: "36452", alias: "36452" },
  { category: "Postleitzahl", value: "36456", alias: "36456" },
  { category: "Postleitzahl", value: "36457", alias: "36457" },
  { category: "Postleitzahl", value: "36460", alias: "36460" },
  { category: "Postleitzahl", value: "36466", alias: "36466" },
  { category: "Postleitzahl", value: "36469", alias: "36469" },
  { category: "Postleitzahl", value: "37073", alias: "37073" },
  { category: "Postleitzahl", value: "37075", alias: "37075" },
  { category: "Postleitzahl", value: "37077", alias: "37077" },
  { category: "Postleitzahl", value: "37079", alias: "37079" },
  { category: "Postleitzahl", value: "37081", alias: "37081" },
  { category: "Postleitzahl", value: "37083", alias: "37083" },
  { category: "Postleitzahl", value: "37085", alias: "37085" },
  { category: "Postleitzahl", value: "37115", alias: "37115" },
  { category: "Postleitzahl", value: "37120", alias: "37120" },
  { category: "Postleitzahl", value: "37124", alias: "37124" },
  { category: "Postleitzahl", value: "37127", alias: "37127" },
  { category: "Postleitzahl", value: "37130", alias: "37130" },
  { category: "Postleitzahl", value: "37133", alias: "37133" },
  { category: "Postleitzahl", value: "37136", alias: "37136" },
  { category: "Postleitzahl", value: "37139", alias: "37139" },
  { category: "Postleitzahl", value: "37154", alias: "37154" },
  { category: "Postleitzahl", value: "37170", alias: "37170" },
  { category: "Postleitzahl", value: "37176", alias: "37176" },
  { category: "Postleitzahl", value: "37181", alias: "37181" },
  { category: "Postleitzahl", value: "37186", alias: "37186" },
  { category: "Postleitzahl", value: "37191", alias: "37191" },
  { category: "Postleitzahl", value: "37194", alias: "37194" },
  { category: "Postleitzahl", value: "37197", alias: "37197" },
  { category: "Postleitzahl", value: "37199", alias: "37199" },
  { category: "Postleitzahl", value: "37213", alias: "37213" },
  { category: "Postleitzahl", value: "37214", alias: "37214" },
  { category: "Postleitzahl", value: "37215", alias: "37215" },
  { category: "Postleitzahl", value: "37216", alias: "37216" },
  { category: "Postleitzahl", value: "37217", alias: "37217" },
  { category: "Postleitzahl", value: "37218", alias: "37218" },
  { category: "Postleitzahl", value: "37235", alias: "37235" },
  { category: "Postleitzahl", value: "37242", alias: "37242" },
  { category: "Postleitzahl", value: "37247", alias: "37247" },
  { category: "Postleitzahl", value: "37249", alias: "37249" },
  { category: "Postleitzahl", value: "37269", alias: "37269" },
  { category: "Postleitzahl", value: "37276", alias: "37276" },
  { category: "Postleitzahl", value: "37281", alias: "37281" },
  { category: "Postleitzahl", value: "37284", alias: "37284" },
  { category: "Postleitzahl", value: "37287", alias: "37287" },
  { category: "Postleitzahl", value: "37290", alias: "37290" },
  { category: "Postleitzahl", value: "37293", alias: "37293" },
  { category: "Postleitzahl", value: "37296", alias: "37296" },
  { category: "Postleitzahl", value: "37297", alias: "37297" },
  { category: "Postleitzahl", value: "37299", alias: "37299" },
  { category: "Postleitzahl", value: "37308", alias: "37308" },
  { category: "Postleitzahl", value: "37318", alias: "37318" },
  { category: "Postleitzahl", value: "37327", alias: "37327" },
  { category: "Postleitzahl", value: "37339", alias: "37339" },
  { category: "Postleitzahl", value: "37345", alias: "37345" },
  { category: "Postleitzahl", value: "37351", alias: "37351" },
  { category: "Postleitzahl", value: "37355", alias: "37355" },
  { category: "Postleitzahl", value: "37359", alias: "37359" },
  { category: "Postleitzahl", value: "37412", alias: "37412" },
  { category: "Postleitzahl", value: "37431", alias: "37431" },
  { category: "Postleitzahl", value: "37434", alias: "37434" },
  { category: "Postleitzahl", value: "37441", alias: "37441" },
  { category: "Postleitzahl", value: "37444", alias: "37444" },
  { category: "Postleitzahl", value: "37445", alias: "37445" },
  { category: "Postleitzahl", value: "37520", alias: "37520" },
  { category: "Postleitzahl", value: "37539", alias: "37539" },
  { category: "Postleitzahl", value: "37574", alias: "37574" },
  { category: "Postleitzahl", value: "37581", alias: "37581" },
  { category: "Postleitzahl", value: "37586", alias: "37586" },
  { category: "Postleitzahl", value: "37589", alias: "37589" },
  { category: "Postleitzahl", value: "37603", alias: "37603" },
  { category: "Postleitzahl", value: "37619", alias: "37619" },
  { category: "Postleitzahl", value: "37620", alias: "37620" },
  { category: "Postleitzahl", value: "37627", alias: "37627" },
  { category: "Postleitzahl", value: "37632", alias: "37632" },
  { category: "Postleitzahl", value: "37633", alias: "37633" },
  { category: "Postleitzahl", value: "37635", alias: "37635" },
  { category: "Postleitzahl", value: "37639", alias: "37639" },
  { category: "Postleitzahl", value: "37640", alias: "37640" },
  { category: "Postleitzahl", value: "37642", alias: "37642" },
  { category: "Postleitzahl", value: "37643", alias: "37643" },
  { category: "Postleitzahl", value: "37647", alias: "37647" },
  { category: "Postleitzahl", value: "37649", alias: "37649" },
  { category: "Postleitzahl", value: "37671", alias: "37671" },
  { category: "Postleitzahl", value: "37688", alias: "37688" },
  { category: "Postleitzahl", value: "37691", alias: "37691" },
  { category: "Postleitzahl", value: "37696", alias: "37696" },
  { category: "Postleitzahl", value: "37697", alias: "37697" },
  { category: "Postleitzahl", value: "37699", alias: "37699" },
  { category: "Postleitzahl", value: "38100", alias: "38100" },
  { category: "Postleitzahl", value: "38102", alias: "38102" },
  { category: "Postleitzahl", value: "38104", alias: "38104" },
  { category: "Postleitzahl", value: "38106", alias: "38106" },
  { category: "Postleitzahl", value: "38108", alias: "38108" },
  { category: "Postleitzahl", value: "38110", alias: "38110" },
  { category: "Postleitzahl", value: "38112", alias: "38112" },
  { category: "Postleitzahl", value: "38114", alias: "38114" },
  { category: "Postleitzahl", value: "38116", alias: "38116" },
  { category: "Postleitzahl", value: "38118", alias: "38118" },
  { category: "Postleitzahl", value: "38120", alias: "38120" },
  { category: "Postleitzahl", value: "38122", alias: "38122" },
  { category: "Postleitzahl", value: "38124", alias: "38124" },
  { category: "Postleitzahl", value: "38126", alias: "38126" },
  { category: "Postleitzahl", value: "38154", alias: "38154" },
  { category: "Postleitzahl", value: "38159", alias: "38159" },
  { category: "Postleitzahl", value: "38162", alias: "38162" },
  { category: "Postleitzahl", value: "38165", alias: "38165" },
  { category: "Postleitzahl", value: "38170", alias: "38170" },
  { category: "Postleitzahl", value: "38173", alias: "38173" },
  { category: "Postleitzahl", value: "38176", alias: "38176" },
  { category: "Postleitzahl", value: "38179", alias: "38179" },
  { category: "Postleitzahl", value: "38226", alias: "38226" },
  { category: "Postleitzahl", value: "38228", alias: "38228" },
  { category: "Postleitzahl", value: "38229", alias: "38229" },
  { category: "Postleitzahl", value: "38239", alias: "38239" },
  { category: "Postleitzahl", value: "38259", alias: "38259" },
  { category: "Postleitzahl", value: "38268", alias: "38268" },
  { category: "Postleitzahl", value: "38271", alias: "38271" },
  { category: "Postleitzahl", value: "38272", alias: "38272" },
  { category: "Postleitzahl", value: "38274", alias: "38274" },
  { category: "Postleitzahl", value: "38275", alias: "38275" },
  { category: "Postleitzahl", value: "38277", alias: "38277" },
  { category: "Postleitzahl", value: "38279", alias: "38279" },
  { category: "Postleitzahl", value: "38300", alias: "38300" },
  { category: "Postleitzahl", value: "38302", alias: "38302" },
  { category: "Postleitzahl", value: "38304", alias: "38304" },
  { category: "Postleitzahl", value: "38312", alias: "38312" },
  { category: "Postleitzahl", value: "38315", alias: "38315" },
  { category: "Postleitzahl", value: "38319", alias: "38319" },
  { category: "Postleitzahl", value: "38321", alias: "38321" },
  { category: "Postleitzahl", value: "38322", alias: "38322" },
  { category: "Postleitzahl", value: "38324", alias: "38324" },
  { category: "Postleitzahl", value: "38325", alias: "38325" },
  { category: "Postleitzahl", value: "38327", alias: "38327" },
  { category: "Postleitzahl", value: "38329", alias: "38329" },
  { category: "Postleitzahl", value: "38350", alias: "38350" },
  { category: "Postleitzahl", value: "38364", alias: "38364" },
  { category: "Postleitzahl", value: "38368", alias: "38368" },
  { category: "Postleitzahl", value: "38372", alias: "38372" },
  { category: "Postleitzahl", value: "38373", alias: "38373" },
  { category: "Postleitzahl", value: "38375", alias: "38375" },
  { category: "Postleitzahl", value: "38376", alias: "38376" },
  { category: "Postleitzahl", value: "38378", alias: "38378" },
  { category: "Postleitzahl", value: "38379", alias: "38379" },
  { category: "Postleitzahl", value: "38381", alias: "38381" },
  { category: "Postleitzahl", value: "38382", alias: "38382" },
  { category: "Postleitzahl", value: "38384", alias: "38384" },
  { category: "Postleitzahl", value: "38387", alias: "38387" },
  { category: "Postleitzahl", value: "38440", alias: "38440" },
  { category: "Postleitzahl", value: "38442", alias: "38442" },
  { category: "Postleitzahl", value: "38444", alias: "38444" },
  { category: "Postleitzahl", value: "38446", alias: "38446" },
  { category: "Postleitzahl", value: "38448", alias: "38448" },
  { category: "Postleitzahl", value: "38458", alias: "38458" },
  { category: "Postleitzahl", value: "38459", alias: "38459" },
  { category: "Postleitzahl", value: "38461", alias: "38461" },
  { category: "Postleitzahl", value: "38462", alias: "38462" },
  { category: "Postleitzahl", value: "38464", alias: "38464" },
  { category: "Postleitzahl", value: "38465", alias: "38465" },
  { category: "Postleitzahl", value: "38467", alias: "38467" },
  { category: "Postleitzahl", value: "38468", alias: "38468" },
  { category: "Postleitzahl", value: "38470", alias: "38470" },
  { category: "Postleitzahl", value: "38471", alias: "38471" },
  { category: "Postleitzahl", value: "38473", alias: "38473" },
  { category: "Postleitzahl", value: "38474", alias: "38474" },
  { category: "Postleitzahl", value: "38476", alias: "38476" },
  { category: "Postleitzahl", value: "38477", alias: "38477" },
  { category: "Postleitzahl", value: "38479", alias: "38479" },
  { category: "Postleitzahl", value: "38486", alias: "38486" },
  { category: "Postleitzahl", value: "38489", alias: "38489" },
  { category: "Postleitzahl", value: "38518", alias: "38518" },
  { category: "Postleitzahl", value: "38524", alias: "38524" },
  { category: "Postleitzahl", value: "38527", alias: "38527" },
  { category: "Postleitzahl", value: "38528", alias: "38528" },
  { category: "Postleitzahl", value: "38530", alias: "38530" },
  { category: "Postleitzahl", value: "38531", alias: "38531" },
  { category: "Postleitzahl", value: "38533", alias: "38533" },
  { category: "Postleitzahl", value: "38536", alias: "38536" },
  { category: "Postleitzahl", value: "38539", alias: "38539" },
  { category: "Postleitzahl", value: "38542", alias: "38542" },
  { category: "Postleitzahl", value: "38543", alias: "38543" },
  { category: "Postleitzahl", value: "38547", alias: "38547" },
  { category: "Postleitzahl", value: "38550", alias: "38550" },
  { category: "Postleitzahl", value: "38551", alias: "38551" },
  { category: "Postleitzahl", value: "38553", alias: "38553" },
  { category: "Postleitzahl", value: "38554", alias: "38554" },
  { category: "Postleitzahl", value: "38556", alias: "38556" },
  { category: "Postleitzahl", value: "38557", alias: "38557" },
  { category: "Postleitzahl", value: "38559", alias: "38559" },
  { category: "Postleitzahl", value: "38640", alias: "38640" },
  { category: "Postleitzahl", value: "38642", alias: "38642" },
  { category: "Postleitzahl", value: "38644", alias: "38644" },
  { category: "Postleitzahl", value: "38667", alias: "38667" },
  { category: "Postleitzahl", value: "38678", alias: "38678" },
  { category: "Postleitzahl", value: "38685", alias: "38685" },
  { category: "Postleitzahl", value: "38690", alias: "38690" },
  { category: "Postleitzahl", value: "38700", alias: "38700" },
  { category: "Postleitzahl", value: "38704", alias: "38704" },
  { category: "Postleitzahl", value: "38707", alias: "38707" },
  { category: "Postleitzahl", value: "38709", alias: "38709" },
  { category: "Postleitzahl", value: "38723", alias: "38723" },
  { category: "Postleitzahl", value: "38729", alias: "38729" },
  { category: "Postleitzahl", value: "38820", alias: "38820" },
  { category: "Postleitzahl", value: "38822", alias: "38822" },
  { category: "Postleitzahl", value: "38828", alias: "38828" },
  { category: "Postleitzahl", value: "38829", alias: "38829" },
  { category: "Postleitzahl", value: "38835", alias: "38835" },
  { category: "Postleitzahl", value: "38836", alias: "38836" },
  { category: "Postleitzahl", value: "38838", alias: "38838" },
  { category: "Postleitzahl", value: "38855", alias: "38855" },
  { category: "Postleitzahl", value: "38871", alias: "38871" },
  { category: "Postleitzahl", value: "38875", alias: "38875" },
  { category: "Postleitzahl", value: "38877", alias: "38877" },
  { category: "Postleitzahl", value: "38879", alias: "38879" },
  { category: "Postleitzahl", value: "38889", alias: "38889" },
  { category: "Postleitzahl", value: "38895", alias: "38895" },
  { category: "Postleitzahl", value: "38899", alias: "38899" },
  { category: "Postleitzahl", value: "39104", alias: "39104" },
  { category: "Postleitzahl", value: "39106", alias: "39106" },
  { category: "Postleitzahl", value: "39108", alias: "39108" },
  { category: "Postleitzahl", value: "39110", alias: "39110" },
  { category: "Postleitzahl", value: "39112", alias: "39112" },
  { category: "Postleitzahl", value: "39114", alias: "39114" },
  { category: "Postleitzahl", value: "39116", alias: "39116" },
  { category: "Postleitzahl", value: "39118", alias: "39118" },
  { category: "Postleitzahl", value: "39120", alias: "39120" },
  { category: "Postleitzahl", value: "39122", alias: "39122" },
  { category: "Postleitzahl", value: "39124", alias: "39124" },
  { category: "Postleitzahl", value: "39126", alias: "39126" },
  { category: "Postleitzahl", value: "39128", alias: "39128" },
  { category: "Postleitzahl", value: "39130", alias: "39130" },
  { category: "Postleitzahl", value: "39164", alias: "39164" },
  { category: "Postleitzahl", value: "39167", alias: "39167" },
  { category: "Postleitzahl", value: "39171", alias: "39171" },
  { category: "Postleitzahl", value: "39175", alias: "39175" },
  { category: "Postleitzahl", value: "39179", alias: "39179" },
  { category: "Postleitzahl", value: "39217", alias: "39217" },
  { category: "Postleitzahl", value: "39218", alias: "39218" },
  { category: "Postleitzahl", value: "39221", alias: "39221" },
  { category: "Postleitzahl", value: "39240", alias: "39240" },
  { category: "Postleitzahl", value: "39245", alias: "39245" },
  { category: "Postleitzahl", value: "39249", alias: "39249" },
  { category: "Postleitzahl", value: "39261", alias: "39261" },
  { category: "Postleitzahl", value: "39264", alias: "39264" },
  { category: "Postleitzahl", value: "39279", alias: "39279" },
  { category: "Postleitzahl", value: "39288", alias: "39288" },
  { category: "Postleitzahl", value: "39291", alias: "39291" },
  { category: "Postleitzahl", value: "39307", alias: "39307" },
  { category: "Postleitzahl", value: "39317", alias: "39317" },
  { category: "Postleitzahl", value: "39319", alias: "39319" },
  { category: "Postleitzahl", value: "39326", alias: "39326" },
  { category: "Postleitzahl", value: "39340", alias: "39340" },
  { category: "Postleitzahl", value: "39343", alias: "39343" },
  { category: "Postleitzahl", value: "39345", alias: "39345" },
  { category: "Postleitzahl", value: "39356", alias: "39356" },
  { category: "Postleitzahl", value: "39359", alias: "39359" },
  { category: "Postleitzahl", value: "39365", alias: "39365" },
  { category: "Postleitzahl", value: "39387", alias: "39387" },
  { category: "Postleitzahl", value: "39393", alias: "39393" },
  { category: "Postleitzahl", value: "39397", alias: "39397" },
  { category: "Postleitzahl", value: "39418", alias: "39418" },
  { category: "Postleitzahl", value: "39435", alias: "39435" },
  { category: "Postleitzahl", value: "39439", alias: "39439" },
  { category: "Postleitzahl", value: "39443", alias: "39443" },
  { category: "Postleitzahl", value: "39444", alias: "39444" },
  { category: "Postleitzahl", value: "39446", alias: "39446" },
  { category: "Postleitzahl", value: "39448", alias: "39448" },
  { category: "Postleitzahl", value: "39517", alias: "39517" },
  { category: "Postleitzahl", value: "39524", alias: "39524" },
  { category: "Postleitzahl", value: "39539", alias: "39539" },
  { category: "Postleitzahl", value: "39576", alias: "39576" },
  { category: "Postleitzahl", value: "39579", alias: "39579" },
  { category: "Postleitzahl", value: "39590", alias: "39590" },
  { category: "Postleitzahl", value: "39596", alias: "39596" },
  { category: "Postleitzahl", value: "39606", alias: "39606" },
  { category: "Postleitzahl", value: "39615", alias: "39615" },
  { category: "Postleitzahl", value: "39619", alias: "39619" },
  { category: "Postleitzahl", value: "39624", alias: "39624" },
  { category: "Postleitzahl", value: "39628", alias: "39628" },
  { category: "Postleitzahl", value: "39629", alias: "39629" },
  { category: "Postleitzahl", value: "39638", alias: "39638" },
  { category: "Postleitzahl", value: "39646", alias: "39646" },
  { category: "Postleitzahl", value: "39649", alias: "39649" },
  { category: "Postleitzahl", value: "40210", alias: "40210" },
  { category: "Postleitzahl", value: "40211", alias: "40211" },
  { category: "Postleitzahl", value: "40212", alias: "40212" },
  { category: "Postleitzahl", value: "40213", alias: "40213" },
  { category: "Postleitzahl", value: "40215", alias: "40215" },
  { category: "Postleitzahl", value: "40217", alias: "40217" },
  { category: "Postleitzahl", value: "40219", alias: "40219" },
  { category: "Postleitzahl", value: "40221", alias: "40221" },
  { category: "Postleitzahl", value: "40223", alias: "40223" },
  { category: "Postleitzahl", value: "40225", alias: "40225" },
  { category: "Postleitzahl", value: "40227", alias: "40227" },
  { category: "Postleitzahl", value: "40229", alias: "40229" },
  { category: "Postleitzahl", value: "40231", alias: "40231" },
  { category: "Postleitzahl", value: "40233", alias: "40233" },
  { category: "Postleitzahl", value: "40235", alias: "40235" },
  { category: "Postleitzahl", value: "40237", alias: "40237" },
  { category: "Postleitzahl", value: "40239", alias: "40239" },
  { category: "Postleitzahl", value: "40468", alias: "40468" },
  { category: "Postleitzahl", value: "40470", alias: "40470" },
  { category: "Postleitzahl", value: "40472", alias: "40472" },
  { category: "Postleitzahl", value: "40474", alias: "40474" },
  { category: "Postleitzahl", value: "40476", alias: "40476" },
  { category: "Postleitzahl", value: "40477", alias: "40477" },
  { category: "Postleitzahl", value: "40479", alias: "40479" },
  { category: "Postleitzahl", value: "40489", alias: "40489" },
  { category: "Postleitzahl", value: "40545", alias: "40545" },
  { category: "Postleitzahl", value: "40547", alias: "40547" },
  { category: "Postleitzahl", value: "40549", alias: "40549" },
  { category: "Postleitzahl", value: "40589", alias: "40589" },
  { category: "Postleitzahl", value: "40591", alias: "40591" },
  { category: "Postleitzahl", value: "40593", alias: "40593" },
  { category: "Postleitzahl", value: "40595", alias: "40595" },
  { category: "Postleitzahl", value: "40597", alias: "40597" },
  { category: "Postleitzahl", value: "40599", alias: "40599" },
  { category: "Postleitzahl", value: "40625", alias: "40625" },
  { category: "Postleitzahl", value: "40627", alias: "40627" },
  { category: "Postleitzahl", value: "40629", alias: "40629" },
  { category: "Postleitzahl", value: "40667", alias: "40667" },
  { category: "Postleitzahl", value: "40668", alias: "40668" },
  { category: "Postleitzahl", value: "40670", alias: "40670" },
  { category: "Postleitzahl", value: "40699", alias: "40699" },
  { category: "Postleitzahl", value: "40721", alias: "40721" },
  { category: "Postleitzahl", value: "40723", alias: "40723" },
  { category: "Postleitzahl", value: "40724", alias: "40724" },
  { category: "Postleitzahl", value: "40764", alias: "40764" },
  { category: "Postleitzahl", value: "40789", alias: "40789" },
  { category: "Postleitzahl", value: "40822", alias: "40822" },
  { category: "Postleitzahl", value: "40878", alias: "40878" },
  { category: "Postleitzahl", value: "40880", alias: "40880" },
  { category: "Postleitzahl", value: "40882", alias: "40882" },
  { category: "Postleitzahl", value: "40883", alias: "40883" },
  { category: "Postleitzahl", value: "40885", alias: "40885" },
  { category: "Postleitzahl", value: "41061", alias: "41061" },
  { category: "Postleitzahl", value: "41063", alias: "41063" },
  { category: "Postleitzahl", value: "41065", alias: "41065" },
  { category: "Postleitzahl", value: "41066", alias: "41066" },
  { category: "Postleitzahl", value: "41068", alias: "41068" },
  { category: "Postleitzahl", value: "41069", alias: "41069" },
  { category: "Postleitzahl", value: "41169", alias: "41169" },
  { category: "Postleitzahl", value: "41179", alias: "41179" },
  { category: "Postleitzahl", value: "41189", alias: "41189" },
  { category: "Postleitzahl", value: "41199", alias: "41199" },
  { category: "Postleitzahl", value: "41236", alias: "41236" },
  { category: "Postleitzahl", value: "41238", alias: "41238" },
  { category: "Postleitzahl", value: "41239", alias: "41239" },
  { category: "Postleitzahl", value: "41334", alias: "41334" },
  { category: "Postleitzahl", value: "41352", alias: "41352" },
  { category: "Postleitzahl", value: "41363", alias: "41363" },
  { category: "Postleitzahl", value: "41366", alias: "41366" },
  { category: "Postleitzahl", value: "41372", alias: "41372" },
  { category: "Postleitzahl", value: "41379", alias: "41379" },
  { category: "Postleitzahl", value: "41460", alias: "41460" },
  { category: "Postleitzahl", value: "41462", alias: "41462" },
  { category: "Postleitzahl", value: "41464", alias: "41464" },
  { category: "Postleitzahl", value: "41466", alias: "41466" },
  { category: "Postleitzahl", value: "41468", alias: "41468" },
  { category: "Postleitzahl", value: "41469", alias: "41469" },
  { category: "Postleitzahl", value: "41470", alias: "41470" },
  { category: "Postleitzahl", value: "41472", alias: "41472" },
  { category: "Postleitzahl", value: "41515", alias: "41515" },
  { category: "Postleitzahl", value: "41516", alias: "41516" },
  { category: "Postleitzahl", value: "41517", alias: "41517" },
  { category: "Postleitzahl", value: "41539", alias: "41539" },
  { category: "Postleitzahl", value: "41540", alias: "41540" },
  { category: "Postleitzahl", value: "41541", alias: "41541" },
  { category: "Postleitzahl", value: "41542", alias: "41542" },
  { category: "Postleitzahl", value: "41564", alias: "41564" },
  { category: "Postleitzahl", value: "41569", alias: "41569" },
  { category: "Postleitzahl", value: "41747", alias: "41747" },
  { category: "Postleitzahl", value: "41748", alias: "41748" },
  { category: "Postleitzahl", value: "41749", alias: "41749" },
  { category: "Postleitzahl", value: "41751", alias: "41751" },
  { category: "Postleitzahl", value: "41812", alias: "41812" },
  { category: "Postleitzahl", value: "41836", alias: "41836" },
  { category: "Postleitzahl", value: "41844", alias: "41844" },
  { category: "Postleitzahl", value: "41849", alias: "41849" },
  { category: "Postleitzahl", value: "42103", alias: "42103" },
  { category: "Postleitzahl", value: "42105", alias: "42105" },
  { category: "Postleitzahl", value: "42107", alias: "42107" },
  { category: "Postleitzahl", value: "42109", alias: "42109" },
  { category: "Postleitzahl", value: "42111", alias: "42111" },
  { category: "Postleitzahl", value: "42113", alias: "42113" },
  { category: "Postleitzahl", value: "42115", alias: "42115" },
  { category: "Postleitzahl", value: "42117", alias: "42117" },
  { category: "Postleitzahl", value: "42119", alias: "42119" },
  { category: "Postleitzahl", value: "42275", alias: "42275" },
  { category: "Postleitzahl", value: "42277", alias: "42277" },
  { category: "Postleitzahl", value: "42279", alias: "42279" },
  { category: "Postleitzahl", value: "42281", alias: "42281" },
  { category: "Postleitzahl", value: "42283", alias: "42283" },
  { category: "Postleitzahl", value: "42285", alias: "42285" },
  { category: "Postleitzahl", value: "42287", alias: "42287" },
  { category: "Postleitzahl", value: "42289", alias: "42289" },
  { category: "Postleitzahl", value: "42327", alias: "42327" },
  { category: "Postleitzahl", value: "42329", alias: "42329" },
  { category: "Postleitzahl", value: "42349", alias: "42349" },
  { category: "Postleitzahl", value: "42369", alias: "42369" },
  { category: "Postleitzahl", value: "42389", alias: "42389" },
  { category: "Postleitzahl", value: "42399", alias: "42399" },
  { category: "Postleitzahl", value: "42477", alias: "42477" },
  { category: "Postleitzahl", value: "42489", alias: "42489" },
  { category: "Postleitzahl", value: "42499", alias: "42499" },
  { category: "Postleitzahl", value: "42549", alias: "42549" },
  { category: "Postleitzahl", value: "42551", alias: "42551" },
  { category: "Postleitzahl", value: "42553", alias: "42553" },
  { category: "Postleitzahl", value: "42555", alias: "42555" },
  { category: "Postleitzahl", value: "42579", alias: "42579" },
  { category: "Postleitzahl", value: "42651", alias: "42651" },
  { category: "Postleitzahl", value: "42653", alias: "42653" },
  { category: "Postleitzahl", value: "42655", alias: "42655" },
  { category: "Postleitzahl", value: "42657", alias: "42657" },
  { category: "Postleitzahl", value: "42659", alias: "42659" },
  { category: "Postleitzahl", value: "42697", alias: "42697" },
  { category: "Postleitzahl", value: "42699", alias: "42699" },
  { category: "Postleitzahl", value: "42719", alias: "42719" },
  { category: "Postleitzahl", value: "42781", alias: "42781" },
  { category: "Postleitzahl", value: "42799", alias: "42799" },
  { category: "Postleitzahl", value: "42853", alias: "42853" },
  { category: "Postleitzahl", value: "42855", alias: "42855" },
  { category: "Postleitzahl", value: "42857", alias: "42857" },
  { category: "Postleitzahl", value: "42859", alias: "42859" },
  { category: "Postleitzahl", value: "42897", alias: "42897" },
  { category: "Postleitzahl", value: "42899", alias: "42899" },
  { category: "Postleitzahl", value: "42929", alias: "42929" },
  { category: "Postleitzahl", value: "44135", alias: "44135" },
  { category: "Postleitzahl", value: "44137", alias: "44137" },
  { category: "Postleitzahl", value: "44139", alias: "44139" },
  { category: "Postleitzahl", value: "44141", alias: "44141" },
  { category: "Postleitzahl", value: "44143", alias: "44143" },
  { category: "Postleitzahl", value: "44145", alias: "44145" },
  { category: "Postleitzahl", value: "44147", alias: "44147" },
  { category: "Postleitzahl", value: "44149", alias: "44149" },
  { category: "Postleitzahl", value: "44225", alias: "44225" },
  { category: "Postleitzahl", value: "44227", alias: "44227" },
  { category: "Postleitzahl", value: "44229", alias: "44229" },
  { category: "Postleitzahl", value: "44263", alias: "44263" },
  { category: "Postleitzahl", value: "44265", alias: "44265" },
  { category: "Postleitzahl", value: "44267", alias: "44267" },
  { category: "Postleitzahl", value: "44269", alias: "44269" },
  { category: "Postleitzahl", value: "44287", alias: "44287" },
  { category: "Postleitzahl", value: "44289", alias: "44289" },
  { category: "Postleitzahl", value: "44309", alias: "44309" },
  { category: "Postleitzahl", value: "44319", alias: "44319" },
  { category: "Postleitzahl", value: "44328", alias: "44328" },
  { category: "Postleitzahl", value: "44329", alias: "44329" },
  { category: "Postleitzahl", value: "44339", alias: "44339" },
  { category: "Postleitzahl", value: "44357", alias: "44357" },
  { category: "Postleitzahl", value: "44359", alias: "44359" },
  { category: "Postleitzahl", value: "44369", alias: "44369" },
  { category: "Postleitzahl", value: "44379", alias: "44379" },
  { category: "Postleitzahl", value: "44388", alias: "44388" },
  { category: "Postleitzahl", value: "44532", alias: "44532" },
  { category: "Postleitzahl", value: "44534", alias: "44534" },
  { category: "Postleitzahl", value: "44536", alias: "44536" },
  { category: "Postleitzahl", value: "44575", alias: "44575" },
  { category: "Postleitzahl", value: "44577", alias: "44577" },
  { category: "Postleitzahl", value: "44579", alias: "44579" },
  { category: "Postleitzahl", value: "44581", alias: "44581" },
  { category: "Postleitzahl", value: "44623", alias: "44623" },
  { category: "Postleitzahl", value: "44625", alias: "44625" },
  { category: "Postleitzahl", value: "44627", alias: "44627" },
  { category: "Postleitzahl", value: "44628", alias: "44628" },
  { category: "Postleitzahl", value: "44629", alias: "44629" },
  { category: "Postleitzahl", value: "44649", alias: "44649" },
  { category: "Postleitzahl", value: "44651", alias: "44651" },
  { category: "Postleitzahl", value: "44652", alias: "44652" },
  { category: "Postleitzahl", value: "44653", alias: "44653" },
  { category: "Postleitzahl", value: "44787", alias: "44787" },
  { category: "Postleitzahl", value: "44789", alias: "44789" },
  { category: "Postleitzahl", value: "44791", alias: "44791" },
  { category: "Postleitzahl", value: "44793", alias: "44793" },
  { category: "Postleitzahl", value: "44795", alias: "44795" },
  { category: "Postleitzahl", value: "44797", alias: "44797" },
  { category: "Postleitzahl", value: "44799", alias: "44799" },
  { category: "Postleitzahl", value: "44801", alias: "44801" },
  { category: "Postleitzahl", value: "44803", alias: "44803" },
  { category: "Postleitzahl", value: "44805", alias: "44805" },
  { category: "Postleitzahl", value: "44807", alias: "44807" },
  { category: "Postleitzahl", value: "44809", alias: "44809" },
  { category: "Postleitzahl", value: "44866", alias: "44866" },
  { category: "Postleitzahl", value: "44867", alias: "44867" },
  { category: "Postleitzahl", value: "44869", alias: "44869" },
  { category: "Postleitzahl", value: "44879", alias: "44879" },
  { category: "Postleitzahl", value: "44892", alias: "44892" },
  { category: "Postleitzahl", value: "44894", alias: "44894" },
  { category: "Postleitzahl", value: "45127", alias: "45127" },
  { category: "Postleitzahl", value: "45128", alias: "45128" },
  { category: "Postleitzahl", value: "45130", alias: "45130" },
  { category: "Postleitzahl", value: "45131", alias: "45131" },
  { category: "Postleitzahl", value: "45133", alias: "45133" },
  { category: "Postleitzahl", value: "45134", alias: "45134" },
  { category: "Postleitzahl", value: "45136", alias: "45136" },
  { category: "Postleitzahl", value: "45138", alias: "45138" },
  { category: "Postleitzahl", value: "45139", alias: "45139" },
  { category: "Postleitzahl", value: "45141", alias: "45141" },
  { category: "Postleitzahl", value: "45143", alias: "45143" },
  { category: "Postleitzahl", value: "45144", alias: "45144" },
  { category: "Postleitzahl", value: "45145", alias: "45145" },
  { category: "Postleitzahl", value: "45147", alias: "45147" },
  { category: "Postleitzahl", value: "45149", alias: "45149" },
  { category: "Postleitzahl", value: "45219", alias: "45219" },
  { category: "Postleitzahl", value: "45239", alias: "45239" },
  { category: "Postleitzahl", value: "45257", alias: "45257" },
  { category: "Postleitzahl", value: "45259", alias: "45259" },
  { category: "Postleitzahl", value: "45276", alias: "45276" },
  { category: "Postleitzahl", value: "45277", alias: "45277" },
  { category: "Postleitzahl", value: "45279", alias: "45279" },
  { category: "Postleitzahl", value: "45289", alias: "45289" },
  { category: "Postleitzahl", value: "45307", alias: "45307" },
  { category: "Postleitzahl", value: "45309", alias: "45309" },
  { category: "Postleitzahl", value: "45326", alias: "45326" },
  { category: "Postleitzahl", value: "45327", alias: "45327" },
  { category: "Postleitzahl", value: "45329", alias: "45329" },
  { category: "Postleitzahl", value: "45355", alias: "45355" },
  { category: "Postleitzahl", value: "45356", alias: "45356" },
  { category: "Postleitzahl", value: "45357", alias: "45357" },
  { category: "Postleitzahl", value: "45359", alias: "45359" },
  { category: "Postleitzahl", value: "45468", alias: "45468" },
  { category: "Postleitzahl", value: "45470", alias: "45470" },
  { category: "Postleitzahl", value: "45472", alias: "45472" },
  { category: "Postleitzahl", value: "45473", alias: "45473" },
  { category: "Postleitzahl", value: "45475", alias: "45475" },
  { category: "Postleitzahl", value: "45476", alias: "45476" },
  { category: "Postleitzahl", value: "45478", alias: "45478" },
  { category: "Postleitzahl", value: "45479", alias: "45479" },
  { category: "Postleitzahl", value: "45481", alias: "45481" },
  { category: "Postleitzahl", value: "45525", alias: "45525" },
  { category: "Postleitzahl", value: "45527", alias: "45527" },
  { category: "Postleitzahl", value: "45529", alias: "45529" },
  { category: "Postleitzahl", value: "45549", alias: "45549" },
  { category: "Postleitzahl", value: "45657", alias: "45657" },
  { category: "Postleitzahl", value: "45659", alias: "45659" },
  { category: "Postleitzahl", value: "45661", alias: "45661" },
  { category: "Postleitzahl", value: "45663", alias: "45663" },
  { category: "Postleitzahl", value: "45665", alias: "45665" },
  { category: "Postleitzahl", value: "45699", alias: "45699" },
  { category: "Postleitzahl", value: "45701", alias: "45701" },
  { category: "Postleitzahl", value: "45711", alias: "45711" },
  { category: "Postleitzahl", value: "45721", alias: "45721" },
  { category: "Postleitzahl", value: "45731", alias: "45731" },
  { category: "Postleitzahl", value: "45739", alias: "45739" },
  { category: "Postleitzahl", value: "45768", alias: "45768" },
  { category: "Postleitzahl", value: "45770", alias: "45770" },
  { category: "Postleitzahl", value: "45772", alias: "45772" },
  { category: "Postleitzahl", value: "45879", alias: "45879" },
  { category: "Postleitzahl", value: "45881", alias: "45881" },
  { category: "Postleitzahl", value: "45883", alias: "45883" },
  { category: "Postleitzahl", value: "45884", alias: "45884" },
  { category: "Postleitzahl", value: "45886", alias: "45886" },
  { category: "Postleitzahl", value: "45888", alias: "45888" },
  { category: "Postleitzahl", value: "45889", alias: "45889" },
  { category: "Postleitzahl", value: "45891", alias: "45891" },
  { category: "Postleitzahl", value: "45892", alias: "45892" },
  { category: "Postleitzahl", value: "45894", alias: "45894" },
  { category: "Postleitzahl", value: "45896", alias: "45896" },
  { category: "Postleitzahl", value: "45897", alias: "45897" },
  { category: "Postleitzahl", value: "45899", alias: "45899" },
  { category: "Postleitzahl", value: "45964", alias: "45964" },
  { category: "Postleitzahl", value: "45966", alias: "45966" },
  { category: "Postleitzahl", value: "45968", alias: "45968" },
  { category: "Postleitzahl", value: "46045", alias: "46045" },
  { category: "Postleitzahl", value: "46047", alias: "46047" },
  { category: "Postleitzahl", value: "46049", alias: "46049" },
  { category: "Postleitzahl", value: "46117", alias: "46117" },
  { category: "Postleitzahl", value: "46119", alias: "46119" },
  { category: "Postleitzahl", value: "46145", alias: "46145" },
  { category: "Postleitzahl", value: "46147", alias: "46147" },
  { category: "Postleitzahl", value: "46149", alias: "46149" },
  { category: "Postleitzahl", value: "46236", alias: "46236" },
  { category: "Postleitzahl", value: "46238", alias: "46238" },
  { category: "Postleitzahl", value: "46240", alias: "46240" },
  { category: "Postleitzahl", value: "46242", alias: "46242" },
  { category: "Postleitzahl", value: "46244", alias: "46244" },
  { category: "Postleitzahl", value: "46282", alias: "46282" },
  { category: "Postleitzahl", value: "46284", alias: "46284" },
  { category: "Postleitzahl", value: "46286", alias: "46286" },
  { category: "Postleitzahl", value: "46325", alias: "46325" },
  { category: "Postleitzahl", value: "46342", alias: "46342" },
  { category: "Postleitzahl", value: "46348", alias: "46348" },
  { category: "Postleitzahl", value: "46354", alias: "46354" },
  { category: "Postleitzahl", value: "46359", alias: "46359" },
  { category: "Postleitzahl", value: "46395", alias: "46395" },
  { category: "Postleitzahl", value: "46397", alias: "46397" },
  { category: "Postleitzahl", value: "46399", alias: "46399" },
  { category: "Postleitzahl", value: "46414", alias: "46414" },
  { category: "Postleitzahl", value: "46419", alias: "46419" },
  { category: "Postleitzahl", value: "46446", alias: "46446" },
  { category: "Postleitzahl", value: "46459", alias: "46459" },
  { category: "Postleitzahl", value: "46483", alias: "46483" },
  { category: "Postleitzahl", value: "46485", alias: "46485" },
  { category: "Postleitzahl", value: "46487", alias: "46487" },
  { category: "Postleitzahl", value: "46499", alias: "46499" },
  { category: "Postleitzahl", value: "46509", alias: "46509" },
  { category: "Postleitzahl", value: "46514", alias: "46514" },
  { category: "Postleitzahl", value: "46519", alias: "46519" },
  { category: "Postleitzahl", value: "46535", alias: "46535" },
  { category: "Postleitzahl", value: "46537", alias: "46537" },
  { category: "Postleitzahl", value: "46539", alias: "46539" },
  { category: "Postleitzahl", value: "46562", alias: "46562" },
  { category: "Postleitzahl", value: "46569", alias: "46569" },
  { category: "Postleitzahl", value: "47051", alias: "47051" },
  { category: "Postleitzahl", value: "47053", alias: "47053" },
  { category: "Postleitzahl", value: "47055", alias: "47055" },
  { category: "Postleitzahl", value: "47057", alias: "47057" },
  { category: "Postleitzahl", value: "47058", alias: "47058" },
  { category: "Postleitzahl", value: "47059", alias: "47059" },
  { category: "Postleitzahl", value: "47119", alias: "47119" },
  { category: "Postleitzahl", value: "47137", alias: "47137" },
  { category: "Postleitzahl", value: "47138", alias: "47138" },
  { category: "Postleitzahl", value: "47139", alias: "47139" },
  { category: "Postleitzahl", value: "47166", alias: "47166" },
  { category: "Postleitzahl", value: "47167", alias: "47167" },
  { category: "Postleitzahl", value: "47169", alias: "47169" },
  { category: "Postleitzahl", value: "47178", alias: "47178" },
  { category: "Postleitzahl", value: "47179", alias: "47179" },
  { category: "Postleitzahl", value: "47198", alias: "47198" },
  { category: "Postleitzahl", value: "47199", alias: "47199" },
  { category: "Postleitzahl", value: "47226", alias: "47226" },
  { category: "Postleitzahl", value: "47228", alias: "47228" },
  { category: "Postleitzahl", value: "47229", alias: "47229" },
  { category: "Postleitzahl", value: "47239", alias: "47239" },
  { category: "Postleitzahl", value: "47249", alias: "47249" },
  { category: "Postleitzahl", value: "47259", alias: "47259" },
  { category: "Postleitzahl", value: "47269", alias: "47269" },
  { category: "Postleitzahl", value: "47279", alias: "47279" },
  { category: "Postleitzahl", value: "47441", alias: "47441" },
  { category: "Postleitzahl", value: "47443", alias: "47443" },
  { category: "Postleitzahl", value: "47445", alias: "47445" },
  { category: "Postleitzahl", value: "47447", alias: "47447" },
  { category: "Postleitzahl", value: "47475", alias: "47475" },
  { category: "Postleitzahl", value: "47495", alias: "47495" },
  { category: "Postleitzahl", value: "47506", alias: "47506" },
  { category: "Postleitzahl", value: "47509", alias: "47509" },
  { category: "Postleitzahl", value: "47533", alias: "47533" },
  { category: "Postleitzahl", value: "47546", alias: "47546" },
  { category: "Postleitzahl", value: "47551", alias: "47551" },
  { category: "Postleitzahl", value: "47559", alias: "47559" },
  { category: "Postleitzahl", value: "47574", alias: "47574" },
  { category: "Postleitzahl", value: "47589", alias: "47589" },
  { category: "Postleitzahl", value: "47608", alias: "47608" },
  { category: "Postleitzahl", value: "47623", alias: "47623" },
  { category: "Postleitzahl", value: "47624", alias: "47624" },
  { category: "Postleitzahl", value: "47625", alias: "47625" },
  { category: "Postleitzahl", value: "47626", alias: "47626" },
  { category: "Postleitzahl", value: "47627", alias: "47627" },
  { category: "Postleitzahl", value: "47638", alias: "47638" },
  { category: "Postleitzahl", value: "47647", alias: "47647" },
  { category: "Postleitzahl", value: "47652", alias: "47652" },
  { category: "Postleitzahl", value: "47661", alias: "47661" },
  { category: "Postleitzahl", value: "47665", alias: "47665" },
  { category: "Postleitzahl", value: "47669", alias: "47669" },
  { category: "Postleitzahl", value: "47798", alias: "47798" },
  { category: "Postleitzahl", value: "47799", alias: "47799" },
  { category: "Postleitzahl", value: "47800", alias: "47800" },
  { category: "Postleitzahl", value: "47802", alias: "47802" },
  { category: "Postleitzahl", value: "47803", alias: "47803" },
  { category: "Postleitzahl", value: "47804", alias: "47804" },
  { category: "Postleitzahl", value: "47805", alias: "47805" },
  { category: "Postleitzahl", value: "47807", alias: "47807" },
  { category: "Postleitzahl", value: "47809", alias: "47809" },
  { category: "Postleitzahl", value: "47829", alias: "47829" },
  { category: "Postleitzahl", value: "47839", alias: "47839" },
  { category: "Postleitzahl", value: "47877", alias: "47877" },
  { category: "Postleitzahl", value: "47906", alias: "47906" },
  { category: "Postleitzahl", value: "47918", alias: "47918" },
  { category: "Postleitzahl", value: "47929", alias: "47929" },
  { category: "Postleitzahl", value: "48143", alias: "48143" },
  { category: "Postleitzahl", value: "48145", alias: "48145" },
  { category: "Postleitzahl", value: "48147", alias: "48147" },
  { category: "Postleitzahl", value: "48149", alias: "48149" },
  { category: "Postleitzahl", value: "48151", alias: "48151" },
  { category: "Postleitzahl", value: "48153", alias: "48153" },
  { category: "Postleitzahl", value: "48155", alias: "48155" },
  { category: "Postleitzahl", value: "48157", alias: "48157" },
  { category: "Postleitzahl", value: "48159", alias: "48159" },
  { category: "Postleitzahl", value: "48161", alias: "48161" },
  { category: "Postleitzahl", value: "48163", alias: "48163" },
  { category: "Postleitzahl", value: "48165", alias: "48165" },
  { category: "Postleitzahl", value: "48167", alias: "48167" },
  { category: "Postleitzahl", value: "48231", alias: "48231" },
  { category: "Postleitzahl", value: "48249", alias: "48249" },
  { category: "Postleitzahl", value: "48268", alias: "48268" },
  { category: "Postleitzahl", value: "48282", alias: "48282" },
  { category: "Postleitzahl", value: "48291", alias: "48291" },
  { category: "Postleitzahl", value: "48301", alias: "48301" },
  { category: "Postleitzahl", value: "48308", alias: "48308" },
  { category: "Postleitzahl", value: "48317", alias: "48317" },
  { category: "Postleitzahl", value: "48324", alias: "48324" },
  { category: "Postleitzahl", value: "48329", alias: "48329" },
  { category: "Postleitzahl", value: "48336", alias: "48336" },
  { category: "Postleitzahl", value: "48341", alias: "48341" },
  { category: "Postleitzahl", value: "48346", alias: "48346" },
  { category: "Postleitzahl", value: "48351", alias: "48351" },
  { category: "Postleitzahl", value: "48356", alias: "48356" },
  { category: "Postleitzahl", value: "48361", alias: "48361" },
  { category: "Postleitzahl", value: "48366", alias: "48366" },
  { category: "Postleitzahl", value: "48369", alias: "48369" },
  { category: "Postleitzahl", value: "48429", alias: "48429" },
  { category: "Postleitzahl", value: "48431", alias: "48431" },
  { category: "Postleitzahl", value: "48432", alias: "48432" },
  { category: "Postleitzahl", value: "48455", alias: "48455" },
  { category: "Postleitzahl", value: "48465", alias: "48465" },
  { category: "Postleitzahl", value: "48477", alias: "48477" },
  { category: "Postleitzahl", value: "48480", alias: "48480" },
  { category: "Postleitzahl", value: "48485", alias: "48485" },
  { category: "Postleitzahl", value: "48488", alias: "48488" },
  { category: "Postleitzahl", value: "48493", alias: "48493" },
  { category: "Postleitzahl", value: "48496", alias: "48496" },
  { category: "Postleitzahl", value: "48499", alias: "48499" },
  { category: "Postleitzahl", value: "48527", alias: "48527" },
  { category: "Postleitzahl", value: "48529", alias: "48529" },
  { category: "Postleitzahl", value: "48531", alias: "48531" },
  { category: "Postleitzahl", value: "48565", alias: "48565" },
  { category: "Postleitzahl", value: "48599", alias: "48599" },
  { category: "Postleitzahl", value: "48607", alias: "48607" },
  { category: "Postleitzahl", value: "48612", alias: "48612" },
  { category: "Postleitzahl", value: "48619", alias: "48619" },
  { category: "Postleitzahl", value: "48624", alias: "48624" },
  { category: "Postleitzahl", value: "48629", alias: "48629" },
  { category: "Postleitzahl", value: "48653", alias: "48653" },
  { category: "Postleitzahl", value: "48683", alias: "48683" },
  { category: "Postleitzahl", value: "48691", alias: "48691" },
  { category: "Postleitzahl", value: "48703", alias: "48703" },
  { category: "Postleitzahl", value: "48712", alias: "48712" },
  { category: "Postleitzahl", value: "48720", alias: "48720" },
  { category: "Postleitzahl", value: "48727", alias: "48727" },
  { category: "Postleitzahl", value: "48734", alias: "48734" },
  { category: "Postleitzahl", value: "48739", alias: "48739" },
  { category: "Postleitzahl", value: "49074", alias: "49074" },
  { category: "Postleitzahl", value: "49076", alias: "49076" },
  { category: "Postleitzahl", value: "49078", alias: "49078" },
  { category: "Postleitzahl", value: "49080", alias: "49080" },
  { category: "Postleitzahl", value: "49082", alias: "49082" },
  { category: "Postleitzahl", value: "49084", alias: "49084" },
  { category: "Postleitzahl", value: "49086", alias: "49086" },
  { category: "Postleitzahl", value: "49088", alias: "49088" },
  { category: "Postleitzahl", value: "49090", alias: "49090" },
  { category: "Postleitzahl", value: "49124", alias: "49124" },
  { category: "Postleitzahl", value: "49134", alias: "49134" },
  { category: "Postleitzahl", value: "49143", alias: "49143" },
  { category: "Postleitzahl", value: "49152", alias: "49152" },
  { category: "Postleitzahl", value: "49163", alias: "49163" },
  { category: "Postleitzahl", value: "49170", alias: "49170" },
  { category: "Postleitzahl", value: "49176", alias: "49176" },
  { category: "Postleitzahl", value: "49179", alias: "49179" },
  { category: "Postleitzahl", value: "49186", alias: "49186" },
  { category: "Postleitzahl", value: "49191", alias: "49191" },
  { category: "Postleitzahl", value: "49196", alias: "49196" },
  { category: "Postleitzahl", value: "49201", alias: "49201" },
  { category: "Postleitzahl", value: "49205", alias: "49205" },
  { category: "Postleitzahl", value: "49214", alias: "49214" },
  { category: "Postleitzahl", value: "49219", alias: "49219" },
  { category: "Postleitzahl", value: "49324", alias: "49324" },
  { category: "Postleitzahl", value: "49326", alias: "49326" },
  { category: "Postleitzahl", value: "49328", alias: "49328" },
  { category: "Postleitzahl", value: "49356", alias: "49356" },
  { category: "Postleitzahl", value: "49377", alias: "49377" },
  { category: "Postleitzahl", value: "49393", alias: "49393" },
  { category: "Postleitzahl", value: "49401", alias: "49401" },
  { category: "Postleitzahl", value: "49406", alias: "49406" },
  { category: "Postleitzahl", value: "49413", alias: "49413" },
  { category: "Postleitzahl", value: "49419", alias: "49419" },
  { category: "Postleitzahl", value: "49424", alias: "49424" },
  { category: "Postleitzahl", value: "49429", alias: "49429" },
  { category: "Postleitzahl", value: "49434", alias: "49434" },
  { category: "Postleitzahl", value: "49439", alias: "49439" },
  { category: "Postleitzahl", value: "49448", alias: "49448" },
  { category: "Postleitzahl", value: "49451", alias: "49451" },
  { category: "Postleitzahl", value: "49453", alias: "49453" },
  { category: "Postleitzahl", value: "49456", alias: "49456" },
  { category: "Postleitzahl", value: "49457", alias: "49457" },
  { category: "Postleitzahl", value: "49459", alias: "49459" },
  { category: "Postleitzahl", value: "49477", alias: "49477" },
  { category: "Postleitzahl", value: "49479", alias: "49479" },
  { category: "Postleitzahl", value: "49492", alias: "49492" },
  { category: "Postleitzahl", value: "49497", alias: "49497" },
  { category: "Postleitzahl", value: "49504", alias: "49504" },
  { category: "Postleitzahl", value: "49509", alias: "49509" },
  { category: "Postleitzahl", value: "49525", alias: "49525" },
  { category: "Postleitzahl", value: "49536", alias: "49536" },
  { category: "Postleitzahl", value: "49545", alias: "49545" },
  { category: "Postleitzahl", value: "49549", alias: "49549" },
  { category: "Postleitzahl", value: "49565", alias: "49565" },
  { category: "Postleitzahl", value: "49577", alias: "49577" },
  { category: "Postleitzahl", value: "49584", alias: "49584" },
  { category: "Postleitzahl", value: "49586", alias: "49586" },
  { category: "Postleitzahl", value: "49593", alias: "49593" },
  { category: "Postleitzahl", value: "49594", alias: "49594" },
  { category: "Postleitzahl", value: "49596", alias: "49596" },
  { category: "Postleitzahl", value: "49597", alias: "49597" },
  { category: "Postleitzahl", value: "49599", alias: "49599" },
  { category: "Postleitzahl", value: "49610", alias: "49610" },
  { category: "Postleitzahl", value: "49624", alias: "49624" },
  { category: "Postleitzahl", value: "49626", alias: "49626" },
  { category: "Postleitzahl", value: "49632", alias: "49632" },
  { category: "Postleitzahl", value: "49635", alias: "49635" },
  { category: "Postleitzahl", value: "49637", alias: "49637" },
  { category: "Postleitzahl", value: "49638", alias: "49638" },
  { category: "Postleitzahl", value: "49661", alias: "49661" },
  { category: "Postleitzahl", value: "49681", alias: "49681" },
  { category: "Postleitzahl", value: "49685", alias: "49685" },
  { category: "Postleitzahl", value: "49688", alias: "49688" },
  { category: "Postleitzahl", value: "49692", alias: "49692" },
  { category: "Postleitzahl", value: "49696", alias: "49696" },
  { category: "Postleitzahl", value: "49699", alias: "49699" },
  { category: "Postleitzahl", value: "49716", alias: "49716" },
  { category: "Postleitzahl", value: "49733", alias: "49733" },
  { category: "Postleitzahl", value: "49740", alias: "49740" },
  { category: "Postleitzahl", value: "49744", alias: "49744" },
  { category: "Postleitzahl", value: "49751", alias: "49751" },
  { category: "Postleitzahl", value: "49757", alias: "49757" },
  { category: "Postleitzahl", value: "49762", alias: "49762" },
  { category: "Postleitzahl", value: "49767", alias: "49767" },
  { category: "Postleitzahl", value: "49770", alias: "49770" },
  { category: "Postleitzahl", value: "49774", alias: "49774" },
  { category: "Postleitzahl", value: "49777", alias: "49777" },
  { category: "Postleitzahl", value: "49779", alias: "49779" },
  { category: "Postleitzahl", value: "49808", alias: "49808" },
  { category: "Postleitzahl", value: "49809", alias: "49809" },
  { category: "Postleitzahl", value: "49811", alias: "49811" },
  { category: "Postleitzahl", value: "49824", alias: "49824" },
  { category: "Postleitzahl", value: "49828", alias: "49828" },
  { category: "Postleitzahl", value: "49832", alias: "49832" },
  { category: "Postleitzahl", value: "49835", alias: "49835" },
  { category: "Postleitzahl", value: "49838", alias: "49838" },
  { category: "Postleitzahl", value: "49843", alias: "49843" },
  { category: "Postleitzahl", value: "49844", alias: "49844" },
  { category: "Postleitzahl", value: "49846", alias: "49846" },
  { category: "Postleitzahl", value: "49847", alias: "49847" },
  { category: "Postleitzahl", value: "49849", alias: "49849" },
  { category: "Postleitzahl", value: "50126", alias: "50126" },
  { category: "Postleitzahl", value: "50127", alias: "50127" },
  { category: "Postleitzahl", value: "50129", alias: "50129" },
  { category: "Postleitzahl", value: "50169", alias: "50169" },
  { category: "Postleitzahl", value: "50170", alias: "50170" },
  { category: "Postleitzahl", value: "50171", alias: "50171" },
  { category: "Postleitzahl", value: "50181", alias: "50181" },
  { category: "Postleitzahl", value: "50189", alias: "50189" },
  { category: "Postleitzahl", value: "50226", alias: "50226" },
  { category: "Postleitzahl", value: "50259", alias: "50259" },
  { category: "Postleitzahl", value: "50321", alias: "50321" },
  { category: "Postleitzahl", value: "50354", alias: "50354" },
  { category: "Postleitzahl", value: "50374", alias: "50374" },
  { category: "Postleitzahl", value: "50389", alias: "50389" },
  { category: "Postleitzahl", value: "50667", alias: "50667" },
  { category: "Postleitzahl", value: "50668", alias: "50668" },
  { category: "Postleitzahl", value: "50670", alias: "50670" },
  { category: "Postleitzahl", value: "50672", alias: "50672" },
  { category: "Postleitzahl", value: "50674", alias: "50674" },
  { category: "Postleitzahl", value: "50676", alias: "50676" },
  { category: "Postleitzahl", value: "50677", alias: "50677" },
  { category: "Postleitzahl", value: "50678", alias: "50678" },
  { category: "Postleitzahl", value: "50679", alias: "50679" },
  { category: "Postleitzahl", value: "50733", alias: "50733" },
  { category: "Postleitzahl", value: "50735", alias: "50735" },
  { category: "Postleitzahl", value: "50737", alias: "50737" },
  { category: "Postleitzahl", value: "50739", alias: "50739" },
  { category: "Postleitzahl", value: "50765", alias: "50765" },
  { category: "Postleitzahl", value: "50767", alias: "50767" },
  { category: "Postleitzahl", value: "50769", alias: "50769" },
  { category: "Postleitzahl", value: "50823", alias: "50823" },
  { category: "Postleitzahl", value: "50825", alias: "50825" },
  { category: "Postleitzahl", value: "50827", alias: "50827" },
  { category: "Postleitzahl", value: "50829", alias: "50829" },
  { category: "Postleitzahl", value: "50858", alias: "50858" },
  { category: "Postleitzahl", value: "50859", alias: "50859" },
  { category: "Postleitzahl", value: "50931", alias: "50931" },
  { category: "Postleitzahl", value: "50933", alias: "50933" },
  { category: "Postleitzahl", value: "50935", alias: "50935" },
  { category: "Postleitzahl", value: "50937", alias: "50937" },
  { category: "Postleitzahl", value: "50939", alias: "50939" },
  { category: "Postleitzahl", value: "50968", alias: "50968" },
  { category: "Postleitzahl", value: "50969", alias: "50969" },
  { category: "Postleitzahl", value: "50996", alias: "50996" },
  { category: "Postleitzahl", value: "50997", alias: "50997" },
  { category: "Postleitzahl", value: "50999", alias: "50999" },
  { category: "Postleitzahl", value: "51061", alias: "51061" },
  { category: "Postleitzahl", value: "51063", alias: "51063" },
  { category: "Postleitzahl", value: "51065", alias: "51065" },
  { category: "Postleitzahl", value: "51067", alias: "51067" },
  { category: "Postleitzahl", value: "51069", alias: "51069" },
  { category: "Postleitzahl", value: "51103", alias: "51103" },
  { category: "Postleitzahl", value: "51105", alias: "51105" },
  { category: "Postleitzahl", value: "51107", alias: "51107" },
  { category: "Postleitzahl", value: "51109", alias: "51109" },
  { category: "Postleitzahl", value: "51143", alias: "51143" },
  { category: "Postleitzahl", value: "51145", alias: "51145" },
  { category: "Postleitzahl", value: "51147", alias: "51147" },
  { category: "Postleitzahl", value: "51149", alias: "51149" },
  { category: "Postleitzahl", value: "51371", alias: "51371" },
  { category: "Postleitzahl", value: "51373", alias: "51373" },
  { category: "Postleitzahl", value: "51375", alias: "51375" },
  { category: "Postleitzahl", value: "51377", alias: "51377" },
  { category: "Postleitzahl", value: "51379", alias: "51379" },
  { category: "Postleitzahl", value: "51381", alias: "51381" },
  { category: "Postleitzahl", value: "51399", alias: "51399" },
  { category: "Postleitzahl", value: "51427", alias: "51427" },
  { category: "Postleitzahl", value: "51429", alias: "51429" },
  { category: "Postleitzahl", value: "51465", alias: "51465" },
  { category: "Postleitzahl", value: "51467", alias: "51467" },
  { category: "Postleitzahl", value: "51469", alias: "51469" },
  { category: "Postleitzahl", value: "51491", alias: "51491" },
  { category: "Postleitzahl", value: "51503", alias: "51503" },
  { category: "Postleitzahl", value: "51515", alias: "51515" },
  { category: "Postleitzahl", value: "51519", alias: "51519" },
  { category: "Postleitzahl", value: "51545", alias: "51545" },
  { category: "Postleitzahl", value: "51570", alias: "51570" },
  { category: "Postleitzahl", value: "51580", alias: "51580" },
  { category: "Postleitzahl", value: "51588", alias: "51588" },
  { category: "Postleitzahl", value: "51597", alias: "51597" },
  { category: "Postleitzahl", value: "51598", alias: "51598" },
  { category: "Postleitzahl", value: "51643", alias: "51643" },
  { category: "Postleitzahl", value: "51645", alias: "51645" },
  { category: "Postleitzahl", value: "51647", alias: "51647" },
  { category: "Postleitzahl", value: "51674", alias: "51674" },
  { category: "Postleitzahl", value: "51688", alias: "51688" },
  { category: "Postleitzahl", value: "51702", alias: "51702" },
  { category: "Postleitzahl", value: "51709", alias: "51709" },
  { category: "Postleitzahl", value: "51766", alias: "51766" },
  { category: "Postleitzahl", value: "51789", alias: "51789" },
  { category: "Postleitzahl", value: "52062", alias: "52062" },
  { category: "Postleitzahl", value: "52064", alias: "52064" },
  { category: "Postleitzahl", value: "52066", alias: "52066" },
  { category: "Postleitzahl", value: "52068", alias: "52068" },
  { category: "Postleitzahl", value: "52070", alias: "52070" },
  { category: "Postleitzahl", value: "52072", alias: "52072" },
  { category: "Postleitzahl", value: "52074", alias: "52074" },
  { category: "Postleitzahl", value: "52076", alias: "52076" },
  { category: "Postleitzahl", value: "52078", alias: "52078" },
  { category: "Postleitzahl", value: "52080", alias: "52080" },
  { category: "Postleitzahl", value: "52134", alias: "52134" },
  { category: "Postleitzahl", value: "52146", alias: "52146" },
  { category: "Postleitzahl", value: "52152", alias: "52152" },
  { category: "Postleitzahl", value: "52156", alias: "52156" },
  { category: "Postleitzahl", value: "52159", alias: "52159" },
  { category: "Postleitzahl", value: "52222", alias: "52222" },
  { category: "Postleitzahl", value: "52223", alias: "52223" },
  { category: "Postleitzahl", value: "52224", alias: "52224" },
  { category: "Postleitzahl", value: "52249", alias: "52249" },
  { category: "Postleitzahl", value: "52349", alias: "52349" },
  { category: "Postleitzahl", value: "52351", alias: "52351" },
  { category: "Postleitzahl", value: "52353", alias: "52353" },
  { category: "Postleitzahl", value: "52355", alias: "52355" },
  { category: "Postleitzahl", value: "52372", alias: "52372" },
  { category: "Postleitzahl", value: "52379", alias: "52379" },
  { category: "Postleitzahl", value: "52382", alias: "52382" },
  { category: "Postleitzahl", value: "52385", alias: "52385" },
  { category: "Postleitzahl", value: "52388", alias: "52388" },
  { category: "Postleitzahl", value: "52391", alias: "52391" },
  { category: "Postleitzahl", value: "52393", alias: "52393" },
  { category: "Postleitzahl", value: "52396", alias: "52396" },
  { category: "Postleitzahl", value: "52399", alias: "52399" },
  { category: "Postleitzahl", value: "52428", alias: "52428" },
  { category: "Postleitzahl", value: "52441", alias: "52441" },
  { category: "Postleitzahl", value: "52445", alias: "52445" },
  { category: "Postleitzahl", value: "52457", alias: "52457" },
  { category: "Postleitzahl", value: "52459", alias: "52459" },
  { category: "Postleitzahl", value: "52477", alias: "52477" },
  { category: "Postleitzahl", value: "52499", alias: "52499" },
  { category: "Postleitzahl", value: "52511", alias: "52511" },
  { category: "Postleitzahl", value: "52525", alias: "52525" },
  { category: "Postleitzahl", value: "52531", alias: "52531" },
  { category: "Postleitzahl", value: "52538", alias: "52538" },
  { category: "Postleitzahl", value: "53111", alias: "53111" },
  { category: "Postleitzahl", value: "53113", alias: "53113" },
  { category: "Postleitzahl", value: "53115", alias: "53115" },
  { category: "Postleitzahl", value: "53117", alias: "53117" },
  { category: "Postleitzahl", value: "53119", alias: "53119" },
  { category: "Postleitzahl", value: "53121", alias: "53121" },
  { category: "Postleitzahl", value: "53123", alias: "53123" },
  { category: "Postleitzahl", value: "53125", alias: "53125" },
  { category: "Postleitzahl", value: "53127", alias: "53127" },
  { category: "Postleitzahl", value: "53129", alias: "53129" },
  { category: "Postleitzahl", value: "53173", alias: "53173" },
  { category: "Postleitzahl", value: "53175", alias: "53175" },
  { category: "Postleitzahl", value: "53177", alias: "53177" },
  { category: "Postleitzahl", value: "53179", alias: "53179" },
  { category: "Postleitzahl", value: "53225", alias: "53225" },
  { category: "Postleitzahl", value: "53227", alias: "53227" },
  { category: "Postleitzahl", value: "53229", alias: "53229" },
  { category: "Postleitzahl", value: "53332", alias: "53332" },
  { category: "Postleitzahl", value: "53340", alias: "53340" },
  { category: "Postleitzahl", value: "53343", alias: "53343" },
  { category: "Postleitzahl", value: "53347", alias: "53347" },
  { category: "Postleitzahl", value: "53359", alias: "53359" },
  { category: "Postleitzahl", value: "53424", alias: "53424" },
  { category: "Postleitzahl", value: "53426", alias: "53426" },
  { category: "Postleitzahl", value: "53474", alias: "53474" },
  { category: "Postleitzahl", value: "53489", alias: "53489" },
  { category: "Postleitzahl", value: "53498", alias: "53498" },
  { category: "Postleitzahl", value: "53501", alias: "53501" },
  { category: "Postleitzahl", value: "53505", alias: "53505" },
  { category: "Postleitzahl", value: "53506", alias: "53506" },
  { category: "Postleitzahl", value: "53507", alias: "53507" },
  { category: "Postleitzahl", value: "53508", alias: "53508" },
  { category: "Postleitzahl", value: "53518", alias: "53518" },
  { category: "Postleitzahl", value: "53520", alias: "53520" },
  { category: "Postleitzahl", value: "53533", alias: "53533" },
  { category: "Postleitzahl", value: "53534", alias: "53534" },
  { category: "Postleitzahl", value: "53539", alias: "53539" },
  { category: "Postleitzahl", value: "53545", alias: "53545" },
  { category: "Postleitzahl", value: "53547", alias: "53547" },
  { category: "Postleitzahl", value: "53557", alias: "53557" },
  { category: "Postleitzahl", value: "53560", alias: "53560" },
  { category: "Postleitzahl", value: "53562", alias: "53562" },
  { category: "Postleitzahl", value: "53567", alias: "53567" },
  { category: "Postleitzahl", value: "53572", alias: "53572" },
  { category: "Postleitzahl", value: "53577", alias: "53577" },
  { category: "Postleitzahl", value: "53578", alias: "53578" },
  { category: "Postleitzahl", value: "53579", alias: "53579" },
  { category: "Postleitzahl", value: "53604", alias: "53604" },
  { category: "Postleitzahl", value: "53619", alias: "53619" },
  { category: "Postleitzahl", value: "53639", alias: "53639" },
  { category: "Postleitzahl", value: "53721", alias: "53721" },
  { category: "Postleitzahl", value: "53757", alias: "53757" },
  { category: "Postleitzahl", value: "53773", alias: "53773" },
  { category: "Postleitzahl", value: "53783", alias: "53783" },
  { category: "Postleitzahl", value: "53797", alias: "53797" },
  { category: "Postleitzahl", value: "53804", alias: "53804" },
  { category: "Postleitzahl", value: "53809", alias: "53809" },
  { category: "Postleitzahl", value: "53819", alias: "53819" },
  { category: "Postleitzahl", value: "53840", alias: "53840" },
  { category: "Postleitzahl", value: "53842", alias: "53842" },
  { category: "Postleitzahl", value: "53844", alias: "53844" },
  { category: "Postleitzahl", value: "53859", alias: "53859" },
  { category: "Postleitzahl", value: "53879", alias: "53879" },
  { category: "Postleitzahl", value: "53881", alias: "53881" },
  { category: "Postleitzahl", value: "53894", alias: "53894" },
  { category: "Postleitzahl", value: "53902", alias: "53902" },
  { category: "Postleitzahl", value: "53909", alias: "53909" },
  { category: "Postleitzahl", value: "53913", alias: "53913" },
  { category: "Postleitzahl", value: "53919", alias: "53919" },
  { category: "Postleitzahl", value: "53925", alias: "53925" },
  { category: "Postleitzahl", value: "53937", alias: "53937" },
  { category: "Postleitzahl", value: "53940", alias: "53940" },
  { category: "Postleitzahl", value: "53945", alias: "53945" },
  { category: "Postleitzahl", value: "53947", alias: "53947" },
  { category: "Postleitzahl", value: "53949", alias: "53949" },
  { category: "Postleitzahl", value: "54290", alias: "54290" },
  { category: "Postleitzahl", value: "54292", alias: "54292" },
  { category: "Postleitzahl", value: "54293", alias: "54293" },
  { category: "Postleitzahl", value: "54294", alias: "54294" },
  { category: "Postleitzahl", value: "54295", alias: "54295" },
  { category: "Postleitzahl", value: "54296", alias: "54296" },
  { category: "Postleitzahl", value: "54298", alias: "54298" },
  { category: "Postleitzahl", value: "54306", alias: "54306" },
  { category: "Postleitzahl", value: "54308", alias: "54308" },
  { category: "Postleitzahl", value: "54309", alias: "54309" },
  { category: "Postleitzahl", value: "54310", alias: "54310" },
  { category: "Postleitzahl", value: "54311", alias: "54311" },
  { category: "Postleitzahl", value: "54313", alias: "54313" },
  { category: "Postleitzahl", value: "54314", alias: "54314" },
  { category: "Postleitzahl", value: "54316", alias: "54316" },
  { category: "Postleitzahl", value: "54317", alias: "54317" },
  { category: "Postleitzahl", value: "54318", alias: "54318" },
  { category: "Postleitzahl", value: "54320", alias: "54320" },
  { category: "Postleitzahl", value: "54329", alias: "54329" },
  { category: "Postleitzahl", value: "54331", alias: "54331" },
  { category: "Postleitzahl", value: "54332", alias: "54332" },
  { category: "Postleitzahl", value: "54338", alias: "54338" },
  { category: "Postleitzahl", value: "54340", alias: "54340" },
  { category: "Postleitzahl", value: "54341", alias: "54341" },
  { category: "Postleitzahl", value: "54343", alias: "54343" },
  { category: "Postleitzahl", value: "54344", alias: "54344" },
  { category: "Postleitzahl", value: "54346", alias: "54346" },
  { category: "Postleitzahl", value: "54347", alias: "54347" },
  { category: "Postleitzahl", value: "54349", alias: "54349" },
  { category: "Postleitzahl", value: "54411", alias: "54411" },
  { category: "Postleitzahl", value: "54413", alias: "54413" },
  { category: "Postleitzahl", value: "54421", alias: "54421" },
  { category: "Postleitzahl", value: "54422", alias: "54422" },
  { category: "Postleitzahl", value: "54424", alias: "54424" },
  { category: "Postleitzahl", value: "54426", alias: "54426" },
  { category: "Postleitzahl", value: "54427", alias: "54427" },
  { category: "Postleitzahl", value: "54429", alias: "54429" },
  { category: "Postleitzahl", value: "54439", alias: "54439" },
  { category: "Postleitzahl", value: "54441", alias: "54441" },
  { category: "Postleitzahl", value: "54450", alias: "54450" },
  { category: "Postleitzahl", value: "54451", alias: "54451" },
  { category: "Postleitzahl", value: "54453", alias: "54453" },
  { category: "Postleitzahl", value: "54455", alias: "54455" },
  { category: "Postleitzahl", value: "54456", alias: "54456" },
  { category: "Postleitzahl", value: "54457", alias: "54457" },
  { category: "Postleitzahl", value: "54459", alias: "54459" },
  { category: "Postleitzahl", value: "54470", alias: "54470" },
  { category: "Postleitzahl", value: "54472", alias: "54472" },
  { category: "Postleitzahl", value: "54483", alias: "54483" },
  { category: "Postleitzahl", value: "54484", alias: "54484" },
  { category: "Postleitzahl", value: "54486", alias: "54486" },
  { category: "Postleitzahl", value: "54487", alias: "54487" },
  { category: "Postleitzahl", value: "54492", alias: "54492" },
  { category: "Postleitzahl", value: "54497", alias: "54497" },
  { category: "Postleitzahl", value: "54498", alias: "54498" },
  { category: "Postleitzahl", value: "54516", alias: "54516" },
  { category: "Postleitzahl", value: "54518", alias: "54518" },
  { category: "Postleitzahl", value: "54523", alias: "54523" },
  { category: "Postleitzahl", value: "54524", alias: "54524" },
  { category: "Postleitzahl", value: "54526", alias: "54526" },
  { category: "Postleitzahl", value: "54528", alias: "54528" },
  { category: "Postleitzahl", value: "54529", alias: "54529" },
  { category: "Postleitzahl", value: "54531", alias: "54531" },
  { category: "Postleitzahl", value: "54533", alias: "54533" },
  { category: "Postleitzahl", value: "54534", alias: "54534" },
  { category: "Postleitzahl", value: "54536", alias: "54536" },
  { category: "Postleitzahl", value: "54538", alias: "54538" },
  { category: "Postleitzahl", value: "54539", alias: "54539" },
  { category: "Postleitzahl", value: "54550", alias: "54550" },
  { category: "Postleitzahl", value: "54552", alias: "54552" },
  { category: "Postleitzahl", value: "54558", alias: "54558" },
  { category: "Postleitzahl", value: "54568", alias: "54568" },
  { category: "Postleitzahl", value: "54570", alias: "54570" },
  { category: "Postleitzahl", value: "54574", alias: "54574" },
  { category: "Postleitzahl", value: "54576", alias: "54576" },
  { category: "Postleitzahl", value: "54578", alias: "54578" },
  { category: "Postleitzahl", value: "54579", alias: "54579" },
  { category: "Postleitzahl", value: "54584", alias: "54584" },
  { category: "Postleitzahl", value: "54585", alias: "54585" },
  { category: "Postleitzahl", value: "54586", alias: "54586" },
  { category: "Postleitzahl", value: "54587", alias: "54587" },
  { category: "Postleitzahl", value: "54589", alias: "54589" },
  { category: "Postleitzahl", value: "54595", alias: "54595" },
  { category: "Postleitzahl", value: "54597", alias: "54597" },
  { category: "Postleitzahl", value: "54608", alias: "54608" },
  { category: "Postleitzahl", value: "54610", alias: "54610" },
  { category: "Postleitzahl", value: "54611", alias: "54611" },
  { category: "Postleitzahl", value: "54612", alias: "54612" },
  { category: "Postleitzahl", value: "54614", alias: "54614" },
  { category: "Postleitzahl", value: "54616", alias: "54616" },
  { category: "Postleitzahl", value: "54617", alias: "54617" },
  { category: "Postleitzahl", value: "54619", alias: "54619" },
  { category: "Postleitzahl", value: "54634", alias: "54634" },
  { category: "Postleitzahl", value: "54636", alias: "54636" },
  { category: "Postleitzahl", value: "54646", alias: "54646" },
  { category: "Postleitzahl", value: "54647", alias: "54647" },
  { category: "Postleitzahl", value: "54649", alias: "54649" },
  { category: "Postleitzahl", value: "54655", alias: "54655" },
  { category: "Postleitzahl", value: "54657", alias: "54657" },
  { category: "Postleitzahl", value: "54662", alias: "54662" },
  { category: "Postleitzahl", value: "54664", alias: "54664" },
  { category: "Postleitzahl", value: "54666", alias: "54666" },
  { category: "Postleitzahl", value: "54668", alias: "54668" },
  { category: "Postleitzahl", value: "54669", alias: "54669" },
  { category: "Postleitzahl", value: "54673", alias: "54673" },
  { category: "Postleitzahl", value: "54675", alias: "54675" },
  { category: "Postleitzahl", value: "54687", alias: "54687" },
  { category: "Postleitzahl", value: "54689", alias: "54689" },
  { category: "Postleitzahl", value: "55116", alias: "55116" },
  { category: "Postleitzahl", value: "55118", alias: "55118" },
  { category: "Postleitzahl", value: "55120", alias: "55120" },
  { category: "Postleitzahl", value: "55122", alias: "55122" },
  { category: "Postleitzahl", value: "55124", alias: "55124" },
  { category: "Postleitzahl", value: "55126", alias: "55126" },
  { category: "Postleitzahl", value: "55127", alias: "55127" },
  { category: "Postleitzahl", value: "55128", alias: "55128" },
  { category: "Postleitzahl", value: "55129", alias: "55129" },
  { category: "Postleitzahl", value: "55130", alias: "55130" },
  { category: "Postleitzahl", value: "55131", alias: "55131" },
  { category: "Postleitzahl", value: "55218", alias: "55218" },
  { category: "Postleitzahl", value: "55232", alias: "55232" },
  { category: "Postleitzahl", value: "55234", alias: "55234" },
  { category: "Postleitzahl", value: "55237", alias: "55237" },
  { category: "Postleitzahl", value: "55239", alias: "55239" },
  { category: "Postleitzahl", value: "55246", alias: "55246" },
  { category: "Postleitzahl", value: "55252", alias: "55252" },
  { category: "Postleitzahl", value: "55257", alias: "55257" },
  { category: "Postleitzahl", value: "55262", alias: "55262" },
  { category: "Postleitzahl", value: "55263", alias: "55263" },
  { category: "Postleitzahl", value: "55268", alias: "55268" },
  { category: "Postleitzahl", value: "55270", alias: "55270" },
  { category: "Postleitzahl", value: "55271", alias: "55271" },
  { category: "Postleitzahl", value: "55276", alias: "55276" },
  { category: "Postleitzahl", value: "55278", alias: "55278" },
  { category: "Postleitzahl", value: "55283", alias: "55283" },
  { category: "Postleitzahl", value: "55286", alias: "55286" },
  { category: "Postleitzahl", value: "55288", alias: "55288" },
  { category: "Postleitzahl", value: "55291", alias: "55291" },
  { category: "Postleitzahl", value: "55294", alias: "55294" },
  { category: "Postleitzahl", value: "55296", alias: "55296" },
  { category: "Postleitzahl", value: "55299", alias: "55299" },
  { category: "Postleitzahl", value: "55411", alias: "55411" },
  { category: "Postleitzahl", value: "55413", alias: "55413" },
  { category: "Postleitzahl", value: "55422", alias: "55422" },
  { category: "Postleitzahl", value: "55424", alias: "55424" },
  { category: "Postleitzahl", value: "55425", alias: "55425" },
  { category: "Postleitzahl", value: "55430", alias: "55430" },
  { category: "Postleitzahl", value: "55432", alias: "55432" },
  { category: "Postleitzahl", value: "55435", alias: "55435" },
  { category: "Postleitzahl", value: "55437", alias: "55437" },
  { category: "Postleitzahl", value: "55442", alias: "55442" },
  { category: "Postleitzahl", value: "55444", alias: "55444" },
  { category: "Postleitzahl", value: "55450", alias: "55450" },
  { category: "Postleitzahl", value: "55452", alias: "55452" },
  { category: "Postleitzahl", value: "55457", alias: "55457" },
  { category: "Postleitzahl", value: "55459", alias: "55459" },
  { category: "Postleitzahl", value: "55469", alias: "55469" },
  { category: "Postleitzahl", value: "55471", alias: "55471" },
  { category: "Postleitzahl", value: "55481", alias: "55481" },
  { category: "Postleitzahl", value: "55483", alias: "55483" },
  { category: "Postleitzahl", value: "55487", alias: "55487" },
  { category: "Postleitzahl", value: "55490", alias: "55490" },
  { category: "Postleitzahl", value: "55491", alias: "55491" },
  { category: "Postleitzahl", value: "55494", alias: "55494" },
  { category: "Postleitzahl", value: "55496", alias: "55496" },
  { category: "Postleitzahl", value: "55497", alias: "55497" },
  { category: "Postleitzahl", value: "55499", alias: "55499" },
  { category: "Postleitzahl", value: "55543", alias: "55543" },
  { category: "Postleitzahl", value: "55545", alias: "55545" },
  { category: "Postleitzahl", value: "55546", alias: "55546" },
  { category: "Postleitzahl", value: "55559", alias: "55559" },
  { category: "Postleitzahl", value: "55566", alias: "55566" },
  { category: "Postleitzahl", value: "55568", alias: "55568" },
  { category: "Postleitzahl", value: "55569", alias: "55569" },
  { category: "Postleitzahl", value: "55571", alias: "55571" },
  { category: "Postleitzahl", value: "55576", alias: "55576" },
  { category: "Postleitzahl", value: "55578", alias: "55578" },
  { category: "Postleitzahl", value: "55583", alias: "55583" },
  { category: "Postleitzahl", value: "55585", alias: "55585" },
  { category: "Postleitzahl", value: "55590", alias: "55590" },
  { category: "Postleitzahl", value: "55592", alias: "55592" },
  { category: "Postleitzahl", value: "55593", alias: "55593" },
  { category: "Postleitzahl", value: "55595", alias: "55595" },
  { category: "Postleitzahl", value: "55596", alias: "55596" },
  { category: "Postleitzahl", value: "55597", alias: "55597" },
  { category: "Postleitzahl", value: "55599", alias: "55599" },
  { category: "Postleitzahl", value: "55606", alias: "55606" },
  { category: "Postleitzahl", value: "55608", alias: "55608" },
  { category: "Postleitzahl", value: "55618", alias: "55618" },
  { category: "Postleitzahl", value: "55619", alias: "55619" },
  { category: "Postleitzahl", value: "55621", alias: "55621" },
  { category: "Postleitzahl", value: "55624", alias: "55624" },
  { category: "Postleitzahl", value: "55626", alias: "55626" },
  { category: "Postleitzahl", value: "55627", alias: "55627" },
  { category: "Postleitzahl", value: "55629", alias: "55629" },
  { category: "Postleitzahl", value: "55743", alias: "55743" },
  { category: "Postleitzahl", value: "55756", alias: "55756" },
  { category: "Postleitzahl", value: "55758", alias: "55758" },
  { category: "Postleitzahl", value: "55765", alias: "55765" },
  { category: "Postleitzahl", value: "55767", alias: "55767" },
  { category: "Postleitzahl", value: "55768", alias: "55768" },
  { category: "Postleitzahl", value: "55774", alias: "55774" },
  { category: "Postleitzahl", value: "55776", alias: "55776" },
  { category: "Postleitzahl", value: "55777", alias: "55777" },
  { category: "Postleitzahl", value: "55779", alias: "55779" },
  { category: "Postleitzahl", value: "56068", alias: "56068" },
  { category: "Postleitzahl", value: "56070", alias: "56070" },
  { category: "Postleitzahl", value: "56072", alias: "56072" },
  { category: "Postleitzahl", value: "56073", alias: "56073" },
  { category: "Postleitzahl", value: "56075", alias: "56075" },
  { category: "Postleitzahl", value: "56076", alias: "56076" },
  { category: "Postleitzahl", value: "56077", alias: "56077" },
  { category: "Postleitzahl", value: "56112", alias: "56112" },
  { category: "Postleitzahl", value: "56130", alias: "56130" },
  { category: "Postleitzahl", value: "56132", alias: "56132" },
  { category: "Postleitzahl", value: "56133", alias: "56133" },
  { category: "Postleitzahl", value: "56154", alias: "56154" },
  { category: "Postleitzahl", value: "56170", alias: "56170" },
  { category: "Postleitzahl", value: "56179", alias: "56179" },
  { category: "Postleitzahl", value: "56182", alias: "56182" },
  { category: "Postleitzahl", value: "56191", alias: "56191" },
  { category: "Postleitzahl", value: "56203", alias: "56203" },
  { category: "Postleitzahl", value: "56204", alias: "56204" },
  { category: "Postleitzahl", value: "56206", alias: "56206" },
  { category: "Postleitzahl", value: "56218", alias: "56218" },
  { category: "Postleitzahl", value: "56220", alias: "56220" },
  { category: "Postleitzahl", value: "56235", alias: "56235" },
  { category: "Postleitzahl", value: "56237", alias: "56237" },
  { category: "Postleitzahl", value: "56242", alias: "56242" },
  { category: "Postleitzahl", value: "56244", alias: "56244" },
  { category: "Postleitzahl", value: "56249", alias: "56249" },
  { category: "Postleitzahl", value: "56253", alias: "56253" },
  { category: "Postleitzahl", value: "56254", alias: "56254" },
  { category: "Postleitzahl", value: "56269", alias: "56269" },
  { category: "Postleitzahl", value: "56271", alias: "56271" },
  { category: "Postleitzahl", value: "56276", alias: "56276" },
  { category: "Postleitzahl", value: "56281", alias: "56281" },
  { category: "Postleitzahl", value: "56283", alias: "56283" },
  { category: "Postleitzahl", value: "56288", alias: "56288" },
  { category: "Postleitzahl", value: "56290", alias: "56290" },
  { category: "Postleitzahl", value: "56291", alias: "56291" },
  { category: "Postleitzahl", value: "56294", alias: "56294" },
  { category: "Postleitzahl", value: "56295", alias: "56295" },
  { category: "Postleitzahl", value: "56299", alias: "56299" },
  { category: "Postleitzahl", value: "56305", alias: "56305" },
  { category: "Postleitzahl", value: "56307", alias: "56307" },
  { category: "Postleitzahl", value: "56316", alias: "56316" },
  { category: "Postleitzahl", value: "56317", alias: "56317" },
  { category: "Postleitzahl", value: "56321", alias: "56321" },
  { category: "Postleitzahl", value: "56322", alias: "56322" },
  { category: "Postleitzahl", value: "56323", alias: "56323" },
  { category: "Postleitzahl", value: "56329", alias: "56329" },
  { category: "Postleitzahl", value: "56330", alias: "56330" },
  { category: "Postleitzahl", value: "56332", alias: "56332" },
  { category: "Postleitzahl", value: "56333", alias: "56333" },
  { category: "Postleitzahl", value: "56335", alias: "56335" },
  { category: "Postleitzahl", value: "56337", alias: "56337" },
  { category: "Postleitzahl", value: "56338", alias: "56338" },
  { category: "Postleitzahl", value: "56340", alias: "56340" },
  { category: "Postleitzahl", value: "56341", alias: "56341" },
  { category: "Postleitzahl", value: "56346", alias: "56346" },
  { category: "Postleitzahl", value: "56348", alias: "56348" },
  { category: "Postleitzahl", value: "56349", alias: "56349" },
  { category: "Postleitzahl", value: "56355", alias: "56355" },
  { category: "Postleitzahl", value: "56357", alias: "56357" },
  { category: "Postleitzahl", value: "56368", alias: "56368" },
  { category: "Postleitzahl", value: "56370", alias: "56370" },
  { category: "Postleitzahl", value: "56377", alias: "56377" },
  { category: "Postleitzahl", value: "56379", alias: "56379" },
  { category: "Postleitzahl", value: "56410", alias: "56410" },
  { category: "Postleitzahl", value: "56412", alias: "56412" },
  { category: "Postleitzahl", value: "56414", alias: "56414" },
  { category: "Postleitzahl", value: "56422", alias: "56422" },
  { category: "Postleitzahl", value: "56424", alias: "56424" },
  { category: "Postleitzahl", value: "56427", alias: "56427" },
  { category: "Postleitzahl", value: "56428", alias: "56428" },
  { category: "Postleitzahl", value: "56457", alias: "56457" },
  { category: "Postleitzahl", value: "56459", alias: "56459" },
  { category: "Postleitzahl", value: "56462", alias: "56462" },
  { category: "Postleitzahl", value: "56470", alias: "56470" },
  { category: "Postleitzahl", value: "56472", alias: "56472" },
  { category: "Postleitzahl", value: "56477", alias: "56477" },
  { category: "Postleitzahl", value: "56479", alias: "56479" },
  { category: "Postleitzahl", value: "56564", alias: "56564" },
  { category: "Postleitzahl", value: "56566", alias: "56566" },
  { category: "Postleitzahl", value: "56567", alias: "56567" },
  { category: "Postleitzahl", value: "56575", alias: "56575" },
  { category: "Postleitzahl", value: "56579", alias: "56579" },
  { category: "Postleitzahl", value: "56581", alias: "56581" },
  { category: "Postleitzahl", value: "56584", alias: "56584" },
  { category: "Postleitzahl", value: "56587", alias: "56587" },
  { category: "Postleitzahl", value: "56588", alias: "56588" },
  { category: "Postleitzahl", value: "56589", alias: "56589" },
  { category: "Postleitzahl", value: "56593", alias: "56593" },
  { category: "Postleitzahl", value: "56594", alias: "56594" },
  { category: "Postleitzahl", value: "56598", alias: "56598" },
  { category: "Postleitzahl", value: "56599", alias: "56599" },
  { category: "Postleitzahl", value: "56626", alias: "56626" },
  { category: "Postleitzahl", value: "56630", alias: "56630" },
  { category: "Postleitzahl", value: "56637", alias: "56637" },
  { category: "Postleitzahl", value: "56642", alias: "56642" },
  { category: "Postleitzahl", value: "56645", alias: "56645" },
  { category: "Postleitzahl", value: "56648", alias: "56648" },
  { category: "Postleitzahl", value: "56651", alias: "56651" },
  { category: "Postleitzahl", value: "56653", alias: "56653" },
  { category: "Postleitzahl", value: "56656", alias: "56656" },
  { category: "Postleitzahl", value: "56659", alias: "56659" },
  { category: "Postleitzahl", value: "56727", alias: "56727" },
  { category: "Postleitzahl", value: "56729", alias: "56729" },
  { category: "Postleitzahl", value: "56736", alias: "56736" },
  { category: "Postleitzahl", value: "56743", alias: "56743" },
  { category: "Postleitzahl", value: "56745", alias: "56745" },
  { category: "Postleitzahl", value: "56746", alias: "56746" },
  { category: "Postleitzahl", value: "56751", alias: "56751" },
  { category: "Postleitzahl", value: "56753", alias: "56753" },
  { category: "Postleitzahl", value: "56754", alias: "56754" },
  { category: "Postleitzahl", value: "56759", alias: "56759" },
  { category: "Postleitzahl", value: "56761", alias: "56761" },
  { category: "Postleitzahl", value: "56766", alias: "56766" },
  { category: "Postleitzahl", value: "56767", alias: "56767" },
  { category: "Postleitzahl", value: "56769", alias: "56769" },
  { category: "Postleitzahl", value: "56812", alias: "56812" },
  { category: "Postleitzahl", value: "56814", alias: "56814" },
  { category: "Postleitzahl", value: "56818", alias: "56818" },
  { category: "Postleitzahl", value: "56820", alias: "56820" },
  { category: "Postleitzahl", value: "56821", alias: "56821" },
  { category: "Postleitzahl", value: "56823", alias: "56823" },
  { category: "Postleitzahl", value: "56825", alias: "56825" },
  { category: "Postleitzahl", value: "56826", alias: "56826" },
  { category: "Postleitzahl", value: "56828", alias: "56828" },
  { category: "Postleitzahl", value: "56829", alias: "56829" },
  { category: "Postleitzahl", value: "56841", alias: "56841" },
  { category: "Postleitzahl", value: "56843", alias: "56843" },
  { category: "Postleitzahl", value: "56850", alias: "56850" },
  { category: "Postleitzahl", value: "56856", alias: "56856" },
  { category: "Postleitzahl", value: "56858", alias: "56858" },
  { category: "Postleitzahl", value: "56859", alias: "56859" },
  { category: "Postleitzahl", value: "56861", alias: "56861" },
  { category: "Postleitzahl", value: "56862", alias: "56862" },
  { category: "Postleitzahl", value: "56864", alias: "56864" },
  { category: "Postleitzahl", value: "56865", alias: "56865" },
  { category: "Postleitzahl", value: "56867", alias: "56867" },
  { category: "Postleitzahl", value: "56869", alias: "56869" },
  { category: "Postleitzahl", value: "57072", alias: "57072" },
  { category: "Postleitzahl", value: "57074", alias: "57074" },
  { category: "Postleitzahl", value: "57076", alias: "57076" },
  { category: "Postleitzahl", value: "57078", alias: "57078" },
  { category: "Postleitzahl", value: "57080", alias: "57080" },
  { category: "Postleitzahl", value: "57223", alias: "57223" },
  { category: "Postleitzahl", value: "57234", alias: "57234" },
  { category: "Postleitzahl", value: "57250", alias: "57250" },
  { category: "Postleitzahl", value: "57258", alias: "57258" },
  { category: "Postleitzahl", value: "57271", alias: "57271" },
  { category: "Postleitzahl", value: "57290", alias: "57290" },
  { category: "Postleitzahl", value: "57299", alias: "57299" },
  { category: "Postleitzahl", value: "57319", alias: "57319" },
  { category: "Postleitzahl", value: "57334", alias: "57334" },
  { category: "Postleitzahl", value: "57339", alias: "57339" },
  { category: "Postleitzahl", value: "57368", alias: "57368" },
  { category: "Postleitzahl", value: "57392", alias: "57392" },
  { category: "Postleitzahl", value: "57399", alias: "57399" },
  { category: "Postleitzahl", value: "57413", alias: "57413" },
  { category: "Postleitzahl", value: "57439", alias: "57439" },
  { category: "Postleitzahl", value: "57462", alias: "57462" },
  { category: "Postleitzahl", value: "57482", alias: "57482" },
  { category: "Postleitzahl", value: "57489", alias: "57489" },
  { category: "Postleitzahl", value: "57518", alias: "57518" },
  { category: "Postleitzahl", value: "57520", alias: "57520" },
  { category: "Postleitzahl", value: "57537", alias: "57537" },
  { category: "Postleitzahl", value: "57539", alias: "57539" },
  { category: "Postleitzahl", value: "57548", alias: "57548" },
  { category: "Postleitzahl", value: "57555", alias: "57555" },
  { category: "Postleitzahl", value: "57562", alias: "57562" },
  { category: "Postleitzahl", value: "57567", alias: "57567" },
  { category: "Postleitzahl", value: "57572", alias: "57572" },
  { category: "Postleitzahl", value: "57577", alias: "57577" },
  { category: "Postleitzahl", value: "57578", alias: "57578" },
  { category: "Postleitzahl", value: "57580", alias: "57580" },
  { category: "Postleitzahl", value: "57581", alias: "57581" },
  { category: "Postleitzahl", value: "57583", alias: "57583" },
  { category: "Postleitzahl", value: "57584", alias: "57584" },
  { category: "Postleitzahl", value: "57586", alias: "57586" },
  { category: "Postleitzahl", value: "57587", alias: "57587" },
  { category: "Postleitzahl", value: "57589", alias: "57589" },
  { category: "Postleitzahl", value: "57610", alias: "57610" },
  { category: "Postleitzahl", value: "57612", alias: "57612" },
  { category: "Postleitzahl", value: "57614", alias: "57614" },
  { category: "Postleitzahl", value: "57627", alias: "57627" },
  { category: "Postleitzahl", value: "57629", alias: "57629" },
  { category: "Postleitzahl", value: "57632", alias: "57632" },
  { category: "Postleitzahl", value: "57635", alias: "57635" },
  { category: "Postleitzahl", value: "57636", alias: "57636" },
  { category: "Postleitzahl", value: "57638", alias: "57638" },
  { category: "Postleitzahl", value: "57639", alias: "57639" },
  { category: "Postleitzahl", value: "57641", alias: "57641" },
  { category: "Postleitzahl", value: "57642", alias: "57642" },
  { category: "Postleitzahl", value: "57644", alias: "57644" },
  { category: "Postleitzahl", value: "57645", alias: "57645" },
  { category: "Postleitzahl", value: "57647", alias: "57647" },
  { category: "Postleitzahl", value: "57648", alias: "57648" },
  { category: "Postleitzahl", value: "58089", alias: "58089" },
  { category: "Postleitzahl", value: "58091", alias: "58091" },
  { category: "Postleitzahl", value: "58093", alias: "58093" },
  { category: "Postleitzahl", value: "58095", alias: "58095" },
  { category: "Postleitzahl", value: "58097", alias: "58097" },
  { category: "Postleitzahl", value: "58099", alias: "58099" },
  { category: "Postleitzahl", value: "58119", alias: "58119" },
  { category: "Postleitzahl", value: "58135", alias: "58135" },
  { category: "Postleitzahl", value: "58239", alias: "58239" },
  { category: "Postleitzahl", value: "58256", alias: "58256" },
  { category: "Postleitzahl", value: "58285", alias: "58285" },
  { category: "Postleitzahl", value: "58300", alias: "58300" },
  { category: "Postleitzahl", value: "58313", alias: "58313" },
  { category: "Postleitzahl", value: "58332", alias: "58332" },
  { category: "Postleitzahl", value: "58339", alias: "58339" },
  { category: "Postleitzahl", value: "58452", alias: "58452" },
  { category: "Postleitzahl", value: "58453", alias: "58453" },
  { category: "Postleitzahl", value: "58454", alias: "58454" },
  { category: "Postleitzahl", value: "58455", alias: "58455" },
  { category: "Postleitzahl", value: "58456", alias: "58456" },
  { category: "Postleitzahl", value: "58507", alias: "58507" },
  { category: "Postleitzahl", value: "58509", alias: "58509" },
  { category: "Postleitzahl", value: "58511", alias: "58511" },
  { category: "Postleitzahl", value: "58513", alias: "58513" },
  { category: "Postleitzahl", value: "58515", alias: "58515" },
  { category: "Postleitzahl", value: "58540", alias: "58540" },
  { category: "Postleitzahl", value: "58553", alias: "58553" },
  { category: "Postleitzahl", value: "58566", alias: "58566" },
  { category: "Postleitzahl", value: "58579", alias: "58579" },
  { category: "Postleitzahl", value: "58636", alias: "58636" },
  { category: "Postleitzahl", value: "58638", alias: "58638" },
  { category: "Postleitzahl", value: "58640", alias: "58640" },
  { category: "Postleitzahl", value: "58642", alias: "58642" },
  { category: "Postleitzahl", value: "58644", alias: "58644" },
  { category: "Postleitzahl", value: "58675", alias: "58675" },
  { category: "Postleitzahl", value: "58706", alias: "58706" },
  { category: "Postleitzahl", value: "58708", alias: "58708" },
  { category: "Postleitzahl", value: "58710", alias: "58710" },
  { category: "Postleitzahl", value: "58730", alias: "58730" },
  { category: "Postleitzahl", value: "58739", alias: "58739" },
  { category: "Postleitzahl", value: "58762", alias: "58762" },
  { category: "Postleitzahl", value: "58769", alias: "58769" },
  { category: "Postleitzahl", value: "58791", alias: "58791" },
  { category: "Postleitzahl", value: "58802", alias: "58802" },
  { category: "Postleitzahl", value: "58809", alias: "58809" },
  { category: "Postleitzahl", value: "58840", alias: "58840" },
  { category: "Postleitzahl", value: "58849", alias: "58849" },
  { category: "Postleitzahl", value: "59063", alias: "59063" },
  { category: "Postleitzahl", value: "59065", alias: "59065" },
  { category: "Postleitzahl", value: "59067", alias: "59067" },
  { category: "Postleitzahl", value: "59069", alias: "59069" },
  { category: "Postleitzahl", value: "59071", alias: "59071" },
  { category: "Postleitzahl", value: "59073", alias: "59073" },
  { category: "Postleitzahl", value: "59075", alias: "59075" },
  { category: "Postleitzahl", value: "59077", alias: "59077" },
  { category: "Postleitzahl", value: "59174", alias: "59174" },
  { category: "Postleitzahl", value: "59192", alias: "59192" },
  { category: "Postleitzahl", value: "59199", alias: "59199" },
  { category: "Postleitzahl", value: "59227", alias: "59227" },
  { category: "Postleitzahl", value: "59229", alias: "59229" },
  { category: "Postleitzahl", value: "59269", alias: "59269" },
  { category: "Postleitzahl", value: "59302", alias: "59302" },
  { category: "Postleitzahl", value: "59320", alias: "59320" },
  { category: "Postleitzahl", value: "59329", alias: "59329" },
  { category: "Postleitzahl", value: "59348", alias: "59348" },
  { category: "Postleitzahl", value: "59368", alias: "59368" },
  { category: "Postleitzahl", value: "59379", alias: "59379" },
  { category: "Postleitzahl", value: "59387", alias: "59387" },
  { category: "Postleitzahl", value: "59394", alias: "59394" },
  { category: "Postleitzahl", value: "59399", alias: "59399" },
  { category: "Postleitzahl", value: "59423", alias: "59423" },
  { category: "Postleitzahl", value: "59425", alias: "59425" },
  { category: "Postleitzahl", value: "59427", alias: "59427" },
  { category: "Postleitzahl", value: "59439", alias: "59439" },
  { category: "Postleitzahl", value: "59457", alias: "59457" },
  { category: "Postleitzahl", value: "59469", alias: "59469" },
  { category: "Postleitzahl", value: "59494", alias: "59494" },
  { category: "Postleitzahl", value: "59505", alias: "59505" },
  { category: "Postleitzahl", value: "59510", alias: "59510" },
  { category: "Postleitzahl", value: "59514", alias: "59514" },
  { category: "Postleitzahl", value: "59519", alias: "59519" },
  { category: "Postleitzahl", value: "59555", alias: "59555" },
  { category: "Postleitzahl", value: "59556", alias: "59556" },
  { category: "Postleitzahl", value: "59557", alias: "59557" },
  { category: "Postleitzahl", value: "59558", alias: "59558" },
  { category: "Postleitzahl", value: "59581", alias: "59581" },
  { category: "Postleitzahl", value: "59590", alias: "59590" },
  { category: "Postleitzahl", value: "59597", alias: "59597" },
  { category: "Postleitzahl", value: "59602", alias: "59602" },
  { category: "Postleitzahl", value: "59609", alias: "59609" },
  { category: "Postleitzahl", value: "59755", alias: "59755" },
  { category: "Postleitzahl", value: "59757", alias: "59757" },
  { category: "Postleitzahl", value: "59759", alias: "59759" },
  { category: "Postleitzahl", value: "59821", alias: "59821" },
  { category: "Postleitzahl", value: "59823", alias: "59823" },
  { category: "Postleitzahl", value: "59846", alias: "59846" },
  { category: "Postleitzahl", value: "59872", alias: "59872" },
  { category: "Postleitzahl", value: "59889", alias: "59889" },
  { category: "Postleitzahl", value: "59909", alias: "59909" },
  { category: "Postleitzahl", value: "59929", alias: "59929" },
  { category: "Postleitzahl", value: "59939", alias: "59939" },
  { category: "Postleitzahl", value: "59955", alias: "59955" },
  { category: "Postleitzahl", value: "59964", alias: "59964" },
  { category: "Postleitzahl", value: "59969", alias: "59969" },
  { category: "Postleitzahl", value: "60306", alias: "60306" },
  { category: "Postleitzahl", value: "60308", alias: "60308" },
  { category: "Postleitzahl", value: "60310", alias: "60310" },
  { category: "Postleitzahl", value: "60311", alias: "60311" },
  { category: "Postleitzahl", value: "60312", alias: "60312" },
  { category: "Postleitzahl", value: "60313", alias: "60313" },
  { category: "Postleitzahl", value: "60314", alias: "60314" },
  { category: "Postleitzahl", value: "60315", alias: "60315" },
  { category: "Postleitzahl", value: "60316", alias: "60316" },
  { category: "Postleitzahl", value: "60318", alias: "60318" },
  { category: "Postleitzahl", value: "60320", alias: "60320" },
  { category: "Postleitzahl", value: "60322", alias: "60322" },
  { category: "Postleitzahl", value: "60323", alias: "60323" },
  { category: "Postleitzahl", value: "60325", alias: "60325" },
  { category: "Postleitzahl", value: "60326", alias: "60326" },
  { category: "Postleitzahl", value: "60327", alias: "60327" },
  { category: "Postleitzahl", value: "60329", alias: "60329" },
  { category: "Postleitzahl", value: "60385", alias: "60385" },
  { category: "Postleitzahl", value: "60386", alias: "60386" },
  { category: "Postleitzahl", value: "60388", alias: "60388" },
  { category: "Postleitzahl", value: "60389", alias: "60389" },
  { category: "Postleitzahl", value: "60431", alias: "60431" },
  { category: "Postleitzahl", value: "60433", alias: "60433" },
  { category: "Postleitzahl", value: "60435", alias: "60435" },
  { category: "Postleitzahl", value: "60437", alias: "60437" },
  { category: "Postleitzahl", value: "60438", alias: "60438" },
  { category: "Postleitzahl", value: "60439", alias: "60439" },
  { category: "Postleitzahl", value: "60486", alias: "60486" },
  { category: "Postleitzahl", value: "60487", alias: "60487" },
  { category: "Postleitzahl", value: "60488", alias: "60488" },
  { category: "Postleitzahl", value: "60489", alias: "60489" },
  { category: "Postleitzahl", value: "60528", alias: "60528" },
  { category: "Postleitzahl", value: "60529", alias: "60529" },
  { category: "Postleitzahl", value: "60549", alias: "60549" },
  { category: "Postleitzahl", value: "60594", alias: "60594" },
  { category: "Postleitzahl", value: "60596", alias: "60596" },
  { category: "Postleitzahl", value: "60598", alias: "60598" },
  { category: "Postleitzahl", value: "60599", alias: "60599" },
  { category: "Postleitzahl", value: "61118", alias: "61118" },
  { category: "Postleitzahl", value: "61130", alias: "61130" },
  { category: "Postleitzahl", value: "61137", alias: "61137" },
  { category: "Postleitzahl", value: "61138", alias: "61138" },
  { category: "Postleitzahl", value: "61169", alias: "61169" },
  { category: "Postleitzahl", value: "61184", alias: "61184" },
  { category: "Postleitzahl", value: "61191", alias: "61191" },
  { category: "Postleitzahl", value: "61194", alias: "61194" },
  { category: "Postleitzahl", value: "61197", alias: "61197" },
  { category: "Postleitzahl", value: "61200", alias: "61200" },
  { category: "Postleitzahl", value: "61203", alias: "61203" },
  { category: "Postleitzahl", value: "61206", alias: "61206" },
  { category: "Postleitzahl", value: "61209", alias: "61209" },
  { category: "Postleitzahl", value: "61231", alias: "61231" },
  { category: "Postleitzahl", value: "61239", alias: "61239" },
  { category: "Postleitzahl", value: "61250", alias: "61250" },
  { category: "Postleitzahl", value: "61267", alias: "61267" },
  { category: "Postleitzahl", value: "61273", alias: "61273" },
  { category: "Postleitzahl", value: "61276", alias: "61276" },
  { category: "Postleitzahl", value: "61279", alias: "61279" },
  { category: "Postleitzahl", value: "61348", alias: "61348" },
  { category: "Postleitzahl", value: "61350", alias: "61350" },
  { category: "Postleitzahl", value: "61352", alias: "61352" },
  { category: "Postleitzahl", value: "61381", alias: "61381" },
  { category: "Postleitzahl", value: "61389", alias: "61389" },
  { category: "Postleitzahl", value: "61440", alias: "61440" },
  { category: "Postleitzahl", value: "61449", alias: "61449" },
  { category: "Postleitzahl", value: "61462", alias: "61462" },
  { category: "Postleitzahl", value: "61476", alias: "61476" },
  { category: "Postleitzahl", value: "61479", alias: "61479" },
  { category: "Postleitzahl", value: "63065", alias: "63065" },
  { category: "Postleitzahl", value: "63067", alias: "63067" },
  { category: "Postleitzahl", value: "63069", alias: "63069" },
  { category: "Postleitzahl", value: "63071", alias: "63071" },
  { category: "Postleitzahl", value: "63073", alias: "63073" },
  { category: "Postleitzahl", value: "63075", alias: "63075" },
  { category: "Postleitzahl", value: "63110", alias: "63110" },
  { category: "Postleitzahl", value: "63128", alias: "63128" },
  { category: "Postleitzahl", value: "63150", alias: "63150" },
  { category: "Postleitzahl", value: "63165", alias: "63165" },
  { category: "Postleitzahl", value: "63179", alias: "63179" },
  { category: "Postleitzahl", value: "63225", alias: "63225" },
  { category: "Postleitzahl", value: "63263", alias: "63263" },
  { category: "Postleitzahl", value: "63303", alias: "63303" },
  { category: "Postleitzahl", value: "63322", alias: "63322" },
  { category: "Postleitzahl", value: "63329", alias: "63329" },
  { category: "Postleitzahl", value: "63450", alias: "63450" },
  { category: "Postleitzahl", value: "63452", alias: "63452" },
  { category: "Postleitzahl", value: "63454", alias: "63454" },
  { category: "Postleitzahl", value: "63456", alias: "63456" },
  { category: "Postleitzahl", value: "63457", alias: "63457" },
  { category: "Postleitzahl", value: "63477", alias: "63477" },
  { category: "Postleitzahl", value: "63486", alias: "63486" },
  { category: "Postleitzahl", value: "63500", alias: "63500" },
  { category: "Postleitzahl", value: "63505", alias: "63505" },
  { category: "Postleitzahl", value: "63512", alias: "63512" },
  { category: "Postleitzahl", value: "63517", alias: "63517" },
  { category: "Postleitzahl", value: "63526", alias: "63526" },
  { category: "Postleitzahl", value: "63533", alias: "63533" },
  { category: "Postleitzahl", value: "63538", alias: "63538" },
  { category: "Postleitzahl", value: "63543", alias: "63543" },
  { category: "Postleitzahl", value: "63546", alias: "63546" },
  { category: "Postleitzahl", value: "63549", alias: "63549" },
  { category: "Postleitzahl", value: "63571", alias: "63571" },
  { category: "Postleitzahl", value: "63579", alias: "63579" },
  { category: "Postleitzahl", value: "63584", alias: "63584" },
  { category: "Postleitzahl", value: "63589", alias: "63589" },
  { category: "Postleitzahl", value: "63594", alias: "63594" },
  { category: "Postleitzahl", value: "63599", alias: "63599" },
  { category: "Postleitzahl", value: "63607", alias: "63607" },
  { category: "Postleitzahl", value: "63619", alias: "63619" },
  { category: "Postleitzahl", value: "63628", alias: "63628" },
  { category: "Postleitzahl", value: "63633", alias: "63633" },
  { category: "Postleitzahl", value: "63636", alias: "63636" },
  { category: "Postleitzahl", value: "63637", alias: "63637" },
  { category: "Postleitzahl", value: "63639", alias: "63639" },
  { category: "Postleitzahl", value: "63654", alias: "63654" },
  { category: "Postleitzahl", value: "63667", alias: "63667" },
  { category: "Postleitzahl", value: "63674", alias: "63674" },
  { category: "Postleitzahl", value: "63679", alias: "63679" },
  { category: "Postleitzahl", value: "63683", alias: "63683" },
  { category: "Postleitzahl", value: "63688", alias: "63688" },
  { category: "Postleitzahl", value: "63691", alias: "63691" },
  { category: "Postleitzahl", value: "63694", alias: "63694" },
  { category: "Postleitzahl", value: "63695", alias: "63695" },
  { category: "Postleitzahl", value: "63697", alias: "63697" },
  { category: "Postleitzahl", value: "63699", alias: "63699" },
  { category: "Postleitzahl", value: "63739", alias: "63739" },
  { category: "Postleitzahl", value: "63741", alias: "63741" },
  { category: "Postleitzahl", value: "63743", alias: "63743" },
  { category: "Postleitzahl", value: "63755", alias: "63755" },
  { category: "Postleitzahl", value: "63762", alias: "63762" },
  { category: "Postleitzahl", value: "63768", alias: "63768" },
  { category: "Postleitzahl", value: "63773", alias: "63773" },
  { category: "Postleitzahl", value: "63776", alias: "63776" },
  { category: "Postleitzahl", value: "63785", alias: "63785" },
  { category: "Postleitzahl", value: "63791", alias: "63791" },
  { category: "Postleitzahl", value: "63796", alias: "63796" },
  { category: "Postleitzahl", value: "63801", alias: "63801" },
  { category: "Postleitzahl", value: "63808", alias: "63808" },
  { category: "Postleitzahl", value: "63811", alias: "63811" },
  { category: "Postleitzahl", value: "63814", alias: "63814" },
  { category: "Postleitzahl", value: "63820", alias: "63820" },
  { category: "Postleitzahl", value: "63825", alias: "63825" },
  { category: "Postleitzahl", value: "63826", alias: "63826" },
  { category: "Postleitzahl", value: "63828", alias: "63828" },
  { category: "Postleitzahl", value: "63829", alias: "63829" },
  { category: "Postleitzahl", value: "63831", alias: "63831" },
  { category: "Postleitzahl", value: "63834", alias: "63834" },
  { category: "Postleitzahl", value: "63839", alias: "63839" },
  { category: "Postleitzahl", value: "63840", alias: "63840" },
  { category: "Postleitzahl", value: "63843", alias: "63843" },
  { category: "Postleitzahl", value: "63846", alias: "63846" },
  { category: "Postleitzahl", value: "63849", alias: "63849" },
  { category: "Postleitzahl", value: "63853", alias: "63853" },
  { category: "Postleitzahl", value: "63856", alias: "63856" },
  { category: "Postleitzahl", value: "63857", alias: "63857" },
  { category: "Postleitzahl", value: "63860", alias: "63860" },
  { category: "Postleitzahl", value: "63863", alias: "63863" },
  { category: "Postleitzahl", value: "63864", alias: "63864" },
  { category: "Postleitzahl", value: "63867", alias: "63867" },
  { category: "Postleitzahl", value: "63868", alias: "63868" },
  { category: "Postleitzahl", value: "63869", alias: "63869" },
  { category: "Postleitzahl", value: "63871", alias: "63871" },
  { category: "Postleitzahl", value: "63872", alias: "63872" },
  { category: "Postleitzahl", value: "63874", alias: "63874" },
  { category: "Postleitzahl", value: "63875", alias: "63875" },
  { category: "Postleitzahl", value: "63877", alias: "63877" },
  { category: "Postleitzahl", value: "63879", alias: "63879" },
  { category: "Postleitzahl", value: "63897", alias: "63897" },
  { category: "Postleitzahl", value: "63906", alias: "63906" },
  { category: "Postleitzahl", value: "63911", alias: "63911" },
  { category: "Postleitzahl", value: "63916", alias: "63916" },
  { category: "Postleitzahl", value: "63920", alias: "63920" },
  { category: "Postleitzahl", value: "63924", alias: "63924" },
  { category: "Postleitzahl", value: "63925", alias: "63925" },
  { category: "Postleitzahl", value: "63927", alias: "63927" },
  { category: "Postleitzahl", value: "63928", alias: "63928" },
  { category: "Postleitzahl", value: "63930", alias: "63930" },
  { category: "Postleitzahl", value: "63931", alias: "63931" },
  { category: "Postleitzahl", value: "63933", alias: "63933" },
  { category: "Postleitzahl", value: "63934", alias: "63934" },
  { category: "Postleitzahl", value: "63936", alias: "63936" },
  { category: "Postleitzahl", value: "63937", alias: "63937" },
  { category: "Postleitzahl", value: "63939", alias: "63939" },
  { category: "Postleitzahl", value: "64283", alias: "64283" },
  { category: "Postleitzahl", value: "64285", alias: "64285" },
  { category: "Postleitzahl", value: "64287", alias: "64287" },
  { category: "Postleitzahl", value: "64289", alias: "64289" },
  { category: "Postleitzahl", value: "64291", alias: "64291" },
  { category: "Postleitzahl", value: "64293", alias: "64293" },
  { category: "Postleitzahl", value: "64295", alias: "64295" },
  { category: "Postleitzahl", value: "64297", alias: "64297" },
  { category: "Postleitzahl", value: "64319", alias: "64319" },
  { category: "Postleitzahl", value: "64331", alias: "64331" },
  { category: "Postleitzahl", value: "64342", alias: "64342" },
  { category: "Postleitzahl", value: "64347", alias: "64347" },
  { category: "Postleitzahl", value: "64354", alias: "64354" },
  { category: "Postleitzahl", value: "64367", alias: "64367" },
  { category: "Postleitzahl", value: "64372", alias: "64372" },
  { category: "Postleitzahl", value: "64380", alias: "64380" },
  { category: "Postleitzahl", value: "64385", alias: "64385" },
  { category: "Postleitzahl", value: "64390", alias: "64390" },
  { category: "Postleitzahl", value: "64395", alias: "64395" },
  { category: "Postleitzahl", value: "64397", alias: "64397" },
  { category: "Postleitzahl", value: "64401", alias: "64401" },
  { category: "Postleitzahl", value: "64404", alias: "64404" },
  { category: "Postleitzahl", value: "64405", alias: "64405" },
  { category: "Postleitzahl", value: "64407", alias: "64407" },
  { category: "Postleitzahl", value: "64409", alias: "64409" },
  { category: "Postleitzahl", value: "64521", alias: "64521" },
  { category: "Postleitzahl", value: "64546", alias: "64546" },
  { category: "Postleitzahl", value: "64560", alias: "64560" },
  { category: "Postleitzahl", value: "64569", alias: "64569" },
  { category: "Postleitzahl", value: "64572", alias: "64572" },
  { category: "Postleitzahl", value: "64579", alias: "64579" },
  { category: "Postleitzahl", value: "64584", alias: "64584" },
  { category: "Postleitzahl", value: "64589", alias: "64589" },
  { category: "Postleitzahl", value: "64625", alias: "64625" },
  { category: "Postleitzahl", value: "64646", alias: "64646" },
  { category: "Postleitzahl", value: "64653", alias: "64653" },
  { category: "Postleitzahl", value: "64658", alias: "64658" },
  { category: "Postleitzahl", value: "64665", alias: "64665" },
  { category: "Postleitzahl", value: "64668", alias: "64668" },
  { category: "Postleitzahl", value: "64673", alias: "64673" },
  { category: "Postleitzahl", value: "64678", alias: "64678" },
  { category: "Postleitzahl", value: "64683", alias: "64683" },
  { category: "Postleitzahl", value: "64686", alias: "64686" },
  { category: "Postleitzahl", value: "64689", alias: "64689" },
  { category: "Postleitzahl", value: "64711", alias: "64711" },
  { category: "Postleitzahl", value: "64720", alias: "64720" },
  { category: "Postleitzahl", value: "64732", alias: "64732" },
  { category: "Postleitzahl", value: "64739", alias: "64739" },
  { category: "Postleitzahl", value: "64743", alias: "64743" },
  { category: "Postleitzahl", value: "64747", alias: "64747" },
  { category: "Postleitzahl", value: "64750", alias: "64750" },
  { category: "Postleitzahl", value: "64753", alias: "64753" },
  { category: "Postleitzahl", value: "64754", alias: "64754" },
  { category: "Postleitzahl", value: "64756", alias: "64756" },
  { category: "Postleitzahl", value: "64757", alias: "64757" },
  { category: "Postleitzahl", value: "64760", alias: "64760" },
  { category: "Postleitzahl", value: "64807", alias: "64807" },
  { category: "Postleitzahl", value: "64823", alias: "64823" },
  { category: "Postleitzahl", value: "64832", alias: "64832" },
  { category: "Postleitzahl", value: "64839", alias: "64839" },
  { category: "Postleitzahl", value: "64846", alias: "64846" },
  { category: "Postleitzahl", value: "64850", alias: "64850" },
  { category: "Postleitzahl", value: "64853", alias: "64853" },
  { category: "Postleitzahl", value: "64859", alias: "64859" },
  { category: "Postleitzahl", value: "65183", alias: "65183" },
  { category: "Postleitzahl", value: "65185", alias: "65185" },
  { category: "Postleitzahl", value: "65187", alias: "65187" },
  { category: "Postleitzahl", value: "65189", alias: "65189" },
  { category: "Postleitzahl", value: "65191", alias: "65191" },
  { category: "Postleitzahl", value: "65193", alias: "65193" },
  { category: "Postleitzahl", value: "65195", alias: "65195" },
  { category: "Postleitzahl", value: "65197", alias: "65197" },
  { category: "Postleitzahl", value: "65199", alias: "65199" },
  { category: "Postleitzahl", value: "65201", alias: "65201" },
  { category: "Postleitzahl", value: "65203", alias: "65203" },
  { category: "Postleitzahl", value: "65205", alias: "65205" },
  { category: "Postleitzahl", value: "65207", alias: "65207" },
  { category: "Postleitzahl", value: "65232", alias: "65232" },
  { category: "Postleitzahl", value: "65239", alias: "65239" },
  { category: "Postleitzahl", value: "65307", alias: "65307" },
  { category: "Postleitzahl", value: "65321", alias: "65321" },
  { category: "Postleitzahl", value: "65326", alias: "65326" },
  { category: "Postleitzahl", value: "65329", alias: "65329" },
  { category: "Postleitzahl", value: "65343", alias: "65343" },
  { category: "Postleitzahl", value: "65344", alias: "65344" },
  { category: "Postleitzahl", value: "65345", alias: "65345" },
  { category: "Postleitzahl", value: "65346", alias: "65346" },
  { category: "Postleitzahl", value: "65347", alias: "65347" },
  { category: "Postleitzahl", value: "65366", alias: "65366" },
  { category: "Postleitzahl", value: "65375", alias: "65375" },
  { category: "Postleitzahl", value: "65385", alias: "65385" },
  { category: "Postleitzahl", value: "65388", alias: "65388" },
  { category: "Postleitzahl", value: "65391", alias: "65391" },
  { category: "Postleitzahl", value: "65396", alias: "65396" },
  { category: "Postleitzahl", value: "65399", alias: "65399" },
  { category: "Postleitzahl", value: "65428", alias: "65428" },
  { category: "Postleitzahl", value: "65439", alias: "65439" },
  { category: "Postleitzahl", value: "65451", alias: "65451" },
  { category: "Postleitzahl", value: "65462", alias: "65462" },
  { category: "Postleitzahl", value: "65468", alias: "65468" },
  { category: "Postleitzahl", value: "65474", alias: "65474" },
  { category: "Postleitzahl", value: "65479", alias: "65479" },
  { category: "Postleitzahl", value: "65510", alias: "65510" },
  { category: "Postleitzahl", value: "65520", alias: "65520" },
  { category: "Postleitzahl", value: "65527", alias: "65527" },
  { category: "Postleitzahl", value: "65529", alias: "65529" },
  { category: "Postleitzahl", value: "65549", alias: "65549" },
  { category: "Postleitzahl", value: "65550", alias: "65550" },
  { category: "Postleitzahl", value: "65551", alias: "65551" },
  { category: "Postleitzahl", value: "65552", alias: "65552" },
  { category: "Postleitzahl", value: "65553", alias: "65553" },
  { category: "Postleitzahl", value: "65554", alias: "65554" },
  { category: "Postleitzahl", value: "65555", alias: "65555" },
  { category: "Postleitzahl", value: "65556", alias: "65556" },
  { category: "Postleitzahl", value: "65558", alias: "65558" },
  { category: "Postleitzahl", value: "65582", alias: "65582" },
  { category: "Postleitzahl", value: "65589", alias: "65589" },
  { category: "Postleitzahl", value: "65594", alias: "65594" },
  { category: "Postleitzahl", value: "65597", alias: "65597" },
  { category: "Postleitzahl", value: "65599", alias: "65599" },
  { category: "Postleitzahl", value: "65604", alias: "65604" },
  { category: "Postleitzahl", value: "65606", alias: "65606" },
  { category: "Postleitzahl", value: "65611", alias: "65611" },
  { category: "Postleitzahl", value: "65614", alias: "65614" },
  { category: "Postleitzahl", value: "65618", alias: "65618" },
  { category: "Postleitzahl", value: "65620", alias: "65620" },
  { category: "Postleitzahl", value: "65623", alias: "65623" },
  { category: "Postleitzahl", value: "65624", alias: "65624" },
  { category: "Postleitzahl", value: "65626", alias: "65626" },
  { category: "Postleitzahl", value: "65627", alias: "65627" },
  { category: "Postleitzahl", value: "65629", alias: "65629" },
  { category: "Postleitzahl", value: "65719", alias: "65719" },
  { category: "Postleitzahl", value: "65760", alias: "65760" },
  { category: "Postleitzahl", value: "65779", alias: "65779" },
  { category: "Postleitzahl", value: "65795", alias: "65795" },
  { category: "Postleitzahl", value: "65812", alias: "65812" },
  { category: "Postleitzahl", value: "65817", alias: "65817" },
  { category: "Postleitzahl", value: "65824", alias: "65824" },
  { category: "Postleitzahl", value: "65830", alias: "65830" },
  { category: "Postleitzahl", value: "65835", alias: "65835" },
  { category: "Postleitzahl", value: "65843", alias: "65843" },
  { category: "Postleitzahl", value: "65929", alias: "65929" },
  { category: "Postleitzahl", value: "65931", alias: "65931" },
  { category: "Postleitzahl", value: "65933", alias: "65933" },
  { category: "Postleitzahl", value: "65934", alias: "65934" },
  { category: "Postleitzahl", value: "65936", alias: "65936" },
  { category: "Postleitzahl", value: "66111", alias: "66111" },
  { category: "Postleitzahl", value: "66113", alias: "66113" },
  { category: "Postleitzahl", value: "66115", alias: "66115" },
  { category: "Postleitzahl", value: "66117", alias: "66117" },
  { category: "Postleitzahl", value: "66119", alias: "66119" },
  { category: "Postleitzahl", value: "66121", alias: "66121" },
  { category: "Postleitzahl", value: "66123", alias: "66123" },
  { category: "Postleitzahl", value: "66125", alias: "66125" },
  { category: "Postleitzahl", value: "66126", alias: "66126" },
  { category: "Postleitzahl", value: "66127", alias: "66127" },
  { category: "Postleitzahl", value: "66128", alias: "66128" },
  { category: "Postleitzahl", value: "66129", alias: "66129" },
  { category: "Postleitzahl", value: "66130", alias: "66130" },
  { category: "Postleitzahl", value: "66131", alias: "66131" },
  { category: "Postleitzahl", value: "66132", alias: "66132" },
  { category: "Postleitzahl", value: "66133", alias: "66133" },
  { category: "Postleitzahl", value: "66265", alias: "66265" },
  { category: "Postleitzahl", value: "66271", alias: "66271" },
  { category: "Postleitzahl", value: "66280", alias: "66280" },
  { category: "Postleitzahl", value: "66287", alias: "66287" },
  { category: "Postleitzahl", value: "66292", alias: "66292" },
  { category: "Postleitzahl", value: "66299", alias: "66299" },
  { category: "Postleitzahl", value: "66333", alias: "66333" },
  { category: "Postleitzahl", value: "66346", alias: "66346" },
  { category: "Postleitzahl", value: "66352", alias: "66352" },
  { category: "Postleitzahl", value: "66359", alias: "66359" },
  { category: "Postleitzahl", value: "66386", alias: "66386" },
  { category: "Postleitzahl", value: "66399", alias: "66399" },
  { category: "Postleitzahl", value: "66424", alias: "66424" },
  { category: "Postleitzahl", value: "66440", alias: "66440" },
  { category: "Postleitzahl", value: "66450", alias: "66450" },
  { category: "Postleitzahl", value: "66453", alias: "66453" },
  { category: "Postleitzahl", value: "66459", alias: "66459" },
  { category: "Postleitzahl", value: "66482", alias: "66482" },
  { category: "Postleitzahl", value: "66484", alias: "66484" },
  { category: "Postleitzahl", value: "66497", alias: "66497" },
  { category: "Postleitzahl", value: "66500", alias: "66500" },
  { category: "Postleitzahl", value: "66501", alias: "66501" },
  { category: "Postleitzahl", value: "66503", alias: "66503" },
  { category: "Postleitzahl", value: "66504", alias: "66504" },
  { category: "Postleitzahl", value: "66506", alias: "66506" },
  { category: "Postleitzahl", value: "66507", alias: "66507" },
  { category: "Postleitzahl", value: "66509", alias: "66509" },
  { category: "Postleitzahl", value: "66538", alias: "66538" },
  { category: "Postleitzahl", value: "66539", alias: "66539" },
  { category: "Postleitzahl", value: "66540", alias: "66540" },
  { category: "Postleitzahl", value: "66557", alias: "66557" },
  { category: "Postleitzahl", value: "66564", alias: "66564" },
  { category: "Postleitzahl", value: "66571", alias: "66571" },
  { category: "Postleitzahl", value: "66578", alias: "66578" },
  { category: "Postleitzahl", value: "66583", alias: "66583" },
  { category: "Postleitzahl", value: "66589", alias: "66589" },
  { category: "Postleitzahl", value: "66606", alias: "66606" },
  { category: "Postleitzahl", value: "66620", alias: "66620" },
  { category: "Postleitzahl", value: "66625", alias: "66625" },
  { category: "Postleitzahl", value: "66629", alias: "66629" },
  { category: "Postleitzahl", value: "66636", alias: "66636" },
  { category: "Postleitzahl", value: "66640", alias: "66640" },
  { category: "Postleitzahl", value: "66646", alias: "66646" },
  { category: "Postleitzahl", value: "66649", alias: "66649" },
  { category: "Postleitzahl", value: "66663", alias: "66663" },
  { category: "Postleitzahl", value: "66679", alias: "66679" },
  { category: "Postleitzahl", value: "66687", alias: "66687" },
  { category: "Postleitzahl", value: "66693", alias: "66693" },
  { category: "Postleitzahl", value: "66701", alias: "66701" },
  { category: "Postleitzahl", value: "66706", alias: "66706" },
  { category: "Postleitzahl", value: "66709", alias: "66709" },
  { category: "Postleitzahl", value: "66740", alias: "66740" },
  { category: "Postleitzahl", value: "66763", alias: "66763" },
  { category: "Postleitzahl", value: "66773", alias: "66773" },
  { category: "Postleitzahl", value: "66780", alias: "66780" },
  { category: "Postleitzahl", value: "66787", alias: "66787" },
  { category: "Postleitzahl", value: "66793", alias: "66793" },
  { category: "Postleitzahl", value: "66798", alias: "66798" },
  { category: "Postleitzahl", value: "66802", alias: "66802" },
  { category: "Postleitzahl", value: "66806", alias: "66806" },
  { category: "Postleitzahl", value: "66809", alias: "66809" },
  { category: "Postleitzahl", value: "66822", alias: "66822" },
  { category: "Postleitzahl", value: "66839", alias: "66839" },
  { category: "Postleitzahl", value: "66849", alias: "66849" },
  { category: "Postleitzahl", value: "66851", alias: "66851" },
  { category: "Postleitzahl", value: "66862", alias: "66862" },
  { category: "Postleitzahl", value: "66869", alias: "66869" },
  { category: "Postleitzahl", value: "66871", alias: "66871" },
  { category: "Postleitzahl", value: "66877", alias: "66877" },
  { category: "Postleitzahl", value: "66879", alias: "66879" },
  { category: "Postleitzahl", value: "66882", alias: "66882" },
  { category: "Postleitzahl", value: "66885", alias: "66885" },
  { category: "Postleitzahl", value: "66887", alias: "66887" },
  { category: "Postleitzahl", value: "66892", alias: "66892" },
  { category: "Postleitzahl", value: "66894", alias: "66894" },
  { category: "Postleitzahl", value: "66901", alias: "66901" },
  { category: "Postleitzahl", value: "66903", alias: "66903" },
  { category: "Postleitzahl", value: "66904", alias: "66904" },
  { category: "Postleitzahl", value: "66907", alias: "66907" },
  { category: "Postleitzahl", value: "66909", alias: "66909" },
  { category: "Postleitzahl", value: "66914", alias: "66914" },
  { category: "Postleitzahl", value: "66916", alias: "66916" },
  { category: "Postleitzahl", value: "66917", alias: "66917" },
  { category: "Postleitzahl", value: "66919", alias: "66919" },
  { category: "Postleitzahl", value: "66953", alias: "66953" },
  { category: "Postleitzahl", value: "66954", alias: "66954" },
  { category: "Postleitzahl", value: "66955", alias: "66955" },
  { category: "Postleitzahl", value: "66957", alias: "66957" },
  { category: "Postleitzahl", value: "66969", alias: "66969" },
  { category: "Postleitzahl", value: "66976", alias: "66976" },
  { category: "Postleitzahl", value: "66978", alias: "66978" },
  { category: "Postleitzahl", value: "66981", alias: "66981" },
  { category: "Postleitzahl", value: "66987", alias: "66987" },
  { category: "Postleitzahl", value: "66989", alias: "66989" },
  { category: "Postleitzahl", value: "66994", alias: "66994" },
  { category: "Postleitzahl", value: "66996", alias: "66996" },
  { category: "Postleitzahl", value: "66999", alias: "66999" },
  { category: "Postleitzahl", value: "67059", alias: "67059" },
  { category: "Postleitzahl", value: "67061", alias: "67061" },
  { category: "Postleitzahl", value: "67063", alias: "67063" },
  { category: "Postleitzahl", value: "67065", alias: "67065" },
  { category: "Postleitzahl", value: "67067", alias: "67067" },
  { category: "Postleitzahl", value: "67069", alias: "67069" },
  { category: "Postleitzahl", value: "67071", alias: "67071" },
  { category: "Postleitzahl", value: "67098", alias: "67098" },
  { category: "Postleitzahl", value: "67105", alias: "67105" },
  { category: "Postleitzahl", value: "67112", alias: "67112" },
  { category: "Postleitzahl", value: "67117", alias: "67117" },
  { category: "Postleitzahl", value: "67122", alias: "67122" },
  { category: "Postleitzahl", value: "67125", alias: "67125" },
  { category: "Postleitzahl", value: "67126", alias: "67126" },
  { category: "Postleitzahl", value: "67127", alias: "67127" },
  { category: "Postleitzahl", value: "67133", alias: "67133" },
  { category: "Postleitzahl", value: "67134", alias: "67134" },
  { category: "Postleitzahl", value: "67136", alias: "67136" },
  { category: "Postleitzahl", value: "67141", alias: "67141" },
  { category: "Postleitzahl", value: "67146", alias: "67146" },
  { category: "Postleitzahl", value: "67147", alias: "67147" },
  { category: "Postleitzahl", value: "67149", alias: "67149" },
  { category: "Postleitzahl", value: "67150", alias: "67150" },
  { category: "Postleitzahl", value: "67152", alias: "67152" },
  { category: "Postleitzahl", value: "67157", alias: "67157" },
  { category: "Postleitzahl", value: "67158", alias: "67158" },
  { category: "Postleitzahl", value: "67159", alias: "67159" },
  { category: "Postleitzahl", value: "67161", alias: "67161" },
  { category: "Postleitzahl", value: "67165", alias: "67165" },
  { category: "Postleitzahl", value: "67166", alias: "67166" },
  { category: "Postleitzahl", value: "67167", alias: "67167" },
  { category: "Postleitzahl", value: "67169", alias: "67169" },
  { category: "Postleitzahl", value: "67227", alias: "67227" },
  { category: "Postleitzahl", value: "67229", alias: "67229" },
  { category: "Postleitzahl", value: "67240", alias: "67240" },
  { category: "Postleitzahl", value: "67245", alias: "67245" },
  { category: "Postleitzahl", value: "67246", alias: "67246" },
  { category: "Postleitzahl", value: "67251", alias: "67251" },
  { category: "Postleitzahl", value: "67256", alias: "67256" },
  { category: "Postleitzahl", value: "67258", alias: "67258" },
  { category: "Postleitzahl", value: "67259", alias: "67259" },
  { category: "Postleitzahl", value: "67269", alias: "67269" },
  { category: "Postleitzahl", value: "67271", alias: "67271" },
  { category: "Postleitzahl", value: "67273", alias: "67273" },
  { category: "Postleitzahl", value: "67278", alias: "67278" },
  { category: "Postleitzahl", value: "67280", alias: "67280" },
  { category: "Postleitzahl", value: "67281", alias: "67281" },
  { category: "Postleitzahl", value: "67283", alias: "67283" },
  { category: "Postleitzahl", value: "67292", alias: "67292" },
  { category: "Postleitzahl", value: "67294", alias: "67294" },
  { category: "Postleitzahl", value: "67295", alias: "67295" },
  { category: "Postleitzahl", value: "67297", alias: "67297" },
  { category: "Postleitzahl", value: "67304", alias: "67304" },
  { category: "Postleitzahl", value: "67305", alias: "67305" },
  { category: "Postleitzahl", value: "67307", alias: "67307" },
  { category: "Postleitzahl", value: "67308", alias: "67308" },
  { category: "Postleitzahl", value: "67310", alias: "67310" },
  { category: "Postleitzahl", value: "67311", alias: "67311" },
  { category: "Postleitzahl", value: "67316", alias: "67316" },
  { category: "Postleitzahl", value: "67317", alias: "67317" },
  { category: "Postleitzahl", value: "67319", alias: "67319" },
  { category: "Postleitzahl", value: "67346", alias: "67346" },
  { category: "Postleitzahl", value: "67354", alias: "67354" },
  { category: "Postleitzahl", value: "67360", alias: "67360" },
  { category: "Postleitzahl", value: "67361", alias: "67361" },
  { category: "Postleitzahl", value: "67363", alias: "67363" },
  { category: "Postleitzahl", value: "67365", alias: "67365" },
  { category: "Postleitzahl", value: "67366", alias: "67366" },
  { category: "Postleitzahl", value: "67368", alias: "67368" },
  { category: "Postleitzahl", value: "67373", alias: "67373" },
  { category: "Postleitzahl", value: "67374", alias: "67374" },
  { category: "Postleitzahl", value: "67376", alias: "67376" },
  { category: "Postleitzahl", value: "67377", alias: "67377" },
  { category: "Postleitzahl", value: "67378", alias: "67378" },
  { category: "Postleitzahl", value: "67433", alias: "67433" },
  { category: "Postleitzahl", value: "67434", alias: "67434" },
  { category: "Postleitzahl", value: "67435", alias: "67435" },
  { category: "Postleitzahl", value: "67454", alias: "67454" },
  { category: "Postleitzahl", value: "67459", alias: "67459" },
  { category: "Postleitzahl", value: "67466", alias: "67466" },
  { category: "Postleitzahl", value: "67468", alias: "67468" },
  { category: "Postleitzahl", value: "67471", alias: "67471" },
  { category: "Postleitzahl", value: "67472", alias: "67472" },
  { category: "Postleitzahl", value: "67473", alias: "67473" },
  { category: "Postleitzahl", value: "67475", alias: "67475" },
  { category: "Postleitzahl", value: "67480", alias: "67480" },
  { category: "Postleitzahl", value: "67482", alias: "67482" },
  { category: "Postleitzahl", value: "67483", alias: "67483" },
  { category: "Postleitzahl", value: "67487", alias: "67487" },
  { category: "Postleitzahl", value: "67489", alias: "67489" },
  { category: "Postleitzahl", value: "67547", alias: "67547" },
  { category: "Postleitzahl", value: "67549", alias: "67549" },
  { category: "Postleitzahl", value: "67550", alias: "67550" },
  { category: "Postleitzahl", value: "67551", alias: "67551" },
  { category: "Postleitzahl", value: "67574", alias: "67574" },
  { category: "Postleitzahl", value: "67575", alias: "67575" },
  { category: "Postleitzahl", value: "67577", alias: "67577" },
  { category: "Postleitzahl", value: "67578", alias: "67578" },
  { category: "Postleitzahl", value: "67580", alias: "67580" },
  { category: "Postleitzahl", value: "67582", alias: "67582" },
  { category: "Postleitzahl", value: "67583", alias: "67583" },
  { category: "Postleitzahl", value: "67585", alias: "67585" },
  { category: "Postleitzahl", value: "67586", alias: "67586" },
  { category: "Postleitzahl", value: "67587", alias: "67587" },
  { category: "Postleitzahl", value: "67590", alias: "67590" },
  { category: "Postleitzahl", value: "67591", alias: "67591" },
  { category: "Postleitzahl", value: "67592", alias: "67592" },
  { category: "Postleitzahl", value: "67593", alias: "67593" },
  { category: "Postleitzahl", value: "67595", alias: "67595" },
  { category: "Postleitzahl", value: "67596", alias: "67596" },
  { category: "Postleitzahl", value: "67598", alias: "67598" },
  { category: "Postleitzahl", value: "67599", alias: "67599" },
  { category: "Postleitzahl", value: "67655", alias: "67655" },
  { category: "Postleitzahl", value: "67657", alias: "67657" },
  { category: "Postleitzahl", value: "67659", alias: "67659" },
  { category: "Postleitzahl", value: "67661", alias: "67661" },
  { category: "Postleitzahl", value: "67663", alias: "67663" },
  { category: "Postleitzahl", value: "67677", alias: "67677" },
  { category: "Postleitzahl", value: "67678", alias: "67678" },
  { category: "Postleitzahl", value: "67680", alias: "67680" },
  { category: "Postleitzahl", value: "67681", alias: "67681" },
  { category: "Postleitzahl", value: "67685", alias: "67685" },
  { category: "Postleitzahl", value: "67686", alias: "67686" },
  { category: "Postleitzahl", value: "67688", alias: "67688" },
  { category: "Postleitzahl", value: "67691", alias: "67691" },
  { category: "Postleitzahl", value: "67693", alias: "67693" },
  { category: "Postleitzahl", value: "67697", alias: "67697" },
  { category: "Postleitzahl", value: "67699", alias: "67699" },
  { category: "Postleitzahl", value: "67700", alias: "67700" },
  { category: "Postleitzahl", value: "67701", alias: "67701" },
  { category: "Postleitzahl", value: "67705", alias: "67705" },
  { category: "Postleitzahl", value: "67706", alias: "67706" },
  { category: "Postleitzahl", value: "67707", alias: "67707" },
  { category: "Postleitzahl", value: "67714", alias: "67714" },
  { category: "Postleitzahl", value: "67715", alias: "67715" },
  { category: "Postleitzahl", value: "67716", alias: "67716" },
  { category: "Postleitzahl", value: "67718", alias: "67718" },
  { category: "Postleitzahl", value: "67722", alias: "67722" },
  { category: "Postleitzahl", value: "67724", alias: "67724" },
  { category: "Postleitzahl", value: "67725", alias: "67725" },
  { category: "Postleitzahl", value: "67727", alias: "67727" },
  { category: "Postleitzahl", value: "67728", alias: "67728" },
  { category: "Postleitzahl", value: "67729", alias: "67729" },
  { category: "Postleitzahl", value: "67731", alias: "67731" },
  { category: "Postleitzahl", value: "67732", alias: "67732" },
  { category: "Postleitzahl", value: "67734", alias: "67734" },
  { category: "Postleitzahl", value: "67735", alias: "67735" },
  { category: "Postleitzahl", value: "67737", alias: "67737" },
  { category: "Postleitzahl", value: "67742", alias: "67742" },
  { category: "Postleitzahl", value: "67744", alias: "67744" },
  { category: "Postleitzahl", value: "67745", alias: "67745" },
  { category: "Postleitzahl", value: "67746", alias: "67746" },
  { category: "Postleitzahl", value: "67748", alias: "67748" },
  { category: "Postleitzahl", value: "67749", alias: "67749" },
  { category: "Postleitzahl", value: "67752", alias: "67752" },
  { category: "Postleitzahl", value: "67753", alias: "67753" },
  { category: "Postleitzahl", value: "67754", alias: "67754" },
  { category: "Postleitzahl", value: "67756", alias: "67756" },
  { category: "Postleitzahl", value: "67757", alias: "67757" },
  { category: "Postleitzahl", value: "67759", alias: "67759" },
  { category: "Postleitzahl", value: "67806", alias: "67806" },
  { category: "Postleitzahl", value: "67808", alias: "67808" },
  { category: "Postleitzahl", value: "67811", alias: "67811" },
  { category: "Postleitzahl", value: "67813", alias: "67813" },
  { category: "Postleitzahl", value: "67814", alias: "67814" },
  { category: "Postleitzahl", value: "67816", alias: "67816" },
  { category: "Postleitzahl", value: "67817", alias: "67817" },
  { category: "Postleitzahl", value: "67819", alias: "67819" },
  { category: "Postleitzahl", value: "67821", alias: "67821" },
  { category: "Postleitzahl", value: "67822", alias: "67822" },
  { category: "Postleitzahl", value: "67823", alias: "67823" },
  { category: "Postleitzahl", value: "67824", alias: "67824" },
  { category: "Postleitzahl", value: "67826", alias: "67826" },
  { category: "Postleitzahl", value: "67827", alias: "67827" },
  { category: "Postleitzahl", value: "67829", alias: "67829" },
  { category: "Postleitzahl", value: "68159", alias: "68159" },
  { category: "Postleitzahl", value: "68161", alias: "68161" },
  { category: "Postleitzahl", value: "68163", alias: "68163" },
  { category: "Postleitzahl", value: "68165", alias: "68165" },
  { category: "Postleitzahl", value: "68167", alias: "68167" },
  { category: "Postleitzahl", value: "68169", alias: "68169" },
  { category: "Postleitzahl", value: "68199", alias: "68199" },
  { category: "Postleitzahl", value: "68219", alias: "68219" },
  { category: "Postleitzahl", value: "68229", alias: "68229" },
  { category: "Postleitzahl", value: "68239", alias: "68239" },
  { category: "Postleitzahl", value: "68259", alias: "68259" },
  { category: "Postleitzahl", value: "68305", alias: "68305" },
  { category: "Postleitzahl", value: "68307", alias: "68307" },
  { category: "Postleitzahl", value: "68309", alias: "68309" },
  { category: "Postleitzahl", value: "68519", alias: "68519" },
  { category: "Postleitzahl", value: "68526", alias: "68526" },
  { category: "Postleitzahl", value: "68535", alias: "68535" },
  { category: "Postleitzahl", value: "68542", alias: "68542" },
  { category: "Postleitzahl", value: "68549", alias: "68549" },
  { category: "Postleitzahl", value: "68623", alias: "68623" },
  { category: "Postleitzahl", value: "68642", alias: "68642" },
  { category: "Postleitzahl", value: "68647", alias: "68647" },
  { category: "Postleitzahl", value: "68649", alias: "68649" },
  { category: "Postleitzahl", value: "68723", alias: "68723" },
  { category: "Postleitzahl", value: "68753", alias: "68753" },
  { category: "Postleitzahl", value: "68766", alias: "68766" },
  { category: "Postleitzahl", value: "68775", alias: "68775" },
  { category: "Postleitzahl", value: "68782", alias: "68782" },
  { category: "Postleitzahl", value: "68789", alias: "68789" },
  { category: "Postleitzahl", value: "68794", alias: "68794" },
  { category: "Postleitzahl", value: "68799", alias: "68799" },
  { category: "Postleitzahl", value: "68804", alias: "68804" },
  { category: "Postleitzahl", value: "68809", alias: "68809" },
  { category: "Postleitzahl", value: "69115", alias: "69115" },
  { category: "Postleitzahl", value: "69117", alias: "69117" },
  { category: "Postleitzahl", value: "69118", alias: "69118" },
  { category: "Postleitzahl", value: "69120", alias: "69120" },
  { category: "Postleitzahl", value: "69121", alias: "69121" },
  { category: "Postleitzahl", value: "69123", alias: "69123" },
  { category: "Postleitzahl", value: "69124", alias: "69124" },
  { category: "Postleitzahl", value: "69126", alias: "69126" },
  { category: "Postleitzahl", value: "69151", alias: "69151" },
  { category: "Postleitzahl", value: "69168", alias: "69168" },
  { category: "Postleitzahl", value: "69181", alias: "69181" },
  { category: "Postleitzahl", value: "69190", alias: "69190" },
  { category: "Postleitzahl", value: "69198", alias: "69198" },
  { category: "Postleitzahl", value: "69207", alias: "69207" },
  { category: "Postleitzahl", value: "69214", alias: "69214" },
  { category: "Postleitzahl", value: "69221", alias: "69221" },
  { category: "Postleitzahl", value: "69226", alias: "69226" },
  { category: "Postleitzahl", value: "69231", alias: "69231" },
  { category: "Postleitzahl", value: "69234", alias: "69234" },
  { category: "Postleitzahl", value: "69239", alias: "69239" },
  { category: "Postleitzahl", value: "69242", alias: "69242" },
  { category: "Postleitzahl", value: "69245", alias: "69245" },
  { category: "Postleitzahl", value: "69250", alias: "69250" },
  { category: "Postleitzahl", value: "69251", alias: "69251" },
  { category: "Postleitzahl", value: "69253", alias: "69253" },
  { category: "Postleitzahl", value: "69254", alias: "69254" },
  { category: "Postleitzahl", value: "69256", alias: "69256" },
  { category: "Postleitzahl", value: "69257", alias: "69257" },
  { category: "Postleitzahl", value: "69259", alias: "69259" },
  { category: "Postleitzahl", value: "69412", alias: "69412" },
  { category: "Postleitzahl", value: "69427", alias: "69427" },
  { category: "Postleitzahl", value: "69429", alias: "69429" },
  { category: "Postleitzahl", value: "69434", alias: "69434" },
  { category: "Postleitzahl", value: "69436", alias: "69436" },
  { category: "Postleitzahl", value: "69437", alias: "69437" },
  { category: "Postleitzahl", value: "69439", alias: "69439" },
  { category: "Postleitzahl", value: "69469", alias: "69469" },
  { category: "Postleitzahl", value: "69483", alias: "69483" },
  { category: "Postleitzahl", value: "69488", alias: "69488" },
  { category: "Postleitzahl", value: "69493", alias: "69493" },
  { category: "Postleitzahl", value: "69502", alias: "69502" },
  { category: "Postleitzahl", value: "69509", alias: "69509" },
  { category: "Postleitzahl", value: "69514", alias: "69514" },
  { category: "Postleitzahl", value: "69517", alias: "69517" },
  { category: "Postleitzahl", value: "69518", alias: "69518" },
  { category: "Postleitzahl", value: "70173", alias: "70173" },
  { category: "Postleitzahl", value: "70174", alias: "70174" },
  { category: "Postleitzahl", value: "70176", alias: "70176" },
  { category: "Postleitzahl", value: "70178", alias: "70178" },
  { category: "Postleitzahl", value: "70180", alias: "70180" },
  { category: "Postleitzahl", value: "70182", alias: "70182" },
  { category: "Postleitzahl", value: "70184", alias: "70184" },
  { category: "Postleitzahl", value: "70186", alias: "70186" },
  { category: "Postleitzahl", value: "70188", alias: "70188" },
  { category: "Postleitzahl", value: "70190", alias: "70190" },
  { category: "Postleitzahl", value: "70191", alias: "70191" },
  { category: "Postleitzahl", value: "70192", alias: "70192" },
  { category: "Postleitzahl", value: "70193", alias: "70193" },
  { category: "Postleitzahl", value: "70195", alias: "70195" },
  { category: "Postleitzahl", value: "70197", alias: "70197" },
  { category: "Postleitzahl", value: "70199", alias: "70199" },
  { category: "Postleitzahl", value: "70327", alias: "70327" },
  { category: "Postleitzahl", value: "70329", alias: "70329" },
  { category: "Postleitzahl", value: "70372", alias: "70372" },
  { category: "Postleitzahl", value: "70374", alias: "70374" },
  { category: "Postleitzahl", value: "70376", alias: "70376" },
  { category: "Postleitzahl", value: "70378", alias: "70378" },
  { category: "Postleitzahl", value: "70435", alias: "70435" },
  { category: "Postleitzahl", value: "70437", alias: "70437" },
  { category: "Postleitzahl", value: "70439", alias: "70439" },
  { category: "Postleitzahl", value: "70469", alias: "70469" },
  { category: "Postleitzahl", value: "70499", alias: "70499" },
  { category: "Postleitzahl", value: "70563", alias: "70563" },
  { category: "Postleitzahl", value: "70565", alias: "70565" },
  { category: "Postleitzahl", value: "70567", alias: "70567" },
  { category: "Postleitzahl", value: "70569", alias: "70569" },
  { category: "Postleitzahl", value: "70597", alias: "70597" },
  { category: "Postleitzahl", value: "70599", alias: "70599" },
  { category: "Postleitzahl", value: "70619", alias: "70619" },
  { category: "Postleitzahl", value: "70629", alias: "70629" },
  { category: "Postleitzahl", value: "70734", alias: "70734" },
  { category: "Postleitzahl", value: "70736", alias: "70736" },
  { category: "Postleitzahl", value: "70771", alias: "70771" },
  { category: "Postleitzahl", value: "70794", alias: "70794" },
  { category: "Postleitzahl", value: "70806", alias: "70806" },
  { category: "Postleitzahl", value: "70825", alias: "70825" },
  { category: "Postleitzahl", value: "70839", alias: "70839" },
  { category: "Postleitzahl", value: "71032", alias: "71032" },
  { category: "Postleitzahl", value: "71034", alias: "71034" },
  { category: "Postleitzahl", value: "71063", alias: "71063" },
  { category: "Postleitzahl", value: "71065", alias: "71065" },
  { category: "Postleitzahl", value: "71067", alias: "71067" },
  { category: "Postleitzahl", value: "71069", alias: "71069" },
  { category: "Postleitzahl", value: "71083", alias: "71083" },
  { category: "Postleitzahl", value: "71088", alias: "71088" },
  { category: "Postleitzahl", value: "71093", alias: "71093" },
  { category: "Postleitzahl", value: "71101", alias: "71101" },
  { category: "Postleitzahl", value: "71106", alias: "71106" },
  { category: "Postleitzahl", value: "71111", alias: "71111" },
  { category: "Postleitzahl", value: "71116", alias: "71116" },
  { category: "Postleitzahl", value: "71120", alias: "71120" },
  { category: "Postleitzahl", value: "71126", alias: "71126" },
  { category: "Postleitzahl", value: "71131", alias: "71131" },
  { category: "Postleitzahl", value: "71134", alias: "71134" },
  { category: "Postleitzahl", value: "71139", alias: "71139" },
  { category: "Postleitzahl", value: "71144", alias: "71144" },
  { category: "Postleitzahl", value: "71149", alias: "71149" },
  { category: "Postleitzahl", value: "71154", alias: "71154" },
  { category: "Postleitzahl", value: "71155", alias: "71155" },
  { category: "Postleitzahl", value: "71157", alias: "71157" },
  { category: "Postleitzahl", value: "71159", alias: "71159" },
  { category: "Postleitzahl", value: "71229", alias: "71229" },
  { category: "Postleitzahl", value: "71254", alias: "71254" },
  { category: "Postleitzahl", value: "71263", alias: "71263" },
  { category: "Postleitzahl", value: "71272", alias: "71272" },
  { category: "Postleitzahl", value: "71277", alias: "71277" },
  { category: "Postleitzahl", value: "71282", alias: "71282" },
  { category: "Postleitzahl", value: "71287", alias: "71287" },
  { category: "Postleitzahl", value: "71292", alias: "71292" },
  { category: "Postleitzahl", value: "71296", alias: "71296" },
  { category: "Postleitzahl", value: "71297", alias: "71297" },
  { category: "Postleitzahl", value: "71299", alias: "71299" },
  { category: "Postleitzahl", value: "71332", alias: "71332" },
  { category: "Postleitzahl", value: "71334", alias: "71334" },
  { category: "Postleitzahl", value: "71336", alias: "71336" },
  { category: "Postleitzahl", value: "71364", alias: "71364" },
  { category: "Postleitzahl", value: "71384", alias: "71384" },
  { category: "Postleitzahl", value: "71394", alias: "71394" },
  { category: "Postleitzahl", value: "71397", alias: "71397" },
  { category: "Postleitzahl", value: "71404", alias: "71404" },
  { category: "Postleitzahl", value: "71409", alias: "71409" },
  { category: "Postleitzahl", value: "71522", alias: "71522" },
  { category: "Postleitzahl", value: "71540", alias: "71540" },
  { category: "Postleitzahl", value: "71543", alias: "71543" },
  { category: "Postleitzahl", value: "71546", alias: "71546" },
  { category: "Postleitzahl", value: "71549", alias: "71549" },
  { category: "Postleitzahl", value: "71554", alias: "71554" },
  { category: "Postleitzahl", value: "71560", alias: "71560" },
  { category: "Postleitzahl", value: "71563", alias: "71563" },
  { category: "Postleitzahl", value: "71566", alias: "71566" },
  { category: "Postleitzahl", value: "71570", alias: "71570" },
  { category: "Postleitzahl", value: "71573", alias: "71573" },
  { category: "Postleitzahl", value: "71576", alias: "71576" },
  { category: "Postleitzahl", value: "71577", alias: "71577" },
  { category: "Postleitzahl", value: "71579", alias: "71579" },
  { category: "Postleitzahl", value: "71634", alias: "71634" },
  { category: "Postleitzahl", value: "71636", alias: "71636" },
  { category: "Postleitzahl", value: "71638", alias: "71638" },
  { category: "Postleitzahl", value: "71640", alias: "71640" },
  { category: "Postleitzahl", value: "71642", alias: "71642" },
  { category: "Postleitzahl", value: "71665", alias: "71665" },
  { category: "Postleitzahl", value: "71672", alias: "71672" },
  { category: "Postleitzahl", value: "71679", alias: "71679" },
  { category: "Postleitzahl", value: "71686", alias: "71686" },
  { category: "Postleitzahl", value: "71691", alias: "71691" },
  { category: "Postleitzahl", value: "71696", alias: "71696" },
  { category: "Postleitzahl", value: "71701", alias: "71701" },
  { category: "Postleitzahl", value: "71706", alias: "71706" },
  { category: "Postleitzahl", value: "71711", alias: "71711" },
  { category: "Postleitzahl", value: "71717", alias: "71717" },
  { category: "Postleitzahl", value: "71720", alias: "71720" },
  { category: "Postleitzahl", value: "71723", alias: "71723" },
  { category: "Postleitzahl", value: "71726", alias: "71726" },
  { category: "Postleitzahl", value: "71729", alias: "71729" },
  { category: "Postleitzahl", value: "71732", alias: "71732" },
  { category: "Postleitzahl", value: "71735", alias: "71735" },
  { category: "Postleitzahl", value: "71737", alias: "71737" },
  { category: "Postleitzahl", value: "71739", alias: "71739" },
  { category: "Postleitzahl", value: "72070", alias: "72070" },
  { category: "Postleitzahl", value: "72072", alias: "72072" },
  { category: "Postleitzahl", value: "72074", alias: "72074" },
  { category: "Postleitzahl", value: "72076", alias: "72076" },
  { category: "Postleitzahl", value: "72108", alias: "72108" },
  { category: "Postleitzahl", value: "72116", alias: "72116" },
  { category: "Postleitzahl", value: "72119", alias: "72119" },
  { category: "Postleitzahl", value: "72124", alias: "72124" },
  { category: "Postleitzahl", value: "72127", alias: "72127" },
  { category: "Postleitzahl", value: "72131", alias: "72131" },
  { category: "Postleitzahl", value: "72135", alias: "72135" },
  { category: "Postleitzahl", value: "72138", alias: "72138" },
  { category: "Postleitzahl", value: "72141", alias: "72141" },
  { category: "Postleitzahl", value: "72144", alias: "72144" },
  { category: "Postleitzahl", value: "72145", alias: "72145" },
  { category: "Postleitzahl", value: "72147", alias: "72147" },
  { category: "Postleitzahl", value: "72149", alias: "72149" },
  { category: "Postleitzahl", value: "72160", alias: "72160" },
  { category: "Postleitzahl", value: "72172", alias: "72172" },
  { category: "Postleitzahl", value: "72175", alias: "72175" },
  { category: "Postleitzahl", value: "72178", alias: "72178" },
  { category: "Postleitzahl", value: "72181", alias: "72181" },
  { category: "Postleitzahl", value: "72184", alias: "72184" },
  { category: "Postleitzahl", value: "72186", alias: "72186" },
  { category: "Postleitzahl", value: "72189", alias: "72189" },
  { category: "Postleitzahl", value: "72202", alias: "72202" },
  { category: "Postleitzahl", value: "72213", alias: "72213" },
  { category: "Postleitzahl", value: "72218", alias: "72218" },
  { category: "Postleitzahl", value: "72221", alias: "72221" },
  { category: "Postleitzahl", value: "72224", alias: "72224" },
  { category: "Postleitzahl", value: "72226", alias: "72226" },
  { category: "Postleitzahl", value: "72227", alias: "72227" },
  { category: "Postleitzahl", value: "72229", alias: "72229" },
  { category: "Postleitzahl", value: "72250", alias: "72250" },
  { category: "Postleitzahl", value: "72270", alias: "72270" },
  { category: "Postleitzahl", value: "72275", alias: "72275" },
  { category: "Postleitzahl", value: "72280", alias: "72280" },
  { category: "Postleitzahl", value: "72285", alias: "72285" },
  { category: "Postleitzahl", value: "72290", alias: "72290" },
  { category: "Postleitzahl", value: "72293", alias: "72293" },
  { category: "Postleitzahl", value: "72294", alias: "72294" },
  { category: "Postleitzahl", value: "72296", alias: "72296" },
  { category: "Postleitzahl", value: "72297", alias: "72297" },
  { category: "Postleitzahl", value: "72299", alias: "72299" },
  { category: "Postleitzahl", value: "72336", alias: "72336" },
  { category: "Postleitzahl", value: "72348", alias: "72348" },
  { category: "Postleitzahl", value: "72351", alias: "72351" },
  { category: "Postleitzahl", value: "72355", alias: "72355" },
  { category: "Postleitzahl", value: "72356", alias: "72356" },
  { category: "Postleitzahl", value: "72358", alias: "72358" },
  { category: "Postleitzahl", value: "72359", alias: "72359" },
  { category: "Postleitzahl", value: "72361", alias: "72361" },
  { category: "Postleitzahl", value: "72362", alias: "72362" },
  { category: "Postleitzahl", value: "72364", alias: "72364" },
  { category: "Postleitzahl", value: "72365", alias: "72365" },
  { category: "Postleitzahl", value: "72367", alias: "72367" },
  { category: "Postleitzahl", value: "72369", alias: "72369" },
  { category: "Postleitzahl", value: "72379", alias: "72379" },
  { category: "Postleitzahl", value: "72393", alias: "72393" },
  { category: "Postleitzahl", value: "72401", alias: "72401" },
  { category: "Postleitzahl", value: "72406", alias: "72406" },
  { category: "Postleitzahl", value: "72411", alias: "72411" },
  { category: "Postleitzahl", value: "72414", alias: "72414" },
  { category: "Postleitzahl", value: "72415", alias: "72415" },
  { category: "Postleitzahl", value: "72417", alias: "72417" },
  { category: "Postleitzahl", value: "72419", alias: "72419" },
  { category: "Postleitzahl", value: "72458", alias: "72458" },
  { category: "Postleitzahl", value: "72459", alias: "72459" },
  { category: "Postleitzahl", value: "72461", alias: "72461" },
  { category: "Postleitzahl", value: "72469", alias: "72469" },
  { category: "Postleitzahl", value: "72474", alias: "72474" },
  { category: "Postleitzahl", value: "72475", alias: "72475" },
  { category: "Postleitzahl", value: "72477", alias: "72477" },
  { category: "Postleitzahl", value: "72479", alias: "72479" },
  { category: "Postleitzahl", value: "72488", alias: "72488" },
  { category: "Postleitzahl", value: "72501", alias: "72501" },
  { category: "Postleitzahl", value: "72505", alias: "72505" },
  { category: "Postleitzahl", value: "72510", alias: "72510" },
  { category: "Postleitzahl", value: "72511", alias: "72511" },
  { category: "Postleitzahl", value: "72513", alias: "72513" },
  { category: "Postleitzahl", value: "72514", alias: "72514" },
  { category: "Postleitzahl", value: "72516", alias: "72516" },
  { category: "Postleitzahl", value: "72517", alias: "72517" },
  { category: "Postleitzahl", value: "72519", alias: "72519" },
  { category: "Postleitzahl", value: "72525", alias: "72525" },
  { category: "Postleitzahl", value: "72531", alias: "72531" },
  { category: "Postleitzahl", value: "72532", alias: "72532" },
  { category: "Postleitzahl", value: "72534", alias: "72534" },
  { category: "Postleitzahl", value: "72535", alias: "72535" },
  { category: "Postleitzahl", value: "72537", alias: "72537" },
  { category: "Postleitzahl", value: "72539", alias: "72539" },
  { category: "Postleitzahl", value: "72555", alias: "72555" },
  { category: "Postleitzahl", value: "72574", alias: "72574" },
  { category: "Postleitzahl", value: "72581", alias: "72581" },
  { category: "Postleitzahl", value: "72582", alias: "72582" },
  { category: "Postleitzahl", value: "72584", alias: "72584" },
  { category: "Postleitzahl", value: "72585", alias: "72585" },
  { category: "Postleitzahl", value: "72587", alias: "72587" },
  { category: "Postleitzahl", value: "72589", alias: "72589" },
  { category: "Postleitzahl", value: "72622", alias: "72622" },
  { category: "Postleitzahl", value: "72631", alias: "72631" },
  { category: "Postleitzahl", value: "72636", alias: "72636" },
  { category: "Postleitzahl", value: "72639", alias: "72639" },
  { category: "Postleitzahl", value: "72644", alias: "72644" },
  { category: "Postleitzahl", value: "72649", alias: "72649" },
  { category: "Postleitzahl", value: "72654", alias: "72654" },
  { category: "Postleitzahl", value: "72655", alias: "72655" },
  { category: "Postleitzahl", value: "72657", alias: "72657" },
  { category: "Postleitzahl", value: "72658", alias: "72658" },
  { category: "Postleitzahl", value: "72660", alias: "72660" },
  { category: "Postleitzahl", value: "72661", alias: "72661" },
  { category: "Postleitzahl", value: "72663", alias: "72663" },
  { category: "Postleitzahl", value: "72664", alias: "72664" },
  { category: "Postleitzahl", value: "72666", alias: "72666" },
  { category: "Postleitzahl", value: "72667", alias: "72667" },
  { category: "Postleitzahl", value: "72669", alias: "72669" },
  { category: "Postleitzahl", value: "72760", alias: "72760" },
  { category: "Postleitzahl", value: "72762", alias: "72762" },
  { category: "Postleitzahl", value: "72764", alias: "72764" },
  { category: "Postleitzahl", value: "72766", alias: "72766" },
  { category: "Postleitzahl", value: "72768", alias: "72768" },
  { category: "Postleitzahl", value: "72770", alias: "72770" },
  { category: "Postleitzahl", value: "72793", alias: "72793" },
  { category: "Postleitzahl", value: "72800", alias: "72800" },
  { category: "Postleitzahl", value: "72805", alias: "72805" },
  { category: "Postleitzahl", value: "72810", alias: "72810" },
  { category: "Postleitzahl", value: "72813", alias: "72813" },
  { category: "Postleitzahl", value: "72818", alias: "72818" },
  { category: "Postleitzahl", value: "72820", alias: "72820" },
  { category: "Postleitzahl", value: "72827", alias: "72827" },
  { category: "Postleitzahl", value: "72829", alias: "72829" },
  { category: "Postleitzahl", value: "73033", alias: "73033" },
  { category: "Postleitzahl", value: "73035", alias: "73035" },
  { category: "Postleitzahl", value: "73037", alias: "73037" },
  { category: "Postleitzahl", value: "73054", alias: "73054" },
  { category: "Postleitzahl", value: "73061", alias: "73061" },
  { category: "Postleitzahl", value: "73066", alias: "73066" },
  { category: "Postleitzahl", value: "73072", alias: "73072" },
  { category: "Postleitzahl", value: "73079", alias: "73079" },
  { category: "Postleitzahl", value: "73084", alias: "73084" },
  { category: "Postleitzahl", value: "73087", alias: "73087" },
  { category: "Postleitzahl", value: "73092", alias: "73092" },
  { category: "Postleitzahl", value: "73095", alias: "73095" },
  { category: "Postleitzahl", value: "73098", alias: "73098" },
  { category: "Postleitzahl", value: "73099", alias: "73099" },
  { category: "Postleitzahl", value: "73101", alias: "73101" },
  { category: "Postleitzahl", value: "73102", alias: "73102" },
  { category: "Postleitzahl", value: "73104", alias: "73104" },
  { category: "Postleitzahl", value: "73105", alias: "73105" },
  { category: "Postleitzahl", value: "73107", alias: "73107" },
  { category: "Postleitzahl", value: "73108", alias: "73108" },
  { category: "Postleitzahl", value: "73110", alias: "73110" },
  { category: "Postleitzahl", value: "73111", alias: "73111" },
  { category: "Postleitzahl", value: "73113", alias: "73113" },
  { category: "Postleitzahl", value: "73114", alias: "73114" },
  { category: "Postleitzahl", value: "73116", alias: "73116" },
  { category: "Postleitzahl", value: "73117", alias: "73117" },
  { category: "Postleitzahl", value: "73119", alias: "73119" },
  { category: "Postleitzahl", value: "73207", alias: "73207" },
  { category: "Postleitzahl", value: "73230", alias: "73230" },
  { category: "Postleitzahl", value: "73235", alias: "73235" },
  { category: "Postleitzahl", value: "73240", alias: "73240" },
  { category: "Postleitzahl", value: "73249", alias: "73249" },
  { category: "Postleitzahl", value: "73252", alias: "73252" },
  { category: "Postleitzahl", value: "73257", alias: "73257" },
  { category: "Postleitzahl", value: "73262", alias: "73262" },
  { category: "Postleitzahl", value: "73265", alias: "73265" },
  { category: "Postleitzahl", value: "73266", alias: "73266" },
  { category: "Postleitzahl", value: "73268", alias: "73268" },
  { category: "Postleitzahl", value: "73269", alias: "73269" },
  { category: "Postleitzahl", value: "73271", alias: "73271" },
  { category: "Postleitzahl", value: "73272", alias: "73272" },
  { category: "Postleitzahl", value: "73274", alias: "73274" },
  { category: "Postleitzahl", value: "73275", alias: "73275" },
  { category: "Postleitzahl", value: "73277", alias: "73277" },
  { category: "Postleitzahl", value: "73278", alias: "73278" },
  { category: "Postleitzahl", value: "73312", alias: "73312" },
  { category: "Postleitzahl", value: "73326", alias: "73326" },
  { category: "Postleitzahl", value: "73329", alias: "73329" },
  { category: "Postleitzahl", value: "73333", alias: "73333" },
  { category: "Postleitzahl", value: "73337", alias: "73337" },
  { category: "Postleitzahl", value: "73340", alias: "73340" },
  { category: "Postleitzahl", value: "73342", alias: "73342" },
  { category: "Postleitzahl", value: "73344", alias: "73344" },
  { category: "Postleitzahl", value: "73345", alias: "73345" },
  { category: "Postleitzahl", value: "73347", alias: "73347" },
  { category: "Postleitzahl", value: "73349", alias: "73349" },
  { category: "Postleitzahl", value: "73430", alias: "73430" },
  { category: "Postleitzahl", value: "73431", alias: "73431" },
  { category: "Postleitzahl", value: "73432", alias: "73432" },
  { category: "Postleitzahl", value: "73433", alias: "73433" },
  { category: "Postleitzahl", value: "73434", alias: "73434" },
  { category: "Postleitzahl", value: "73441", alias: "73441" },
  { category: "Postleitzahl", value: "73447", alias: "73447" },
  { category: "Postleitzahl", value: "73450", alias: "73450" },
  { category: "Postleitzahl", value: "73453", alias: "73453" },
  { category: "Postleitzahl", value: "73457", alias: "73457" },
  { category: "Postleitzahl", value: "73460", alias: "73460" },
  { category: "Postleitzahl", value: "73463", alias: "73463" },
  { category: "Postleitzahl", value: "73466", alias: "73466" },
  { category: "Postleitzahl", value: "73467", alias: "73467" },
  { category: "Postleitzahl", value: "73469", alias: "73469" },
  { category: "Postleitzahl", value: "73479", alias: "73479" },
  { category: "Postleitzahl", value: "73485", alias: "73485" },
  { category: "Postleitzahl", value: "73486", alias: "73486" },
  { category: "Postleitzahl", value: "73488", alias: "73488" },
  { category: "Postleitzahl", value: "73489", alias: "73489" },
  { category: "Postleitzahl", value: "73491", alias: "73491" },
  { category: "Postleitzahl", value: "73492", alias: "73492" },
  { category: "Postleitzahl", value: "73494", alias: "73494" },
  { category: "Postleitzahl", value: "73495", alias: "73495" },
  { category: "Postleitzahl", value: "73497", alias: "73497" },
  { category: "Postleitzahl", value: "73499", alias: "73499" },
  { category: "Postleitzahl", value: "73525", alias: "73525" },
  { category: "Postleitzahl", value: "73527", alias: "73527" },
  { category: "Postleitzahl", value: "73529", alias: "73529" },
  { category: "Postleitzahl", value: "73540", alias: "73540" },
  { category: "Postleitzahl", value: "73547", alias: "73547" },
  { category: "Postleitzahl", value: "73550", alias: "73550" },
  { category: "Postleitzahl", value: "73553", alias: "73553" },
  { category: "Postleitzahl", value: "73557", alias: "73557" },
  { category: "Postleitzahl", value: "73560", alias: "73560" },
  { category: "Postleitzahl", value: "73563", alias: "73563" },
  { category: "Postleitzahl", value: "73565", alias: "73565" },
  { category: "Postleitzahl", value: "73566", alias: "73566" },
  { category: "Postleitzahl", value: "73568", alias: "73568" },
  { category: "Postleitzahl", value: "73569", alias: "73569" },
  { category: "Postleitzahl", value: "73571", alias: "73571" },
  { category: "Postleitzahl", value: "73572", alias: "73572" },
  { category: "Postleitzahl", value: "73574", alias: "73574" },
  { category: "Postleitzahl", value: "73575", alias: "73575" },
  { category: "Postleitzahl", value: "73577", alias: "73577" },
  { category: "Postleitzahl", value: "73579", alias: "73579" },
  { category: "Postleitzahl", value: "73614", alias: "73614" },
  { category: "Postleitzahl", value: "73630", alias: "73630" },
  { category: "Postleitzahl", value: "73635", alias: "73635" },
  { category: "Postleitzahl", value: "73642", alias: "73642" },
  { category: "Postleitzahl", value: "73650", alias: "73650" },
  { category: "Postleitzahl", value: "73655", alias: "73655" },
  { category: "Postleitzahl", value: "73660", alias: "73660" },
  { category: "Postleitzahl", value: "73663", alias: "73663" },
  { category: "Postleitzahl", value: "73666", alias: "73666" },
  { category: "Postleitzahl", value: "73667", alias: "73667" },
  { category: "Postleitzahl", value: "73669", alias: "73669" },
  { category: "Postleitzahl", value: "73728", alias: "73728" },
  { category: "Postleitzahl", value: "73730", alias: "73730" },
  { category: "Postleitzahl", value: "73732", alias: "73732" },
  { category: "Postleitzahl", value: "73733", alias: "73733" },
  { category: "Postleitzahl", value: "73734", alias: "73734" },
  { category: "Postleitzahl", value: "73760", alias: "73760" },
  { category: "Postleitzahl", value: "73765", alias: "73765" },
  { category: "Postleitzahl", value: "73770", alias: "73770" },
  { category: "Postleitzahl", value: "73773", alias: "73773" },
  { category: "Postleitzahl", value: "73776", alias: "73776" },
  { category: "Postleitzahl", value: "73779", alias: "73779" },
  { category: "Postleitzahl", value: "74072", alias: "74072" },
  { category: "Postleitzahl", value: "74074", alias: "74074" },
  { category: "Postleitzahl", value: "74076", alias: "74076" },
  { category: "Postleitzahl", value: "74078", alias: "74078" },
  { category: "Postleitzahl", value: "74080", alias: "74080" },
  { category: "Postleitzahl", value: "74081", alias: "74081" },
  { category: "Postleitzahl", value: "74172", alias: "74172" },
  { category: "Postleitzahl", value: "74177", alias: "74177" },
  { category: "Postleitzahl", value: "74182", alias: "74182" },
  { category: "Postleitzahl", value: "74189", alias: "74189" },
  { category: "Postleitzahl", value: "74193", alias: "74193" },
  { category: "Postleitzahl", value: "74196", alias: "74196" },
  { category: "Postleitzahl", value: "74199", alias: "74199" },
  { category: "Postleitzahl", value: "74206", alias: "74206" },
  { category: "Postleitzahl", value: "74211", alias: "74211" },
  { category: "Postleitzahl", value: "74214", alias: "74214" },
  { category: "Postleitzahl", value: "74219", alias: "74219" },
  { category: "Postleitzahl", value: "74223", alias: "74223" },
  { category: "Postleitzahl", value: "74226", alias: "74226" },
  { category: "Postleitzahl", value: "74229", alias: "74229" },
  { category: "Postleitzahl", value: "74232", alias: "74232" },
  { category: "Postleitzahl", value: "74235", alias: "74235" },
  { category: "Postleitzahl", value: "74238", alias: "74238" },
  { category: "Postleitzahl", value: "74239", alias: "74239" },
  { category: "Postleitzahl", value: "74243", alias: "74243" },
  { category: "Postleitzahl", value: "74245", alias: "74245" },
  { category: "Postleitzahl", value: "74246", alias: "74246" },
  { category: "Postleitzahl", value: "74248", alias: "74248" },
  { category: "Postleitzahl", value: "74249", alias: "74249" },
  { category: "Postleitzahl", value: "74251", alias: "74251" },
  { category: "Postleitzahl", value: "74252", alias: "74252" },
  { category: "Postleitzahl", value: "74254", alias: "74254" },
  { category: "Postleitzahl", value: "74255", alias: "74255" },
  { category: "Postleitzahl", value: "74257", alias: "74257" },
  { category: "Postleitzahl", value: "74259", alias: "74259" },
  { category: "Postleitzahl", value: "74321", alias: "74321" },
  { category: "Postleitzahl", value: "74336", alias: "74336" },
  { category: "Postleitzahl", value: "74343", alias: "74343" },
  { category: "Postleitzahl", value: "74348", alias: "74348" },
  { category: "Postleitzahl", value: "74354", alias: "74354" },
  { category: "Postleitzahl", value: "74357", alias: "74357" },
  { category: "Postleitzahl", value: "74360", alias: "74360" },
  { category: "Postleitzahl", value: "74363", alias: "74363" },
  { category: "Postleitzahl", value: "74366", alias: "74366" },
  { category: "Postleitzahl", value: "74369", alias: "74369" },
  { category: "Postleitzahl", value: "74372", alias: "74372" },
  { category: "Postleitzahl", value: "74374", alias: "74374" },
  { category: "Postleitzahl", value: "74376", alias: "74376" },
  { category: "Postleitzahl", value: "74379", alias: "74379" },
  { category: "Postleitzahl", value: "74382", alias: "74382" },
  { category: "Postleitzahl", value: "74385", alias: "74385" },
  { category: "Postleitzahl", value: "74388", alias: "74388" },
  { category: "Postleitzahl", value: "74389", alias: "74389" },
  { category: "Postleitzahl", value: "74391", alias: "74391" },
  { category: "Postleitzahl", value: "74392", alias: "74392" },
  { category: "Postleitzahl", value: "74394", alias: "74394" },
  { category: "Postleitzahl", value: "74395", alias: "74395" },
  { category: "Postleitzahl", value: "74397", alias: "74397" },
  { category: "Postleitzahl", value: "74399", alias: "74399" },
  { category: "Postleitzahl", value: "74405", alias: "74405" },
  { category: "Postleitzahl", value: "74417", alias: "74417" },
  { category: "Postleitzahl", value: "74420", alias: "74420" },
  { category: "Postleitzahl", value: "74423", alias: "74423" },
  { category: "Postleitzahl", value: "74424", alias: "74424" },
  { category: "Postleitzahl", value: "74426", alias: "74426" },
  { category: "Postleitzahl", value: "74427", alias: "74427" },
  { category: "Postleitzahl", value: "74429", alias: "74429" },
  { category: "Postleitzahl", value: "74523", alias: "74523" },
  { category: "Postleitzahl", value: "74532", alias: "74532" },
  { category: "Postleitzahl", value: "74535", alias: "74535" },
  { category: "Postleitzahl", value: "74538", alias: "74538" },
  { category: "Postleitzahl", value: "74541", alias: "74541" },
  { category: "Postleitzahl", value: "74542", alias: "74542" },
  { category: "Postleitzahl", value: "74544", alias: "74544" },
  { category: "Postleitzahl", value: "74545", alias: "74545" },
  { category: "Postleitzahl", value: "74547", alias: "74547" },
  { category: "Postleitzahl", value: "74549", alias: "74549" },
  { category: "Postleitzahl", value: "74564", alias: "74564" },
  { category: "Postleitzahl", value: "74572", alias: "74572" },
  { category: "Postleitzahl", value: "74575", alias: "74575" },
  { category: "Postleitzahl", value: "74579", alias: "74579" },
  { category: "Postleitzahl", value: "74582", alias: "74582" },
  { category: "Postleitzahl", value: "74585", alias: "74585" },
  { category: "Postleitzahl", value: "74586", alias: "74586" },
  { category: "Postleitzahl", value: "74589", alias: "74589" },
  { category: "Postleitzahl", value: "74592", alias: "74592" },
  { category: "Postleitzahl", value: "74594", alias: "74594" },
  { category: "Postleitzahl", value: "74595", alias: "74595" },
  { category: "Postleitzahl", value: "74597", alias: "74597" },
  { category: "Postleitzahl", value: "74599", alias: "74599" },
  { category: "Postleitzahl", value: "74613", alias: "74613" },
  { category: "Postleitzahl", value: "74626", alias: "74626" },
  { category: "Postleitzahl", value: "74629", alias: "74629" },
  { category: "Postleitzahl", value: "74632", alias: "74632" },
  { category: "Postleitzahl", value: "74635", alias: "74635" },
  { category: "Postleitzahl", value: "74638", alias: "74638" },
  { category: "Postleitzahl", value: "74639", alias: "74639" },
  { category: "Postleitzahl", value: "74653", alias: "74653" },
  { category: "Postleitzahl", value: "74670", alias: "74670" },
  { category: "Postleitzahl", value: "74673", alias: "74673" },
  { category: "Postleitzahl", value: "74676", alias: "74676" },
  { category: "Postleitzahl", value: "74677", alias: "74677" },
  { category: "Postleitzahl", value: "74679", alias: "74679" },
  { category: "Postleitzahl", value: "74706", alias: "74706" },
  { category: "Postleitzahl", value: "74722", alias: "74722" },
  { category: "Postleitzahl", value: "74731", alias: "74731" },
  { category: "Postleitzahl", value: "74736", alias: "74736" },
  { category: "Postleitzahl", value: "74740", alias: "74740" },
  { category: "Postleitzahl", value: "74743", alias: "74743" },
  { category: "Postleitzahl", value: "74744", alias: "74744" },
  { category: "Postleitzahl", value: "74746", alias: "74746" },
  { category: "Postleitzahl", value: "74747", alias: "74747" },
  { category: "Postleitzahl", value: "74749", alias: "74749" },
  { category: "Postleitzahl", value: "74821", alias: "74821" },
  { category: "Postleitzahl", value: "74831", alias: "74831" },
  { category: "Postleitzahl", value: "74834", alias: "74834" },
  { category: "Postleitzahl", value: "74838", alias: "74838" },
  { category: "Postleitzahl", value: "74842", alias: "74842" },
  { category: "Postleitzahl", value: "74847", alias: "74847" },
  { category: "Postleitzahl", value: "74850", alias: "74850" },
  { category: "Postleitzahl", value: "74855", alias: "74855" },
  { category: "Postleitzahl", value: "74858", alias: "74858" },
  { category: "Postleitzahl", value: "74861", alias: "74861" },
  { category: "Postleitzahl", value: "74862", alias: "74862" },
  { category: "Postleitzahl", value: "74864", alias: "74864" },
  { category: "Postleitzahl", value: "74865", alias: "74865" },
  { category: "Postleitzahl", value: "74867", alias: "74867" },
  { category: "Postleitzahl", value: "74869", alias: "74869" },
  { category: "Postleitzahl", value: "74889", alias: "74889" },
  { category: "Postleitzahl", value: "74906", alias: "74906" },
  { category: "Postleitzahl", value: "74909", alias: "74909" },
  { category: "Postleitzahl", value: "74912", alias: "74912" },
  { category: "Postleitzahl", value: "74915", alias: "74915" },
  { category: "Postleitzahl", value: "74918", alias: "74918" },
  { category: "Postleitzahl", value: "74921", alias: "74921" },
  { category: "Postleitzahl", value: "74924", alias: "74924" },
  { category: "Postleitzahl", value: "74925", alias: "74925" },
  { category: "Postleitzahl", value: "74927", alias: "74927" },
  { category: "Postleitzahl", value: "74928", alias: "74928" },
  { category: "Postleitzahl", value: "74930", alias: "74930" },
  { category: "Postleitzahl", value: "74931", alias: "74931" },
  { category: "Postleitzahl", value: "74933", alias: "74933" },
  { category: "Postleitzahl", value: "74934", alias: "74934" },
  { category: "Postleitzahl", value: "74936", alias: "74936" },
  { category: "Postleitzahl", value: "74937", alias: "74937" },
  { category: "Postleitzahl", value: "74939", alias: "74939" },
  { category: "Postleitzahl", value: "75015", alias: "75015" },
  { category: "Postleitzahl", value: "75031", alias: "75031" },
  { category: "Postleitzahl", value: "75038", alias: "75038" },
  { category: "Postleitzahl", value: "75045", alias: "75045" },
  { category: "Postleitzahl", value: "75050", alias: "75050" },
  { category: "Postleitzahl", value: "75053", alias: "75053" },
  { category: "Postleitzahl", value: "75056", alias: "75056" },
  { category: "Postleitzahl", value: "75057", alias: "75057" },
  { category: "Postleitzahl", value: "75059", alias: "75059" },
  { category: "Postleitzahl", value: "75172", alias: "75172" },
  { category: "Postleitzahl", value: "75173", alias: "75173" },
  { category: "Postleitzahl", value: "75175", alias: "75175" },
  { category: "Postleitzahl", value: "75177", alias: "75177" },
  { category: "Postleitzahl", value: "75179", alias: "75179" },
  { category: "Postleitzahl", value: "75180", alias: "75180" },
  { category: "Postleitzahl", value: "75181", alias: "75181" },
  { category: "Postleitzahl", value: "75196", alias: "75196" },
  { category: "Postleitzahl", value: "75203", alias: "75203" },
  { category: "Postleitzahl", value: "75210", alias: "75210" },
  { category: "Postleitzahl", value: "75217", alias: "75217" },
  { category: "Postleitzahl", value: "75223", alias: "75223" },
  { category: "Postleitzahl", value: "75228", alias: "75228" },
  { category: "Postleitzahl", value: "75233", alias: "75233" },
  { category: "Postleitzahl", value: "75236", alias: "75236" },
  { category: "Postleitzahl", value: "75239", alias: "75239" },
  { category: "Postleitzahl", value: "75242", alias: "75242" },
  { category: "Postleitzahl", value: "75245", alias: "75245" },
  { category: "Postleitzahl", value: "75248", alias: "75248" },
  { category: "Postleitzahl", value: "75249", alias: "75249" },
  { category: "Postleitzahl", value: "75305", alias: "75305" },
  { category: "Postleitzahl", value: "75323", alias: "75323" },
  { category: "Postleitzahl", value: "75328", alias: "75328" },
  { category: "Postleitzahl", value: "75331", alias: "75331" },
  { category: "Postleitzahl", value: "75334", alias: "75334" },
  { category: "Postleitzahl", value: "75335", alias: "75335" },
  { category: "Postleitzahl", value: "75337", alias: "75337" },
  { category: "Postleitzahl", value: "75339", alias: "75339" },
  { category: "Postleitzahl", value: "75365", alias: "75365" },
  { category: "Postleitzahl", value: "75378", alias: "75378" },
  { category: "Postleitzahl", value: "75382", alias: "75382" },
  { category: "Postleitzahl", value: "75385", alias: "75385" },
  { category: "Postleitzahl", value: "75387", alias: "75387" },
  { category: "Postleitzahl", value: "75389", alias: "75389" },
  { category: "Postleitzahl", value: "75391", alias: "75391" },
  { category: "Postleitzahl", value: "75392", alias: "75392" },
  { category: "Postleitzahl", value: "75394", alias: "75394" },
  { category: "Postleitzahl", value: "75395", alias: "75395" },
  { category: "Postleitzahl", value: "75397", alias: "75397" },
  { category: "Postleitzahl", value: "75399", alias: "75399" },
  { category: "Postleitzahl", value: "75417", alias: "75417" },
  { category: "Postleitzahl", value: "75428", alias: "75428" },
  { category: "Postleitzahl", value: "75433", alias: "75433" },
  { category: "Postleitzahl", value: "75438", alias: "75438" },
  { category: "Postleitzahl", value: "75443", alias: "75443" },
  { category: "Postleitzahl", value: "75446", alias: "75446" },
  { category: "Postleitzahl", value: "75447", alias: "75447" },
  { category: "Postleitzahl", value: "75449", alias: "75449" },
  { category: "Postleitzahl", value: "76131", alias: "76131" },
  { category: "Postleitzahl", value: "76133", alias: "76133" },
  { category: "Postleitzahl", value: "76135", alias: "76135" },
  { category: "Postleitzahl", value: "76137", alias: "76137" },
  { category: "Postleitzahl", value: "76139", alias: "76139" },
  { category: "Postleitzahl", value: "76149", alias: "76149" },
  { category: "Postleitzahl", value: "76185", alias: "76185" },
  { category: "Postleitzahl", value: "76187", alias: "76187" },
  { category: "Postleitzahl", value: "76189", alias: "76189" },
  { category: "Postleitzahl", value: "76199", alias: "76199" },
  { category: "Postleitzahl", value: "76227", alias: "76227" },
  { category: "Postleitzahl", value: "76228", alias: "76228" },
  { category: "Postleitzahl", value: "76229", alias: "76229" },
  { category: "Postleitzahl", value: "76275", alias: "76275" },
  { category: "Postleitzahl", value: "76287", alias: "76287" },
  { category: "Postleitzahl", value: "76297", alias: "76297" },
  { category: "Postleitzahl", value: "76307", alias: "76307" },
  { category: "Postleitzahl", value: "76316", alias: "76316" },
  { category: "Postleitzahl", value: "76327", alias: "76327" },
  { category: "Postleitzahl", value: "76332", alias: "76332" },
  { category: "Postleitzahl", value: "76337", alias: "76337" },
  { category: "Postleitzahl", value: "76344", alias: "76344" },
  { category: "Postleitzahl", value: "76351", alias: "76351" },
  { category: "Postleitzahl", value: "76356", alias: "76356" },
  { category: "Postleitzahl", value: "76359", alias: "76359" },
  { category: "Postleitzahl", value: "76437", alias: "76437" },
  { category: "Postleitzahl", value: "76448", alias: "76448" },
  { category: "Postleitzahl", value: "76456", alias: "76456" },
  { category: "Postleitzahl", value: "76461", alias: "76461" },
  { category: "Postleitzahl", value: "76467", alias: "76467" },
  { category: "Postleitzahl", value: "76470", alias: "76470" },
  { category: "Postleitzahl", value: "76473", alias: "76473" },
  { category: "Postleitzahl", value: "76474", alias: "76474" },
  { category: "Postleitzahl", value: "76476", alias: "76476" },
  { category: "Postleitzahl", value: "76477", alias: "76477" },
  { category: "Postleitzahl", value: "76479", alias: "76479" },
  { category: "Postleitzahl", value: "76530", alias: "76530" },
  { category: "Postleitzahl", value: "76532", alias: "76532" },
  { category: "Postleitzahl", value: "76534", alias: "76534" },
  { category: "Postleitzahl", value: "76547", alias: "76547" },
  { category: "Postleitzahl", value: "76549", alias: "76549" },
  { category: "Postleitzahl", value: "76571", alias: "76571" },
  { category: "Postleitzahl", value: "76593", alias: "76593" },
  { category: "Postleitzahl", value: "76596", alias: "76596" },
  { category: "Postleitzahl", value: "76597", alias: "76597" },
  { category: "Postleitzahl", value: "76599", alias: "76599" },
  { category: "Postleitzahl", value: "76646", alias: "76646" },
  { category: "Postleitzahl", value: "76661", alias: "76661" },
  { category: "Postleitzahl", value: "76669", alias: "76669" },
  { category: "Postleitzahl", value: "76676", alias: "76676" },
  { category: "Postleitzahl", value: "76684", alias: "76684" },
  { category: "Postleitzahl", value: "76689", alias: "76689" },
  { category: "Postleitzahl", value: "76694", alias: "76694" },
  { category: "Postleitzahl", value: "76698", alias: "76698" },
  { category: "Postleitzahl", value: "76703", alias: "76703" },
  { category: "Postleitzahl", value: "76706", alias: "76706" },
  { category: "Postleitzahl", value: "76707", alias: "76707" },
  { category: "Postleitzahl", value: "76709", alias: "76709" },
  { category: "Postleitzahl", value: "76726", alias: "76726" },
  { category: "Postleitzahl", value: "76744", alias: "76744" },
  { category: "Postleitzahl", value: "76751", alias: "76751" },
  { category: "Postleitzahl", value: "76756", alias: "76756" },
  { category: "Postleitzahl", value: "76761", alias: "76761" },
  { category: "Postleitzahl", value: "76764", alias: "76764" },
  { category: "Postleitzahl", value: "76767", alias: "76767" },
  { category: "Postleitzahl", value: "76768", alias: "76768" },
  { category: "Postleitzahl", value: "76770", alias: "76770" },
  { category: "Postleitzahl", value: "76771", alias: "76771" },
  { category: "Postleitzahl", value: "76773", alias: "76773" },
  { category: "Postleitzahl", value: "76774", alias: "76774" },
  { category: "Postleitzahl", value: "76776", alias: "76776" },
  { category: "Postleitzahl", value: "76777", alias: "76777" },
  { category: "Postleitzahl", value: "76779", alias: "76779" },
  { category: "Postleitzahl", value: "76829", alias: "76829" },
  { category: "Postleitzahl", value: "76831", alias: "76831" },
  { category: "Postleitzahl", value: "76833", alias: "76833" },
  { category: "Postleitzahl", value: "76835", alias: "76835" },
  { category: "Postleitzahl", value: "76846", alias: "76846" },
  { category: "Postleitzahl", value: "76848", alias: "76848" },
  { category: "Postleitzahl", value: "76855", alias: "76855" },
  { category: "Postleitzahl", value: "76857", alias: "76857" },
  { category: "Postleitzahl", value: "76863", alias: "76863" },
  { category: "Postleitzahl", value: "76865", alias: "76865" },
  { category: "Postleitzahl", value: "76870", alias: "76870" },
  { category: "Postleitzahl", value: "76872", alias: "76872" },
  { category: "Postleitzahl", value: "76877", alias: "76877" },
  { category: "Postleitzahl", value: "76879", alias: "76879" },
  { category: "Postleitzahl", value: "76887", alias: "76887" },
  { category: "Postleitzahl", value: "76889", alias: "76889" },
  { category: "Postleitzahl", value: "76891", alias: "76891" },
  { category: "Postleitzahl", value: "77652", alias: "77652" },
  { category: "Postleitzahl", value: "77654", alias: "77654" },
  { category: "Postleitzahl", value: "77656", alias: "77656" },
  { category: "Postleitzahl", value: "77694", alias: "77694" },
  { category: "Postleitzahl", value: "77704", alias: "77704" },
  { category: "Postleitzahl", value: "77709", alias: "77709" },
  { category: "Postleitzahl", value: "77716", alias: "77716" },
  { category: "Postleitzahl", value: "77723", alias: "77723" },
  { category: "Postleitzahl", value: "77728", alias: "77728" },
  { category: "Postleitzahl", value: "77731", alias: "77731" },
  { category: "Postleitzahl", value: "77736", alias: "77736" },
  { category: "Postleitzahl", value: "77740", alias: "77740" },
  { category: "Postleitzahl", value: "77743", alias: "77743" },
  { category: "Postleitzahl", value: "77746", alias: "77746" },
  { category: "Postleitzahl", value: "77749", alias: "77749" },
  { category: "Postleitzahl", value: "77756", alias: "77756" },
  { category: "Postleitzahl", value: "77761", alias: "77761" },
  { category: "Postleitzahl", value: "77767", alias: "77767" },
  { category: "Postleitzahl", value: "77770", alias: "77770" },
  { category: "Postleitzahl", value: "77773", alias: "77773" },
  { category: "Postleitzahl", value: "77776", alias: "77776" },
  { category: "Postleitzahl", value: "77781", alias: "77781" },
  { category: "Postleitzahl", value: "77784", alias: "77784" },
  { category: "Postleitzahl", value: "77787", alias: "77787" },
  { category: "Postleitzahl", value: "77790", alias: "77790" },
  { category: "Postleitzahl", value: "77791", alias: "77791" },
  { category: "Postleitzahl", value: "77793", alias: "77793" },
  { category: "Postleitzahl", value: "77794", alias: "77794" },
  { category: "Postleitzahl", value: "77796", alias: "77796" },
  { category: "Postleitzahl", value: "77797", alias: "77797" },
  { category: "Postleitzahl", value: "77799", alias: "77799" },
  { category: "Postleitzahl", value: "77815", alias: "77815" },
  { category: "Postleitzahl", value: "77830", alias: "77830" },
  { category: "Postleitzahl", value: "77833", alias: "77833" },
  { category: "Postleitzahl", value: "77836", alias: "77836" },
  { category: "Postleitzahl", value: "77839", alias: "77839" },
  { category: "Postleitzahl", value: "77855", alias: "77855" },
  { category: "Postleitzahl", value: "77866", alias: "77866" },
  { category: "Postleitzahl", value: "77871", alias: "77871" },
  { category: "Postleitzahl", value: "77876", alias: "77876" },
  { category: "Postleitzahl", value: "77880", alias: "77880" },
  { category: "Postleitzahl", value: "77883", alias: "77883" },
  { category: "Postleitzahl", value: "77886", alias: "77886" },
  { category: "Postleitzahl", value: "77887", alias: "77887" },
  { category: "Postleitzahl", value: "77889", alias: "77889" },
  { category: "Postleitzahl", value: "77933", alias: "77933" },
  { category: "Postleitzahl", value: "77948", alias: "77948" },
  { category: "Postleitzahl", value: "77955", alias: "77955" },
  { category: "Postleitzahl", value: "77960", alias: "77960" },
  { category: "Postleitzahl", value: "77963", alias: "77963" },
  { category: "Postleitzahl", value: "77966", alias: "77966" },
  { category: "Postleitzahl", value: "77971", alias: "77971" },
  { category: "Postleitzahl", value: "77972", alias: "77972" },
  { category: "Postleitzahl", value: "77974", alias: "77974" },
  { category: "Postleitzahl", value: "77975", alias: "77975" },
  { category: "Postleitzahl", value: "77977", alias: "77977" },
  { category: "Postleitzahl", value: "77978", alias: "77978" },
  { category: "Postleitzahl", value: "78048", alias: "78048" },
  { category: "Postleitzahl", value: "78050", alias: "78050" },
  { category: "Postleitzahl", value: "78052", alias: "78052" },
  { category: "Postleitzahl", value: "78054", alias: "78054" },
  { category: "Postleitzahl", value: "78056", alias: "78056" },
  { category: "Postleitzahl", value: "78073", alias: "78073" },
  { category: "Postleitzahl", value: "78078", alias: "78078" },
  { category: "Postleitzahl", value: "78083", alias: "78083" },
  { category: "Postleitzahl", value: "78086", alias: "78086" },
  { category: "Postleitzahl", value: "78087", alias: "78087" },
  { category: "Postleitzahl", value: "78089", alias: "78089" },
  { category: "Postleitzahl", value: "78098", alias: "78098" },
  { category: "Postleitzahl", value: "78112", alias: "78112" },
  { category: "Postleitzahl", value: "78120", alias: "78120" },
  { category: "Postleitzahl", value: "78126", alias: "78126" },
  { category: "Postleitzahl", value: "78132", alias: "78132" },
  { category: "Postleitzahl", value: "78136", alias: "78136" },
  { category: "Postleitzahl", value: "78141", alias: "78141" },
  { category: "Postleitzahl", value: "78144", alias: "78144" },
  { category: "Postleitzahl", value: "78147", alias: "78147" },
  { category: "Postleitzahl", value: "78148", alias: "78148" },
  { category: "Postleitzahl", value: "78166", alias: "78166" },
  { category: "Postleitzahl", value: "78176", alias: "78176" },
  { category: "Postleitzahl", value: "78183", alias: "78183" },
  { category: "Postleitzahl", value: "78187", alias: "78187" },
  { category: "Postleitzahl", value: "78194", alias: "78194" },
  { category: "Postleitzahl", value: "78199", alias: "78199" },
  { category: "Postleitzahl", value: "78224", alias: "78224" },
  { category: "Postleitzahl", value: "78234", alias: "78234" },
  { category: "Postleitzahl", value: "78239", alias: "78239" },
  { category: "Postleitzahl", value: "78244", alias: "78244" },
  { category: "Postleitzahl", value: "78247", alias: "78247" },
  { category: "Postleitzahl", value: "78250", alias: "78250" },
  { category: "Postleitzahl", value: "78253", alias: "78253" },
  { category: "Postleitzahl", value: "78256", alias: "78256" },
  { category: "Postleitzahl", value: "78259", alias: "78259" },
  { category: "Postleitzahl", value: "78262", alias: "78262" },
  { category: "Postleitzahl", value: "78266", alias: "78266" },
  { category: "Postleitzahl", value: "78267", alias: "78267" },
  { category: "Postleitzahl", value: "78269", alias: "78269" },
  { category: "Postleitzahl", value: "78315", alias: "78315" },
  { category: "Postleitzahl", value: "78333", alias: "78333" },
  { category: "Postleitzahl", value: "78337", alias: "78337" },
  { category: "Postleitzahl", value: "78343", alias: "78343" },
  { category: "Postleitzahl", value: "78345", alias: "78345" },
  { category: "Postleitzahl", value: "78351", alias: "78351" },
  { category: "Postleitzahl", value: "78354", alias: "78354" },
  { category: "Postleitzahl", value: "78355", alias: "78355" },
  { category: "Postleitzahl", value: "78357", alias: "78357" },
  { category: "Postleitzahl", value: "78359", alias: "78359" },
  { category: "Postleitzahl", value: "78462", alias: "78462" },
  { category: "Postleitzahl", value: "78464", alias: "78464" },
  { category: "Postleitzahl", value: "78465", alias: "78465" },
  { category: "Postleitzahl", value: "78467", alias: "78467" },
  { category: "Postleitzahl", value: "78476", alias: "78476" },
  { category: "Postleitzahl", value: "78479", alias: "78479" },
  { category: "Postleitzahl", value: "78532", alias: "78532" },
  { category: "Postleitzahl", value: "78549", alias: "78549" },
  { category: "Postleitzahl", value: "78554", alias: "78554" },
  { category: "Postleitzahl", value: "78559", alias: "78559" },
  { category: "Postleitzahl", value: "78564", alias: "78564" },
  { category: "Postleitzahl", value: "78567", alias: "78567" },
  { category: "Postleitzahl", value: "78570", alias: "78570" },
  { category: "Postleitzahl", value: "78573", alias: "78573" },
  { category: "Postleitzahl", value: "78576", alias: "78576" },
  { category: "Postleitzahl", value: "78579", alias: "78579" },
  { category: "Postleitzahl", value: "78580", alias: "78580" },
  { category: "Postleitzahl", value: "78582", alias: "78582" },
  { category: "Postleitzahl", value: "78583", alias: "78583" },
  { category: "Postleitzahl", value: "78585", alias: "78585" },
  { category: "Postleitzahl", value: "78586", alias: "78586" },
  { category: "Postleitzahl", value: "78588", alias: "78588" },
  { category: "Postleitzahl", value: "78589", alias: "78589" },
  { category: "Postleitzahl", value: "78591", alias: "78591" },
  { category: "Postleitzahl", value: "78592", alias: "78592" },
  { category: "Postleitzahl", value: "78594", alias: "78594" },
  { category: "Postleitzahl", value: "78595", alias: "78595" },
  { category: "Postleitzahl", value: "78597", alias: "78597" },
  { category: "Postleitzahl", value: "78598", alias: "78598" },
  { category: "Postleitzahl", value: "78600", alias: "78600" },
  { category: "Postleitzahl", value: "78601", alias: "78601" },
  { category: "Postleitzahl", value: "78603", alias: "78603" },
  { category: "Postleitzahl", value: "78604", alias: "78604" },
  { category: "Postleitzahl", value: "78606", alias: "78606" },
  { category: "Postleitzahl", value: "78607", alias: "78607" },
  { category: "Postleitzahl", value: "78609", alias: "78609" },
  { category: "Postleitzahl", value: "78628", alias: "78628" },
  { category: "Postleitzahl", value: "78647", alias: "78647" },
  { category: "Postleitzahl", value: "78652", alias: "78652" },
  { category: "Postleitzahl", value: "78655", alias: "78655" },
  { category: "Postleitzahl", value: "78658", alias: "78658" },
  { category: "Postleitzahl", value: "78661", alias: "78661" },
  { category: "Postleitzahl", value: "78662", alias: "78662" },
  { category: "Postleitzahl", value: "78664", alias: "78664" },
  { category: "Postleitzahl", value: "78665", alias: "78665" },
  { category: "Postleitzahl", value: "78667", alias: "78667" },
  { category: "Postleitzahl", value: "78669", alias: "78669" },
  { category: "Postleitzahl", value: "78713", alias: "78713" },
  { category: "Postleitzahl", value: "78727", alias: "78727" },
  { category: "Postleitzahl", value: "78730", alias: "78730" },
  { category: "Postleitzahl", value: "78733", alias: "78733" },
  { category: "Postleitzahl", value: "78736", alias: "78736" },
  { category: "Postleitzahl", value: "78737", alias: "78737" },
  { category: "Postleitzahl", value: "78739", alias: "78739" },
  { category: "Postleitzahl", value: "79098", alias: "79098" },
  { category: "Postleitzahl", value: "79100", alias: "79100" },
  { category: "Postleitzahl", value: "79102", alias: "79102" },
  { category: "Postleitzahl", value: "79104", alias: "79104" },
  { category: "Postleitzahl", value: "79106", alias: "79106" },
  { category: "Postleitzahl", value: "79108", alias: "79108" },
  { category: "Postleitzahl", value: "79110", alias: "79110" },
  { category: "Postleitzahl", value: "79111", alias: "79111" },
  { category: "Postleitzahl", value: "79112", alias: "79112" },
  { category: "Postleitzahl", value: "79114", alias: "79114" },
  { category: "Postleitzahl", value: "79115", alias: "79115" },
  { category: "Postleitzahl", value: "79117", alias: "79117" },
  { category: "Postleitzahl", value: "79183", alias: "79183" },
  { category: "Postleitzahl", value: "79189", alias: "79189" },
  { category: "Postleitzahl", value: "79194", alias: "79194" },
  { category: "Postleitzahl", value: "79199", alias: "79199" },
  { category: "Postleitzahl", value: "79206", alias: "79206" },
  { category: "Postleitzahl", value: "79211", alias: "79211" },
  { category: "Postleitzahl", value: "79215", alias: "79215" },
  { category: "Postleitzahl", value: "79219", alias: "79219" },
  { category: "Postleitzahl", value: "79224", alias: "79224" },
  { category: "Postleitzahl", value: "79227", alias: "79227" },
  { category: "Postleitzahl", value: "79232", alias: "79232" },
  { category: "Postleitzahl", value: "79235", alias: "79235" },
  { category: "Postleitzahl", value: "79238", alias: "79238" },
  { category: "Postleitzahl", value: "79241", alias: "79241" },
  { category: "Postleitzahl", value: "79244", alias: "79244" },
  { category: "Postleitzahl", value: "79249", alias: "79249" },
  { category: "Postleitzahl", value: "79252", alias: "79252" },
  { category: "Postleitzahl", value: "79254", alias: "79254" },
  { category: "Postleitzahl", value: "79256", alias: "79256" },
  { category: "Postleitzahl", value: "79258", alias: "79258" },
  { category: "Postleitzahl", value: "79261", alias: "79261" },
  { category: "Postleitzahl", value: "79263", alias: "79263" },
  { category: "Postleitzahl", value: "79268", alias: "79268" },
  { category: "Postleitzahl", value: "79271", alias: "79271" },
  { category: "Postleitzahl", value: "79274", alias: "79274" },
  { category: "Postleitzahl", value: "79276", alias: "79276" },
  { category: "Postleitzahl", value: "79279", alias: "79279" },
  { category: "Postleitzahl", value: "79280", alias: "79280" },
  { category: "Postleitzahl", value: "79282", alias: "79282" },
  { category: "Postleitzahl", value: "79283", alias: "79283" },
  { category: "Postleitzahl", value: "79285", alias: "79285" },
  { category: "Postleitzahl", value: "79286", alias: "79286" },
  { category: "Postleitzahl", value: "79288", alias: "79288" },
  { category: "Postleitzahl", value: "79289", alias: "79289" },
  { category: "Postleitzahl", value: "79291", alias: "79291" },
  { category: "Postleitzahl", value: "79292", alias: "79292" },
  { category: "Postleitzahl", value: "79294", alias: "79294" },
  { category: "Postleitzahl", value: "79295", alias: "79295" },
  { category: "Postleitzahl", value: "79297", alias: "79297" },
  { category: "Postleitzahl", value: "79299", alias: "79299" },
  { category: "Postleitzahl", value: "79312", alias: "79312" },
  { category: "Postleitzahl", value: "79331", alias: "79331" },
  { category: "Postleitzahl", value: "79336", alias: "79336" },
  { category: "Postleitzahl", value: "79341", alias: "79341" },
  { category: "Postleitzahl", value: "79346", alias: "79346" },
  { category: "Postleitzahl", value: "79348", alias: "79348" },
  { category: "Postleitzahl", value: "79350", alias: "79350" },
  { category: "Postleitzahl", value: "79353", alias: "79353" },
  { category: "Postleitzahl", value: "79356", alias: "79356" },
  { category: "Postleitzahl", value: "79359", alias: "79359" },
  { category: "Postleitzahl", value: "79361", alias: "79361" },
  { category: "Postleitzahl", value: "79362", alias: "79362" },
  { category: "Postleitzahl", value: "79364", alias: "79364" },
  { category: "Postleitzahl", value: "79365", alias: "79365" },
  { category: "Postleitzahl", value: "79367", alias: "79367" },
  { category: "Postleitzahl", value: "79369", alias: "79369" },
  { category: "Postleitzahl", value: "79379", alias: "79379" },
  { category: "Postleitzahl", value: "79395", alias: "79395" },
  { category: "Postleitzahl", value: "79400", alias: "79400" },
  { category: "Postleitzahl", value: "79410", alias: "79410" },
  { category: "Postleitzahl", value: "79415", alias: "79415" },
  { category: "Postleitzahl", value: "79418", alias: "79418" },
  { category: "Postleitzahl", value: "79423", alias: "79423" },
  { category: "Postleitzahl", value: "79424", alias: "79424" },
  { category: "Postleitzahl", value: "79426", alias: "79426" },
  { category: "Postleitzahl", value: "79427", alias: "79427" },
  { category: "Postleitzahl", value: "79429", alias: "79429" },
  { category: "Postleitzahl", value: "79539", alias: "79539" },
  { category: "Postleitzahl", value: "79540", alias: "79540" },
  { category: "Postleitzahl", value: "79541", alias: "79541" },
  { category: "Postleitzahl", value: "79576", alias: "79576" },
  { category: "Postleitzahl", value: "79585", alias: "79585" },
  { category: "Postleitzahl", value: "79588", alias: "79588" },
  { category: "Postleitzahl", value: "79589", alias: "79589" },
  { category: "Postleitzahl", value: "79591", alias: "79591" },
  { category: "Postleitzahl", value: "79592", alias: "79592" },
  { category: "Postleitzahl", value: "79594", alias: "79594" },
  { category: "Postleitzahl", value: "79595", alias: "79595" },
  { category: "Postleitzahl", value: "79597", alias: "79597" },
  { category: "Postleitzahl", value: "79599", alias: "79599" },
  { category: "Postleitzahl", value: "79618", alias: "79618" },
  { category: "Postleitzahl", value: "79639", alias: "79639" },
  { category: "Postleitzahl", value: "79650", alias: "79650" },
  { category: "Postleitzahl", value: "79664", alias: "79664" },
  { category: "Postleitzahl", value: "79669", alias: "79669" },
  { category: "Postleitzahl", value: "79674", alias: "79674" },
  { category: "Postleitzahl", value: "79677", alias: "79677" },
  { category: "Postleitzahl", value: "79682", alias: "79682" },
  { category: "Postleitzahl", value: "79685", alias: "79685" },
  { category: "Postleitzahl", value: "79686", alias: "79686" },
  { category: "Postleitzahl", value: "79688", alias: "79688" },
  { category: "Postleitzahl", value: "79689", alias: "79689" },
  { category: "Postleitzahl", value: "79692", alias: "79692" },
  { category: "Postleitzahl", value: "79694", alias: "79694" },
  { category: "Postleitzahl", value: "79695", alias: "79695" },
  { category: "Postleitzahl", value: "79713", alias: "79713" },
  { category: "Postleitzahl", value: "79725", alias: "79725" },
  { category: "Postleitzahl", value: "79730", alias: "79730" },
  { category: "Postleitzahl", value: "79733", alias: "79733" },
  { category: "Postleitzahl", value: "79736", alias: "79736" },
  { category: "Postleitzahl", value: "79737", alias: "79737" },
  { category: "Postleitzahl", value: "79739", alias: "79739" },
  { category: "Postleitzahl", value: "79761", alias: "79761" },
  { category: "Postleitzahl", value: "79771", alias: "79771" },
  { category: "Postleitzahl", value: "79774", alias: "79774" },
  { category: "Postleitzahl", value: "79777", alias: "79777" },
  { category: "Postleitzahl", value: "79780", alias: "79780" },
  { category: "Postleitzahl", value: "79787", alias: "79787" },
  { category: "Postleitzahl", value: "79790", alias: "79790" },
  { category: "Postleitzahl", value: "79793", alias: "79793" },
  { category: "Postleitzahl", value: "79798", alias: "79798" },
  { category: "Postleitzahl", value: "79801", alias: "79801" },
  { category: "Postleitzahl", value: "79802", alias: "79802" },
  { category: "Postleitzahl", value: "79804", alias: "79804" },
  { category: "Postleitzahl", value: "79805", alias: "79805" },
  { category: "Postleitzahl", value: "79807", alias: "79807" },
  { category: "Postleitzahl", value: "79809", alias: "79809" },
  { category: "Postleitzahl", value: "79822", alias: "79822" },
  { category: "Postleitzahl", value: "79837", alias: "79837" },
  { category: "Postleitzahl", value: "79843", alias: "79843" },
  { category: "Postleitzahl", value: "79848", alias: "79848" },
  { category: "Postleitzahl", value: "79853", alias: "79853" },
  { category: "Postleitzahl", value: "79856", alias: "79856" },
  { category: "Postleitzahl", value: "79859", alias: "79859" },
  { category: "Postleitzahl", value: "79862", alias: "79862" },
  { category: "Postleitzahl", value: "79865", alias: "79865" },
  { category: "Postleitzahl", value: "79868", alias: "79868" },
  { category: "Postleitzahl", value: "79871", alias: "79871" },
  { category: "Postleitzahl", value: "79872", alias: "79872" },
  { category: "Postleitzahl", value: "79874", alias: "79874" },
  { category: "Postleitzahl", value: "79875", alias: "79875" },
  { category: "Postleitzahl", value: "79877", alias: "79877" },
  { category: "Postleitzahl", value: "79879", alias: "79879" },
  { category: "Postleitzahl", value: "80331", alias: "80331" },
  { category: "Postleitzahl", value: "80333", alias: "80333" },
  { category: "Postleitzahl", value: "80335", alias: "80335" },
  { category: "Postleitzahl", value: "80336", alias: "80336" },
  { category: "Postleitzahl", value: "80337", alias: "80337" },
  { category: "Postleitzahl", value: "80339", alias: "80339" },
  { category: "Postleitzahl", value: "80469", alias: "80469" },
  { category: "Postleitzahl", value: "80538", alias: "80538" },
  { category: "Postleitzahl", value: "80539", alias: "80539" },
  { category: "Postleitzahl", value: "80634", alias: "80634" },
  { category: "Postleitzahl", value: "80636", alias: "80636" },
  { category: "Postleitzahl", value: "80637", alias: "80637" },
  { category: "Postleitzahl", value: "80638", alias: "80638" },
  { category: "Postleitzahl", value: "80639", alias: "80639" },
  { category: "Postleitzahl", value: "80686", alias: "80686" },
  { category: "Postleitzahl", value: "80687", alias: "80687" },
  { category: "Postleitzahl", value: "80689", alias: "80689" },
  { category: "Postleitzahl", value: "80796", alias: "80796" },
  { category: "Postleitzahl", value: "80797", alias: "80797" },
  { category: "Postleitzahl", value: "80798", alias: "80798" },
  { category: "Postleitzahl", value: "80799", alias: "80799" },
  { category: "Postleitzahl", value: "80801", alias: "80801" },
  { category: "Postleitzahl", value: "80802", alias: "80802" },
  { category: "Postleitzahl", value: "80803", alias: "80803" },
  { category: "Postleitzahl", value: "80804", alias: "80804" },
  { category: "Postleitzahl", value: "80805", alias: "80805" },
  { category: "Postleitzahl", value: "80807", alias: "80807" },
  { category: "Postleitzahl", value: "80809", alias: "80809" },
  { category: "Postleitzahl", value: "80933", alias: "80933" },
  { category: "Postleitzahl", value: "80935", alias: "80935" },
  { category: "Postleitzahl", value: "80937", alias: "80937" },
  { category: "Postleitzahl", value: "80939", alias: "80939" },
  { category: "Postleitzahl", value: "80992", alias: "80992" },
  { category: "Postleitzahl", value: "80993", alias: "80993" },
  { category: "Postleitzahl", value: "80995", alias: "80995" },
  { category: "Postleitzahl", value: "80997", alias: "80997" },
  { category: "Postleitzahl", value: "80999", alias: "80999" },
  { category: "Postleitzahl", value: "81241", alias: "81241" },
  { category: "Postleitzahl", value: "81243", alias: "81243" },
  { category: "Postleitzahl", value: "81245", alias: "81245" },
  { category: "Postleitzahl", value: "81247", alias: "81247" },
  { category: "Postleitzahl", value: "81248", alias: "81248" },
  { category: "Postleitzahl", value: "81249", alias: "81249" },
  { category: "Postleitzahl", value: "81369", alias: "81369" },
  { category: "Postleitzahl", value: "81371", alias: "81371" },
  { category: "Postleitzahl", value: "81373", alias: "81373" },
  { category: "Postleitzahl", value: "81375", alias: "81375" },
  { category: "Postleitzahl", value: "81377", alias: "81377" },
  { category: "Postleitzahl", value: "81379", alias: "81379" },
  { category: "Postleitzahl", value: "81475", alias: "81475" },
  { category: "Postleitzahl", value: "81476", alias: "81476" },
  { category: "Postleitzahl", value: "81477", alias: "81477" },
  { category: "Postleitzahl", value: "81479", alias: "81479" },
  { category: "Postleitzahl", value: "81539", alias: "81539" },
  { category: "Postleitzahl", value: "81541", alias: "81541" },
  { category: "Postleitzahl", value: "81543", alias: "81543" },
  { category: "Postleitzahl", value: "81545", alias: "81545" },
  { category: "Postleitzahl", value: "81547", alias: "81547" },
  { category: "Postleitzahl", value: "81549", alias: "81549" },
  { category: "Postleitzahl", value: "81667", alias: "81667" },
  { category: "Postleitzahl", value: "81669", alias: "81669" },
  { category: "Postleitzahl", value: "81671", alias: "81671" },
  { category: "Postleitzahl", value: "81673", alias: "81673" },
  { category: "Postleitzahl", value: "81675", alias: "81675" },
  { category: "Postleitzahl", value: "81677", alias: "81677" },
  { category: "Postleitzahl", value: "81679", alias: "81679" },
  { category: "Postleitzahl", value: "81735", alias: "81735" },
  { category: "Postleitzahl", value: "81737", alias: "81737" },
  { category: "Postleitzahl", value: "81739", alias: "81739" },
  { category: "Postleitzahl", value: "81825", alias: "81825" },
  { category: "Postleitzahl", value: "81827", alias: "81827" },
  { category: "Postleitzahl", value: "81829", alias: "81829" },
  { category: "Postleitzahl", value: "81925", alias: "81925" },
  { category: "Postleitzahl", value: "81927", alias: "81927" },
  { category: "Postleitzahl", value: "81929", alias: "81929" },
  { category: "Postleitzahl", value: "82008", alias: "82008" },
  { category: "Postleitzahl", value: "82024", alias: "82024" },
  { category: "Postleitzahl", value: "82031", alias: "82031" },
  { category: "Postleitzahl", value: "82041", alias: "82041" },
  { category: "Postleitzahl", value: "82049", alias: "82049" },
  { category: "Postleitzahl", value: "82054", alias: "82054" },
  { category: "Postleitzahl", value: "82057", alias: "82057" },
  { category: "Postleitzahl", value: "82061", alias: "82061" },
  { category: "Postleitzahl", value: "82064", alias: "82064" },
  { category: "Postleitzahl", value: "82065", alias: "82065" },
  { category: "Postleitzahl", value: "82067", alias: "82067" },
  { category: "Postleitzahl", value: "82069", alias: "82069" },
  { category: "Postleitzahl", value: "82110", alias: "82110" },
  { category: "Postleitzahl", value: "82131", alias: "82131" },
  { category: "Postleitzahl", value: "82140", alias: "82140" },
  { category: "Postleitzahl", value: "82152", alias: "82152" },
  { category: "Postleitzahl", value: "82166", alias: "82166" },
  { category: "Postleitzahl", value: "82178", alias: "82178" },
  { category: "Postleitzahl", value: "82194", alias: "82194" },
  { category: "Postleitzahl", value: "82205", alias: "82205" },
  { category: "Postleitzahl", value: "82211", alias: "82211" },
  { category: "Postleitzahl", value: "82216", alias: "82216" },
  { category: "Postleitzahl", value: "82223", alias: "82223" },
  { category: "Postleitzahl", value: "82229", alias: "82229" },
  { category: "Postleitzahl", value: "82234", alias: "82234" },
  { category: "Postleitzahl", value: "82237", alias: "82237" },
  { category: "Postleitzahl", value: "82239", alias: "82239" },
  { category: "Postleitzahl", value: "82256", alias: "82256" },
  { category: "Postleitzahl", value: "82266", alias: "82266" },
  { category: "Postleitzahl", value: "82269", alias: "82269" },
  { category: "Postleitzahl", value: "82272", alias: "82272" },
  { category: "Postleitzahl", value: "82275", alias: "82275" },
  { category: "Postleitzahl", value: "82276", alias: "82276" },
  { category: "Postleitzahl", value: "82278", alias: "82278" },
  { category: "Postleitzahl", value: "82279", alias: "82279" },
  { category: "Postleitzahl", value: "82281", alias: "82281" },
  { category: "Postleitzahl", value: "82284", alias: "82284" },
  { category: "Postleitzahl", value: "82285", alias: "82285" },
  { category: "Postleitzahl", value: "82287", alias: "82287" },
  { category: "Postleitzahl", value: "82288", alias: "82288" },
  { category: "Postleitzahl", value: "82290", alias: "82290" },
  { category: "Postleitzahl", value: "82291", alias: "82291" },
  { category: "Postleitzahl", value: "82293", alias: "82293" },
  { category: "Postleitzahl", value: "82294", alias: "82294" },
  { category: "Postleitzahl", value: "82296", alias: "82296" },
  { category: "Postleitzahl", value: "82297", alias: "82297" },
  { category: "Postleitzahl", value: "82299", alias: "82299" },
  { category: "Postleitzahl", value: "82319", alias: "82319" },
  { category: "Postleitzahl", value: "82327", alias: "82327" },
  { category: "Postleitzahl", value: "82335", alias: "82335" },
  { category: "Postleitzahl", value: "82340", alias: "82340" },
  { category: "Postleitzahl", value: "82343", alias: "82343" },
  { category: "Postleitzahl", value: "82346", alias: "82346" },
  { category: "Postleitzahl", value: "82347", alias: "82347" },
  { category: "Postleitzahl", value: "82349", alias: "82349" },
  { category: "Postleitzahl", value: "82362", alias: "82362" },
  { category: "Postleitzahl", value: "82377", alias: "82377" },
  { category: "Postleitzahl", value: "82380", alias: "82380" },
  { category: "Postleitzahl", value: "82383", alias: "82383" },
  { category: "Postleitzahl", value: "82386", alias: "82386" },
  { category: "Postleitzahl", value: "82387", alias: "82387" },
  { category: "Postleitzahl", value: "82389", alias: "82389" },
  { category: "Postleitzahl", value: "82390", alias: "82390" },
  { category: "Postleitzahl", value: "82392", alias: "82392" },
  { category: "Postleitzahl", value: "82393", alias: "82393" },
  { category: "Postleitzahl", value: "82395", alias: "82395" },
  { category: "Postleitzahl", value: "82396", alias: "82396" },
  { category: "Postleitzahl", value: "82398", alias: "82398" },
  { category: "Postleitzahl", value: "82399", alias: "82399" },
  { category: "Postleitzahl", value: "82401", alias: "82401" },
  { category: "Postleitzahl", value: "82402", alias: "82402" },
  { category: "Postleitzahl", value: "82404", alias: "82404" },
  { category: "Postleitzahl", value: "82405", alias: "82405" },
  { category: "Postleitzahl", value: "82407", alias: "82407" },
  { category: "Postleitzahl", value: "82409", alias: "82409" },
  { category: "Postleitzahl", value: "82418", alias: "82418" },
  { category: "Postleitzahl", value: "82431", alias: "82431" },
  { category: "Postleitzahl", value: "82432", alias: "82432" },
  { category: "Postleitzahl", value: "82433", alias: "82433" },
  { category: "Postleitzahl", value: "82435", alias: "82435" },
  { category: "Postleitzahl", value: "82436", alias: "82436" },
  { category: "Postleitzahl", value: "82438", alias: "82438" },
  { category: "Postleitzahl", value: "82439", alias: "82439" },
  { category: "Postleitzahl", value: "82441", alias: "82441" },
  { category: "Postleitzahl", value: "82442", alias: "82442" },
  { category: "Postleitzahl", value: "82444", alias: "82444" },
  { category: "Postleitzahl", value: "82445", alias: "82445" },
  { category: "Postleitzahl", value: "82447", alias: "82447" },
  { category: "Postleitzahl", value: "82449", alias: "82449" },
  { category: "Postleitzahl", value: "82467", alias: "82467" },
  { category: "Postleitzahl", value: "82475", alias: "82475" },
  { category: "Postleitzahl", value: "82481", alias: "82481" },
  { category: "Postleitzahl", value: "82487", alias: "82487" },
  { category: "Postleitzahl", value: "82488", alias: "82488" },
  { category: "Postleitzahl", value: "82490", alias: "82490" },
  { category: "Postleitzahl", value: "82491", alias: "82491" },
  { category: "Postleitzahl", value: "82493", alias: "82493" },
  { category: "Postleitzahl", value: "82494", alias: "82494" },
  { category: "Postleitzahl", value: "82496", alias: "82496" },
  { category: "Postleitzahl", value: "82497", alias: "82497" },
  { category: "Postleitzahl", value: "82499", alias: "82499" },
  { category: "Postleitzahl", value: "82515", alias: "82515" },
  { category: "Postleitzahl", value: "82538", alias: "82538" },
  { category: "Postleitzahl", value: "82541", alias: "82541" },
  { category: "Postleitzahl", value: "82544", alias: "82544" },
  { category: "Postleitzahl", value: "82547", alias: "82547" },
  { category: "Postleitzahl", value: "82549", alias: "82549" },
  { category: "Postleitzahl", value: "83022", alias: "83022" },
  { category: "Postleitzahl", value: "83024", alias: "83024" },
  { category: "Postleitzahl", value: "83026", alias: "83026" },
  { category: "Postleitzahl", value: "83043", alias: "83043" },
  { category: "Postleitzahl", value: "83052", alias: "83052" },
  { category: "Postleitzahl", value: "83059", alias: "83059" },
  { category: "Postleitzahl", value: "83064", alias: "83064" },
  { category: "Postleitzahl", value: "83071", alias: "83071" },
  { category: "Postleitzahl", value: "83075", alias: "83075" },
  { category: "Postleitzahl", value: "83080", alias: "83080" },
  { category: "Postleitzahl", value: "83083", alias: "83083" },
  { category: "Postleitzahl", value: "83088", alias: "83088" },
  { category: "Postleitzahl", value: "83093", alias: "83093" },
  { category: "Postleitzahl", value: "83098", alias: "83098" },
  { category: "Postleitzahl", value: "83101", alias: "83101" },
  { category: "Postleitzahl", value: "83104", alias: "83104" },
  { category: "Postleitzahl", value: "83109", alias: "83109" },
  { category: "Postleitzahl", value: "83112", alias: "83112" },
  { category: "Postleitzahl", value: "83115", alias: "83115" },
  { category: "Postleitzahl", value: "83119", alias: "83119" },
  { category: "Postleitzahl", value: "83122", alias: "83122" },
  { category: "Postleitzahl", value: "83123", alias: "83123" },
  { category: "Postleitzahl", value: "83125", alias: "83125" },
  { category: "Postleitzahl", value: "83126", alias: "83126" },
  { category: "Postleitzahl", value: "83128", alias: "83128" },
  { category: "Postleitzahl", value: "83129", alias: "83129" },
  { category: "Postleitzahl", value: "83131", alias: "83131" },
  { category: "Postleitzahl", value: "83132", alias: "83132" },
  { category: "Postleitzahl", value: "83134", alias: "83134" },
  { category: "Postleitzahl", value: "83135", alias: "83135" },
  { category: "Postleitzahl", value: "83137", alias: "83137" },
  { category: "Postleitzahl", value: "83139", alias: "83139" },
  { category: "Postleitzahl", value: "83209", alias: "83209" },
  { category: "Postleitzahl", value: "83224", alias: "83224" },
  { category: "Postleitzahl", value: "83229", alias: "83229" },
  { category: "Postleitzahl", value: "83233", alias: "83233" },
  { category: "Postleitzahl", value: "83236", alias: "83236" },
  { category: "Postleitzahl", value: "83242", alias: "83242" },
  { category: "Postleitzahl", value: "83246", alias: "83246" },
  { category: "Postleitzahl", value: "83250", alias: "83250" },
  { category: "Postleitzahl", value: "83253", alias: "83253" },
  { category: "Postleitzahl", value: "83254", alias: "83254" },
  { category: "Postleitzahl", value: "83256", alias: "83256" },
  { category: "Postleitzahl", value: "83257", alias: "83257" },
  { category: "Postleitzahl", value: "83259", alias: "83259" },
  { category: "Postleitzahl", value: "83278", alias: "83278" },
  { category: "Postleitzahl", value: "83301", alias: "83301" },
  { category: "Postleitzahl", value: "83308", alias: "83308" },
  { category: "Postleitzahl", value: "83313", alias: "83313" },
  { category: "Postleitzahl", value: "83317", alias: "83317" },
  { category: "Postleitzahl", value: "83324", alias: "83324" },
  { category: "Postleitzahl", value: "83329", alias: "83329" },
  { category: "Postleitzahl", value: "83334", alias: "83334" },
  { category: "Postleitzahl", value: "83339", alias: "83339" },
  { category: "Postleitzahl", value: "83342", alias: "83342" },
  { category: "Postleitzahl", value: "83346", alias: "83346" },
  { category: "Postleitzahl", value: "83349", alias: "83349" },
  { category: "Postleitzahl", value: "83352", alias: "83352" },
  { category: "Postleitzahl", value: "83355", alias: "83355" },
  { category: "Postleitzahl", value: "83358", alias: "83358" },
  { category: "Postleitzahl", value: "83361", alias: "83361" },
  { category: "Postleitzahl", value: "83362", alias: "83362" },
  { category: "Postleitzahl", value: "83364", alias: "83364" },
  { category: "Postleitzahl", value: "83365", alias: "83365" },
  { category: "Postleitzahl", value: "83367", alias: "83367" },
  { category: "Postleitzahl", value: "83368", alias: "83368" },
  { category: "Postleitzahl", value: "83370", alias: "83370" },
  { category: "Postleitzahl", value: "83371", alias: "83371" },
  { category: "Postleitzahl", value: "83373", alias: "83373" },
  { category: "Postleitzahl", value: "83374", alias: "83374" },
  { category: "Postleitzahl", value: "83376", alias: "83376" },
  { category: "Postleitzahl", value: "83377", alias: "83377" },
  { category: "Postleitzahl", value: "83379", alias: "83379" },
  { category: "Postleitzahl", value: "83395", alias: "83395" },
  { category: "Postleitzahl", value: "83404", alias: "83404" },
  { category: "Postleitzahl", value: "83410", alias: "83410" },
  { category: "Postleitzahl", value: "83413", alias: "83413" },
  { category: "Postleitzahl", value: "83416", alias: "83416" },
  { category: "Postleitzahl", value: "83417", alias: "83417" },
  { category: "Postleitzahl", value: "83435", alias: "83435" },
  { category: "Postleitzahl", value: "83451", alias: "83451" },
  { category: "Postleitzahl", value: "83454", alias: "83454" },
  { category: "Postleitzahl", value: "83457", alias: "83457" },
  { category: "Postleitzahl", value: "83458", alias: "83458" },
  { category: "Postleitzahl", value: "83471", alias: "83471" },
  { category: "Postleitzahl", value: "83483", alias: "83483" },
  { category: "Postleitzahl", value: "83486", alias: "83486" },
  { category: "Postleitzahl", value: "83487", alias: "83487" },
  { category: "Postleitzahl", value: "83512", alias: "83512" },
  { category: "Postleitzahl", value: "83527", alias: "83527" },
  { category: "Postleitzahl", value: "83530", alias: "83530" },
  { category: "Postleitzahl", value: "83533", alias: "83533" },
  { category: "Postleitzahl", value: "83536", alias: "83536" },
  { category: "Postleitzahl", value: "83539", alias: "83539" },
  { category: "Postleitzahl", value: "83543", alias: "83543" },
  { category: "Postleitzahl", value: "83544", alias: "83544" },
  { category: "Postleitzahl", value: "83546", alias: "83546" },
  { category: "Postleitzahl", value: "83547", alias: "83547" },
  { category: "Postleitzahl", value: "83549", alias: "83549" },
  { category: "Postleitzahl", value: "83550", alias: "83550" },
  { category: "Postleitzahl", value: "83553", alias: "83553" },
  { category: "Postleitzahl", value: "83555", alias: "83555" },
  { category: "Postleitzahl", value: "83556", alias: "83556" },
  { category: "Postleitzahl", value: "83558", alias: "83558" },
  { category: "Postleitzahl", value: "83559", alias: "83559" },
  { category: "Postleitzahl", value: "83561", alias: "83561" },
  { category: "Postleitzahl", value: "83562", alias: "83562" },
  { category: "Postleitzahl", value: "83564", alias: "83564" },
  { category: "Postleitzahl", value: "83567", alias: "83567" },
  { category: "Postleitzahl", value: "83569", alias: "83569" },
  { category: "Postleitzahl", value: "83607", alias: "83607" },
  { category: "Postleitzahl", value: "83620", alias: "83620" },
  { category: "Postleitzahl", value: "83623", alias: "83623" },
  { category: "Postleitzahl", value: "83624", alias: "83624" },
  { category: "Postleitzahl", value: "83626", alias: "83626" },
  { category: "Postleitzahl", value: "83627", alias: "83627" },
  { category: "Postleitzahl", value: "83629", alias: "83629" },
  { category: "Postleitzahl", value: "83646", alias: "83646" },
  { category: "Postleitzahl", value: "83661", alias: "83661" },
  { category: "Postleitzahl", value: "83666", alias: "83666" },
  { category: "Postleitzahl", value: "83670", alias: "83670" },
  { category: "Postleitzahl", value: "83671", alias: "83671" },
  { category: "Postleitzahl", value: "83673", alias: "83673" },
  { category: "Postleitzahl", value: "83674", alias: "83674" },
  { category: "Postleitzahl", value: "83676", alias: "83676" },
  { category: "Postleitzahl", value: "83677", alias: "83677" },
  { category: "Postleitzahl", value: "83679", alias: "83679" },
  { category: "Postleitzahl", value: "83684", alias: "83684" },
  { category: "Postleitzahl", value: "83700", alias: "83700" },
  { category: "Postleitzahl", value: "83703", alias: "83703" },
  { category: "Postleitzahl", value: "83707", alias: "83707" },
  { category: "Postleitzahl", value: "83708", alias: "83708" },
  { category: "Postleitzahl", value: "83714", alias: "83714" },
  { category: "Postleitzahl", value: "83727", alias: "83727" },
  { category: "Postleitzahl", value: "83730", alias: "83730" },
  { category: "Postleitzahl", value: "83734", alias: "83734" },
  { category: "Postleitzahl", value: "83735", alias: "83735" },
  { category: "Postleitzahl", value: "83737", alias: "83737" },
  { category: "Postleitzahl", value: "84028", alias: "84028" },
  { category: "Postleitzahl", value: "84030", alias: "84030" },
  { category: "Postleitzahl", value: "84032", alias: "84032" },
  { category: "Postleitzahl", value: "84034", alias: "84034" },
  { category: "Postleitzahl", value: "84036", alias: "84036" },
  { category: "Postleitzahl", value: "84048", alias: "84048" },
  { category: "Postleitzahl", value: "84051", alias: "84051" },
  { category: "Postleitzahl", value: "84056", alias: "84056" },
  { category: "Postleitzahl", value: "84061", alias: "84061" },
  { category: "Postleitzahl", value: "84066", alias: "84066" },
  { category: "Postleitzahl", value: "84069", alias: "84069" },
  { category: "Postleitzahl", value: "84072", alias: "84072" },
  { category: "Postleitzahl", value: "84076", alias: "84076" },
  { category: "Postleitzahl", value: "84079", alias: "84079" },
  { category: "Postleitzahl", value: "84082", alias: "84082" },
  { category: "Postleitzahl", value: "84085", alias: "84085" },
  { category: "Postleitzahl", value: "84088", alias: "84088" },
  { category: "Postleitzahl", value: "84089", alias: "84089" },
  { category: "Postleitzahl", value: "84091", alias: "84091" },
  { category: "Postleitzahl", value: "84092", alias: "84092" },
  { category: "Postleitzahl", value: "84094", alias: "84094" },
  { category: "Postleitzahl", value: "84095", alias: "84095" },
  { category: "Postleitzahl", value: "84097", alias: "84097" },
  { category: "Postleitzahl", value: "84098", alias: "84098" },
  { category: "Postleitzahl", value: "84100", alias: "84100" },
  { category: "Postleitzahl", value: "84101", alias: "84101" },
  { category: "Postleitzahl", value: "84103", alias: "84103" },
  { category: "Postleitzahl", value: "84104", alias: "84104" },
  { category: "Postleitzahl", value: "84106", alias: "84106" },
  { category: "Postleitzahl", value: "84107", alias: "84107" },
  { category: "Postleitzahl", value: "84109", alias: "84109" },
  { category: "Postleitzahl", value: "84130", alias: "84130" },
  { category: "Postleitzahl", value: "84137", alias: "84137" },
  { category: "Postleitzahl", value: "84140", alias: "84140" },
  { category: "Postleitzahl", value: "84144", alias: "84144" },
  { category: "Postleitzahl", value: "84149", alias: "84149" },
  { category: "Postleitzahl", value: "84152", alias: "84152" },
  { category: "Postleitzahl", value: "84155", alias: "84155" },
  { category: "Postleitzahl", value: "84160", alias: "84160" },
  { category: "Postleitzahl", value: "84163", alias: "84163" },
  { category: "Postleitzahl", value: "84164", alias: "84164" },
  { category: "Postleitzahl", value: "84166", alias: "84166" },
  { category: "Postleitzahl", value: "84168", alias: "84168" },
  { category: "Postleitzahl", value: "84169", alias: "84169" },
  { category: "Postleitzahl", value: "84171", alias: "84171" },
  { category: "Postleitzahl", value: "84172", alias: "84172" },
  { category: "Postleitzahl", value: "84174", alias: "84174" },
  { category: "Postleitzahl", value: "84175", alias: "84175" },
  { category: "Postleitzahl", value: "84177", alias: "84177" },
  { category: "Postleitzahl", value: "84178", alias: "84178" },
  { category: "Postleitzahl", value: "84180", alias: "84180" },
  { category: "Postleitzahl", value: "84181", alias: "84181" },
  { category: "Postleitzahl", value: "84183", alias: "84183" },
  { category: "Postleitzahl", value: "84184", alias: "84184" },
  { category: "Postleitzahl", value: "84186", alias: "84186" },
  { category: "Postleitzahl", value: "84187", alias: "84187" },
  { category: "Postleitzahl", value: "84189", alias: "84189" },
  { category: "Postleitzahl", value: "84307", alias: "84307" },
  { category: "Postleitzahl", value: "84323", alias: "84323" },
  { category: "Postleitzahl", value: "84326", alias: "84326" },
  { category: "Postleitzahl", value: "84329", alias: "84329" },
  { category: "Postleitzahl", value: "84332", alias: "84332" },
  { category: "Postleitzahl", value: "84333", alias: "84333" },
  { category: "Postleitzahl", value: "84335", alias: "84335" },
  { category: "Postleitzahl", value: "84337", alias: "84337" },
  { category: "Postleitzahl", value: "84339", alias: "84339" },
  { category: "Postleitzahl", value: "84347", alias: "84347" },
  { category: "Postleitzahl", value: "84359", alias: "84359" },
  { category: "Postleitzahl", value: "84364", alias: "84364" },
  { category: "Postleitzahl", value: "84367", alias: "84367" },
  { category: "Postleitzahl", value: "84371", alias: "84371" },
  { category: "Postleitzahl", value: "84375", alias: "84375" },
  { category: "Postleitzahl", value: "84378", alias: "84378" },
  { category: "Postleitzahl", value: "84381", alias: "84381" },
  { category: "Postleitzahl", value: "84384", alias: "84384" },
  { category: "Postleitzahl", value: "84385", alias: "84385" },
  { category: "Postleitzahl", value: "84387", alias: "84387" },
  { category: "Postleitzahl", value: "84389", alias: "84389" },
  { category: "Postleitzahl", value: "84405", alias: "84405" },
  { category: "Postleitzahl", value: "84416", alias: "84416" },
  { category: "Postleitzahl", value: "84419", alias: "84419" },
  { category: "Postleitzahl", value: "84424", alias: "84424" },
  { category: "Postleitzahl", value: "84427", alias: "84427" },
  { category: "Postleitzahl", value: "84428", alias: "84428" },
  { category: "Postleitzahl", value: "84431", alias: "84431" },
  { category: "Postleitzahl", value: "84432", alias: "84432" },
  { category: "Postleitzahl", value: "84434", alias: "84434" },
  { category: "Postleitzahl", value: "84435", alias: "84435" },
  { category: "Postleitzahl", value: "84437", alias: "84437" },
  { category: "Postleitzahl", value: "84439", alias: "84439" },
  { category: "Postleitzahl", value: "84453", alias: "84453" },
  { category: "Postleitzahl", value: "84478", alias: "84478" },
  { category: "Postleitzahl", value: "84489", alias: "84489" },
  { category: "Postleitzahl", value: "84494", alias: "84494" },
  { category: "Postleitzahl", value: "84503", alias: "84503" },
  { category: "Postleitzahl", value: "84508", alias: "84508" },
  { category: "Postleitzahl", value: "84513", alias: "84513" },
  { category: "Postleitzahl", value: "84518", alias: "84518" },
  { category: "Postleitzahl", value: "84524", alias: "84524" },
  { category: "Postleitzahl", value: "84529", alias: "84529" },
  { category: "Postleitzahl", value: "84533", alias: "84533" },
  { category: "Postleitzahl", value: "84539", alias: "84539" },
  { category: "Postleitzahl", value: "84543", alias: "84543" },
  { category: "Postleitzahl", value: "84544", alias: "84544" },
  { category: "Postleitzahl", value: "84546", alias: "84546" },
  { category: "Postleitzahl", value: "84547", alias: "84547" },
  { category: "Postleitzahl", value: "84549", alias: "84549" },
  { category: "Postleitzahl", value: "84550", alias: "84550" },
  { category: "Postleitzahl", value: "84552", alias: "84552" },
  { category: "Postleitzahl", value: "84553", alias: "84553" },
  { category: "Postleitzahl", value: "84555", alias: "84555" },
  { category: "Postleitzahl", value: "84556", alias: "84556" },
  { category: "Postleitzahl", value: "84558", alias: "84558" },
  { category: "Postleitzahl", value: "84559", alias: "84559" },
  { category: "Postleitzahl", value: "84561", alias: "84561" },
  { category: "Postleitzahl", value: "84562", alias: "84562" },
  { category: "Postleitzahl", value: "84564", alias: "84564" },
  { category: "Postleitzahl", value: "84565", alias: "84565" },
  { category: "Postleitzahl", value: "84567", alias: "84567" },
  { category: "Postleitzahl", value: "84568", alias: "84568" },
  { category: "Postleitzahl", value: "84570", alias: "84570" },
  { category: "Postleitzahl", value: "84571", alias: "84571" },
  { category: "Postleitzahl", value: "84573", alias: "84573" },
  { category: "Postleitzahl", value: "84574", alias: "84574" },
  { category: "Postleitzahl", value: "84576", alias: "84576" },
  { category: "Postleitzahl", value: "84577", alias: "84577" },
  { category: "Postleitzahl", value: "84579", alias: "84579" },
  { category: "Postleitzahl", value: "85049", alias: "85049" },
  { category: "Postleitzahl", value: "85051", alias: "85051" },
  { category: "Postleitzahl", value: "85053", alias: "85053" },
  { category: "Postleitzahl", value: "85055", alias: "85055" },
  { category: "Postleitzahl", value: "85057", alias: "85057" },
  { category: "Postleitzahl", value: "85072", alias: "85072" },
  { category: "Postleitzahl", value: "85077", alias: "85077" },
  { category: "Postleitzahl", value: "85080", alias: "85080" },
  { category: "Postleitzahl", value: "85084", alias: "85084" },
  { category: "Postleitzahl", value: "85088", alias: "85088" },
  { category: "Postleitzahl", value: "85092", alias: "85092" },
  { category: "Postleitzahl", value: "85095", alias: "85095" },
  { category: "Postleitzahl", value: "85098", alias: "85098" },
  { category: "Postleitzahl", value: "85101", alias: "85101" },
  { category: "Postleitzahl", value: "85104", alias: "85104" },
  { category: "Postleitzahl", value: "85107", alias: "85107" },
  { category: "Postleitzahl", value: "85110", alias: "85110" },
  { category: "Postleitzahl", value: "85111", alias: "85111" },
  { category: "Postleitzahl", value: "85113", alias: "85113" },
  { category: "Postleitzahl", value: "85114", alias: "85114" },
  { category: "Postleitzahl", value: "85116", alias: "85116" },
  { category: "Postleitzahl", value: "85117", alias: "85117" },
  { category: "Postleitzahl", value: "85119", alias: "85119" },
  { category: "Postleitzahl", value: "85120", alias: "85120" },
  { category: "Postleitzahl", value: "85122", alias: "85122" },
  { category: "Postleitzahl", value: "85123", alias: "85123" },
  { category: "Postleitzahl", value: "85125", alias: "85125" },
  { category: "Postleitzahl", value: "85126", alias: "85126" },
  { category: "Postleitzahl", value: "85128", alias: "85128" },
  { category: "Postleitzahl", value: "85129", alias: "85129" },
  { category: "Postleitzahl", value: "85131", alias: "85131" },
  { category: "Postleitzahl", value: "85132", alias: "85132" },
  { category: "Postleitzahl", value: "85134", alias: "85134" },
  { category: "Postleitzahl", value: "85135", alias: "85135" },
  { category: "Postleitzahl", value: "85137", alias: "85137" },
  { category: "Postleitzahl", value: "85139", alias: "85139" },
  { category: "Postleitzahl", value: "85221", alias: "85221" },
  { category: "Postleitzahl", value: "85229", alias: "85229" },
  { category: "Postleitzahl", value: "85232", alias: "85232" },
  { category: "Postleitzahl", value: "85235", alias: "85235" },
  { category: "Postleitzahl", value: "85238", alias: "85238" },
  { category: "Postleitzahl", value: "85241", alias: "85241" },
  { category: "Postleitzahl", value: "85244", alias: "85244" },
  { category: "Postleitzahl", value: "85247", alias: "85247" },
  { category: "Postleitzahl", value: "85250", alias: "85250" },
  { category: "Postleitzahl", value: "85253", alias: "85253" },
  { category: "Postleitzahl", value: "85254", alias: "85254" },
  { category: "Postleitzahl", value: "85256", alias: "85256" },
  { category: "Postleitzahl", value: "85258", alias: "85258" },
  { category: "Postleitzahl", value: "85259", alias: "85259" },
  { category: "Postleitzahl", value: "85276", alias: "85276" },
  { category: "Postleitzahl", value: "85283", alias: "85283" },
  { category: "Postleitzahl", value: "85290", alias: "85290" },
  { category: "Postleitzahl", value: "85293", alias: "85293" },
  { category: "Postleitzahl", value: "85296", alias: "85296" },
  { category: "Postleitzahl", value: "85298", alias: "85298" },
  { category: "Postleitzahl", value: "85301", alias: "85301" },
  { category: "Postleitzahl", value: "85302", alias: "85302" },
  { category: "Postleitzahl", value: "85304", alias: "85304" },
  { category: "Postleitzahl", value: "85305", alias: "85305" },
  { category: "Postleitzahl", value: "85307", alias: "85307" },
  { category: "Postleitzahl", value: "85309", alias: "85309" },
  { category: "Postleitzahl", value: "85354", alias: "85354" },
  { category: "Postleitzahl", value: "85356", alias: "85356" },
  { category: "Postleitzahl", value: "85368", alias: "85368" },
  { category: "Postleitzahl", value: "85375", alias: "85375" },
  { category: "Postleitzahl", value: "85376", alias: "85376" },
  { category: "Postleitzahl", value: "85386", alias: "85386" },
  { category: "Postleitzahl", value: "85391", alias: "85391" },
  { category: "Postleitzahl", value: "85395", alias: "85395" },
  { category: "Postleitzahl", value: "85399", alias: "85399" },
  { category: "Postleitzahl", value: "85402", alias: "85402" },
  { category: "Postleitzahl", value: "85405", alias: "85405" },
  { category: "Postleitzahl", value: "85406", alias: "85406" },
  { category: "Postleitzahl", value: "85408", alias: "85408" },
  { category: "Postleitzahl", value: "85410", alias: "85410" },
  { category: "Postleitzahl", value: "85411", alias: "85411" },
  { category: "Postleitzahl", value: "85413", alias: "85413" },
  { category: "Postleitzahl", value: "85414", alias: "85414" },
  { category: "Postleitzahl", value: "85416", alias: "85416" },
  { category: "Postleitzahl", value: "85417", alias: "85417" },
  { category: "Postleitzahl", value: "85419", alias: "85419" },
  { category: "Postleitzahl", value: "85435", alias: "85435" },
  { category: "Postleitzahl", value: "85445", alias: "85445" },
  { category: "Postleitzahl", value: "85447", alias: "85447" },
  { category: "Postleitzahl", value: "85452", alias: "85452" },
  { category: "Postleitzahl", value: "85456", alias: "85456" },
  { category: "Postleitzahl", value: "85457", alias: "85457" },
  { category: "Postleitzahl", value: "85459", alias: "85459" },
  { category: "Postleitzahl", value: "85461", alias: "85461" },
  { category: "Postleitzahl", value: "85462", alias: "85462" },
  { category: "Postleitzahl", value: "85464", alias: "85464" },
  { category: "Postleitzahl", value: "85465", alias: "85465" },
  { category: "Postleitzahl", value: "85467", alias: "85467" },
  { category: "Postleitzahl", value: "85469", alias: "85469" },
  { category: "Postleitzahl", value: "85521", alias: "85521" },
  { category: "Postleitzahl", value: "85540", alias: "85540" },
  { category: "Postleitzahl", value: "85551", alias: "85551" },
  { category: "Postleitzahl", value: "85560", alias: "85560" },
  { category: "Postleitzahl", value: "85567", alias: "85567" },
  { category: "Postleitzahl", value: "85570", alias: "85570" },
  { category: "Postleitzahl", value: "85579", alias: "85579" },
  { category: "Postleitzahl", value: "85586", alias: "85586" },
  { category: "Postleitzahl", value: "85591", alias: "85591" },
  { category: "Postleitzahl", value: "85598", alias: "85598" },
  { category: "Postleitzahl", value: "85599", alias: "85599" },
  { category: "Postleitzahl", value: "85604", alias: "85604" },
  { category: "Postleitzahl", value: "85609", alias: "85609" },
  { category: "Postleitzahl", value: "85614", alias: "85614" },
  { category: "Postleitzahl", value: "85617", alias: "85617" },
  { category: "Postleitzahl", value: "85622", alias: "85622" },
  { category: "Postleitzahl", value: "85625", alias: "85625" },
  { category: "Postleitzahl", value: "85630", alias: "85630" },
  { category: "Postleitzahl", value: "85635", alias: "85635" },
  { category: "Postleitzahl", value: "85640", alias: "85640" },
  { category: "Postleitzahl", value: "85643", alias: "85643" },
  { category: "Postleitzahl", value: "85646", alias: "85646" },
  { category: "Postleitzahl", value: "85649", alias: "85649" },
  { category: "Postleitzahl", value: "85652", alias: "85652" },
  { category: "Postleitzahl", value: "85653", alias: "85653" },
  { category: "Postleitzahl", value: "85656", alias: "85656" },
  { category: "Postleitzahl", value: "85658", alias: "85658" },
  { category: "Postleitzahl", value: "85659", alias: "85659" },
  { category: "Postleitzahl", value: "85661", alias: "85661" },
  { category: "Postleitzahl", value: "85662", alias: "85662" },
  { category: "Postleitzahl", value: "85664", alias: "85664" },
  { category: "Postleitzahl", value: "85665", alias: "85665" },
  { category: "Postleitzahl", value: "85667", alias: "85667" },
  { category: "Postleitzahl", value: "85669", alias: "85669" },
  { category: "Postleitzahl", value: "85716", alias: "85716" },
  { category: "Postleitzahl", value: "85737", alias: "85737" },
  { category: "Postleitzahl", value: "85748", alias: "85748" },
  { category: "Postleitzahl", value: "85757", alias: "85757" },
  { category: "Postleitzahl", value: "85764", alias: "85764" },
  { category: "Postleitzahl", value: "85774", alias: "85774" },
  { category: "Postleitzahl", value: "85777", alias: "85777" },
  { category: "Postleitzahl", value: "85778", alias: "85778" },
  { category: "Postleitzahl", value: "86150", alias: "86150" },
  { category: "Postleitzahl", value: "86152", alias: "86152" },
  { category: "Postleitzahl", value: "86153", alias: "86153" },
  { category: "Postleitzahl", value: "86154", alias: "86154" },
  { category: "Postleitzahl", value: "86156", alias: "86156" },
  { category: "Postleitzahl", value: "86157", alias: "86157" },
  { category: "Postleitzahl", value: "86159", alias: "86159" },
  { category: "Postleitzahl", value: "86161", alias: "86161" },
  { category: "Postleitzahl", value: "86163", alias: "86163" },
  { category: "Postleitzahl", value: "86165", alias: "86165" },
  { category: "Postleitzahl", value: "86167", alias: "86167" },
  { category: "Postleitzahl", value: "86169", alias: "86169" },
  { category: "Postleitzahl", value: "86179", alias: "86179" },
  { category: "Postleitzahl", value: "86199", alias: "86199" },
  { category: "Postleitzahl", value: "86316", alias: "86316" },
  { category: "Postleitzahl", value: "86343", alias: "86343" },
  { category: "Postleitzahl", value: "86356", alias: "86356" },
  { category: "Postleitzahl", value: "86368", alias: "86368" },
  { category: "Postleitzahl", value: "86381", alias: "86381" },
  { category: "Postleitzahl", value: "86391", alias: "86391" },
  { category: "Postleitzahl", value: "86399", alias: "86399" },
  { category: "Postleitzahl", value: "86405", alias: "86405" },
  { category: "Postleitzahl", value: "86415", alias: "86415" },
  { category: "Postleitzahl", value: "86420", alias: "86420" },
  { category: "Postleitzahl", value: "86424", alias: "86424" },
  { category: "Postleitzahl", value: "86438", alias: "86438" },
  { category: "Postleitzahl", value: "86441", alias: "86441" },
  { category: "Postleitzahl", value: "86444", alias: "86444" },
  { category: "Postleitzahl", value: "86447", alias: "86447" },
  { category: "Postleitzahl", value: "86450", alias: "86450" },
  { category: "Postleitzahl", value: "86453", alias: "86453" },
  { category: "Postleitzahl", value: "86456", alias: "86456" },
  { category: "Postleitzahl", value: "86459", alias: "86459" },
  { category: "Postleitzahl", value: "86462", alias: "86462" },
  { category: "Postleitzahl", value: "86465", alias: "86465" },
  { category: "Postleitzahl", value: "86470", alias: "86470" },
  { category: "Postleitzahl", value: "86473", alias: "86473" },
  { category: "Postleitzahl", value: "86476", alias: "86476" },
  { category: "Postleitzahl", value: "86477", alias: "86477" },
  { category: "Postleitzahl", value: "86479", alias: "86479" },
  { category: "Postleitzahl", value: "86480", alias: "86480" },
  { category: "Postleitzahl", value: "86482", alias: "86482" },
  { category: "Postleitzahl", value: "86483", alias: "86483" },
  { category: "Postleitzahl", value: "86485", alias: "86485" },
  { category: "Postleitzahl", value: "86486", alias: "86486" },
  { category: "Postleitzahl", value: "86488", alias: "86488" },
  { category: "Postleitzahl", value: "86489", alias: "86489" },
  { category: "Postleitzahl", value: "86491", alias: "86491" },
  { category: "Postleitzahl", value: "86492", alias: "86492" },
  { category: "Postleitzahl", value: "86494", alias: "86494" },
  { category: "Postleitzahl", value: "86495", alias: "86495" },
  { category: "Postleitzahl", value: "86497", alias: "86497" },
  { category: "Postleitzahl", value: "86498", alias: "86498" },
  { category: "Postleitzahl", value: "86500", alias: "86500" },
  { category: "Postleitzahl", value: "86502", alias: "86502" },
  { category: "Postleitzahl", value: "86504", alias: "86504" },
  { category: "Postleitzahl", value: "86505", alias: "86505" },
  { category: "Postleitzahl", value: "86507", alias: "86507" },
  { category: "Postleitzahl", value: "86508", alias: "86508" },
  { category: "Postleitzahl", value: "86510", alias: "86510" },
  { category: "Postleitzahl", value: "86511", alias: "86511" },
  { category: "Postleitzahl", value: "86513", alias: "86513" },
  { category: "Postleitzahl", value: "86514", alias: "86514" },
  { category: "Postleitzahl", value: "86517", alias: "86517" },
  { category: "Postleitzahl", value: "86519", alias: "86519" },
  { category: "Postleitzahl", value: "86529", alias: "86529" },
  { category: "Postleitzahl", value: "86551", alias: "86551" },
  { category: "Postleitzahl", value: "86554", alias: "86554" },
  { category: "Postleitzahl", value: "86556", alias: "86556" },
  { category: "Postleitzahl", value: "86558", alias: "86558" },
  { category: "Postleitzahl", value: "86559", alias: "86559" },
  { category: "Postleitzahl", value: "86561", alias: "86561" },
  { category: "Postleitzahl", value: "86562", alias: "86562" },
  { category: "Postleitzahl", value: "86564", alias: "86564" },
  { category: "Postleitzahl", value: "86565", alias: "86565" },
  { category: "Postleitzahl", value: "86567", alias: "86567" },
  { category: "Postleitzahl", value: "86568", alias: "86568" },
  { category: "Postleitzahl", value: "86570", alias: "86570" },
  { category: "Postleitzahl", value: "86571", alias: "86571" },
  { category: "Postleitzahl", value: "86573", alias: "86573" },
  { category: "Postleitzahl", value: "86574", alias: "86574" },
  { category: "Postleitzahl", value: "86576", alias: "86576" },
  { category: "Postleitzahl", value: "86577", alias: "86577" },
  { category: "Postleitzahl", value: "86579", alias: "86579" },
  { category: "Postleitzahl", value: "86609", alias: "86609" },
  { category: "Postleitzahl", value: "86633", alias: "86633" },
  { category: "Postleitzahl", value: "86637", alias: "86637" },
  { category: "Postleitzahl", value: "86641", alias: "86641" },
  { category: "Postleitzahl", value: "86643", alias: "86643" },
  { category: "Postleitzahl", value: "86647", alias: "86647" },
  { category: "Postleitzahl", value: "86650", alias: "86650" },
  { category: "Postleitzahl", value: "86653", alias: "86653" },
  { category: "Postleitzahl", value: "86655", alias: "86655" },
  { category: "Postleitzahl", value: "86657", alias: "86657" },
  { category: "Postleitzahl", value: "86660", alias: "86660" },
  { category: "Postleitzahl", value: "86663", alias: "86663" },
  { category: "Postleitzahl", value: "86666", alias: "86666" },
  { category: "Postleitzahl", value: "86668", alias: "86668" },
  { category: "Postleitzahl", value: "86669", alias: "86669" },
  { category: "Postleitzahl", value: "86672", alias: "86672" },
  { category: "Postleitzahl", value: "86673", alias: "86673" },
  { category: "Postleitzahl", value: "86674", alias: "86674" },
  { category: "Postleitzahl", value: "86675", alias: "86675" },
  { category: "Postleitzahl", value: "86676", alias: "86676" },
  { category: "Postleitzahl", value: "86678", alias: "86678" },
  { category: "Postleitzahl", value: "86679", alias: "86679" },
  { category: "Postleitzahl", value: "86681", alias: "86681" },
  { category: "Postleitzahl", value: "86682", alias: "86682" },
  { category: "Postleitzahl", value: "86684", alias: "86684" },
  { category: "Postleitzahl", value: "86685", alias: "86685" },
  { category: "Postleitzahl", value: "86687", alias: "86687" },
  { category: "Postleitzahl", value: "86688", alias: "86688" },
  { category: "Postleitzahl", value: "86690", alias: "86690" },
  { category: "Postleitzahl", value: "86692", alias: "86692" },
  { category: "Postleitzahl", value: "86694", alias: "86694" },
  { category: "Postleitzahl", value: "86695", alias: "86695" },
  { category: "Postleitzahl", value: "86697", alias: "86697" },
  { category: "Postleitzahl", value: "86698", alias: "86698" },
  { category: "Postleitzahl", value: "86700", alias: "86700" },
  { category: "Postleitzahl", value: "86701", alias: "86701" },
  { category: "Postleitzahl", value: "86703", alias: "86703" },
  { category: "Postleitzahl", value: "86704", alias: "86704" },
  { category: "Postleitzahl", value: "86706", alias: "86706" },
  { category: "Postleitzahl", value: "86707", alias: "86707" },
  { category: "Postleitzahl", value: "86709", alias: "86709" },
  { category: "Postleitzahl", value: "86720", alias: "86720" },
  { category: "Postleitzahl", value: "86732", alias: "86732" },
  { category: "Postleitzahl", value: "86733", alias: "86733" },
  { category: "Postleitzahl", value: "86735", alias: "86735" },
  { category: "Postleitzahl", value: "86736", alias: "86736" },
  { category: "Postleitzahl", value: "86738", alias: "86738" },
  { category: "Postleitzahl", value: "86739", alias: "86739" },
  { category: "Postleitzahl", value: "86741", alias: "86741" },
  { category: "Postleitzahl", value: "86742", alias: "86742" },
  { category: "Postleitzahl", value: "86744", alias: "86744" },
  { category: "Postleitzahl", value: "86745", alias: "86745" },
  { category: "Postleitzahl", value: "86747", alias: "86747" },
  { category: "Postleitzahl", value: "86748", alias: "86748" },
  { category: "Postleitzahl", value: "86750", alias: "86750" },
  { category: "Postleitzahl", value: "86751", alias: "86751" },
  { category: "Postleitzahl", value: "86753", alias: "86753" },
  { category: "Postleitzahl", value: "86754", alias: "86754" },
  { category: "Postleitzahl", value: "86756", alias: "86756" },
  { category: "Postleitzahl", value: "86757", alias: "86757" },
  { category: "Postleitzahl", value: "86759", alias: "86759" },
  { category: "Postleitzahl", value: "86807", alias: "86807" },
  { category: "Postleitzahl", value: "86825", alias: "86825" },
  { category: "Postleitzahl", value: "86830", alias: "86830" },
  { category: "Postleitzahl", value: "86833", alias: "86833" },
  { category: "Postleitzahl", value: "86836", alias: "86836" },
  { category: "Postleitzahl", value: "86842", alias: "86842" },
  { category: "Postleitzahl", value: "86845", alias: "86845" },
  { category: "Postleitzahl", value: "86850", alias: "86850" },
  { category: "Postleitzahl", value: "86853", alias: "86853" },
  { category: "Postleitzahl", value: "86854", alias: "86854" },
  { category: "Postleitzahl", value: "86856", alias: "86856" },
  { category: "Postleitzahl", value: "86857", alias: "86857" },
  { category: "Postleitzahl", value: "86859", alias: "86859" },
  { category: "Postleitzahl", value: "86860", alias: "86860" },
  { category: "Postleitzahl", value: "86862", alias: "86862" },
  { category: "Postleitzahl", value: "86863", alias: "86863" },
  { category: "Postleitzahl", value: "86865", alias: "86865" },
  { category: "Postleitzahl", value: "86866", alias: "86866" },
  { category: "Postleitzahl", value: "86868", alias: "86868" },
  { category: "Postleitzahl", value: "86869", alias: "86869" },
  { category: "Postleitzahl", value: "86871", alias: "86871" },
  { category: "Postleitzahl", value: "86872", alias: "86872" },
  { category: "Postleitzahl", value: "86874", alias: "86874" },
  { category: "Postleitzahl", value: "86875", alias: "86875" },
  { category: "Postleitzahl", value: "86877", alias: "86877" },
  { category: "Postleitzahl", value: "86879", alias: "86879" },
  { category: "Postleitzahl", value: "86899", alias: "86899" },
  { category: "Postleitzahl", value: "86911", alias: "86911" },
  { category: "Postleitzahl", value: "86916", alias: "86916" },
  { category: "Postleitzahl", value: "86919", alias: "86919" },
  { category: "Postleitzahl", value: "86920", alias: "86920" },
  { category: "Postleitzahl", value: "86922", alias: "86922" },
  { category: "Postleitzahl", value: "86923", alias: "86923" },
  { category: "Postleitzahl", value: "86925", alias: "86925" },
  { category: "Postleitzahl", value: "86926", alias: "86926" },
  { category: "Postleitzahl", value: "86928", alias: "86928" },
  { category: "Postleitzahl", value: "86929", alias: "86929" },
  { category: "Postleitzahl", value: "86931", alias: "86931" },
  { category: "Postleitzahl", value: "86932", alias: "86932" },
  { category: "Postleitzahl", value: "86934", alias: "86934" },
  { category: "Postleitzahl", value: "86935", alias: "86935" },
  { category: "Postleitzahl", value: "86937", alias: "86937" },
  { category: "Postleitzahl", value: "86938", alias: "86938" },
  { category: "Postleitzahl", value: "86940", alias: "86940" },
  { category: "Postleitzahl", value: "86941", alias: "86941" },
  { category: "Postleitzahl", value: "86943", alias: "86943" },
  { category: "Postleitzahl", value: "86944", alias: "86944" },
  { category: "Postleitzahl", value: "86946", alias: "86946" },
  { category: "Postleitzahl", value: "86947", alias: "86947" },
  { category: "Postleitzahl", value: "86949", alias: "86949" },
  { category: "Postleitzahl", value: "86956", alias: "86956" },
  { category: "Postleitzahl", value: "86971", alias: "86971" },
  { category: "Postleitzahl", value: "86972", alias: "86972" },
  { category: "Postleitzahl", value: "86974", alias: "86974" },
  { category: "Postleitzahl", value: "86975", alias: "86975" },
  { category: "Postleitzahl", value: "86977", alias: "86977" },
  { category: "Postleitzahl", value: "86978", alias: "86978" },
  { category: "Postleitzahl", value: "86980", alias: "86980" },
  { category: "Postleitzahl", value: "86981", alias: "86981" },
  { category: "Postleitzahl", value: "86983", alias: "86983" },
  { category: "Postleitzahl", value: "86984", alias: "86984" },
  { category: "Postleitzahl", value: "86986", alias: "86986" },
  { category: "Postleitzahl", value: "86987", alias: "86987" },
  { category: "Postleitzahl", value: "86989", alias: "86989" },
  { category: "Postleitzahl", value: "87435", alias: "87435" },
  { category: "Postleitzahl", value: "87437", alias: "87437" },
  { category: "Postleitzahl", value: "87439", alias: "87439" },
  { category: "Postleitzahl", value: "87448", alias: "87448" },
  { category: "Postleitzahl", value: "87452", alias: "87452" },
  { category: "Postleitzahl", value: "87459", alias: "87459" },
  { category: "Postleitzahl", value: "87463", alias: "87463" },
  { category: "Postleitzahl", value: "87466", alias: "87466" },
  { category: "Postleitzahl", value: "87471", alias: "87471" },
  { category: "Postleitzahl", value: "87474", alias: "87474" },
  { category: "Postleitzahl", value: "87477", alias: "87477" },
  { category: "Postleitzahl", value: "87480", alias: "87480" },
  { category: "Postleitzahl", value: "87484", alias: "87484" },
  { category: "Postleitzahl", value: "87487", alias: "87487" },
  { category: "Postleitzahl", value: "87488", alias: "87488" },
  { category: "Postleitzahl", value: "87490", alias: "87490" },
  { category: "Postleitzahl", value: "87493", alias: "87493" },
  { category: "Postleitzahl", value: "87494", alias: "87494" },
  { category: "Postleitzahl", value: "87496", alias: "87496" },
  { category: "Postleitzahl", value: "87497", alias: "87497" },
  { category: "Postleitzahl", value: "87499", alias: "87499" },
  { category: "Postleitzahl", value: "87509", alias: "87509" },
  { category: "Postleitzahl", value: "87527", alias: "87527" },
  { category: "Postleitzahl", value: "87534", alias: "87534" },
  { category: "Postleitzahl", value: "87538", alias: "87538" },
  { category: "Postleitzahl", value: "87541", alias: "87541" },
  { category: "Postleitzahl", value: "87544", alias: "87544" },
  { category: "Postleitzahl", value: "87545", alias: "87545" },
  { category: "Postleitzahl", value: "87547", alias: "87547" },
  { category: "Postleitzahl", value: "87549", alias: "87549" },
  { category: "Postleitzahl", value: "87561", alias: "87561" },
  { category: "Postleitzahl", value: "87600", alias: "87600" },
  { category: "Postleitzahl", value: "87616", alias: "87616" },
  { category: "Postleitzahl", value: "87629", alias: "87629" },
  { category: "Postleitzahl", value: "87634", alias: "87634" },
  { category: "Postleitzahl", value: "87637", alias: "87637" },
  { category: "Postleitzahl", value: "87640", alias: "87640" },
  { category: "Postleitzahl", value: "87642", alias: "87642" },
  { category: "Postleitzahl", value: "87645", alias: "87645" },
  { category: "Postleitzahl", value: "87647", alias: "87647" },
  { category: "Postleitzahl", value: "87648", alias: "87648" },
  { category: "Postleitzahl", value: "87650", alias: "87650" },
  { category: "Postleitzahl", value: "87651", alias: "87651" },
  { category: "Postleitzahl", value: "87653", alias: "87653" },
  { category: "Postleitzahl", value: "87654", alias: "87654" },
  { category: "Postleitzahl", value: "87656", alias: "87656" },
  { category: "Postleitzahl", value: "87657", alias: "87657" },
  { category: "Postleitzahl", value: "87659", alias: "87659" },
  { category: "Postleitzahl", value: "87660", alias: "87660" },
  { category: "Postleitzahl", value: "87662", alias: "87662" },
  { category: "Postleitzahl", value: "87663", alias: "87663" },
  { category: "Postleitzahl", value: "87665", alias: "87665" },
  { category: "Postleitzahl", value: "87666", alias: "87666" },
  { category: "Postleitzahl", value: "87668", alias: "87668" },
  { category: "Postleitzahl", value: "87669", alias: "87669" },
  { category: "Postleitzahl", value: "87671", alias: "87671" },
  { category: "Postleitzahl", value: "87672", alias: "87672" },
  { category: "Postleitzahl", value: "87674", alias: "87674" },
  { category: "Postleitzahl", value: "87675", alias: "87675" },
  { category: "Postleitzahl", value: "87677", alias: "87677" },
  { category: "Postleitzahl", value: "87679", alias: "87679" },
  { category: "Postleitzahl", value: "87700", alias: "87700" },
  { category: "Postleitzahl", value: "87719", alias: "87719" },
  { category: "Postleitzahl", value: "87724", alias: "87724" },
  { category: "Postleitzahl", value: "87727", alias: "87727" },
  { category: "Postleitzahl", value: "87730", alias: "87730" },
  { category: "Postleitzahl", value: "87733", alias: "87733" },
  { category: "Postleitzahl", value: "87734", alias: "87734" },
  { category: "Postleitzahl", value: "87736", alias: "87736" },
  { category: "Postleitzahl", value: "87737", alias: "87737" },
  { category: "Postleitzahl", value: "87739", alias: "87739" },
  { category: "Postleitzahl", value: "87740", alias: "87740" },
  { category: "Postleitzahl", value: "87742", alias: "87742" },
  { category: "Postleitzahl", value: "87743", alias: "87743" },
  { category: "Postleitzahl", value: "87745", alias: "87745" },
  { category: "Postleitzahl", value: "87746", alias: "87746" },
  { category: "Postleitzahl", value: "87748", alias: "87748" },
  { category: "Postleitzahl", value: "87749", alias: "87749" },
  { category: "Postleitzahl", value: "87751", alias: "87751" },
  { category: "Postleitzahl", value: "87752", alias: "87752" },
  { category: "Postleitzahl", value: "87754", alias: "87754" },
  { category: "Postleitzahl", value: "87755", alias: "87755" },
  { category: "Postleitzahl", value: "87757", alias: "87757" },
  { category: "Postleitzahl", value: "87758", alias: "87758" },
  { category: "Postleitzahl", value: "87760", alias: "87760" },
  { category: "Postleitzahl", value: "87761", alias: "87761" },
  { category: "Postleitzahl", value: "87763", alias: "87763" },
  { category: "Postleitzahl", value: "87764", alias: "87764" },
  { category: "Postleitzahl", value: "87766", alias: "87766" },
  { category: "Postleitzahl", value: "87767", alias: "87767" },
  { category: "Postleitzahl", value: "87769", alias: "87769" },
  { category: "Postleitzahl", value: "87770", alias: "87770" },
  { category: "Postleitzahl", value: "87772", alias: "87772" },
  { category: "Postleitzahl", value: "87773", alias: "87773" },
  { category: "Postleitzahl", value: "87775", alias: "87775" },
  { category: "Postleitzahl", value: "87776", alias: "87776" },
  { category: "Postleitzahl", value: "87778", alias: "87778" },
  { category: "Postleitzahl", value: "87779", alias: "87779" },
  { category: "Postleitzahl", value: "87781", alias: "87781" },
  { category: "Postleitzahl", value: "87782", alias: "87782" },
  { category: "Postleitzahl", value: "87784", alias: "87784" },
  { category: "Postleitzahl", value: "87785", alias: "87785" },
  { category: "Postleitzahl", value: "87787", alias: "87787" },
  { category: "Postleitzahl", value: "87789", alias: "87789" },
  { category: "Postleitzahl", value: "88045", alias: "88045" },
  { category: "Postleitzahl", value: "88046", alias: "88046" },
  { category: "Postleitzahl", value: "88048", alias: "88048" },
  { category: "Postleitzahl", value: "88069", alias: "88069" },
  { category: "Postleitzahl", value: "88074", alias: "88074" },
  { category: "Postleitzahl", value: "88079", alias: "88079" },
  { category: "Postleitzahl", value: "88085", alias: "88085" },
  { category: "Postleitzahl", value: "88090", alias: "88090" },
  { category: "Postleitzahl", value: "88094", alias: "88094" },
  { category: "Postleitzahl", value: "88097", alias: "88097" },
  { category: "Postleitzahl", value: "88099", alias: "88099" },
  { category: "Postleitzahl", value: "88131", alias: "88131" },
  { category: "Postleitzahl", value: "88138", alias: "88138" },
  { category: "Postleitzahl", value: "88142", alias: "88142" },
  { category: "Postleitzahl", value: "88145", alias: "88145" },
  { category: "Postleitzahl", value: "88147", alias: "88147" },
  { category: "Postleitzahl", value: "88149", alias: "88149" },
  { category: "Postleitzahl", value: "88161", alias: "88161" },
  { category: "Postleitzahl", value: "88167", alias: "88167" },
  { category: "Postleitzahl", value: "88171", alias: "88171" },
  { category: "Postleitzahl", value: "88175", alias: "88175" },
  { category: "Postleitzahl", value: "88178", alias: "88178" },
  { category: "Postleitzahl", value: "88179", alias: "88179" },
  { category: "Postleitzahl", value: "88212", alias: "88212" },
  { category: "Postleitzahl", value: "88213", alias: "88213" },
  { category: "Postleitzahl", value: "88214", alias: "88214" },
  { category: "Postleitzahl", value: "88239", alias: "88239" },
  { category: "Postleitzahl", value: "88250", alias: "88250" },
  { category: "Postleitzahl", value: "88255", alias: "88255" },
  { category: "Postleitzahl", value: "88260", alias: "88260" },
  { category: "Postleitzahl", value: "88263", alias: "88263" },
  { category: "Postleitzahl", value: "88267", alias: "88267" },
  { category: "Postleitzahl", value: "88271", alias: "88271" },
  { category: "Postleitzahl", value: "88273", alias: "88273" },
  { category: "Postleitzahl", value: "88276", alias: "88276" },
  { category: "Postleitzahl", value: "88279", alias: "88279" },
  { category: "Postleitzahl", value: "88281", alias: "88281" },
  { category: "Postleitzahl", value: "88284", alias: "88284" },
  { category: "Postleitzahl", value: "88285", alias: "88285" },
  { category: "Postleitzahl", value: "88287", alias: "88287" },
  { category: "Postleitzahl", value: "88289", alias: "88289" },
  { category: "Postleitzahl", value: "88299", alias: "88299" },
  { category: "Postleitzahl", value: "88316", alias: "88316" },
  { category: "Postleitzahl", value: "88317", alias: "88317" },
  { category: "Postleitzahl", value: "88319", alias: "88319" },
  { category: "Postleitzahl", value: "88326", alias: "88326" },
  { category: "Postleitzahl", value: "88339", alias: "88339" },
  { category: "Postleitzahl", value: "88348", alias: "88348" },
  { category: "Postleitzahl", value: "88353", alias: "88353" },
  { category: "Postleitzahl", value: "88356", alias: "88356" },
  { category: "Postleitzahl", value: "88361", alias: "88361" },
  { category: "Postleitzahl", value: "88364", alias: "88364" },
  { category: "Postleitzahl", value: "88367", alias: "88367" },
  { category: "Postleitzahl", value: "88368", alias: "88368" },
  { category: "Postleitzahl", value: "88370", alias: "88370" },
  { category: "Postleitzahl", value: "88371", alias: "88371" },
  { category: "Postleitzahl", value: "88373", alias: "88373" },
  { category: "Postleitzahl", value: "88374", alias: "88374" },
  { category: "Postleitzahl", value: "88376", alias: "88376" },
  { category: "Postleitzahl", value: "88377", alias: "88377" },
  { category: "Postleitzahl", value: "88379", alias: "88379" },
  { category: "Postleitzahl", value: "88400", alias: "88400" },
  { category: "Postleitzahl", value: "88410", alias: "88410" },
  { category: "Postleitzahl", value: "88416", alias: "88416" },
  { category: "Postleitzahl", value: "88422", alias: "88422" },
  { category: "Postleitzahl", value: "88427", alias: "88427" },
  { category: "Postleitzahl", value: "88430", alias: "88430" },
  { category: "Postleitzahl", value: "88433", alias: "88433" },
  { category: "Postleitzahl", value: "88436", alias: "88436" },
  { category: "Postleitzahl", value: "88437", alias: "88437" },
  { category: "Postleitzahl", value: "88441", alias: "88441" },
  { category: "Postleitzahl", value: "88444", alias: "88444" },
  { category: "Postleitzahl", value: "88447", alias: "88447" },
  { category: "Postleitzahl", value: "88448", alias: "88448" },
  { category: "Postleitzahl", value: "88450", alias: "88450" },
  { category: "Postleitzahl", value: "88451", alias: "88451" },
  { category: "Postleitzahl", value: "88453", alias: "88453" },
  { category: "Postleitzahl", value: "88454", alias: "88454" },
  { category: "Postleitzahl", value: "88456", alias: "88456" },
  { category: "Postleitzahl", value: "88457", alias: "88457" },
  { category: "Postleitzahl", value: "88459", alias: "88459" },
  { category: "Postleitzahl", value: "88471", alias: "88471" },
  { category: "Postleitzahl", value: "88477", alias: "88477" },
  { category: "Postleitzahl", value: "88480", alias: "88480" },
  { category: "Postleitzahl", value: "88481", alias: "88481" },
  { category: "Postleitzahl", value: "88483", alias: "88483" },
  { category: "Postleitzahl", value: "88484", alias: "88484" },
  { category: "Postleitzahl", value: "88486", alias: "88486" },
  { category: "Postleitzahl", value: "88487", alias: "88487" },
  { category: "Postleitzahl", value: "88489", alias: "88489" },
  { category: "Postleitzahl", value: "88499", alias: "88499" },
  { category: "Postleitzahl", value: "88512", alias: "88512" },
  { category: "Postleitzahl", value: "88515", alias: "88515" },
  { category: "Postleitzahl", value: "88518", alias: "88518" },
  { category: "Postleitzahl", value: "88521", alias: "88521" },
  { category: "Postleitzahl", value: "88524", alias: "88524" },
  { category: "Postleitzahl", value: "88525", alias: "88525" },
  { category: "Postleitzahl", value: "88527", alias: "88527" },
  { category: "Postleitzahl", value: "88529", alias: "88529" },
  { category: "Postleitzahl", value: "88605", alias: "88605" },
  { category: "Postleitzahl", value: "88630", alias: "88630" },
  { category: "Postleitzahl", value: "88631", alias: "88631" },
  { category: "Postleitzahl", value: "88633", alias: "88633" },
  { category: "Postleitzahl", value: "88634", alias: "88634" },
  { category: "Postleitzahl", value: "88636", alias: "88636" },
  { category: "Postleitzahl", value: "88637", alias: "88637" },
  { category: "Postleitzahl", value: "88639", alias: "88639" },
  { category: "Postleitzahl", value: "88662", alias: "88662" },
  { category: "Postleitzahl", value: "88677", alias: "88677" },
  { category: "Postleitzahl", value: "88682", alias: "88682" },
  { category: "Postleitzahl", value: "88690", alias: "88690" },
  { category: "Postleitzahl", value: "88693", alias: "88693" },
  { category: "Postleitzahl", value: "88696", alias: "88696" },
  { category: "Postleitzahl", value: "88697", alias: "88697" },
  { category: "Postleitzahl", value: "88699", alias: "88699" },
  { category: "Postleitzahl", value: "88709", alias: "88709" },
  { category: "Postleitzahl", value: "88718", alias: "88718" },
  { category: "Postleitzahl", value: "88719", alias: "88719" },
  { category: "Postleitzahl", value: "89073", alias: "89073" },
  { category: "Postleitzahl", value: "89075", alias: "89075" },
  { category: "Postleitzahl", value: "89077", alias: "89077" },
  { category: "Postleitzahl", value: "89079", alias: "89079" },
  { category: "Postleitzahl", value: "89081", alias: "89081" },
  { category: "Postleitzahl", value: "89129", alias: "89129" },
  { category: "Postleitzahl", value: "89134", alias: "89134" },
  { category: "Postleitzahl", value: "89143", alias: "89143" },
  { category: "Postleitzahl", value: "89150", alias: "89150" },
  { category: "Postleitzahl", value: "89155", alias: "89155" },
  { category: "Postleitzahl", value: "89160", alias: "89160" },
  { category: "Postleitzahl", value: "89165", alias: "89165" },
  { category: "Postleitzahl", value: "89168", alias: "89168" },
  { category: "Postleitzahl", value: "89171", alias: "89171" },
  { category: "Postleitzahl", value: "89173", alias: "89173" },
  { category: "Postleitzahl", value: "89174", alias: "89174" },
  { category: "Postleitzahl", value: "89176", alias: "89176" },
  { category: "Postleitzahl", value: "89177", alias: "89177" },
  { category: "Postleitzahl", value: "89179", alias: "89179" },
  { category: "Postleitzahl", value: "89180", alias: "89180" },
  { category: "Postleitzahl", value: "89182", alias: "89182" },
  { category: "Postleitzahl", value: "89183", alias: "89183" },
  { category: "Postleitzahl", value: "89185", alias: "89185" },
  { category: "Postleitzahl", value: "89186", alias: "89186" },
  { category: "Postleitzahl", value: "89188", alias: "89188" },
  { category: "Postleitzahl", value: "89189", alias: "89189" },
  { category: "Postleitzahl", value: "89191", alias: "89191" },
  { category: "Postleitzahl", value: "89192", alias: "89192" },
  { category: "Postleitzahl", value: "89194", alias: "89194" },
  { category: "Postleitzahl", value: "89195", alias: "89195" },
  { category: "Postleitzahl", value: "89197", alias: "89197" },
  { category: "Postleitzahl", value: "89198", alias: "89198" },
  { category: "Postleitzahl", value: "89231", alias: "89231" },
  { category: "Postleitzahl", value: "89233", alias: "89233" },
  { category: "Postleitzahl", value: "89250", alias: "89250" },
  { category: "Postleitzahl", value: "89257", alias: "89257" },
  { category: "Postleitzahl", value: "89264", alias: "89264" },
  { category: "Postleitzahl", value: "89269", alias: "89269" },
  { category: "Postleitzahl", value: "89275", alias: "89275" },
  { category: "Postleitzahl", value: "89278", alias: "89278" },
  { category: "Postleitzahl", value: "89281", alias: "89281" },
  { category: "Postleitzahl", value: "89284", alias: "89284" },
  { category: "Postleitzahl", value: "89287", alias: "89287" },
  { category: "Postleitzahl", value: "89290", alias: "89290" },
  { category: "Postleitzahl", value: "89291", alias: "89291" },
  { category: "Postleitzahl", value: "89293", alias: "89293" },
  { category: "Postleitzahl", value: "89294", alias: "89294" },
  { category: "Postleitzahl", value: "89296", alias: "89296" },
  { category: "Postleitzahl", value: "89297", alias: "89297" },
  { category: "Postleitzahl", value: "89299", alias: "89299" },
  { category: "Postleitzahl", value: "89312", alias: "89312" },
  { category: "Postleitzahl", value: "89331", alias: "89331" },
  { category: "Postleitzahl", value: "89335", alias: "89335" },
  { category: "Postleitzahl", value: "89340", alias: "89340" },
  { category: "Postleitzahl", value: "89343", alias: "89343" },
  { category: "Postleitzahl", value: "89344", alias: "89344" },
  { category: "Postleitzahl", value: "89346", alias: "89346" },
  { category: "Postleitzahl", value: "89347", alias: "89347" },
  { category: "Postleitzahl", value: "89349", alias: "89349" },
  { category: "Postleitzahl", value: "89350", alias: "89350" },
  { category: "Postleitzahl", value: "89352", alias: "89352" },
  { category: "Postleitzahl", value: "89353", alias: "89353" },
  { category: "Postleitzahl", value: "89355", alias: "89355" },
  { category: "Postleitzahl", value: "89356", alias: "89356" },
  { category: "Postleitzahl", value: "89358", alias: "89358" },
  { category: "Postleitzahl", value: "89359", alias: "89359" },
  { category: "Postleitzahl", value: "89361", alias: "89361" },
  { category: "Postleitzahl", value: "89362", alias: "89362" },
  { category: "Postleitzahl", value: "89364", alias: "89364" },
  { category: "Postleitzahl", value: "89365", alias: "89365" },
  { category: "Postleitzahl", value: "89367", alias: "89367" },
  { category: "Postleitzahl", value: "89368", alias: "89368" },
  { category: "Postleitzahl", value: "89407", alias: "89407" },
  { category: "Postleitzahl", value: "89415", alias: "89415" },
  { category: "Postleitzahl", value: "89420", alias: "89420" },
  { category: "Postleitzahl", value: "89423", alias: "89423" },
  { category: "Postleitzahl", value: "89426", alias: "89426" },
  { category: "Postleitzahl", value: "89428", alias: "89428" },
  { category: "Postleitzahl", value: "89429", alias: "89429" },
  { category: "Postleitzahl", value: "89431", alias: "89431" },
  { category: "Postleitzahl", value: "89434", alias: "89434" },
  { category: "Postleitzahl", value: "89435", alias: "89435" },
  { category: "Postleitzahl", value: "89437", alias: "89437" },
  { category: "Postleitzahl", value: "89438", alias: "89438" },
  { category: "Postleitzahl", value: "89440", alias: "89440" },
  { category: "Postleitzahl", value: "89441", alias: "89441" },
  { category: "Postleitzahl", value: "89443", alias: "89443" },
  { category: "Postleitzahl", value: "89446", alias: "89446" },
  { category: "Postleitzahl", value: "89447", alias: "89447" },
  { category: "Postleitzahl", value: "89518", alias: "89518" },
  { category: "Postleitzahl", value: "89520", alias: "89520" },
  { category: "Postleitzahl", value: "89522", alias: "89522" },
  { category: "Postleitzahl", value: "89537", alias: "89537" },
  { category: "Postleitzahl", value: "89542", alias: "89542" },
  { category: "Postleitzahl", value: "89547", alias: "89547" },
  { category: "Postleitzahl", value: "89551", alias: "89551" },
  { category: "Postleitzahl", value: "89555", alias: "89555" },
  { category: "Postleitzahl", value: "89558", alias: "89558" },
  { category: "Postleitzahl", value: "89561", alias: "89561" },
  { category: "Postleitzahl", value: "89564", alias: "89564" },
  { category: "Postleitzahl", value: "89567", alias: "89567" },
  { category: "Postleitzahl", value: "89568", alias: "89568" },
  { category: "Postleitzahl", value: "89584", alias: "89584" },
  { category: "Postleitzahl", value: "89597", alias: "89597" },
  { category: "Postleitzahl", value: "89601", alias: "89601" },
  { category: "Postleitzahl", value: "89604", alias: "89604" },
  { category: "Postleitzahl", value: "89605", alias: "89605" },
  { category: "Postleitzahl", value: "89607", alias: "89607" },
  { category: "Postleitzahl", value: "89608", alias: "89608" },
  { category: "Postleitzahl", value: "89610", alias: "89610" },
  { category: "Postleitzahl", value: "89611", alias: "89611" },
  { category: "Postleitzahl", value: "89613", alias: "89613" },
  { category: "Postleitzahl", value: "89614", alias: "89614" },
  { category: "Postleitzahl", value: "89616", alias: "89616" },
  { category: "Postleitzahl", value: "89617", alias: "89617" },
  { category: "Postleitzahl", value: "89619", alias: "89619" },
  { category: "Postleitzahl", value: "90402", alias: "90402" },
  { category: "Postleitzahl", value: "90403", alias: "90403" },
  { category: "Postleitzahl", value: "90408", alias: "90408" },
  { category: "Postleitzahl", value: "90409", alias: "90409" },
  { category: "Postleitzahl", value: "90411", alias: "90411" },
  { category: "Postleitzahl", value: "90419", alias: "90419" },
  { category: "Postleitzahl", value: "90425", alias: "90425" },
  { category: "Postleitzahl", value: "90427", alias: "90427" },
  { category: "Postleitzahl", value: "90429", alias: "90429" },
  { category: "Postleitzahl", value: "90431", alias: "90431" },
  { category: "Postleitzahl", value: "90439", alias: "90439" },
  { category: "Postleitzahl", value: "90441", alias: "90441" },
  { category: "Postleitzahl", value: "90443", alias: "90443" },
  { category: "Postleitzahl", value: "90449", alias: "90449" },
  { category: "Postleitzahl", value: "90451", alias: "90451" },
  { category: "Postleitzahl", value: "90453", alias: "90453" },
  { category: "Postleitzahl", value: "90455", alias: "90455" },
  { category: "Postleitzahl", value: "90459", alias: "90459" },
  { category: "Postleitzahl", value: "90461", alias: "90461" },
  { category: "Postleitzahl", value: "90469", alias: "90469" },
  { category: "Postleitzahl", value: "90471", alias: "90471" },
  { category: "Postleitzahl", value: "90473", alias: "90473" },
  { category: "Postleitzahl", value: "90475", alias: "90475" },
  { category: "Postleitzahl", value: "90478", alias: "90478" },
  { category: "Postleitzahl", value: "90480", alias: "90480" },
  { category: "Postleitzahl", value: "90482", alias: "90482" },
  { category: "Postleitzahl", value: "90489", alias: "90489" },
  { category: "Postleitzahl", value: "90491", alias: "90491" },
  { category: "Postleitzahl", value: "90513", alias: "90513" },
  { category: "Postleitzahl", value: "90518", alias: "90518" },
  { category: "Postleitzahl", value: "90522", alias: "90522" },
  { category: "Postleitzahl", value: "90530", alias: "90530" },
  { category: "Postleitzahl", value: "90537", alias: "90537" },
  { category: "Postleitzahl", value: "90542", alias: "90542" },
  { category: "Postleitzahl", value: "90547", alias: "90547" },
  { category: "Postleitzahl", value: "90552", alias: "90552" },
  { category: "Postleitzahl", value: "90556", alias: "90556" },
  { category: "Postleitzahl", value: "90559", alias: "90559" },
  { category: "Postleitzahl", value: "90562", alias: "90562" },
  { category: "Postleitzahl", value: "90571", alias: "90571" },
  { category: "Postleitzahl", value: "90574", alias: "90574" },
  { category: "Postleitzahl", value: "90579", alias: "90579" },
  { category: "Postleitzahl", value: "90584", alias: "90584" },
  { category: "Postleitzahl", value: "90587", alias: "90587" },
  { category: "Postleitzahl", value: "90592", alias: "90592" },
  { category: "Postleitzahl", value: "90596", alias: "90596" },
  { category: "Postleitzahl", value: "90599", alias: "90599" },
  { category: "Postleitzahl", value: "90602", alias: "90602" },
  { category: "Postleitzahl", value: "90607", alias: "90607" },
  { category: "Postleitzahl", value: "90610", alias: "90610" },
  { category: "Postleitzahl", value: "90613", alias: "90613" },
  { category: "Postleitzahl", value: "90614", alias: "90614" },
  { category: "Postleitzahl", value: "90616", alias: "90616" },
  { category: "Postleitzahl", value: "90617", alias: "90617" },
  { category: "Postleitzahl", value: "90619", alias: "90619" },
  { category: "Postleitzahl", value: "90762", alias: "90762" },
  { category: "Postleitzahl", value: "90763", alias: "90763" },
  { category: "Postleitzahl", value: "90765", alias: "90765" },
  { category: "Postleitzahl", value: "90766", alias: "90766" },
  { category: "Postleitzahl", value: "90768", alias: "90768" },
  { category: "Postleitzahl", value: "91052", alias: "91052" },
  { category: "Postleitzahl", value: "91054", alias: "91054" },
  { category: "Postleitzahl", value: "91056", alias: "91056" },
  { category: "Postleitzahl", value: "91058", alias: "91058" },
  { category: "Postleitzahl", value: "91074", alias: "91074" },
  { category: "Postleitzahl", value: "91077", alias: "91077" },
  { category: "Postleitzahl", value: "91080", alias: "91080" },
  { category: "Postleitzahl", value: "91083", alias: "91083" },
  { category: "Postleitzahl", value: "91085", alias: "91085" },
  { category: "Postleitzahl", value: "91086", alias: "91086" },
  { category: "Postleitzahl", value: "91088", alias: "91088" },
  { category: "Postleitzahl", value: "91090", alias: "91090" },
  { category: "Postleitzahl", value: "91091", alias: "91091" },
  { category: "Postleitzahl", value: "91093", alias: "91093" },
  { category: "Postleitzahl", value: "91094", alias: "91094" },
  { category: "Postleitzahl", value: "91096", alias: "91096" },
  { category: "Postleitzahl", value: "91097", alias: "91097" },
  { category: "Postleitzahl", value: "91099", alias: "91099" },
  { category: "Postleitzahl", value: "91126", alias: "91126" },
  { category: "Postleitzahl", value: "91154", alias: "91154" },
  { category: "Postleitzahl", value: "91161", alias: "91161" },
  { category: "Postleitzahl", value: "91166", alias: "91166" },
  { category: "Postleitzahl", value: "91171", alias: "91171" },
  { category: "Postleitzahl", value: "91174", alias: "91174" },
  { category: "Postleitzahl", value: "91177", alias: "91177" },
  { category: "Postleitzahl", value: "91180", alias: "91180" },
  { category: "Postleitzahl", value: "91183", alias: "91183" },
  { category: "Postleitzahl", value: "91186", alias: "91186" },
  { category: "Postleitzahl", value: "91187", alias: "91187" },
  { category: "Postleitzahl", value: "91189", alias: "91189" },
  { category: "Postleitzahl", value: "91207", alias: "91207" },
  { category: "Postleitzahl", value: "91217", alias: "91217" },
  { category: "Postleitzahl", value: "91220", alias: "91220" },
  { category: "Postleitzahl", value: "91224", alias: "91224" },
  { category: "Postleitzahl", value: "91227", alias: "91227" },
  { category: "Postleitzahl", value: "91230", alias: "91230" },
  { category: "Postleitzahl", value: "91233", alias: "91233" },
  { category: "Postleitzahl", value: "91235", alias: "91235" },
  { category: "Postleitzahl", value: "91236", alias: "91236" },
  { category: "Postleitzahl", value: "91238", alias: "91238" },
  { category: "Postleitzahl", value: "91239", alias: "91239" },
  { category: "Postleitzahl", value: "91241", alias: "91241" },
  { category: "Postleitzahl", value: "91242", alias: "91242" },
  { category: "Postleitzahl", value: "91244", alias: "91244" },
  { category: "Postleitzahl", value: "91245", alias: "91245" },
  { category: "Postleitzahl", value: "91247", alias: "91247" },
  { category: "Postleitzahl", value: "91249", alias: "91249" },
  { category: "Postleitzahl", value: "91257", alias: "91257" },
  { category: "Postleitzahl", value: "91275", alias: "91275" },
  { category: "Postleitzahl", value: "91278", alias: "91278" },
  { category: "Postleitzahl", value: "91281", alias: "91281" },
  { category: "Postleitzahl", value: "91282", alias: "91282" },
  { category: "Postleitzahl", value: "91284", alias: "91284" },
  { category: "Postleitzahl", value: "91286", alias: "91286" },
  { category: "Postleitzahl", value: "91287", alias: "91287" },
  { category: "Postleitzahl", value: "91289", alias: "91289" },
  { category: "Postleitzahl", value: "91301", alias: "91301" },
  { category: "Postleitzahl", value: "91315", alias: "91315" },
  { category: "Postleitzahl", value: "91320", alias: "91320" },
  { category: "Postleitzahl", value: "91322", alias: "91322" },
  { category: "Postleitzahl", value: "91325", alias: "91325" },
  { category: "Postleitzahl", value: "91327", alias: "91327" },
  { category: "Postleitzahl", value: "91330", alias: "91330" },
  { category: "Postleitzahl", value: "91332", alias: "91332" },
  { category: "Postleitzahl", value: "91334", alias: "91334" },
  { category: "Postleitzahl", value: "91336", alias: "91336" },
  { category: "Postleitzahl", value: "91338", alias: "91338" },
  { category: "Postleitzahl", value: "91341", alias: "91341" },
  { category: "Postleitzahl", value: "91344", alias: "91344" },
  { category: "Postleitzahl", value: "91346", alias: "91346" },
  { category: "Postleitzahl", value: "91347", alias: "91347" },
  { category: "Postleitzahl", value: "91349", alias: "91349" },
  { category: "Postleitzahl", value: "91350", alias: "91350" },
  { category: "Postleitzahl", value: "91352", alias: "91352" },
  { category: "Postleitzahl", value: "91353", alias: "91353" },
  { category: "Postleitzahl", value: "91355", alias: "91355" },
  { category: "Postleitzahl", value: "91356", alias: "91356" },
  { category: "Postleitzahl", value: "91358", alias: "91358" },
  { category: "Postleitzahl", value: "91359", alias: "91359" },
  { category: "Postleitzahl", value: "91361", alias: "91361" },
  { category: "Postleitzahl", value: "91362", alias: "91362" },
  { category: "Postleitzahl", value: "91364", alias: "91364" },
  { category: "Postleitzahl", value: "91365", alias: "91365" },
  { category: "Postleitzahl", value: "91367", alias: "91367" },
  { category: "Postleitzahl", value: "91369", alias: "91369" },
  { category: "Postleitzahl", value: "91413", alias: "91413" },
  { category: "Postleitzahl", value: "91438", alias: "91438" },
  { category: "Postleitzahl", value: "91443", alias: "91443" },
  { category: "Postleitzahl", value: "91448", alias: "91448" },
  { category: "Postleitzahl", value: "91452", alias: "91452" },
  { category: "Postleitzahl", value: "91456", alias: "91456" },
  { category: "Postleitzahl", value: "91459", alias: "91459" },
  { category: "Postleitzahl", value: "91460", alias: "91460" },
  { category: "Postleitzahl", value: "91462", alias: "91462" },
  { category: "Postleitzahl", value: "91463", alias: "91463" },
  { category: "Postleitzahl", value: "91465", alias: "91465" },
  { category: "Postleitzahl", value: "91466", alias: "91466" },
  { category: "Postleitzahl", value: "91468", alias: "91468" },
  { category: "Postleitzahl", value: "91469", alias: "91469" },
  { category: "Postleitzahl", value: "91471", alias: "91471" },
  { category: "Postleitzahl", value: "91472", alias: "91472" },
  { category: "Postleitzahl", value: "91474", alias: "91474" },
  { category: "Postleitzahl", value: "91475", alias: "91475" },
  { category: "Postleitzahl", value: "91477", alias: "91477" },
  { category: "Postleitzahl", value: "91478", alias: "91478" },
  { category: "Postleitzahl", value: "91480", alias: "91480" },
  { category: "Postleitzahl", value: "91481", alias: "91481" },
  { category: "Postleitzahl", value: "91483", alias: "91483" },
  { category: "Postleitzahl", value: "91484", alias: "91484" },
  { category: "Postleitzahl", value: "91486", alias: "91486" },
  { category: "Postleitzahl", value: "91487", alias: "91487" },
  { category: "Postleitzahl", value: "91489", alias: "91489" },
  { category: "Postleitzahl", value: "91522", alias: "91522" },
  { category: "Postleitzahl", value: "91541", alias: "91541" },
  { category: "Postleitzahl", value: "91550", alias: "91550" },
  { category: "Postleitzahl", value: "91555", alias: "91555" },
  { category: "Postleitzahl", value: "91560", alias: "91560" },
  { category: "Postleitzahl", value: "91564", alias: "91564" },
  { category: "Postleitzahl", value: "91567", alias: "91567" },
  { category: "Postleitzahl", value: "91572", alias: "91572" },
  { category: "Postleitzahl", value: "91575", alias: "91575" },
  { category: "Postleitzahl", value: "91578", alias: "91578" },
  { category: "Postleitzahl", value: "91580", alias: "91580" },
  { category: "Postleitzahl", value: "91583", alias: "91583" },
  { category: "Postleitzahl", value: "91586", alias: "91586" },
  { category: "Postleitzahl", value: "91587", alias: "91587" },
  { category: "Postleitzahl", value: "91589", alias: "91589" },
  { category: "Postleitzahl", value: "91590", alias: "91590" },
  { category: "Postleitzahl", value: "91592", alias: "91592" },
  { category: "Postleitzahl", value: "91593", alias: "91593" },
  { category: "Postleitzahl", value: "91595", alias: "91595" },
  { category: "Postleitzahl", value: "91596", alias: "91596" },
  { category: "Postleitzahl", value: "91598", alias: "91598" },
  { category: "Postleitzahl", value: "91599", alias: "91599" },
  { category: "Postleitzahl", value: "91601", alias: "91601" },
  { category: "Postleitzahl", value: "91602", alias: "91602" },
  { category: "Postleitzahl", value: "91604", alias: "91604" },
  { category: "Postleitzahl", value: "91605", alias: "91605" },
  { category: "Postleitzahl", value: "91607", alias: "91607" },
  { category: "Postleitzahl", value: "91608", alias: "91608" },
  { category: "Postleitzahl", value: "91610", alias: "91610" },
  { category: "Postleitzahl", value: "91611", alias: "91611" },
  { category: "Postleitzahl", value: "91613", alias: "91613" },
  { category: "Postleitzahl", value: "91614", alias: "91614" },
  { category: "Postleitzahl", value: "91616", alias: "91616" },
  { category: "Postleitzahl", value: "91617", alias: "91617" },
  { category: "Postleitzahl", value: "91619", alias: "91619" },
  { category: "Postleitzahl", value: "91620", alias: "91620" },
  { category: "Postleitzahl", value: "91622", alias: "91622" },
  { category: "Postleitzahl", value: "91623", alias: "91623" },
  { category: "Postleitzahl", value: "91625", alias: "91625" },
  { category: "Postleitzahl", value: "91626", alias: "91626" },
  { category: "Postleitzahl", value: "91628", alias: "91628" },
  { category: "Postleitzahl", value: "91629", alias: "91629" },
  { category: "Postleitzahl", value: "91631", alias: "91631" },
  { category: "Postleitzahl", value: "91632", alias: "91632" },
  { category: "Postleitzahl", value: "91634", alias: "91634" },
  { category: "Postleitzahl", value: "91635", alias: "91635" },
  { category: "Postleitzahl", value: "91637", alias: "91637" },
  { category: "Postleitzahl", value: "91639", alias: "91639" },
  { category: "Postleitzahl", value: "91710", alias: "91710" },
  { category: "Postleitzahl", value: "91717", alias: "91717" },
  { category: "Postleitzahl", value: "91719", alias: "91719" },
  { category: "Postleitzahl", value: "91720", alias: "91720" },
  { category: "Postleitzahl", value: "91722", alias: "91722" },
  { category: "Postleitzahl", value: "91723", alias: "91723" },
  { category: "Postleitzahl", value: "91725", alias: "91725" },
  { category: "Postleitzahl", value: "91726", alias: "91726" },
  { category: "Postleitzahl", value: "91728", alias: "91728" },
  { category: "Postleitzahl", value: "91729", alias: "91729" },
  { category: "Postleitzahl", value: "91731", alias: "91731" },
  { category: "Postleitzahl", value: "91732", alias: "91732" },
  { category: "Postleitzahl", value: "91734", alias: "91734" },
  { category: "Postleitzahl", value: "91735", alias: "91735" },
  { category: "Postleitzahl", value: "91737", alias: "91737" },
  { category: "Postleitzahl", value: "91738", alias: "91738" },
  { category: "Postleitzahl", value: "91740", alias: "91740" },
  { category: "Postleitzahl", value: "91741", alias: "91741" },
  { category: "Postleitzahl", value: "91743", alias: "91743" },
  { category: "Postleitzahl", value: "91744", alias: "91744" },
  { category: "Postleitzahl", value: "91746", alias: "91746" },
  { category: "Postleitzahl", value: "91747", alias: "91747" },
  { category: "Postleitzahl", value: "91749", alias: "91749" },
  { category: "Postleitzahl", value: "91757", alias: "91757" },
  { category: "Postleitzahl", value: "91781", alias: "91781" },
  { category: "Postleitzahl", value: "91785", alias: "91785" },
  { category: "Postleitzahl", value: "91788", alias: "91788" },
  { category: "Postleitzahl", value: "91790", alias: "91790" },
  { category: "Postleitzahl", value: "91792", alias: "91792" },
  { category: "Postleitzahl", value: "91793", alias: "91793" },
  { category: "Postleitzahl", value: "91795", alias: "91795" },
  { category: "Postleitzahl", value: "91796", alias: "91796" },
  { category: "Postleitzahl", value: "91798", alias: "91798" },
  { category: "Postleitzahl", value: "91799", alias: "91799" },
  { category: "Postleitzahl", value: "91801", alias: "91801" },
  { category: "Postleitzahl", value: "91802", alias: "91802" },
  { category: "Postleitzahl", value: "91804", alias: "91804" },
  { category: "Postleitzahl", value: "91805", alias: "91805" },
  { category: "Postleitzahl", value: "91807", alias: "91807" },
  { category: "Postleitzahl", value: "91809", alias: "91809" },
  { category: "Postleitzahl", value: "92224", alias: "92224" },
  { category: "Postleitzahl", value: "92237", alias: "92237" },
  { category: "Postleitzahl", value: "92242", alias: "92242" },
  { category: "Postleitzahl", value: "92245", alias: "92245" },
  { category: "Postleitzahl", value: "92249", alias: "92249" },
  { category: "Postleitzahl", value: "92253", alias: "92253" },
  { category: "Postleitzahl", value: "92256", alias: "92256" },
  { category: "Postleitzahl", value: "92259", alias: "92259" },
  { category: "Postleitzahl", value: "92260", alias: "92260" },
  { category: "Postleitzahl", value: "92262", alias: "92262" },
  { category: "Postleitzahl", value: "92263", alias: "92263" },
  { category: "Postleitzahl", value: "92265", alias: "92265" },
  { category: "Postleitzahl", value: "92266", alias: "92266" },
  { category: "Postleitzahl", value: "92268", alias: "92268" },
  { category: "Postleitzahl", value: "92269", alias: "92269" },
  { category: "Postleitzahl", value: "92271", alias: "92271" },
  { category: "Postleitzahl", value: "92272", alias: "92272" },
  { category: "Postleitzahl", value: "92274", alias: "92274" },
  { category: "Postleitzahl", value: "92275", alias: "92275" },
  { category: "Postleitzahl", value: "92277", alias: "92277" },
  { category: "Postleitzahl", value: "92278", alias: "92278" },
  { category: "Postleitzahl", value: "92280", alias: "92280" },
  { category: "Postleitzahl", value: "92281", alias: "92281" },
  { category: "Postleitzahl", value: "92283", alias: "92283" },
  { category: "Postleitzahl", value: "92284", alias: "92284" },
  { category: "Postleitzahl", value: "92286", alias: "92286" },
  { category: "Postleitzahl", value: "92287", alias: "92287" },
  { category: "Postleitzahl", value: "92289", alias: "92289" },
  { category: "Postleitzahl", value: "92318", alias: "92318" },
  { category: "Postleitzahl", value: "92331", alias: "92331" },
  { category: "Postleitzahl", value: "92334", alias: "92334" },
  { category: "Postleitzahl", value: "92339", alias: "92339" },
  { category: "Postleitzahl", value: "92342", alias: "92342" },
  { category: "Postleitzahl", value: "92345", alias: "92345" },
  { category: "Postleitzahl", value: "92348", alias: "92348" },
  { category: "Postleitzahl", value: "92353", alias: "92353" },
  { category: "Postleitzahl", value: "92355", alias: "92355" },
  { category: "Postleitzahl", value: "92358", alias: "92358" },
  { category: "Postleitzahl", value: "92360", alias: "92360" },
  { category: "Postleitzahl", value: "92361", alias: "92361" },
  { category: "Postleitzahl", value: "92363", alias: "92363" },
  { category: "Postleitzahl", value: "92364", alias: "92364" },
  { category: "Postleitzahl", value: "92366", alias: "92366" },
  { category: "Postleitzahl", value: "92367", alias: "92367" },
  { category: "Postleitzahl", value: "92369", alias: "92369" },
  { category: "Postleitzahl", value: "92421", alias: "92421" },
  { category: "Postleitzahl", value: "92431", alias: "92431" },
  { category: "Postleitzahl", value: "92436", alias: "92436" },
  { category: "Postleitzahl", value: "92439", alias: "92439" },
  { category: "Postleitzahl", value: "92442", alias: "92442" },
  { category: "Postleitzahl", value: "92444", alias: "92444" },
  { category: "Postleitzahl", value: "92445", alias: "92445" },
  { category: "Postleitzahl", value: "92447", alias: "92447" },
  { category: "Postleitzahl", value: "92449", alias: "92449" },
  { category: "Postleitzahl", value: "92507", alias: "92507" },
  { category: "Postleitzahl", value: "92521", alias: "92521" },
  { category: "Postleitzahl", value: "92526", alias: "92526" },
  { category: "Postleitzahl", value: "92533", alias: "92533" },
  { category: "Postleitzahl", value: "92536", alias: "92536" },
  { category: "Postleitzahl", value: "92539", alias: "92539" },
  { category: "Postleitzahl", value: "92540", alias: "92540" },
  { category: "Postleitzahl", value: "92542", alias: "92542" },
  { category: "Postleitzahl", value: "92543", alias: "92543" },
  { category: "Postleitzahl", value: "92545", alias: "92545" },
  { category: "Postleitzahl", value: "92546", alias: "92546" },
  { category: "Postleitzahl", value: "92548", alias: "92548" },
  { category: "Postleitzahl", value: "92549", alias: "92549" },
  { category: "Postleitzahl", value: "92551", alias: "92551" },
  { category: "Postleitzahl", value: "92552", alias: "92552" },
  { category: "Postleitzahl", value: "92554", alias: "92554" },
  { category: "Postleitzahl", value: "92555", alias: "92555" },
  { category: "Postleitzahl", value: "92557", alias: "92557" },
  { category: "Postleitzahl", value: "92559", alias: "92559" },
  { category: "Postleitzahl", value: "92637", alias: "92637" },
  { category: "Postleitzahl", value: "92648", alias: "92648" },
  { category: "Postleitzahl", value: "92655", alias: "92655" },
  { category: "Postleitzahl", value: "92660", alias: "92660" },
  { category: "Postleitzahl", value: "92665", alias: "92665" },
  { category: "Postleitzahl", value: "92670", alias: "92670" },
  { category: "Postleitzahl", value: "92676", alias: "92676" },
  { category: "Postleitzahl", value: "92681", alias: "92681" },
  { category: "Postleitzahl", value: "92685", alias: "92685" },
  { category: "Postleitzahl", value: "92690", alias: "92690" },
  { category: "Postleitzahl", value: "92693", alias: "92693" },
  { category: "Postleitzahl", value: "92694", alias: "92694" },
  { category: "Postleitzahl", value: "92696", alias: "92696" },
  { category: "Postleitzahl", value: "92697", alias: "92697" },
  { category: "Postleitzahl", value: "92699", alias: "92699" },
  { category: "Postleitzahl", value: "92700", alias: "92700" },
  { category: "Postleitzahl", value: "92702", alias: "92702" },
  { category: "Postleitzahl", value: "92703", alias: "92703" },
  { category: "Postleitzahl", value: "92705", alias: "92705" },
  { category: "Postleitzahl", value: "92706", alias: "92706" },
  { category: "Postleitzahl", value: "92708", alias: "92708" },
  { category: "Postleitzahl", value: "92709", alias: "92709" },
  { category: "Postleitzahl", value: "92711", alias: "92711" },
  { category: "Postleitzahl", value: "92712", alias: "92712" },
  { category: "Postleitzahl", value: "92714", alias: "92714" },
  { category: "Postleitzahl", value: "92715", alias: "92715" },
  { category: "Postleitzahl", value: "92717", alias: "92717" },
  { category: "Postleitzahl", value: "92718", alias: "92718" },
  { category: "Postleitzahl", value: "92720", alias: "92720" },
  { category: "Postleitzahl", value: "92721", alias: "92721" },
  { category: "Postleitzahl", value: "92723", alias: "92723" },
  { category: "Postleitzahl", value: "92724", alias: "92724" },
  { category: "Postleitzahl", value: "92726", alias: "92726" },
  { category: "Postleitzahl", value: "92727", alias: "92727" },
  { category: "Postleitzahl", value: "92729", alias: "92729" },
  { category: "Postleitzahl", value: "93047", alias: "93047" },
  { category: "Postleitzahl", value: "93049", alias: "93049" },
  { category: "Postleitzahl", value: "93051", alias: "93051" },
  { category: "Postleitzahl", value: "93053", alias: "93053" },
  { category: "Postleitzahl", value: "93055", alias: "93055" },
  { category: "Postleitzahl", value: "93057", alias: "93057" },
  { category: "Postleitzahl", value: "93059", alias: "93059" },
  { category: "Postleitzahl", value: "93073", alias: "93073" },
  { category: "Postleitzahl", value: "93077", alias: "93077" },
  { category: "Postleitzahl", value: "93080", alias: "93080" },
  { category: "Postleitzahl", value: "93083", alias: "93083" },
  { category: "Postleitzahl", value: "93086", alias: "93086" },
  { category: "Postleitzahl", value: "93087", alias: "93087" },
  { category: "Postleitzahl", value: "93089", alias: "93089" },
  { category: "Postleitzahl", value: "93090", alias: "93090" },
  { category: "Postleitzahl", value: "93092", alias: "93092" },
  { category: "Postleitzahl", value: "93093", alias: "93093" },
  { category: "Postleitzahl", value: "93095", alias: "93095" },
  { category: "Postleitzahl", value: "93096", alias: "93096" },
  { category: "Postleitzahl", value: "93098", alias: "93098" },
  { category: "Postleitzahl", value: "93099", alias: "93099" },
  { category: "Postleitzahl", value: "93101", alias: "93101" },
  { category: "Postleitzahl", value: "93102", alias: "93102" },
  { category: "Postleitzahl", value: "93104", alias: "93104" },
  { category: "Postleitzahl", value: "93105", alias: "93105" },
  { category: "Postleitzahl", value: "93107", alias: "93107" },
  { category: "Postleitzahl", value: "93109", alias: "93109" },
  { category: "Postleitzahl", value: "93128", alias: "93128" },
  { category: "Postleitzahl", value: "93133", alias: "93133" },
  { category: "Postleitzahl", value: "93138", alias: "93138" },
  { category: "Postleitzahl", value: "93142", alias: "93142" },
  { category: "Postleitzahl", value: "93149", alias: "93149" },
  { category: "Postleitzahl", value: "93152", alias: "93152" },
  { category: "Postleitzahl", value: "93155", alias: "93155" },
  { category: "Postleitzahl", value: "93158", alias: "93158" },
  { category: "Postleitzahl", value: "93161", alias: "93161" },
  { category: "Postleitzahl", value: "93164", alias: "93164" },
  { category: "Postleitzahl", value: "93167", alias: "93167" },
  { category: "Postleitzahl", value: "93170", alias: "93170" },
  { category: "Postleitzahl", value: "93173", alias: "93173" },
  { category: "Postleitzahl", value: "93176", alias: "93176" },
  { category: "Postleitzahl", value: "93177", alias: "93177" },
  { category: "Postleitzahl", value: "93179", alias: "93179" },
  { category: "Postleitzahl", value: "93180", alias: "93180" },
  { category: "Postleitzahl", value: "93182", alias: "93182" },
  { category: "Postleitzahl", value: "93183", alias: "93183" },
  { category: "Postleitzahl", value: "93185", alias: "93185" },
  { category: "Postleitzahl", value: "93186", alias: "93186" },
  { category: "Postleitzahl", value: "93188", alias: "93188" },
  { category: "Postleitzahl", value: "93189", alias: "93189" },
  { category: "Postleitzahl", value: "93191", alias: "93191" },
  { category: "Postleitzahl", value: "93192", alias: "93192" },
  { category: "Postleitzahl", value: "93194", alias: "93194" },
  { category: "Postleitzahl", value: "93195", alias: "93195" },
  { category: "Postleitzahl", value: "93197", alias: "93197" },
  { category: "Postleitzahl", value: "93199", alias: "93199" },
  { category: "Postleitzahl", value: "93309", alias: "93309" },
  { category: "Postleitzahl", value: "93326", alias: "93326" },
  { category: "Postleitzahl", value: "93333", alias: "93333" },
  { category: "Postleitzahl", value: "93336", alias: "93336" },
  { category: "Postleitzahl", value: "93339", alias: "93339" },
  { category: "Postleitzahl", value: "93342", alias: "93342" },
  { category: "Postleitzahl", value: "93343", alias: "93343" },
  { category: "Postleitzahl", value: "93345", alias: "93345" },
  { category: "Postleitzahl", value: "93346", alias: "93346" },
  { category: "Postleitzahl", value: "93348", alias: "93348" },
  { category: "Postleitzahl", value: "93349", alias: "93349" },
  { category: "Postleitzahl", value: "93351", alias: "93351" },
  { category: "Postleitzahl", value: "93352", alias: "93352" },
  { category: "Postleitzahl", value: "93354", alias: "93354" },
  { category: "Postleitzahl", value: "93356", alias: "93356" },
  { category: "Postleitzahl", value: "93358", alias: "93358" },
  { category: "Postleitzahl", value: "93359", alias: "93359" },
  { category: "Postleitzahl", value: "93413", alias: "93413" },
  { category: "Postleitzahl", value: "93426", alias: "93426" },
  { category: "Postleitzahl", value: "93437", alias: "93437" },
  { category: "Postleitzahl", value: "93444", alias: "93444" },
  { category: "Postleitzahl", value: "93449", alias: "93449" },
  { category: "Postleitzahl", value: "93453", alias: "93453" },
  { category: "Postleitzahl", value: "93455", alias: "93455" },
  { category: "Postleitzahl", value: "93458", alias: "93458" },
  { category: "Postleitzahl", value: "93462", alias: "93462" },
  { category: "Postleitzahl", value: "93464", alias: "93464" },
  { category: "Postleitzahl", value: "93466", alias: "93466" },
  { category: "Postleitzahl", value: "93468", alias: "93468" },
  { category: "Postleitzahl", value: "93470", alias: "93470" },
  { category: "Postleitzahl", value: "93471", alias: "93471" },
  { category: "Postleitzahl", value: "93473", alias: "93473" },
  { category: "Postleitzahl", value: "93474", alias: "93474" },
  { category: "Postleitzahl", value: "93476", alias: "93476" },
  { category: "Postleitzahl", value: "93477", alias: "93477" },
  { category: "Postleitzahl", value: "93479", alias: "93479" },
  { category: "Postleitzahl", value: "93480", alias: "93480" },
  { category: "Postleitzahl", value: "93482", alias: "93482" },
  { category: "Postleitzahl", value: "93483", alias: "93483" },
  { category: "Postleitzahl", value: "93485", alias: "93485" },
  { category: "Postleitzahl", value: "93486", alias: "93486" },
  { category: "Postleitzahl", value: "93488", alias: "93488" },
  { category: "Postleitzahl", value: "93489", alias: "93489" },
  { category: "Postleitzahl", value: "93491", alias: "93491" },
  { category: "Postleitzahl", value: "93492", alias: "93492" },
  { category: "Postleitzahl", value: "93494", alias: "93494" },
  { category: "Postleitzahl", value: "93495", alias: "93495" },
  { category: "Postleitzahl", value: "93497", alias: "93497" },
  { category: "Postleitzahl", value: "93499", alias: "93499" },
  { category: "Postleitzahl", value: "94032", alias: "94032" },
  { category: "Postleitzahl", value: "94034", alias: "94034" },
  { category: "Postleitzahl", value: "94036", alias: "94036" },
  { category: "Postleitzahl", value: "94051", alias: "94051" },
  { category: "Postleitzahl", value: "94060", alias: "94060" },
  { category: "Postleitzahl", value: "94065", alias: "94065" },
  { category: "Postleitzahl", value: "94072", alias: "94072" },
  { category: "Postleitzahl", value: "94078", alias: "94078" },
  { category: "Postleitzahl", value: "94081", alias: "94081" },
  { category: "Postleitzahl", value: "94086", alias: "94086" },
  { category: "Postleitzahl", value: "94089", alias: "94089" },
  { category: "Postleitzahl", value: "94094", alias: "94094" },
  { category: "Postleitzahl", value: "94099", alias: "94099" },
  { category: "Postleitzahl", value: "94104", alias: "94104" },
  { category: "Postleitzahl", value: "94107", alias: "94107" },
  { category: "Postleitzahl", value: "94110", alias: "94110" },
  { category: "Postleitzahl", value: "94113", alias: "94113" },
  { category: "Postleitzahl", value: "94116", alias: "94116" },
  { category: "Postleitzahl", value: "94118", alias: "94118" },
  { category: "Postleitzahl", value: "94121", alias: "94121" },
  { category: "Postleitzahl", value: "94124", alias: "94124" },
  { category: "Postleitzahl", value: "94127", alias: "94127" },
  { category: "Postleitzahl", value: "94130", alias: "94130" },
  { category: "Postleitzahl", value: "94133", alias: "94133" },
  { category: "Postleitzahl", value: "94136", alias: "94136" },
  { category: "Postleitzahl", value: "94137", alias: "94137" },
  { category: "Postleitzahl", value: "94139", alias: "94139" },
  { category: "Postleitzahl", value: "94140", alias: "94140" },
  { category: "Postleitzahl", value: "94142", alias: "94142" },
  { category: "Postleitzahl", value: "94143", alias: "94143" },
  { category: "Postleitzahl", value: "94145", alias: "94145" },
  { category: "Postleitzahl", value: "94146", alias: "94146" },
  { category: "Postleitzahl", value: "94148", alias: "94148" },
  { category: "Postleitzahl", value: "94149", alias: "94149" },
  { category: "Postleitzahl", value: "94151", alias: "94151" },
  { category: "Postleitzahl", value: "94152", alias: "94152" },
  { category: "Postleitzahl", value: "94154", alias: "94154" },
  { category: "Postleitzahl", value: "94157", alias: "94157" },
  { category: "Postleitzahl", value: "94158", alias: "94158" },
  { category: "Postleitzahl", value: "94160", alias: "94160" },
  { category: "Postleitzahl", value: "94161", alias: "94161" },
  { category: "Postleitzahl", value: "94163", alias: "94163" },
  { category: "Postleitzahl", value: "94164", alias: "94164" },
  { category: "Postleitzahl", value: "94166", alias: "94166" },
  { category: "Postleitzahl", value: "94167", alias: "94167" },
  { category: "Postleitzahl", value: "94169", alias: "94169" },
  { category: "Postleitzahl", value: "94209", alias: "94209" },
  { category: "Postleitzahl", value: "94227", alias: "94227" },
  { category: "Postleitzahl", value: "94234", alias: "94234" },
  { category: "Postleitzahl", value: "94239", alias: "94239" },
  { category: "Postleitzahl", value: "94244", alias: "94244" },
  { category: "Postleitzahl", value: "94249", alias: "94249" },
  { category: "Postleitzahl", value: "94250", alias: "94250" },
  { category: "Postleitzahl", value: "94252", alias: "94252" },
  { category: "Postleitzahl", value: "94253", alias: "94253" },
  { category: "Postleitzahl", value: "94255", alias: "94255" },
  { category: "Postleitzahl", value: "94256", alias: "94256" },
  { category: "Postleitzahl", value: "94258", alias: "94258" },
  { category: "Postleitzahl", value: "94259", alias: "94259" },
  { category: "Postleitzahl", value: "94261", alias: "94261" },
  { category: "Postleitzahl", value: "94262", alias: "94262" },
  { category: "Postleitzahl", value: "94264", alias: "94264" },
  { category: "Postleitzahl", value: "94265", alias: "94265" },
  { category: "Postleitzahl", value: "94267", alias: "94267" },
  { category: "Postleitzahl", value: "94269", alias: "94269" },
  { category: "Postleitzahl", value: "94315", alias: "94315" },
  { category: "Postleitzahl", value: "94327", alias: "94327" },
  { category: "Postleitzahl", value: "94330", alias: "94330" },
  { category: "Postleitzahl", value: "94333", alias: "94333" },
  { category: "Postleitzahl", value: "94336", alias: "94336" },
  { category: "Postleitzahl", value: "94339", alias: "94339" },
  { category: "Postleitzahl", value: "94342", alias: "94342" },
  { category: "Postleitzahl", value: "94344", alias: "94344" },
  { category: "Postleitzahl", value: "94345", alias: "94345" },
  { category: "Postleitzahl", value: "94347", alias: "94347" },
  { category: "Postleitzahl", value: "94348", alias: "94348" },
  { category: "Postleitzahl", value: "94350", alias: "94350" },
  { category: "Postleitzahl", value: "94351", alias: "94351" },
  { category: "Postleitzahl", value: "94353", alias: "94353" },
  { category: "Postleitzahl", value: "94354", alias: "94354" },
  { category: "Postleitzahl", value: "94356", alias: "94356" },
  { category: "Postleitzahl", value: "94357", alias: "94357" },
  { category: "Postleitzahl", value: "94359", alias: "94359" },
  { category: "Postleitzahl", value: "94360", alias: "94360" },
  { category: "Postleitzahl", value: "94362", alias: "94362" },
  { category: "Postleitzahl", value: "94363", alias: "94363" },
  { category: "Postleitzahl", value: "94365", alias: "94365" },
  { category: "Postleitzahl", value: "94366", alias: "94366" },
  { category: "Postleitzahl", value: "94368", alias: "94368" },
  { category: "Postleitzahl", value: "94369", alias: "94369" },
  { category: "Postleitzahl", value: "94371", alias: "94371" },
  { category: "Postleitzahl", value: "94372", alias: "94372" },
  { category: "Postleitzahl", value: "94374", alias: "94374" },
  { category: "Postleitzahl", value: "94375", alias: "94375" },
  { category: "Postleitzahl", value: "94377", alias: "94377" },
  { category: "Postleitzahl", value: "94379", alias: "94379" },
  { category: "Postleitzahl", value: "94405", alias: "94405" },
  { category: "Postleitzahl", value: "94419", alias: "94419" },
  { category: "Postleitzahl", value: "94424", alias: "94424" },
  { category: "Postleitzahl", value: "94428", alias: "94428" },
  { category: "Postleitzahl", value: "94431", alias: "94431" },
  { category: "Postleitzahl", value: "94436", alias: "94436" },
  { category: "Postleitzahl", value: "94437", alias: "94437" },
  { category: "Postleitzahl", value: "94439", alias: "94439" },
  { category: "Postleitzahl", value: "94447", alias: "94447" },
  { category: "Postleitzahl", value: "94469", alias: "94469" },
  { category: "Postleitzahl", value: "94474", alias: "94474" },
  { category: "Postleitzahl", value: "94481", alias: "94481" },
  { category: "Postleitzahl", value: "94486", alias: "94486" },
  { category: "Postleitzahl", value: "94491", alias: "94491" },
  { category: "Postleitzahl", value: "94496", alias: "94496" },
  { category: "Postleitzahl", value: "94501", alias: "94501" },
  { category: "Postleitzahl", value: "94505", alias: "94505" },
  { category: "Postleitzahl", value: "94508", alias: "94508" },
  { category: "Postleitzahl", value: "94513", alias: "94513" },
  { category: "Postleitzahl", value: "94518", alias: "94518" },
  { category: "Postleitzahl", value: "94522", alias: "94522" },
  { category: "Postleitzahl", value: "94526", alias: "94526" },
  { category: "Postleitzahl", value: "94527", alias: "94527" },
  { category: "Postleitzahl", value: "94529", alias: "94529" },
  { category: "Postleitzahl", value: "94530", alias: "94530" },
  { category: "Postleitzahl", value: "94532", alias: "94532" },
  { category: "Postleitzahl", value: "94533", alias: "94533" },
  { category: "Postleitzahl", value: "94535", alias: "94535" },
  { category: "Postleitzahl", value: "94536", alias: "94536" },
  { category: "Postleitzahl", value: "94538", alias: "94538" },
  { category: "Postleitzahl", value: "94539", alias: "94539" },
  { category: "Postleitzahl", value: "94541", alias: "94541" },
  { category: "Postleitzahl", value: "94542", alias: "94542" },
  { category: "Postleitzahl", value: "94544", alias: "94544" },
  { category: "Postleitzahl", value: "94545", alias: "94545" },
  { category: "Postleitzahl", value: "94547", alias: "94547" },
  { category: "Postleitzahl", value: "94548", alias: "94548" },
  { category: "Postleitzahl", value: "94550", alias: "94550" },
  { category: "Postleitzahl", value: "94551", alias: "94551" },
  { category: "Postleitzahl", value: "94553", alias: "94553" },
  { category: "Postleitzahl", value: "94554", alias: "94554" },
  { category: "Postleitzahl", value: "94556", alias: "94556" },
  { category: "Postleitzahl", value: "94557", alias: "94557" },
  { category: "Postleitzahl", value: "94559", alias: "94559" },
  { category: "Postleitzahl", value: "94560", alias: "94560" },
  { category: "Postleitzahl", value: "94562", alias: "94562" },
  { category: "Postleitzahl", value: "94563", alias: "94563" },
  { category: "Postleitzahl", value: "94566", alias: "94566" },
  { category: "Postleitzahl", value: "94568", alias: "94568" },
  { category: "Postleitzahl", value: "94569", alias: "94569" },
  { category: "Postleitzahl", value: "94571", alias: "94571" },
  { category: "Postleitzahl", value: "94572", alias: "94572" },
  { category: "Postleitzahl", value: "94574", alias: "94574" },
  { category: "Postleitzahl", value: "94575", alias: "94575" },
  { category: "Postleitzahl", value: "94577", alias: "94577" },
  { category: "Postleitzahl", value: "94579", alias: "94579" },
  { category: "Postleitzahl", value: "95028", alias: "95028" },
  { category: "Postleitzahl", value: "95030", alias: "95030" },
  { category: "Postleitzahl", value: "95032", alias: "95032" },
  { category: "Postleitzahl", value: "95100", alias: "95100" },
  { category: "Postleitzahl", value: "95111", alias: "95111" },
  { category: "Postleitzahl", value: "95119", alias: "95119" },
  { category: "Postleitzahl", value: "95126", alias: "95126" },
  { category: "Postleitzahl", value: "95131", alias: "95131" },
  { category: "Postleitzahl", value: "95138", alias: "95138" },
  { category: "Postleitzahl", value: "95145", alias: "95145" },
  { category: "Postleitzahl", value: "95152", alias: "95152" },
  { category: "Postleitzahl", value: "95158", alias: "95158" },
  { category: "Postleitzahl", value: "95163", alias: "95163" },
  { category: "Postleitzahl", value: "95168", alias: "95168" },
  { category: "Postleitzahl", value: "95173", alias: "95173" },
  { category: "Postleitzahl", value: "95176", alias: "95176" },
  { category: "Postleitzahl", value: "95179", alias: "95179" },
  { category: "Postleitzahl", value: "95180", alias: "95180" },
  { category: "Postleitzahl", value: "95182", alias: "95182" },
  { category: "Postleitzahl", value: "95183", alias: "95183" },
  { category: "Postleitzahl", value: "95185", alias: "95185" },
  { category: "Postleitzahl", value: "95186", alias: "95186" },
  { category: "Postleitzahl", value: "95188", alias: "95188" },
  { category: "Postleitzahl", value: "95189", alias: "95189" },
  { category: "Postleitzahl", value: "95191", alias: "95191" },
  { category: "Postleitzahl", value: "95192", alias: "95192" },
  { category: "Postleitzahl", value: "95194", alias: "95194" },
  { category: "Postleitzahl", value: "95195", alias: "95195" },
  { category: "Postleitzahl", value: "95197", alias: "95197" },
  { category: "Postleitzahl", value: "95199", alias: "95199" },
  { category: "Postleitzahl", value: "95213", alias: "95213" },
  { category: "Postleitzahl", value: "95233", alias: "95233" },
  { category: "Postleitzahl", value: "95234", alias: "95234" },
  { category: "Postleitzahl", value: "95236", alias: "95236" },
  { category: "Postleitzahl", value: "95237", alias: "95237" },
  { category: "Postleitzahl", value: "95239", alias: "95239" },
  { category: "Postleitzahl", value: "95326", alias: "95326" },
  { category: "Postleitzahl", value: "95336", alias: "95336" },
  { category: "Postleitzahl", value: "95339", alias: "95339" },
  { category: "Postleitzahl", value: "95346", alias: "95346" },
  { category: "Postleitzahl", value: "95349", alias: "95349" },
  { category: "Postleitzahl", value: "95352", alias: "95352" },
  { category: "Postleitzahl", value: "95355", alias: "95355" },
  { category: "Postleitzahl", value: "95356", alias: "95356" },
  { category: "Postleitzahl", value: "95358", alias: "95358" },
  { category: "Postleitzahl", value: "95359", alias: "95359" },
  { category: "Postleitzahl", value: "95361", alias: "95361" },
  { category: "Postleitzahl", value: "95362", alias: "95362" },
  { category: "Postleitzahl", value: "95364", alias: "95364" },
  { category: "Postleitzahl", value: "95365", alias: "95365" },
  { category: "Postleitzahl", value: "95367", alias: "95367" },
  { category: "Postleitzahl", value: "95369", alias: "95369" },
  { category: "Postleitzahl", value: "95444", alias: "95444" },
  { category: "Postleitzahl", value: "95445", alias: "95445" },
  { category: "Postleitzahl", value: "95447", alias: "95447" },
  { category: "Postleitzahl", value: "95448", alias: "95448" },
  { category: "Postleitzahl", value: "95460", alias: "95460" },
  { category: "Postleitzahl", value: "95463", alias: "95463" },
  { category: "Postleitzahl", value: "95466", alias: "95466" },
  { category: "Postleitzahl", value: "95469", alias: "95469" },
  { category: "Postleitzahl", value: "95473", alias: "95473" },
  { category: "Postleitzahl", value: "95478", alias: "95478" },
  { category: "Postleitzahl", value: "95482", alias: "95482" },
  { category: "Postleitzahl", value: "95485", alias: "95485" },
  { category: "Postleitzahl", value: "95488", alias: "95488" },
  { category: "Postleitzahl", value: "95490", alias: "95490" },
  { category: "Postleitzahl", value: "95491", alias: "95491" },
  { category: "Postleitzahl", value: "95493", alias: "95493" },
  { category: "Postleitzahl", value: "95494", alias: "95494" },
  { category: "Postleitzahl", value: "95496", alias: "95496" },
  { category: "Postleitzahl", value: "95497", alias: "95497" },
  { category: "Postleitzahl", value: "95499", alias: "95499" },
  { category: "Postleitzahl", value: "95500", alias: "95500" },
  { category: "Postleitzahl", value: "95502", alias: "95502" },
  { category: "Postleitzahl", value: "95503", alias: "95503" },
  { category: "Postleitzahl", value: "95505", alias: "95505" },
  { category: "Postleitzahl", value: "95506", alias: "95506" },
  { category: "Postleitzahl", value: "95508", alias: "95508" },
  { category: "Postleitzahl", value: "95509", alias: "95509" },
  { category: "Postleitzahl", value: "95511", alias: "95511" },
  { category: "Postleitzahl", value: "95512", alias: "95512" },
  { category: "Postleitzahl", value: "95514", alias: "95514" },
  { category: "Postleitzahl", value: "95515", alias: "95515" },
  { category: "Postleitzahl", value: "95517", alias: "95517" },
  { category: "Postleitzahl", value: "95519", alias: "95519" },
  { category: "Postleitzahl", value: "95615", alias: "95615" },
  { category: "Postleitzahl", value: "95632", alias: "95632" },
  { category: "Postleitzahl", value: "95643", alias: "95643" },
  { category: "Postleitzahl", value: "95652", alias: "95652" },
  { category: "Postleitzahl", value: "95659", alias: "95659" },
  { category: "Postleitzahl", value: "95666", alias: "95666" },
  { category: "Postleitzahl", value: "95671", alias: "95671" },
  { category: "Postleitzahl", value: "95676", alias: "95676" },
  { category: "Postleitzahl", value: "95679", alias: "95679" },
  { category: "Postleitzahl", value: "95680", alias: "95680" },
  { category: "Postleitzahl", value: "95682", alias: "95682" },
  { category: "Postleitzahl", value: "95683", alias: "95683" },
  { category: "Postleitzahl", value: "95685", alias: "95685" },
  { category: "Postleitzahl", value: "95686", alias: "95686" },
  { category: "Postleitzahl", value: "95688", alias: "95688" },
  { category: "Postleitzahl", value: "95689", alias: "95689" },
  { category: "Postleitzahl", value: "95691", alias: "95691" },
  { category: "Postleitzahl", value: "95692", alias: "95692" },
  { category: "Postleitzahl", value: "95694", alias: "95694" },
  { category: "Postleitzahl", value: "95695", alias: "95695" },
  { category: "Postleitzahl", value: "95697", alias: "95697" },
  { category: "Postleitzahl", value: "95698", alias: "95698" },
  { category: "Postleitzahl", value: "95700", alias: "95700" },
  { category: "Postleitzahl", value: "95701", alias: "95701" },
  { category: "Postleitzahl", value: "95703", alias: "95703" },
  { category: "Postleitzahl", value: "95704", alias: "95704" },
  { category: "Postleitzahl", value: "95706", alias: "95706" },
  { category: "Postleitzahl", value: "95707", alias: "95707" },
  { category: "Postleitzahl", value: "95709", alias: "95709" },
  { category: "Postleitzahl", value: "96047", alias: "96047" },
  { category: "Postleitzahl", value: "96049", alias: "96049" },
  { category: "Postleitzahl", value: "96050", alias: "96050" },
  { category: "Postleitzahl", value: "96052", alias: "96052" },
  { category: "Postleitzahl", value: "96103", alias: "96103" },
  { category: "Postleitzahl", value: "96106", alias: "96106" },
  { category: "Postleitzahl", value: "96110", alias: "96110" },
  { category: "Postleitzahl", value: "96114", alias: "96114" },
  { category: "Postleitzahl", value: "96117", alias: "96117" },
  { category: "Postleitzahl", value: "96120", alias: "96120" },
  { category: "Postleitzahl", value: "96123", alias: "96123" },
  { category: "Postleitzahl", value: "96126", alias: "96126" },
  { category: "Postleitzahl", value: "96129", alias: "96129" },
  { category: "Postleitzahl", value: "96132", alias: "96132" },
  { category: "Postleitzahl", value: "96135", alias: "96135" },
  { category: "Postleitzahl", value: "96138", alias: "96138" },
  { category: "Postleitzahl", value: "96142", alias: "96142" },
  { category: "Postleitzahl", value: "96145", alias: "96145" },
  { category: "Postleitzahl", value: "96146", alias: "96146" },
  { category: "Postleitzahl", value: "96148", alias: "96148" },
  { category: "Postleitzahl", value: "96149", alias: "96149" },
  { category: "Postleitzahl", value: "96151", alias: "96151" },
  { category: "Postleitzahl", value: "96152", alias: "96152" },
  { category: "Postleitzahl", value: "96154", alias: "96154" },
  { category: "Postleitzahl", value: "96155", alias: "96155" },
  { category: "Postleitzahl", value: "96157", alias: "96157" },
  { category: "Postleitzahl", value: "96158", alias: "96158" },
  { category: "Postleitzahl", value: "96160", alias: "96160" },
  { category: "Postleitzahl", value: "96161", alias: "96161" },
  { category: "Postleitzahl", value: "96163", alias: "96163" },
  { category: "Postleitzahl", value: "96164", alias: "96164" },
  { category: "Postleitzahl", value: "96166", alias: "96166" },
  { category: "Postleitzahl", value: "96167", alias: "96167" },
  { category: "Postleitzahl", value: "96169", alias: "96169" },
  { category: "Postleitzahl", value: "96170", alias: "96170" },
  { category: "Postleitzahl", value: "96172", alias: "96172" },
  { category: "Postleitzahl", value: "96173", alias: "96173" },
  { category: "Postleitzahl", value: "96175", alias: "96175" },
  { category: "Postleitzahl", value: "96176", alias: "96176" },
  { category: "Postleitzahl", value: "96178", alias: "96178" },
  { category: "Postleitzahl", value: "96179", alias: "96179" },
  { category: "Postleitzahl", value: "96181", alias: "96181" },
  { category: "Postleitzahl", value: "96182", alias: "96182" },
  { category: "Postleitzahl", value: "96184", alias: "96184" },
  { category: "Postleitzahl", value: "96185", alias: "96185" },
  { category: "Postleitzahl", value: "96187", alias: "96187" },
  { category: "Postleitzahl", value: "96188", alias: "96188" },
  { category: "Postleitzahl", value: "96190", alias: "96190" },
  { category: "Postleitzahl", value: "96191", alias: "96191" },
  { category: "Postleitzahl", value: "96193", alias: "96193" },
  { category: "Postleitzahl", value: "96194", alias: "96194" },
  { category: "Postleitzahl", value: "96196", alias: "96196" },
  { category: "Postleitzahl", value: "96197", alias: "96197" },
  { category: "Postleitzahl", value: "96199", alias: "96199" },
  { category: "Postleitzahl", value: "96215", alias: "96215" },
  { category: "Postleitzahl", value: "96224", alias: "96224" },
  { category: "Postleitzahl", value: "96231", alias: "96231" },
  { category: "Postleitzahl", value: "96237", alias: "96237" },
  { category: "Postleitzahl", value: "96242", alias: "96242" },
  { category: "Postleitzahl", value: "96247", alias: "96247" },
  { category: "Postleitzahl", value: "96250", alias: "96250" },
  { category: "Postleitzahl", value: "96253", alias: "96253" },
  { category: "Postleitzahl", value: "96257", alias: "96257" },
  { category: "Postleitzahl", value: "96260", alias: "96260" },
  { category: "Postleitzahl", value: "96264", alias: "96264" },
  { category: "Postleitzahl", value: "96268", alias: "96268" },
  { category: "Postleitzahl", value: "96269", alias: "96269" },
  { category: "Postleitzahl", value: "96271", alias: "96271" },
  { category: "Postleitzahl", value: "96272", alias: "96272" },
  { category: "Postleitzahl", value: "96274", alias: "96274" },
  { category: "Postleitzahl", value: "96275", alias: "96275" },
  { category: "Postleitzahl", value: "96277", alias: "96277" },
  { category: "Postleitzahl", value: "96279", alias: "96279" },
  { category: "Postleitzahl", value: "96317", alias: "96317" },
  { category: "Postleitzahl", value: "96328", alias: "96328" },
  { category: "Postleitzahl", value: "96332", alias: "96332" },
  { category: "Postleitzahl", value: "96337", alias: "96337" },
  { category: "Postleitzahl", value: "96342", alias: "96342" },
  { category: "Postleitzahl", value: "96346", alias: "96346" },
  { category: "Postleitzahl", value: "96349", alias: "96349" },
  { category: "Postleitzahl", value: "96352", alias: "96352" },
  { category: "Postleitzahl", value: "96355", alias: "96355" },
  { category: "Postleitzahl", value: "96358", alias: "96358" },
  { category: "Postleitzahl", value: "96361", alias: "96361" },
  { category: "Postleitzahl", value: "96364", alias: "96364" },
  { category: "Postleitzahl", value: "96365", alias: "96365" },
  { category: "Postleitzahl", value: "96367", alias: "96367" },
  { category: "Postleitzahl", value: "96369", alias: "96369" },
  { category: "Postleitzahl", value: "96450", alias: "96450" },
  { category: "Postleitzahl", value: "96465", alias: "96465" },
  { category: "Postleitzahl", value: "96472", alias: "96472" },
  { category: "Postleitzahl", value: "96476", alias: "96476" },
  { category: "Postleitzahl", value: "96479", alias: "96479" },
  { category: "Postleitzahl", value: "96482", alias: "96482" },
  { category: "Postleitzahl", value: "96484", alias: "96484" },
  { category: "Postleitzahl", value: "96486", alias: "96486" },
  { category: "Postleitzahl", value: "96487", alias: "96487" },
  { category: "Postleitzahl", value: "96489", alias: "96489" },
  { category: "Postleitzahl", value: "96515", alias: "96515" },
  { category: "Postleitzahl", value: "96523", alias: "96523" },
  { category: "Postleitzahl", value: "96524", alias: "96524" },
  { category: "Postleitzahl", value: "96528", alias: "96528" },
  { category: "Postleitzahl", value: "97070", alias: "97070" },
  { category: "Postleitzahl", value: "97072", alias: "97072" },
  { category: "Postleitzahl", value: "97074", alias: "97074" },
  { category: "Postleitzahl", value: "97076", alias: "97076" },
  { category: "Postleitzahl", value: "97078", alias: "97078" },
  { category: "Postleitzahl", value: "97080", alias: "97080" },
  { category: "Postleitzahl", value: "97082", alias: "97082" },
  { category: "Postleitzahl", value: "97084", alias: "97084" },
  { category: "Postleitzahl", value: "97199", alias: "97199" },
  { category: "Postleitzahl", value: "97204", alias: "97204" },
  { category: "Postleitzahl", value: "97209", alias: "97209" },
  { category: "Postleitzahl", value: "97215", alias: "97215" },
  { category: "Postleitzahl", value: "97218", alias: "97218" },
  { category: "Postleitzahl", value: "97222", alias: "97222" },
  { category: "Postleitzahl", value: "97225", alias: "97225" },
  { category: "Postleitzahl", value: "97228", alias: "97228" },
  { category: "Postleitzahl", value: "97230", alias: "97230" },
  { category: "Postleitzahl", value: "97232", alias: "97232" },
  { category: "Postleitzahl", value: "97234", alias: "97234" },
  { category: "Postleitzahl", value: "97236", alias: "97236" },
  { category: "Postleitzahl", value: "97237", alias: "97237" },
  { category: "Postleitzahl", value: "97239", alias: "97239" },
  { category: "Postleitzahl", value: "97241", alias: "97241" },
  { category: "Postleitzahl", value: "97243", alias: "97243" },
  { category: "Postleitzahl", value: "97244", alias: "97244" },
  { category: "Postleitzahl", value: "97246", alias: "97246" },
  { category: "Postleitzahl", value: "97247", alias: "97247" },
  { category: "Postleitzahl", value: "97249", alias: "97249" },
  { category: "Postleitzahl", value: "97250", alias: "97250" },
  { category: "Postleitzahl", value: "97252", alias: "97252" },
  { category: "Postleitzahl", value: "97253", alias: "97253" },
  { category: "Postleitzahl", value: "97255", alias: "97255" },
  { category: "Postleitzahl", value: "97256", alias: "97256" },
  { category: "Postleitzahl", value: "97258", alias: "97258" },
  { category: "Postleitzahl", value: "97259", alias: "97259" },
  { category: "Postleitzahl", value: "97261", alias: "97261" },
  { category: "Postleitzahl", value: "97262", alias: "97262" },
  { category: "Postleitzahl", value: "97264", alias: "97264" },
  { category: "Postleitzahl", value: "97265", alias: "97265" },
  { category: "Postleitzahl", value: "97267", alias: "97267" },
  { category: "Postleitzahl", value: "97268", alias: "97268" },
  { category: "Postleitzahl", value: "97270", alias: "97270" },
  { category: "Postleitzahl", value: "97271", alias: "97271" },
  { category: "Postleitzahl", value: "97273", alias: "97273" },
  { category: "Postleitzahl", value: "97274", alias: "97274" },
  { category: "Postleitzahl", value: "97276", alias: "97276" },
  { category: "Postleitzahl", value: "97277", alias: "97277" },
  { category: "Postleitzahl", value: "97279", alias: "97279" },
  { category: "Postleitzahl", value: "97280", alias: "97280" },
  { category: "Postleitzahl", value: "97282", alias: "97282" },
  { category: "Postleitzahl", value: "97283", alias: "97283" },
  { category: "Postleitzahl", value: "97285", alias: "97285" },
  { category: "Postleitzahl", value: "97286", alias: "97286" },
  { category: "Postleitzahl", value: "97288", alias: "97288" },
  { category: "Postleitzahl", value: "97289", alias: "97289" },
  { category: "Postleitzahl", value: "97291", alias: "97291" },
  { category: "Postleitzahl", value: "97292", alias: "97292" },
  { category: "Postleitzahl", value: "97294", alias: "97294" },
  { category: "Postleitzahl", value: "97295", alias: "97295" },
  { category: "Postleitzahl", value: "97297", alias: "97297" },
  { category: "Postleitzahl", value: "97299", alias: "97299" },
  { category: "Postleitzahl", value: "97318", alias: "97318" },
  { category: "Postleitzahl", value: "97320", alias: "97320" },
  { category: "Postleitzahl", value: "97332", alias: "97332" },
  { category: "Postleitzahl", value: "97334", alias: "97334" },
  { category: "Postleitzahl", value: "97337", alias: "97337" },
  { category: "Postleitzahl", value: "97340", alias: "97340" },
  { category: "Postleitzahl", value: "97342", alias: "97342" },
  { category: "Postleitzahl", value: "97346", alias: "97346" },
  { category: "Postleitzahl", value: "97348", alias: "97348" },
  { category: "Postleitzahl", value: "97350", alias: "97350" },
  { category: "Postleitzahl", value: "97353", alias: "97353" },
  { category: "Postleitzahl", value: "97355", alias: "97355" },
  { category: "Postleitzahl", value: "97357", alias: "97357" },
  { category: "Postleitzahl", value: "97359", alias: "97359" },
  { category: "Postleitzahl", value: "97421", alias: "97421" },
  { category: "Postleitzahl", value: "97422", alias: "97422" },
  { category: "Postleitzahl", value: "97424", alias: "97424" },
  { category: "Postleitzahl", value: "97437", alias: "97437" },
  { category: "Postleitzahl", value: "97440", alias: "97440" },
  { category: "Postleitzahl", value: "97447", alias: "97447" },
  { category: "Postleitzahl", value: "97450", alias: "97450" },
  { category: "Postleitzahl", value: "97453", alias: "97453" },
  { category: "Postleitzahl", value: "97456", alias: "97456" },
  { category: "Postleitzahl", value: "97461", alias: "97461" },
  { category: "Postleitzahl", value: "97464", alias: "97464" },
  { category: "Postleitzahl", value: "97469", alias: "97469" },
  { category: "Postleitzahl", value: "97475", alias: "97475" },
  { category: "Postleitzahl", value: "97478", alias: "97478" },
  { category: "Postleitzahl", value: "97483", alias: "97483" },
  { category: "Postleitzahl", value: "97486", alias: "97486" },
  { category: "Postleitzahl", value: "97488", alias: "97488" },
  { category: "Postleitzahl", value: "97490", alias: "97490" },
  { category: "Postleitzahl", value: "97491", alias: "97491" },
  { category: "Postleitzahl", value: "97493", alias: "97493" },
  { category: "Postleitzahl", value: "97494", alias: "97494" },
  { category: "Postleitzahl", value: "97496", alias: "97496" },
  { category: "Postleitzahl", value: "97497", alias: "97497" },
  { category: "Postleitzahl", value: "97499", alias: "97499" },
  { category: "Postleitzahl", value: "97500", alias: "97500" },
  { category: "Postleitzahl", value: "97502", alias: "97502" },
  { category: "Postleitzahl", value: "97503", alias: "97503" },
  { category: "Postleitzahl", value: "97505", alias: "97505" },
  { category: "Postleitzahl", value: "97506", alias: "97506" },
  { category: "Postleitzahl", value: "97508", alias: "97508" },
  { category: "Postleitzahl", value: "97509", alias: "97509" },
  { category: "Postleitzahl", value: "97511", alias: "97511" },
  { category: "Postleitzahl", value: "97513", alias: "97513" },
  { category: "Postleitzahl", value: "97514", alias: "97514" },
  { category: "Postleitzahl", value: "97516", alias: "97516" },
  { category: "Postleitzahl", value: "97517", alias: "97517" },
  { category: "Postleitzahl", value: "97519", alias: "97519" },
  { category: "Postleitzahl", value: "97520", alias: "97520" },
  { category: "Postleitzahl", value: "97522", alias: "97522" },
  { category: "Postleitzahl", value: "97523", alias: "97523" },
  { category: "Postleitzahl", value: "97525", alias: "97525" },
  { category: "Postleitzahl", value: "97526", alias: "97526" },
  { category: "Postleitzahl", value: "97528", alias: "97528" },
  { category: "Postleitzahl", value: "97529", alias: "97529" },
  { category: "Postleitzahl", value: "97531", alias: "97531" },
  { category: "Postleitzahl", value: "97532", alias: "97532" },
  { category: "Postleitzahl", value: "97534", alias: "97534" },
  { category: "Postleitzahl", value: "97535", alias: "97535" },
  { category: "Postleitzahl", value: "97537", alias: "97537" },
  { category: "Postleitzahl", value: "97539", alias: "97539" },
  { category: "Postleitzahl", value: "97616", alias: "97616" },
  { category: "Postleitzahl", value: "97618", alias: "97618" },
  { category: "Postleitzahl", value: "97631", alias: "97631" },
  { category: "Postleitzahl", value: "97633", alias: "97633" },
  { category: "Postleitzahl", value: "97638", alias: "97638" },
  { category: "Postleitzahl", value: "97640", alias: "97640" },
  { category: "Postleitzahl", value: "97645", alias: "97645" },
  { category: "Postleitzahl", value: "97647", alias: "97647" },
  { category: "Postleitzahl", value: "97650", alias: "97650" },
  { category: "Postleitzahl", value: "97653", alias: "97653" },
  { category: "Postleitzahl", value: "97654", alias: "97654" },
  { category: "Postleitzahl", value: "97656", alias: "97656" },
  { category: "Postleitzahl", value: "97657", alias: "97657" },
  { category: "Postleitzahl", value: "97659", alias: "97659" },
  { category: "Postleitzahl", value: "97688", alias: "97688" },
  { category: "Postleitzahl", value: "97702", alias: "97702" },
  { category: "Postleitzahl", value: "97705", alias: "97705" },
  { category: "Postleitzahl", value: "97708", alias: "97708" },
  { category: "Postleitzahl", value: "97711", alias: "97711" },
  { category: "Postleitzahl", value: "97714", alias: "97714" },
  { category: "Postleitzahl", value: "97717", alias: "97717" },
  { category: "Postleitzahl", value: "97720", alias: "97720" },
  { category: "Postleitzahl", value: "97723", alias: "97723" },
  { category: "Postleitzahl", value: "97724", alias: "97724" },
  { category: "Postleitzahl", value: "97725", alias: "97725" },
  { category: "Postleitzahl", value: "97727", alias: "97727" },
  { category: "Postleitzahl", value: "97729", alias: "97729" },
  { category: "Postleitzahl", value: "97737", alias: "97737" },
  { category: "Postleitzahl", value: "97753", alias: "97753" },
  { category: "Postleitzahl", value: "97762", alias: "97762" },
  { category: "Postleitzahl", value: "97769", alias: "97769" },
  { category: "Postleitzahl", value: "97772", alias: "97772" },
  { category: "Postleitzahl", value: "97773", alias: "97773" },
  { category: "Postleitzahl", value: "97775", alias: "97775" },
  { category: "Postleitzahl", value: "97776", alias: "97776" },
  { category: "Postleitzahl", value: "97778", alias: "97778" },
  { category: "Postleitzahl", value: "97779", alias: "97779" },
  { category: "Postleitzahl", value: "97780", alias: "97780" },
  { category: "Postleitzahl", value: "97782", alias: "97782" },
  { category: "Postleitzahl", value: "97783", alias: "97783" },
  { category: "Postleitzahl", value: "97785", alias: "97785" },
  { category: "Postleitzahl", value: "97786", alias: "97786" },
  { category: "Postleitzahl", value: "97788", alias: "97788" },
  { category: "Postleitzahl", value: "97789", alias: "97789" },
  { category: "Postleitzahl", value: "97791", alias: "97791" },
  { category: "Postleitzahl", value: "97792", alias: "97792" },
  { category: "Postleitzahl", value: "97794", alias: "97794" },
  { category: "Postleitzahl", value: "97795", alias: "97795" },
  { category: "Postleitzahl", value: "97797", alias: "97797" },
  { category: "Postleitzahl", value: "97799", alias: "97799" },
  { category: "Postleitzahl", value: "97816", alias: "97816" },
  { category: "Postleitzahl", value: "97828", alias: "97828" },
  { category: "Postleitzahl", value: "97833", alias: "97833" },
  { category: "Postleitzahl", value: "97834", alias: "97834" },
  { category: "Postleitzahl", value: "97836", alias: "97836" },
  { category: "Postleitzahl", value: "97837", alias: "97837" },
  { category: "Postleitzahl", value: "97839", alias: "97839" },
  { category: "Postleitzahl", value: "97840", alias: "97840" },
  { category: "Postleitzahl", value: "97842", alias: "97842" },
  { category: "Postleitzahl", value: "97843", alias: "97843" },
  { category: "Postleitzahl", value: "97845", alias: "97845" },
  { category: "Postleitzahl", value: "97846", alias: "97846" },
  { category: "Postleitzahl", value: "97848", alias: "97848" },
  { category: "Postleitzahl", value: "97849", alias: "97849" },
  { category: "Postleitzahl", value: "97851", alias: "97851" },
  { category: "Postleitzahl", value: "97852", alias: "97852" },
  { category: "Postleitzahl", value: "97854", alias: "97854" },
  { category: "Postleitzahl", value: "97855", alias: "97855" },
  { category: "Postleitzahl", value: "97857", alias: "97857" },
  { category: "Postleitzahl", value: "97859", alias: "97859" },
  { category: "Postleitzahl", value: "97877", alias: "97877" },
  { category: "Postleitzahl", value: "97892", alias: "97892" },
  { category: "Postleitzahl", value: "97896", alias: "97896" },
  { category: "Postleitzahl", value: "97900", alias: "97900" },
  { category: "Postleitzahl", value: "97901", alias: "97901" },
  { category: "Postleitzahl", value: "97903", alias: "97903" },
  { category: "Postleitzahl", value: "97904", alias: "97904" },
  { category: "Postleitzahl", value: "97906", alias: "97906" },
  { category: "Postleitzahl", value: "97907", alias: "97907" },
  { category: "Postleitzahl", value: "97909", alias: "97909" },
  { category: "Postleitzahl", value: "97922", alias: "97922" },
  { category: "Postleitzahl", value: "97941", alias: "97941" },
  { category: "Postleitzahl", value: "97944", alias: "97944" },
  { category: "Postleitzahl", value: "97947", alias: "97947" },
  { category: "Postleitzahl", value: "97950", alias: "97950" },
  { category: "Postleitzahl", value: "97953", alias: "97953" },
  { category: "Postleitzahl", value: "97956", alias: "97956" },
  { category: "Postleitzahl", value: "97957", alias: "97957" },
  { category: "Postleitzahl", value: "97959", alias: "97959" },
  { category: "Postleitzahl", value: "97980", alias: "97980" },
  { category: "Postleitzahl", value: "97990", alias: "97990" },
  { category: "Postleitzahl", value: "97993", alias: "97993" },
  { category: "Postleitzahl", value: "97996", alias: "97996" },
  { category: "Postleitzahl", value: "97999", alias: "97999" },
  { category: "Postleitzahl", value: "98527", alias: "98527" },
  { category: "Postleitzahl", value: "98528", alias: "98528" },
  { category: "Postleitzahl", value: "98529", alias: "98529" },
  { category: "Postleitzahl", value: "98530", alias: "98530" },
  { category: "Postleitzahl", value: "98544", alias: "98544" },
  { category: "Postleitzahl", value: "98547", alias: "98547" },
  { category: "Postleitzahl", value: "98553", alias: "98553" },
  { category: "Postleitzahl", value: "98559", alias: "98559" },
  { category: "Postleitzahl", value: "98574", alias: "98574" },
  { category: "Postleitzahl", value: "98587", alias: "98587" },
  { category: "Postleitzahl", value: "98590", alias: "98590" },
  { category: "Postleitzahl", value: "98593", alias: "98593" },
  { category: "Postleitzahl", value: "98596", alias: "98596" },
  { category: "Postleitzahl", value: "98597", alias: "98597" },
  { category: "Postleitzahl", value: "98617", alias: "98617" },
  { category: "Postleitzahl", value: "98630", alias: "98630" },
  { category: "Postleitzahl", value: "98631", alias: "98631" },
  { category: "Postleitzahl", value: "98634", alias: "98634" },
  { category: "Postleitzahl", value: "98639", alias: "98639" },
  { category: "Postleitzahl", value: "98646", alias: "98646" },
  { category: "Postleitzahl", value: "98660", alias: "98660" },
  { category: "Postleitzahl", value: "98663", alias: "98663" },
  { category: "Postleitzahl", value: "98666", alias: "98666" },
  { category: "Postleitzahl", value: "98667", alias: "98667" },
  { category: "Postleitzahl", value: "98669", alias: "98669" },
  { category: "Postleitzahl", value: "98673", alias: "98673" },
  { category: "Postleitzahl", value: "98693", alias: "98693" },
  { category: "Postleitzahl", value: "98694", alias: "98694" },
  { category: "Postleitzahl", value: "98701", alias: "98701" },
  { category: "Postleitzahl", value: "98716", alias: "98716" },
  { category: "Postleitzahl", value: "98724", alias: "98724" },
  { category: "Postleitzahl", value: "98743", alias: "98743" },
  { category: "Postleitzahl", value: "98744", alias: "98744" },
  { category: "Postleitzahl", value: "98746", alias: "98746" },
  { category: "Postleitzahl", value: "99084", alias: "99084" },
  { category: "Postleitzahl", value: "99085", alias: "99085" },
  { category: "Postleitzahl", value: "99086", alias: "99086" },
  { category: "Postleitzahl", value: "99087", alias: "99087" },
  { category: "Postleitzahl", value: "99089", alias: "99089" },
  { category: "Postleitzahl", value: "99090", alias: "99090" },
  { category: "Postleitzahl", value: "99091", alias: "99091" },
  { category: "Postleitzahl", value: "99092", alias: "99092" },
  { category: "Postleitzahl", value: "99094", alias: "99094" },
  { category: "Postleitzahl", value: "99095", alias: "99095" },
  { category: "Postleitzahl", value: "99096", alias: "99096" },
  { category: "Postleitzahl", value: "99097", alias: "99097" },
  { category: "Postleitzahl", value: "99098", alias: "99098" },
  { category: "Postleitzahl", value: "99099", alias: "99099" },
  { category: "Postleitzahl", value: "99100", alias: "99100" },
  { category: "Postleitzahl", value: "99102", alias: "99102" },
  { category: "Postleitzahl", value: "99189", alias: "99189" },
  { category: "Postleitzahl", value: "99192", alias: "99192" },
  { category: "Postleitzahl", value: "99195", alias: "99195" },
  { category: "Postleitzahl", value: "99198", alias: "99198" },
  { category: "Postleitzahl", value: "99310", alias: "99310" },
  { category: "Postleitzahl", value: "99326", alias: "99326" },
  { category: "Postleitzahl", value: "99330", alias: "99330" },
  { category: "Postleitzahl", value: "99331", alias: "99331" },
  { category: "Postleitzahl", value: "99334", alias: "99334" },
  { category: "Postleitzahl", value: "99338", alias: "99338" },
  { category: "Postleitzahl", value: "99423", alias: "99423" },
  { category: "Postleitzahl", value: "99425", alias: "99425" },
  { category: "Postleitzahl", value: "99427", alias: "99427" },
  { category: "Postleitzahl", value: "99428", alias: "99428" },
  { category: "Postleitzahl", value: "99438", alias: "99438" },
  { category: "Postleitzahl", value: "99439", alias: "99439" },
  { category: "Postleitzahl", value: "99441", alias: "99441" },
  { category: "Postleitzahl", value: "99444", alias: "99444" },
  { category: "Postleitzahl", value: "99448", alias: "99448" },
  { category: "Postleitzahl", value: "99510", alias: "99510" },
  { category: "Postleitzahl", value: "99518", alias: "99518" },
  { category: "Postleitzahl", value: "99610", alias: "99610" },
  { category: "Postleitzahl", value: "99625", alias: "99625" },
  { category: "Postleitzahl", value: "99628", alias: "99628" },
  { category: "Postleitzahl", value: "99631", alias: "99631" },
  { category: "Postleitzahl", value: "99634", alias: "99634" },
  { category: "Postleitzahl", value: "99636", alias: "99636" },
  { category: "Postleitzahl", value: "99638", alias: "99638" },
  { category: "Postleitzahl", value: "99706", alias: "99706" },
  { category: "Postleitzahl", value: "99707", alias: "99707" },
  { category: "Postleitzahl", value: "99713", alias: "99713" },
  { category: "Postleitzahl", value: "99718", alias: "99718" },
  { category: "Postleitzahl", value: "99734", alias: "99734" },
  { category: "Postleitzahl", value: "99735", alias: "99735" },
  { category: "Postleitzahl", value: "99752", alias: "99752" },
  { category: "Postleitzahl", value: "99755", alias: "99755" },
  { category: "Postleitzahl", value: "99759", alias: "99759" },
  { category: "Postleitzahl", value: "99765", alias: "99765" },
  { category: "Postleitzahl", value: "99768", alias: "99768" },
  { category: "Postleitzahl", value: "99817", alias: "99817" },
  { category: "Postleitzahl", value: "99819", alias: "99819" },
  { category: "Postleitzahl", value: "99820", alias: "99820" },
  { category: "Postleitzahl", value: "99826", alias: "99826" },
  { category: "Postleitzahl", value: "99830", alias: "99830" },
  { category: "Postleitzahl", value: "99831", alias: "99831" },
  { category: "Postleitzahl", value: "99834", alias: "99834" },
  { category: "Postleitzahl", value: "99837", alias: "99837" },
  { category: "Postleitzahl", value: "99842", alias: "99842" },
  { category: "Postleitzahl", value: "99846", alias: "99846" },
  { category: "Postleitzahl", value: "99848", alias: "99848" },
  { category: "Postleitzahl", value: "99867", alias: "99867" },
  { category: "Postleitzahl", value: "99869", alias: "99869" },
  { category: "Postleitzahl", value: "99880", alias: "99880" },
  { category: "Postleitzahl", value: "99885", alias: "99885" },
  { category: "Postleitzahl", value: "99887", alias: "99887" },
  { category: "Postleitzahl", value: "99891", alias: "99891" },
  { category: "Postleitzahl", value: "99894", alias: "99894" },
  { category: "Postleitzahl", value: "99897", alias: "99897" },
  { category: "Postleitzahl", value: "99947", alias: "99947" },
  { category: "Postleitzahl", value: "99955", alias: "99955" },
  { category: "Postleitzahl", value: "99958", alias: "99958" },
  { category: "Postleitzahl", value: "99974", alias: "99974" },
  { category: "Postleitzahl", value: "99976", alias: "99976" },
  { category: "Postleitzahl", value: "99986", alias: "99986" },
  { category: "Postleitzahl", value: "99988", alias: "99988" },
  { category: "Postleitzahl", value: "99991", alias: "99991" },
  { category: "Postleitzahl", value: "99994", alias: "99994" },
  { category: "Postleitzahl", value: "99996", alias: "99996" },
  { category: "Postleitzahl", value: "99998", alias: "99998" }
]