import {
  Body,
  Section,
  Tailwind,
  Head,
  Html,
  Text
} from '@react-email/components';
import { EmailValues } from '../../data/schema';
import { defaultValues } from './data/offer-default-vaules';
import { H2 } from './components/Typography';
import DefaultContainer from './components/DefaultContainer';
import Footer from './components/Footer';
import Unsubscribe from './components/Unsubscribe';
import Contact from './components/Contact';
import OfferOrderContent from './components/OfferOrderContent';
import Header from './components/Header';
import { twConfig } from './data/tailwind-config';

interface ListflixOrderEmailProps {
  values: EmailValues
}

/*
  #FECB18	<-  PRIMÄR GELB
  #29AD7D	<- PRIMÄR GRÜN
  #F58634	<- SEKUNDÄR Orange
  #e66e16		<- SEKUNDÄR Orange (mehr Kontrast)
  #208A64 Dunkelgrün
*/

export default function ListflixOrderEmail ({ values }: ListflixOrderEmailProps) {
  if (!values) { 
    values = defaultValues
  }

  return (
    <Tailwind config={twConfig}>
      <Html>
        <Head />
        <Body>
          {/* wrap in section because Outlook does not accept background color of body for some reason */}
          <Section className="bg-gray-100 mx-auto font-email my-[0px]">
            {/* Header */}
            <Header />
          
            <DefaultContainer>

              {/* Anrede */}
              <Section className="px-[8px] xl:px-[32px]">
                <H2 className="text-[#29AD7D] mt-[0px]">
                  Hallo {values.salutation} {values.lastName},
                </H2>
                <Text className="text-[14px] mb-[8px]">vielen Dank für Ihre Bestellung! Mit dieser Mail bestätigen wir Ihnen den Eingang Ihrer Bestellung.</Text>
                <Text className="text-[14px] mt-[0px] mb-[0px] font-bold">Wir werden Ihren Auftrag schnellstmöglich prüfen und Ihnen eine Auftragsbestätigung zusenden.</Text>
              </Section>

              {/* Angebot */}
              <OfferOrderContent values={values} />

              <Section className="px-[8px] xl:px-[32px]">
                <Text className='mb-0 mt-0'>Hinweis: Diese E-Mail wurde automatisch erstellt. Sie stellt eine Eingangsbestätigung Ihrer Bestellung und keine verbindliche Lieferzusage dar.</Text>
              </Section>

              {/* Kontakt */}
              <Contact />
            </DefaultContainer>

            {/* Footer */}
            <Footer />

            {/* unsubscribe */}
            <Unsubscribe />

          </Section>
        </Body>
      </Html>
    </Tailwind>
  )
}