import { Column, Link, Row, Section, Text } from "@react-email/components";
import { H2 } from "./Typography";

export default function Footer() {
  return (
    <Section className="bg-listflix-green-dark w-full border-solid border-t-4 border-l-0 border-b-0 border-r-0 border-listflix-green-dark border-t-listflix-yellow-main">
      <Section className="w-full max-w-[800px] px-[16px] xl:px-16 py-[32px] mx-auto">
        <H2 className="text-white mt-[0px]">Kontakt</H2>
        <Row className="pb-4">
          <Column>
            <Text className="mb-[0px] mt-[0px] text-white text-[14px]"><span className="font-bold">Datamego GmbH</span>, Karlstraße 9 in 90403 Nürnberg</Text>
          </Column>
        </Row>

        {/* --------- Contact details small screen start ----------- */}
        <div id="not-mso-if-start"></div>
        <Row className="sm:hidden">
          <Column className="w-[40%]">
            <Text className="mb-[0px] mt-[0px] text-white text-[14px]">
              <span className="font-bold text-white text-[14px]">Tel: </span><Link className="text-white underline text-[14px]" href="tel:091114898127">0911 148 98 127</Link><br/>
              <span className="font-bold text-white text-[14px]">E-Mail: </span><Link className="text-white underline text-[14px]" href="mailto:anfrage@listflix.de?subject=&amp;body=">anfrage@listflix.de</Link><br/>
              <span className="font-bold text-white text-[14px]">Webseite: </span><Link className="text-white underline text-[14px]" href="https://listflix.de">Listflix.de</Link><br/>
              <span className="font-bold text-white text-[14px]">Ansprechpartner: </span>Michael Wechsler             
            </Text>
          </Column>
        </Row>

        <Row className="h-4 sm:h-0">
          <Column></Column>
        </Row>

        <Row className="sm:hidden">
          <Column>
            <Text className="mb-[0px] mt-[0px] text-white text-[14px]">
              <span className="font-bold text-white text-[14px]">USt-IdNR.: </span> DE341819155<br />
              <span className="font-bold text-white text-[14px]">Steuernummer: </span> 241/124/10992<br />
              <span className="font-bold text-white text-[14px]">Registernummer: </span> HRB 38729, Amtsgericht Nürnberg<br />
              <span className="font-bold text-white text-[14px]">Geschäftsführer: </span> Marc Bickel, Michael Wechsler<br />
            </Text>
          </Column>
        </Row>
        <div id="end-if"></div>
        {/* --------- Contact details small screen end ----------- */}

        {/* --------- Contact details large screen start ----------- */}
        <Row className="hidden sm:table">
          <Column className="w-[40%]">
            <Text className="mb-[0px] mt-[0px] text-white text-[14px]">
              <span className="font-bold text-white text-[14px]">Tel: </span><Link className="text-white underline text-[14px]" href="tel:091114898127">0911 148 98 127</Link><br/>
              <span className="font-bold text-white text-[14px]">E-Mail: </span><Link className="text-white underline text-[14px]" href="mailto:anfrage@listflix.de?subject=&amp;body=">anfrage@listflix.de</Link><br/>
              <span className="font-bold text-white text-[14px]">Webseite: </span><Link className="text-white underline text-[14px]" href="https://listflix.de">Listflix.de</Link><br/>
              <span className="font-bold text-white text-[14px]">Ansprechpartner: </span>Michael Wechsler             
            </Text>
          </Column>

          <Column className="w-[8px]" />

          <Column valign='top'>
            <Text className="mb-[0px] mt-[0px] text-white text-[14px]">
              <span className="font-bold text-white text-[14px]">USt-IdNR.: </span> DE341819155<br />
              <span className="font-bold text-white text-[14px]">Steuernummer: </span> 241/124/10992<br />
              <span className="font-bold text-white text-[14px]">Registernummer: </span> HRB 38729, Amtsgericht Nürnberg<br />
              <span className="font-bold text-white text-[14px]">Geschäftsführer: </span> Marc Bickel, Michael Wechsler<br />
            </Text>
          </Column>
        </Row>
        {/* --------- Contact details large screen end ----------- */}

      </Section>
    </Section>
  )
}