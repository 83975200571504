import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Padding {
    xs: string,
    sm: string,
    md: string,
    lg: string,
    xl: string
  }

  interface Theme {
    padding: Padding
  }

  interface ThemeOptions {
    padding: Padding
  }

  interface Variant {

  }
}

const theme = createMuiTheme({
    // overrides: {
    //   MuiCssBaseline: {
    //     '@global': {
    //       body: {
    //         backgroundColor: 'white',
    //         height: '100%'
    //       },
    //       // Adjust default html font size to 10px
    //       html: {
    //         fontSize: "62.5%",
    //         height: '100%'
    //       }
    //     }
    //   }
    // },
    palette: {
      primary: {
        main: '#29AD7D'
      },
      secondary: {
        main: '#560B90'
      },
      text: {
        primary: '#333333',
        secondary: '#ffffff'
      }
    },
    padding: {
      xs: '8px',
      sm: '16px',
      md: '32px',
      lg: '64px',
      xl: '320px'
    },
    typography: {
      // Adjust default html font size to 10 px (only in combination with html.fontSize tag (see above)!)
      // htmlFontSize: 10,
      // fontFamily: '"Open Sans Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
      // Derive headline font sizes from base font size

      h1: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '34px',
        [breakpoints.down('xs')]: {
          fontSize: '24px',
          lineHeight: '32px'
        }
      },

      h2: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '8px',
        [breakpoints.down('xs')]: {
          fontSize: '20px',
          marginBottom: '6px'
        },
      },

      h3: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '6px'
      },

      h4: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '8px',
        marginBottom: '6px'
      },
      
      body1: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        [breakpoints.down('md')]: {
          fontSize: '16px'
        }
      },
      body2: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
        [breakpoints.down('xs')]: {
          fontSize: '16px'
        }
      },

      button: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        fontWeight: 'bold'
      },
      caption: {
        fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '18px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        [breakpoints.down('xs')]: {
          fontSize: '16px'
        }
      }
    }
  });

  export default theme;