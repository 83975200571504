import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Icon } from '@material-ui/core';
import { SuggestionValue } from '../data/schema';

const useStyles = makeStyles((theme) => ({
  tag:{
    backgroundColor: theme.palette.primary.main,
    padding: '2px ' + theme.padding.xs,
    borderRadius: '2px',
    marginRight: theme.padding.xs,
    marginBottom: theme.padding.xs
  },
  closeIcon: {
    color: 'white',
    fontSize: '16px!important'
  },
  close: {
    marginLeft: theme.padding.xs,
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center'
  }
}));

interface TagProps {
  tag: SuggestionValue,
  deselect: Function
}

export default function Tag(props: TagProps) {
  const tag = props.tag;
  const classes = useStyles();
  const deselect = props.deselect;
  const tagText = tag.value + (tag.count !== undefined ? ` (${tag.count.toLocaleString('de-DE')})` : '');

  return (
    <Grid item className={classes.tag}>
      <Grid container direction="row" alignItems="center" key={tag.value}>
        <Grid item>
          <Typography color="textSecondary" variant="body1" key={tag.value}>{tagText}</Typography>
        </Grid>
        <Grid item>
          <div onClick={() => deselect(tag)} className={classes.close}><Icon className={classes.closeIcon}>close</Icon></div>
        </Grid>
      </Grid>
    </Grid>
  );
}
