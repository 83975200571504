import React, { useState, useEffect, useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import { nanoid } from 'nanoid';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { getSchema, getInitialValues, getEmptyFormConfig, mapViewToFormikFields, mapEmailValues } from '../utils/form-utils';
import { createQuoteRequest } from '../utils/nexus-utils';
import { fetchConfig, submitAndGetOffer, submitAndBuy, postIntermediateValues, initGA, fetchFreemailerList, fetchPrice, fireGtmEvent, sendOfferEmail, sendOrderEmail } from '../api/client';
import { FormConfig, VerticalSearchConfig, ContactConfig, ViewConfig, RegionSearchConfig, RequiredFieldsConfig, FormValues, SizeClassesConfig, LimitConfig, OfferConfig } from '../data/schema';

import ProgressBar from './ProgressBar';
import BackNextButtons from './BackNextButtons';
import OfferWidget from './OfferWidget';
import ContactStep from './ContactStep';
import VerticalSearchView from '../views/VerticalSearchView';
import RegionSearchView from '../views/RegionSearchView';
import RequiredFieldsView from '../views/RequiredFieldsView';
import SizeClassesView from '../views/SizeClassesView';
import LimitView from '../views/LimitView';
import OfferView from '../views/OfferView';
import { getExpectedDealSize } from '../utils/helpers';
import { render } from '@react-email/render';
import ListflixOfferEmail from '../react-email-starter/emails/listflix-offer';
import ListflixOrderEmail from '../react-email-starter/emails/listflix-order';

const useStyles = makeStyles((theme) => ({
  rootContainer: (activeStep) => ({
    maxWidth: process.env.NODE_ENV === 'development' ? '1200px' : '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: activeStep === 5 || activeStep === 6 ? 'column-reverse' : 'column'
    }
  }),
  formContainer: {
    zIndex: 10
  },
  progressBar: {
    padding: theme.padding.md + ' 48px',
    paddingBottom: theme.padding.xs,
    [theme.breakpoints.down('sm')]: {
      padding: theme.padding.md + ' ' + theme.padding.md,
      paddingBottom: theme.padding.xs
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.padding.md + ' ' + theme.padding.xs,
      paddingBottom: theme.padding.xs,
    }
  },
  formContent: {
    padding: theme.padding.md + ' 48px',
    [theme.breakpoints.down('lg')]: {
      padding: theme.padding.md + ' ' + theme.padding.md,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.padding.md + ' ' + theme.padding.xs,
    }
  },
  shopButton: {
    marginTop: theme.padding.md,
    marginBottom: theme.padding.lg
  },
  subHeader: {
    marginTop: theme.spacing(2)
  },
  offerWidgetContainer: {
    width: '100%'
  }
}));

interface FormContainerProps {
  formConfigFile: string
}

export default function FormContainer({ formConfigFile }: FormContainerProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [jumpFromStep, setJumpFromStep] = useState<number | undefined>(undefined);
  const [freemailerValues, setFreemailerValues] = useState<Set<any>>(new Set());

  const [formConfig, setFormConfig] = useState<FormConfig>(getEmptyFormConfig());
  const [formValues, setFormValues] = useState<FormValues>(getInitialValues());
  const formRef = useRef<FormikProps<FormValues>>(null);

  const [count, setCount] = useState<number | undefined>(undefined);
  const [price, setPrice] = useState<number>(0);

  const [blockNext, setBlockNext] = useState(false);

  // userId needs to be state variable so it won't be replaced with every render
  // even though setUserId is never used
  const [userId, setUserId] = useState(nanoid());

  // tmp storage for quotation after sending get-offer request
  const [storedQuotation, setStoredQuotation] = useState<any>();
  const [creatingOffer, setCreatingOffer] = useState<boolean>(false);
  const [quoteError, setQuoteError] = useState<boolean>(false);
  const [orderError, setOrderError] = useState<boolean>(false);

  const classes = useStyles(activeStep);
  const currentStep: ViewConfig = formConfig.views[activeStep];

  // Set Google Analytics ID
  useEffect(() => {
    initGA();
  }, []);

  // Fetch needed resources
  useEffect(() => {
    (async () => {
      const freemailerValues = await fetchFreemailerList();
      setFreemailerValues(convertFreemailerValues(freemailerValues));
    })();
  }, []);

  // Fetch and set form config
  useEffect(() => {
    (async () => {
      const config = (await fetchConfig(formConfigFile) as FormConfig);
      setFormConfig(config);
    })();
  }, [formConfigFile]);

  // fetch new quote every time the count changes
  useEffect(() => {
    //setPrice(0);
    (async () => {
      if (count) {
        const quote = await fetchPrice(count);
        setPrice(quote.net);
      }
    })();
  }, [count])

  if (!formConfig || !formConfig.views || formConfig.views.length === 0) {
    return <div>Loading...</div>
  }

  // Helpers start

  function convertFreemailerValues(freemailerValues: any) {
    if (freemailerValues !== undefined) {
      return new Set(freemailerValues);
    } else return new Set();
  }

  function isCountTooSmall() {
    return count !== undefined && count < formConfig.minCount;
  }

  // Handle button clicks start

  async function handleNext() {
    const fieldValid = await isFieldsValid();
    if (!fieldValid) return;

    const exclFields = [
      "postCodeRanges",
      "limitsByVerts"
    ]

    if (currentStep.id !== "contact") {
      for (let field of mapViewToFormikFields(currentStep.id)) {
        if (!exclFields.includes(field)) {
          handleIntermediateChange(field, formValues[field as keyof FormValues], "next");
        }
      }
    }

    if (currentStep.id === "limit") {
      const expectedDealSize = getExpectedDealSize(price);
      // push Google Tag Manager event
      (window as any).dataLayer.push({
        'event': 'Anzahl_weiter_DKF',
        'offer_value': expectedDealSize ? expectedDealSize * 0.05 : null
      });
    }

    fireGtmEvent('weiter', activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  async function handleIntermediateChange(fieldName: string, values: any, event: string) {
    const intermediateValues = {
      user_id: userId,
      field_name: fieldName,
      value: JSON.stringify(values)
    };

    return await postIntermediateValues(intermediateValues, event);
  }

  async function isFieldsValid() {
    if (formRef.current) {
      const { validateForm, setFieldTouched } = formRef.current;
      const validationErrors = await validateForm();
      const fields = mapViewToFormikFields(currentStep.id);
      let hasError = false;

      for (let field of fields) {
        setFieldTouched(field);

        if (field in validationErrors) {
          hasError = true;
        }
      }

      return !hasError;
    }
  }

  function handleBack() {
    fireGtmEvent('zurück', activeStep);
    
    if(jumpFromStep !== undefined){
      setJumpFromStep(undefined);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (values: FormValues, type: 'buy' | 'offer' = 'offer') => {
    if (type === 'buy') {
      handleSubmitBuy();
    }

    handleSubmitGetOffer();
  }

  const handleSubmitGetOffer = async () => {
    const fieldValid = await isFieldsValid();
    if (!fieldValid) return;

    const expectedDealSize = getExpectedDealSize(price);
    
    // push Google Tag Manager event
    (window as any).dataLayer.push({
      'event': 'Angebot_angefordert_DKF',
      'offer_value': expectedDealSize,
      'email': formValues.email
    });

    (window as any).uetq.push(
      'event', 
      'Angebot_angefordert_DKF', {
        'event_value': expectedDealSize,
        'currency': 'EUR'
      }
    );

    // push Google Tag Manager event
    (window as any).dataLayer.push({
      'event': 'Angebot_angefordert_value2_DKF',
      'offer_value': expectedDealSize ? expectedDealSize - (expectedDealSize * 0.05) : null,
      'email': formValues.email
    });

    let quoteRequest = createQuoteRequest(formValues);
    quoteRequest.form_raw = JSON.stringify({
      ...formValues,
      user_id: userId,
      source_url: window.location.href,
      count: count,
      price: price
    });

    const submitValuesGetOffer = {
      user_id: userId,
      source_url: window.location.href,
      abo: formValues.abo.length > 0,
      quote_request: quoteRequest
    };
    
    try {
      setCreatingOffer(true);
      const response = await submitAndGetOffer(submitValuesGetOffer);

      if (response && [200, 201].includes(response.status)) {
        const quotation = await response.json()
        setStoredQuotation(quotation);
        handleNext();
  
        const emailHtml = render(<ListflixOfferEmail values={mapEmailValues(formValues, quotation)} />)
  
        const submitValuesEmail = {
          email_html: renderMso(emailHtml),
          to_email: quotation.com_email
        };
  
        await sendOfferEmail(submitValuesEmail)
      } else {
        throw new Error("response status not 200 or 201")
      }
    } catch (e) {
      setQuoteError(true);
    } finally {
      setCreatingOffer(false);
    }

    return;
  }

  function renderMso(htmlString: string): string {
    const updatedHtmlString = htmlString
      .replace(
        /<div id="not-mso-if-start"><\/div>/g,
        '<!--[if !mso]><!-->'
      )
      .replace(
        /<div id="end-if"><\/div>/g,
        '<!--<![endif]-->'
      );

    return updatedHtmlString;
  }

  const handleSubmitBuy = async () => {
    (window as any).dataLayer.push({'event': 'Liste_bestellt_DKF'});

    const submitValuesBuy = {
      quotation: storedQuotation,
      user_id: userId,
    };

    try {
      await submitAndBuy(submitValuesBuy);
  
      const emailHtml = render(<ListflixOrderEmail values={mapEmailValues(formValues, storedQuotation)} />)
      const submitValuesEmail = {
        email_html: renderMso(emailHtml),
        to_email: storedQuotation.com_email
      };
  
      const emailResponse = await sendOrderEmail(submitValuesEmail);

      if ([200, 201].includes(emailResponse.status)) {
        window.location.href = 'https://listflix.de/bestellung-eingegangen/?status=success';
      } else {
        throw new Error("Error sending order email.")
      }

    } catch (e) {
      setOrderError(true);
    }
    
    return;
  }

  // Handle button clicks end

  // Render component start

  function Buttons({ hideBack, hideNext, config }: { hideBack?: boolean, hideNext?: boolean, config: ViewConfig }) {
    return (
      <BackNextButtons
        disableNext={isCountTooSmall() || blockNext}
        hideBack={hideBack}
        hideNext={hideNext}
        backButtonText={config.footer?.backButtonText}
        nextButtonText={config.footer?.nextButtonText}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    );
  }

  function getCurrentComponent(): React.ReactNode {
    const currentConfig = formConfig.views[activeStep];

    switch (activeStep) {
      case 0:
        const verticalSearchConfig = (currentConfig as VerticalSearchConfig);
        return (
          <>
            <VerticalSearchView
              verticalSearchConfig={verticalSearchConfig}
              handleIntermediateChange={handleIntermediateChange}
              setCount={setCount}
              setFormValues={setFormValues}
              views={formConfig.views}
            />
            <Buttons hideBack config={verticalSearchConfig} />
          </>
        );
      case 1:
        const regionSearchConfig = (currentConfig as RegionSearchConfig);
        return (
          <>
            <RegionSearchView
              handleIntermediateChange={handleIntermediateChange}
              regionSearchConfig={regionSearchConfig}
              count={count}
              setCount={setCount}
              setFormValues={setFormValues}
              views={formConfig.views}
            />
            <Buttons config={regionSearchConfig} />
          </>
        );
      case 2:
        const requiredFieldsConfig = (currentConfig as RequiredFieldsConfig);
        return (
          <>
            <RequiredFieldsView
              handleIntermediateChange={handleIntermediateChange}
              requiredFieldsConfig={requiredFieldsConfig}
              setCount={setCount}
              setFormValues={setFormValues}
              views={formConfig.views}
            />
            <Buttons config={requiredFieldsConfig} />
          </>
        );
      case 3:
        const sizeClassesConfig = (currentConfig as SizeClassesConfig);
        return (
          <>
            <SizeClassesView
              handleIntermediateChange={handleIntermediateChange}
              sizeClassesConfig={sizeClassesConfig}
              setCount={setCount}
              setFormValues={setFormValues}
              views={formConfig.views}
              setBlockNext={setBlockNext}
            />
            <Buttons config={sizeClassesConfig} />
          </>
        );
      case 4:
        const limitConfig = (currentConfig as LimitConfig);
        return (
          <>
            <LimitView
              handleIntermediateChange={handleIntermediateChange}
              limitConfig={limitConfig}
              count={count}
              setCount={setCount}
              setFormValues={setFormValues}
              views={formConfig.views}
            />
            <Buttons config={limitConfig} />
          </>
        );
      case 5:
        const contactConfig = (currentConfig as ContactConfig);
        return (
          <ContactStep
            contactConfig={contactConfig}
            setFormValues={setFormValues}
            handleBack={handleBack}
            processing={creatingOffer}
            error={quoteError}
          />
        );
      case 6:
        const offerConfig = (currentConfig as OfferConfig);
        return (
          <>
            <OfferView
              formValues={formValues}
              offerConfig={offerConfig}
              count={count}
            />
          </>
        )
      default:
        return <></>;
    }
  }

  // Render component end

  const countTooSmallValue = (isCountTooSmall()) ? formConfig.minCount : undefined;
  const initialValues: FormValues = getInitialValues();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getSchema(freemailerValues)}
      onSubmit={(values) => handleSubmit(values)}
      innerRef={formRef}
    >
      {props => (
        <Grid container direction="row" className={classes.rootContainer}>
          <Grid container item direction="column" sm={12} md={7} className={classes.formContainer}>

            <Grid item className={classes.progressBar}>
              <ProgressBar activeStep={activeStep} views={formConfig.views} />
            </Grid>

            <Grid item className={classes.formContent}>
              <form onSubmit={props.handleSubmit}>
                {getCurrentComponent()}
              </form>
            </Grid>

          </Grid>

          <Grid item sm={12} md={5} className={classes.offerWidgetContainer} >
            <OfferWidget
              formValues={formValues}
              count={count}
              price={price}
              countTooSmallValue={countTooSmallValue}
              activeStep={activeStep}
              handleBuy={handleSubmitBuy}
              orderError={orderError}
              quotation={storedQuotation}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}