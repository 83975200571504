import {
  Section,
  Text,
  Row,
  Column,
} from '@react-email/components';
import { H2, H3 } from './Typography';
import { AnsprechpartnerDetails, BasisdatenDetails, FinanzdatenDetails, KontaktdatenDetails } from './PositionDetails';
import { EmailValues } from '../../../data/schema';

type OfferOrderContentProps = {
  values: EmailValues,
  isOffer?: boolean
}

export default function OfferOrderContent ({ values, isOffer }: OfferOrderContentProps) {
  return (
    <Section className="pb-[32px]">
      <Row className="px-[8px] xl:px-[32px]">
        <Column>
          <H2>{isOffer ? 'Ihr Angebot' : 'Ihre Bestellung'}</H2>
          
          {/*<Heading as="h1" className="text-gray-800 text-[24px] leading-[32px] mb-[32px] mt-[32px] underline underline-offset-[14px] decoration-4 decoration-listflix-green-main">{isOffer ? 'Ihr Angebot' : 'Ihre Bestellung'}</Heading>*/}
        </Column>
      </Row>

      
      <Row className='pb-8'>
        <Column className="h-[4px] bg-listflix-green-main rounded-full w-full" />
      </Row>

      {/* --------- Offer header details small screen start ----------- */}
      <div id="not-mso-if-start"></div>
      <Row className="sm:hidden">
        <Column className="bg-gray-50 px-[8px] xl:px-[32px] py-[16px] rounded-sm">
          <Text className="mb-[0px] mt-[0px] text-[14px]">
            {values.comName}<br/>
            {values.comStreet} {values.comHouseNumber}<br />
            {values.comPostCode} {values.comCity}
            {values.comCountry ? <><br />{values.comCountry}</> : ''}
          </Text>
        </Column>
      </Row>

      <Row className="h-2 sm:h-0">
        <Column></Column>
      </Row>
      
      <Row className="sm:hidden">
        <Column className="bg-gray-50 px-[8px] xl:px-[32px] py-[16px] rounded-sm">
          {isOffer && <Text className="mb-[0px] mt-[0px] text-[14px]">Datum: {values.quoteDate}</Text>}
          {isOffer && <Text className="mb-[0px] mt-[0px] text-[14px]">Angebot gültig bis: {values.validTo}</Text>}
          {!isOffer && <Text className="mb-[0px] mt-[0px] text-[14px]">Datum der Bestellung: {values.orderDate}</Text>}
          <Text className="mb-[0px] mt-[0px] text-[14px]">Angebotsnummer: {values.quoteNumber}</Text>
        </Column>
      </Row>
      <div id="end-if"></div>
      {/* --------- Offer header details small screen end ----------- */}

      {/* --------- Offer header details large screen start ----------- */}
      <Row className="hidden sm:table">
        <Column className="bg-gray-50 px-[8px] xl:px-[32px] py-[16px] rounded-sm">
          <Text className="mb-[0px] mt-[0px] text-[14px]">
            {values.comName}<br/>
            {values.comStreet} {values.comHouseNumber}<br />
            {values.comPostCode} {values.comCity}
            {values.comCountry ? <><br />{values.comCountry}</> : ''}
          </Text>
        </Column>
        <Column className='w-[10px]' />
        <Column className="bg-gray-50 px-[8px] xl:px-[32px] py-[16px] rounded-sm">
          {isOffer && <Text className="mb-[0px] mt-[0px] text-[14px]">Datum: {values.quoteDate}</Text>}
          {isOffer && <Text className="mb-[0px] mt-[0px] text-[14px]">Angebot gültig bis: {values.validTo}</Text>}
          {!isOffer && <Text className="mb-[0px] mt-[0px] text-[14px]">Datum der Bestellung: {new Date().toLocaleDateString('de-DE')}</Text>}
          <Text className="mb-[0px] mt-[0px] text-[14px]">Angebotsnummer: {values.quoteNumber}</Text>
        </Column>
      </Row>
      {/* --------- Offer header details large screen end ----------- */}

      {values.positions.map((pos, index) => (
        <Section key={index}>
          <Row>
            <Column className="px-[8px] xl:px-[32px]">
              <H3 className="text-listflix-green-main">Position {index+1}: Liste {pos.name_short}</H3>
            </Column>
          </Row>

          {/* <Row>
            <Column className="h-[4px] bg-listflix-green-main rounded-full w-full" />
          </Row> */}

          <Row className="px-[8px] xl:px-[32px]">
            <Column>
              <Text className='text-[14px] mb-0 mt-0'>Branche: <span className="font-bold">{pos.name_long}</span></Text>
              {pos.regions && <Text className='text-[14px] mt-0 mb-0'>Region: <span className="font-bold">{pos.regions}</span></Text>}
              {pos.req_fields && <Text className='text-[14px] mt-0 mb-0'>Alle Firmen enthalten: <span className="font-bold">{pos.req_fields}</span></Text>}
              {pos.size_classes && <Text className='text-[14px] mt-0 mb-0'>Größenklassen: <span className="font-bold">{pos.size_classes}</span></Text>}
              <Text className='text-[14px] mt-0'>Nutzungslizenz: <span className="font-bold">unbegrenzt</span></Text>
            </Column>
          </Row>

          <Row className="px-[8px] xl:px-[32px]">
            <Column className="w-[30px]">
              <Text className='mt-[0px] mb-[0px] font-bold text-listflix-green-main text-[14px] leading-[14px]'>Anzahl Firmen: {(pos.cnt as number).toLocaleString('de-DE')}</Text>
            </Column>
          </Row>

          {/* <Row>
            <Column className="h-[2px] bg-gray-200 rounded-full w-full" />
          </Row> */}

          {/* --------- Statistics small screen start ----------- */}
          <div id="not-mso-if-start"></div>
          <Row className="sm:hidden w-full">
            <BasisdatenDetails pos={pos} className='px-[8px] pt-[32px]' />
          </Row>

          <Row className="sm:hidden w-full">
            <AnsprechpartnerDetails pos={pos} className='px-[8px] pt-[16px]' />
          </Row>

          <Row className="sm:hidden w-full">
            <KontaktdatenDetails pos={pos} className='px-[8px] pt-[16px]' />
          </Row>

          <Row className="sm:hidden w-full">
            <FinanzdatenDetails pos={pos} className='px-[8px] py-[16px]' />
          </Row>
          <div id="end-if"></div>
          {/* --------- Statistics small screen end ----------- */}

          {/* --------- Statistics large screen start ----------- */}
          <Row className="hidden sm:block px-[8px] xl:px-[32px] pt-[32px] pb-[16px]">
            <BasisdatenDetails pos={pos} className='w-1/2' />

            <Column className="min-w-[16px]" />

            <AnsprechpartnerDetails pos={pos} className='w-1/2' />
          </Row>

          <Row className="hidden sm:block px-[8px] xl:px-[32px] pt-[16px] pb-[16px]">
            <KontaktdatenDetails pos={pos} className='w-1/2' />

            <Column className="min-w-[16px]" />

            <FinanzdatenDetails pos={pos} className='w-1/2' />
          </Row>
          {/* --------- Statistics large screen end ----------- */}

          <Row>
            <Column className="bg-listflix-yellow-main h-[2px] rounded-full w-full" />
          </Row>

          <Row className="px-[8px] xl:px-[32px] bg-gray-50">
            <Column>
              <Text className="mt-[8px] mb-[8px] text-right font-bold text-[14px]">Einzelpreis: {pos.net} {pos.currency}</Text>
            </Column>
          </Row>

        </Section>
      ))}

      <Row>
        <Column className="bg-listflix-green-main h-[4px] rounded-full w-full" />
      </Row>

      <Row className="px-[8px] xl:px-[32px] pt-[8px]">
        <Column>
          <Text className="mb-[0px] mt-[0px] text-right font-bold text-[14px]">Gesamtanzahl der Firmen: {values.totalCnt}</Text>
          {values.totalTax && 
          <>
            <Text className="mb-[0px] mt-[0px] text-right text-[14px]">Zwischensumme (netto): {values.totalNet} {values.totalCurrency}</Text>
            <Text className="mb-[0px] mt-[0px] text-right text-[14px]">{values.totalTaxRate} Umsatzsteuer: {values.totalTax} {values.totalCurrency}</Text>
          </>
          }

          <Text className="mb-[0px] mt-[0px] text-right font-bold text-[14px]">Gesamtbetrag: {values.totalGross} {values.totalCurrency}</Text>
        </Column>
      </Row>

      {values.comCountryCode !== 'DE' &&
      <Row className="px-[8px] xl:px-[32px] pt-[8px]">
        <Column>
          <Text className="text-[14px]">Der Gesamtbetrag ist eine nicht im Inland steuerbare Leistung (Steuerschuldnerschaft des Leistungsempfängers/Reverse Charge).</Text>
        </Column>
      </Row>}

    </Section>
  )
}