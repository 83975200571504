import React, { useEffect, useRef } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import { fetchGlobalCount } from '../api/client';
import { getInitialValues, isEqual, mapViewToFormikFields } from '../utils/form-utils';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';
import { ViewConfig } from '../data/schema';

interface DefaultViewProps {
  viewId: string,
  views: Array<ViewConfig>;
  useDefaultCount?: boolean,
  setCount: Function,
  setFormValues: Function,
  children: Array<React.ReactNode>
}

/* 
  All views need to be put inside a "DefaultView" component.
  
  Default view handles all generic changes to state:
  - update form values in FormContainer
  - reset next fields if "back" is clicked
  - update count (if view doesn't handle it itself)

*/
export default function DefaultView({
  viewId,
  views,
  useDefaultCount,
  setCount,
  setFormValues,
  children
}: DefaultViewProps) {
  const formik: FormikContextType<FormikValues> = useFormikContext();
  let nextFormikFields: Array<string> = [];
  const valueRef = useRef<FormikValues>();
  
  // when clicking "back" in form, reset all values in next views
  useEffect(() => {
    for (
      let i = views.indexOf(views.filter(v => v.id === viewId)[0]) + 1;
      i < views.length && views[i].id !== "contact";
      i++
    ) {
      nextFormikFields.push(...mapViewToFormikFields(views[i].id));
    };

    const initialValues: { [key: string]: any } = getInitialValues();
    nextFormikFields.forEach((field) => {
      formik.setFieldValue(field, initialValues[field]);
    });
  }, []);

  // update count with real change to relevant formik values (defined in "isEqual" method)
  useEffect(() => {
    const previousValues = valueRef.current;
    const currentValues = formik.values;
    
    if (previousValues && useDefaultCount) {
      if (!isEqual(previousValues, currentValues)) {
        (async () => {
          const count = await fetchGlobalCount(mapFormValuesToVertQueries(formik.values));
          setCount(count);
        })();
      }
    }

    valueRef.current = formik.values;
  }, [formik.values]);

  // update form values with every change to formik values
  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  return (
    <>
      {children}
    </>
  );
};