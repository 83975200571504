import { useState } from "react";
import { Grid, Icon, makeStyles, Typography } from "@material-ui/core";

import { FormValues, OfferPreviewItem } from "../data/schema";
import theme from "../theme";

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold'
  },
  listIcon: (expand) => ({
    backgroundColor: expand ? theme.palette.secondary.light : theme.palette.primary.light,
    maxWidth: '36px',
    minHeight: '36px',
    maxHeight: '36px',
    borderRadius: '18px',
    '& .MuiIcon-root': {
      color: 'white'
    },
    marginRight: theme.padding.sm,
    marginTop: 'auto',
    marginBottom: 'auto'
  }),
  posText: (expand) => ({
    color: 'white',
    backgroundColor: expand ? theme.palette.secondary.light : theme.palette.primary.light,
    padding: `2px ${theme.padding.xs}`,
    borderRadius: '2px',
    width: 'fit-content',
    marginBottom: theme.padding.xs
  }),
  posSmall: {
    display: 'none',
    [theme.breakpoints.only('md')]: {
      display: 'block'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block'
    }
  },
  posLarge: {
    [theme.breakpoints.only('md')]: {
      display: 'none'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  container: (expand) => ({
    borderLeft: expand ? '3px solid ' + theme.palette.secondary.main : '3px solid ' + theme.palette.primary.main,
    marginBottom: theme.padding.sm
  }),
  posContainer: {
    padding: `${theme.padding.md} 0px ${theme.padding.md} ${theme.padding.sm}`,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.padding.sm
    }
  },
  detailsContainer: {
    padding: `0px 0px ${theme.padding.md} ${theme.padding.sm}`,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.padding.sm
    }
  },
  showDetails: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

interface OfferPreviewItemProps {
  formValues: FormValues,
  offerPreviewItem: OfferPreviewItem,
  pos: number
}

export default function OfferPreviewItemView({ formValues, offerPreviewItem, pos }: OfferPreviewItemProps) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles(expand);

  const PosPrice = () => (
    <Grid item>
      <Typography variant="h3" align='right' style={{ whiteSpace: 'nowrap' }} color={expand ? 'secondary' : 'primary'}>{offerPreviewItem.price.toLocaleString('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2})} €</Typography>
    </Grid>
  )

  const PosOverview = () => (
    <Grid container item direction="column">
      <Typography variant="h3">Liste {offerPreviewItem.name}</Typography>

      <Typography variant="body1"><span >Anzahl:</span> {offerPreviewItem.overallCount.toLocaleString('de-DE')}</Typography>

      <Grid container direction="row" wrap="nowrap" alignItems='center'>
        <Typography variant="h4" color={expand ? 'secondary' : 'primary'}  onClick={() => setExpand(!expand)} className={classes.showDetails}>Listendetails anzeigen</Typography>
        <Icon color={expand ? 'secondary' : 'primary'}  onClick={() => setExpand(!expand)} className={classes.showDetails}>{expand ? 'expand_less' : 'expand_more'}</Icon>
      </Grid>
    </Grid>
  )

  function getRegionValue() {
    if (formValues.regionValues.length > 0) {
      return formValues.regionValues.map(r => r.alias).join(', ')
    } else if (formValues.postCodes.length > 0) {
      // remove info about number of post codes given in brackets -> (123 PLZ)
      return 'Postleitzahlen ' + formValues.postCodes.map(pc => {
        if (pc.value.includes('(')) {
          return pc.value.substring(0, pc.value.indexOf("(")-1)
        } else {
          return pc.value
        }}).join(', ')
    } else if (formValues.postCodeRange) {
      return `Im Umkreis ${formValues.postCodeRange} km um ${formValues.postCodeRangeInput}`
    }
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container className={classes.posContainer}>

        <Grid container item direction="column" className={classes.posSmall}>
          <Grid container item direction="row" justify="space-between">
            <Typography variant="body2" color="primary" className={classes.posText} component='span'>Pos. {pos + 1}</Typography>
            <PosPrice />
          </Grid>

          <Grid item>
            <PosOverview />
          </Grid>
        </Grid>

        <Grid container item direction="row" wrap="nowrap" className={classes.posLarge} justify="space-between">
          
          <Grid container item direction="row" wrap="nowrap">
            <Grid container item alignItems='center' justify='center' className={classes.listIcon}>
              <Typography color='textSecondary' variant="body2">{pos + 1}</Typography>
            </Grid>

            <Grid item style={{marginRight: theme.padding.sm}}>
              <PosOverview />
            </Grid>
          </Grid>
          
          <Grid item>
            <PosPrice />
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row">

        <Grid item />

        {expand &&
        <Grid container item direction="column" className={classes.detailsContainer}>
          <Grid item>
            <Typography variant="body1"><span className={classes.bold}>Branche:</span> {offerPreviewItem.nameLong}</Typography>
            <Typography variant="body1"><span className={classes.bold}>Region:</span> {getRegionValue()}</Typography>
            <Typography variant="body1"><span className={classes.bold}>Alle Firmen enthalten:</span> {formValues.requiredFields.length === 0 ? 'Postadresse' : 'Postadresse, ' + formValues.requiredFields.join(', ')}</Typography>
            <Typography variant="body1"><span className={classes.bold}>Größenklassen:</span> {formValues.sizeClasses.join(', ')}</Typography>
            <Typography variant="body1"><span className={classes.bold}>Nutzungslizenz:</span> unbegrenzt</Typography>
          </Grid>

          <Grid container item direction="row" spacing={3} style={{marginTop: theme.padding.sm}}>
            <Grid item xs={12}>
              <Typography variant="h4" color='secondary'>Basisdaten:</Typography>
              {offerPreviewItem.basics.map((item, i) => (
                <Typography key={i} variant="body1"><span className={classes.bold}>{item.total.toLocaleString('de-DE', {maximumFractionDigits: 0})}</span> mit {item.name} ({(100 * item.percent).toLocaleString('de-DE', {maximumFractionDigits: 1})} %)</Typography>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" color='secondary'>Ansprechpartner:</Typography>
              {offerPreviewItem.persons.map((item, i) => (
                <Typography key={i} variant="body1"><span className={classes.bold}>{item.total.toLocaleString('de-DE', {maximumFractionDigits: 0})}</span> mit {item.name} ({(100 * item.percent).toLocaleString('de-DE', {maximumFractionDigits: 1})} %)</Typography>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" color='secondary'>Kontaktdaten:</Typography>
              {offerPreviewItem.contactData.map((item, i) => (
                <Typography key={i} variant="body1"><span className={classes.bold}>{item.total.toLocaleString('de-DE', {maximumFractionDigits: 0})}</span> mit {item.name} ({(100 * item.percent).toLocaleString('de-DE', {maximumFractionDigits: 1})} %)</Typography>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" color='secondary'>Finanzdaten:</Typography>
              {offerPreviewItem.financialData.map((item, i) => (
                <Typography key={i} variant="body1"><span className={classes.bold}>{item.total.toLocaleString('de-DE', {maximumFractionDigits: 0})}</span> mit {item.name} ({(100 * item.percent).toLocaleString('de-DE', {maximumFractionDigits: 1})} %)</Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Grid>
  )
}