import theme from '../src/theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import FormContainer from '../src/form/FormContainer';
import './App.css';
import { useEffect } from 'react';

interface AppProps {
  formConfigFile: string
}

function App({ formConfigFile }:AppProps) {
  useEffect(() => {
    // set white background for form
    document.body.classList.add('App-bg');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormContainer formConfigFile={formConfigFile} />
    </ThemeProvider>
  );
}

export default App;
