import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { FormValues, FormConfig, EmailValues } from '../data/schema';

const phoneRegExp = /^[+|0][0-9-/]{7,}[0-9]$/
const emailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
const postCodeRegExp = /(^[0-9]{1,5}$)|(^[0-9]{1,5})([ ]?-[ ]?| bis )([0-9]{1,5}$)/

export function getSchema(freemailerValues: Set<String>) {
  const atLeastOneText = 'Bitte wählen Sie mindestens eine Branche oder Firmenart aus.';
  const postCodeFormatText = 'Bitte geben Sie eine valide Postleitzahl ein (5 Zahlen).'
  const freemailerText = 'Bitte geben Sie Ihre geschäftliche E-Mail Adresse ein (kein Freemailer).';

  return Yup.object().shape({
    salutation: Yup.string().required("Bitte geben Sie die passende Anrede ein."),
    firstName: Yup.string().required("Bitte geben Sie den Vornamen ein."),
    lastName: Yup.string().required("Bitte geben Sie den Nachnamen ein."),
    firmName: Yup.string().required("Bitte geben Sie den genauen Firmennamen ein."),
    street: Yup.string().required("Bitte geben Sie die Straße ein."),
    number: Yup.string().required("Bitte geben Sie die Hausnummer ein."),
    postCode: Yup.string().required("Bitte geben Sie die Postleitzahl ein."),
    city: Yup.string().required("Bitte geben Sie den Ort oder die Stadt ein."),
    country:  Yup.string().required("Bitte wählen Sie den passenden Wert aus."),
    ustid: Yup.string()
      .when('country', {
        is: (country: string) => country !== 'DE',
        then: Yup.string().required("Bitte geben Sie die Umsatzsteuer ID ein.")
      }),
    phone: Yup.string()
      .matches(phoneRegExp, 'Bitte geben Sie eine gültige Telefonnummer ein.'),
    email: Yup.string()
      .matches(emailRegExp, 'Bitte geben Sie Ihre geschäftliche E-Mail Adresse ein.')
      .test('freemailer-validation', freemailerText, function(value) {
        let provider = value ? value.split('@') : "";
        return !process.env.REACT_APP_BACKEND_PREVENT_FREEMAILER || !freemailerValues.has(provider[1]);
      })
      .required("Bitte geben Sie Ihre geschäftliche E-Mail Adresse ein."),
    verticalValues: Yup.array()
      .test('industry-selection-validation', atLeastOneText, function(values) {
        return !(values?.length === 0);
      }),
    // working example of how to validate vertical values field based on non-empty input field as well as selected values
    // verticalValuesInput: Yup.string(),
    // verticalValues: Yup.array()
    //   .when('verticalValuesInput', (verticalValuesInput) => {
    //     // if input field is empty, evaluate if values have already been selected
    //     if(verticalValuesInput === '' || verticalValuesInput === undefined) {
    //       return Yup.array()
    //         .test('industry-selection-validation', atLeastOneText, function(values) {
    //           return !(values?.length === 0);
    //         });
    //     } else {
    //     // allow empty values if input field is not empty
    //       return Yup.array();
    //     }
    //   }),
    regionValues: Yup.array(),
    regionValuesInput: Yup.string(),
    // postCodesInput: Yup.string()
    //   .test('post-codes-in-list', "Die eingegebene Postleitzahl existiert nicht.", function(value) {
    //     if (value) {
    //       // replace all white spaces with regex, instead of only the first one when using " "
    //       const cleanValue = value.replace(/ /g, "");
    //       let validPc = true;

    //       const pcToCheck: Array<string> = [];

    //       if (cleanValue.includes(",")) {
    //         pcToCheck.push(...cleanValue.split(","));
    //       } else if (cleanValue.includes("-")) {
    //         pcToCheck.push(...cleanValue.split("-"));
    //       } else {
    //         pcToCheck.push(cleanValue)
    //       }

    //       for (let pc of pcToCheck) {
    //         if (!allPostCodes.map(item => item.value).includes(pc)) {
    //           validPc = false;
    //           break;
    //         }
    //       }
          
    //       return validPc;
    //     }
        
    //     return true;
    //   })
    //   .test('post-codes-format', "Einzeln, als Liste (z.B. 80333, 80334) oder als Bereich (z.B. 80333 - 80999) eingeben.", function(value) {
    //     if (value) {
    //       // replace all white spaces with regex, instead of only the first one when using " "
    //       const count = value.replace(/ /g, "").match(postCodeRegExp);          
    //       return count !== null && count.length > 0;
    //     }
        
    //     return true;
    //   }),
      //.matches(postCodeRegExp, "Bitte geben Sie Postleitzahlen im richtigen Format ein."),
    postCodesInput: Yup.string()
      .test('post-codes-in-list', "Bitte Bereich mit aufsteigenden Zahlen wählen (z.B. 8 - 9 statt 9 - 8)", function(value) {
        const match = value?.match(postCodeRegExp);
        if (match && match[2] && match[4]) {
          return match[2] <= match[4]
        }
        return true;
      }),
      //.matches(postCodeRegExp, 'Postleitzahl einzeln oder als Bereich (z.B. 80333 - 80999) eingeben.'),
    postCodeRangeInput: Yup.string()
      .test('post-code-length', postCodeFormatText, function(value) {
        return value?.length === undefined || value?.length === 5
      })
  });
}

export function isEqual(prevValues: FormikValues, currentValues: FormikValues) {
  //const vertValuesEqual = arrayIsEqual(prevValues.verticalValues, currentValues.verticalValues);
  //const regionValuesEqual = arrayIsEqual(prevValues.regionValues, currentValues.regionValues);
  
  //const postCodeEqual = prevValues.postCodeInput === currentValues.postCodeInput;
  //const postCodeRangeEqual = prevValues.postCodeRange === currentValues.postCodeRange;

  const reqFieldsEqual = arrayIsEqual(prevValues.requiredFields, currentValues.requiredFields);
  const sizeClassesEqual = arrayIsEqual(prevValues.sizeClasses, currentValues.sizeClasses);

  const limitEqual = prevValues.limit === currentValues.limit;

  //return vertValuesEqual && regionValuesEqual && postCodeEqual && postCodeRangeEqual && reqFieldsEqual && sizeClassesEqual && limitEqual;
  return reqFieldsEqual && sizeClassesEqual && limitEqual;
}

function arrayIsEqual(prevValues: Array<any>, currentValues: Array<any>): boolean {
  for (let item of prevValues) {
    if (currentValues.filter(i => i === item).length > 0) {
      continue;
    } else {
      return false;
    }
  }

  for (let item of currentValues) {
    if (prevValues.filter(i => i === item).length > 0) {
      continue;
    } else {
      return false;
    }
  }

  return true;
}

export function getInitialValues(): FormValues {
  return {
    verticalValues: [],
    verticalValuesInput: '',
    regionValues: [],
    regionValuesInput: '',
    postCodes: [],
    postCodesInput: '',
    postCodeRangeInput: '',
    postCodeRange: undefined,
    postCodeRanges: undefined,
    requiredFields: [],
    sizeClasses: ['Großunternehmen', 'Mittelstand', 'Kleinunternehmen', 'Kleinstunternehmen', 'Kleingewerbe/Freiberuf'],
    limit: undefined,
    limitsByVerts: undefined,
    salutation: '',
    firstName: '',
    lastName: '',
    firmName: '',
    street: '',
    number: '',
    postCode: '',
    city: '',
    country: 'DE',
    ustid: '',
    email: '',
    phone: '',
    dataSecurityConsent: [],
    abo: []
  };
}

export function getEmptyFormConfig(): FormConfig {
  return {
    submitButtonText: '',
    minCount: 0,
    benefits: {
      header: '',
      bullets: []
    },
    views: []
  }
}

export function mapViewToFormikFields(viewId: string): Array<string>{
  const fields: {[key:string]: Array<string>} = {
    'verticalSearch': [
      'verticalValues',
      'verticalValuesInput'
    ],
    'regionSearch': [
      // Bundesland / Stadt
      'regionValues',
      'regionValuesInput',
      // Postleitzahlen
      'postCodes',
      'postCodesInput',
      // Umkreis
      'postCodeRangeInput',
      'postCodeRange',
      'postCodeRanges'
    ],
    'requiredFields': [
      'requiredFields'
    ],
    'sizeClasses': [
      'sizeClasses'
    ],
    'limit': [
      'limit',
      'limitsByVerts'
    ],
    'offer': [],
    'contact': [
      'salutation',
      'firstName',
      'lastName',
      'firmName',
      'street',
      'number',
      'postCode',
      'city',
      'country',
      'ustid',
      'email',
      'phone',
      'dataSecurityConsent'
    ]
  }

  return fields[viewId];
}

export function mapEmailValues(formValues: FormValues, quotation: any): EmailValues {
  return {
    lastName: formValues.lastName,
    salutation: formValues.salutation,
    comName: quotation.com_name,
    comHouseNumber: quotation.com_house_number,
    comStreet: quotation.com_street,
    comCity: quotation.com_city,
    comPostCode: quotation.com_post_code,
    comCountry: quotation.com_country,
    comCountryCode: quotation.com_country_code,
    quoteNumber: quotation.quote_number,
    validTo: quotation.expiration,
    quoteDate: quotation.quote_date,
    positions: quotation.positions,
    totalCnt: quotation.total_cnt,
    totalNet: quotation.total_net,
    totalCurrency: quotation.total_currency,
    totalTaxRate: quotation.total_tax_rate,
    totalGross: quotation.total_gross,
    totalTax: quotation.total_tax,
    paymentTerm: quotation.payment_term,
    prepayment: quotation.prepayment,
    acceptLink: quotation.accept_link,
    orderDate: quotation.order_date
  }
}