import * as React from 'react';
import { Heading } from "@react-email/components";
import { twMerge } from "tailwind-merge";

export function H2({ children, className }: { children: React.ReactNode, className?: string }) {
  return (
    <Heading as="h2" className={twMerge("text-[20px] mb-[16px] mt-[40px] text-gray-800", className)}>{children}</Heading>
  )
}

export function H3({ children, className }: { children: React.ReactNode, className?: string }) {
  return (
    <Heading as="h3" className={twMerge("text-[18px] mb-[24px] mt-[40px] text-gray-800 font-bold", className)}>{children}</Heading>
  )
}