import formConfig from '../_dev/files/form_config';
import { freemailerValues } from '../_dev/files/freemailer/freemailer_values';
import ReactGA from 'react-ga';
import { SuggestionValue, VertQuery } from '../data/schema';

export async function fetchConfig(fileName: string) {
  switch(process.env.NODE_ENV){
    case 'production':
      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/config/' + fileName);
      const json = await response.json();
      return json;
    default:
      return formConfig;
  }
};

export async function fetchIndustrySuggestionList(listName: string) {
  switch(process.env.NODE_ENV){
    case 'production':
      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/config/suggestions/' + listName);
      const json = await response.json();
      return json;
    case 'development':
      const response2 = await fetch('https://form.biwex.de/config/suggestions/' + listName);
      const json2 = await response2.json();
      return json2;
      //return searchValues;
  }
};

export async function fetchFreemailerList() {
  switch(process.env.NODE_ENV){
    case 'production':
      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/config/freemailer/freemailer_values.json');
      const json = await response.json();
      return json;
    case 'development':
      return freemailerValues;
  }  
}

export async function fetchVerticalCounts() {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/api/v3/nexus/verticals', options);
  return response.json();
}

export async function fetchPrice(count: number) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/price?rows=${count}`, options);
  return response.json();
}

export async function fetchCountsByRegion(values: Array<SuggestionValue>) {
  const queryParam = values.filter(v => v.vert_id !== undefined && v.vert_id !== null).map(v => v.vert_id).join(',');

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/counts-by-region?verticals=${queryParam}`, options);
  const json = response.json();

  return json;
}

export async function fetchGlobalCount(vertQueries: Array<VertQuery>) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vertQueries)
  }
  
  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/get-global-count`, options);
  const json = response.json();

  return json;
}

export async function fetchCountsOfReqFields(vertQueries: Array<VertQuery>) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vertQueries)
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/counts-of-required-fields`, options);
  const json = response.json();

  return json;
}

export async function fetchCountsOfSizeClasses(vertQueries: Array<VertQuery>) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vertQueries)
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/counts-of-size-classes`, options);
  const json = response.json();

  return json;
}

export async function fetchCountsByPostCode(vertQueries: Array<VertQuery>, postCode: string) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vertQueries)
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/counts-by-post-code/${postCode}`, options);
  const json = response.json();

  return json;
}

export async function fetchLimits(vertQueries: Array<VertQuery>) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vertQueries)
  }

  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/limits`, options);
  const json = response.json();

  return json;
}

export async function fetchVertFrcs(vertQuery: VertQuery) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(vertQuery)
  }
  
  const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_API}/api/v3/nexus/vertical-frcs`, options);
  const json = response.json();

  return json;
}

export async function submitAndGetOffer(values: any) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  }

  return fetch(process.env.REACT_APP_BACKEND_BASE_API + '/api/v3/get-offer', options);
}

export async function sendOfferEmail(submitValues: any) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/text'
    },
    body: JSON.stringify({...submitValues})
  }

  const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/api/v3/send-offer-email', options);
  return response;
}

export async function sendOrderEmail(submitValues: any) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/text'
    },
    body: JSON.stringify({...submitValues})
  }

  const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/api/v3/send-order-email', options);
  return response;
}

export async function submitAndBuy(values: any) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  }

  return fetch(process.env.REACT_APP_BACKEND_BASE_API + '/api/v3/buy', options);
}

export async function postIntermediateValues(values: any, event="intermediate"){
  const body = {
    ...values,
    event: event
  }
  
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }

  const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + '/api/v3/intermediate', options);
  return response;
}

export async function getPostCodeRanges(input: string) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const response = await fetch(process.env.REACT_APP_BACKEND_BASE_API + `/api/v3/nexus/post-code-ranges?ranges=${input}`, options)

  return response;
}
 
export function initGA(){
  ReactGA.initialize(process.env.REACT_APP_BACKEND_BASE_API || '');
}

export function fireGtmEvent(direction: 'weiter' | 'zurück', step: number) {
  let stepString = '';

  switch (step) {
    case 0: {
      stepString = 'Branche';
      break;
    }
    case 1: {
      stepString = 'Region';
      break;
    }
    case 2: {
      stepString = 'Kontaktdetails';
      break;
    }
    case 3: {
      stepString = 'Groesse';
      break;
    }
    case 4: {
      stepString = 'Anzahl';
      break;
    }
    case 5: {
      stepString = 'Angebot'
      break;
    }
  }

  (window as any).dataLayer.push({'event': `${stepString}_${direction}_DKF`});
}

// export function fireGAEvent(category: string, action: string, label: string){
//   ReactGA.event({
//     category: category,
//     action: action,
//     label: label
//   });
// }