export const twConfig = {
  theme: {
    fontFamily: {
      email: ['"Arial", "Calibri", "Verdana"', 'sans-serif']
    },
    extend: {
      colors: {
        'listflix-green': {
          main: '#29AD7D',
          dark: '#208a64'
        },
        'listflix-yellow': {
          main: '#FECB18',
          dark: '#e66e16'
        }
      },
    }
  }
}