import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    boxShadow: theme.shadows[5],
    padding: theme.padding.md,
    position: 'absolute',
    backgroundColor: 'white',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderLeft: '4px solid ' + theme.palette.primary.main,
    borderRadius: '4px',
    zIndex: 100
  }
}));

interface InfoHoverProps {
  text: Array<string>,
  show: boolean
}

export default function InfoHover(props: InfoHoverProps) {
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.modalContainer} alignItems='center'>
      <Grid item>
        {props.text.map((p, i) => <Typography key={i} paragraph={i !== props.text.length-1} variant="body1">{p}</Typography>)}
      </Grid>
    </Grid>
  )
}