import { useEffect, useState } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { Typography, makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';

import { SelectItemConfig, SizeClassesConfig, ViewConfig } from '../data/schema';
import { fetchCountsOfSizeClasses } from '../api/client';
import { mapViewToFormikFields } from '../utils/form-utils';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';

import ErrorMessage from '../form/ErrorMessage';
import Header from '../form/Header';
import CheckboxGroup from '../inputs/CheckboxGroup';
import DefaultView from './DefaultView';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold'
  },
  rowSpan: {
    borderBottom: 'none',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)'
  },
  or: {
    marginTop: '-45px'
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)',
    '& p': {
      [theme.breakpoints.down('md')]: {
        fontSize: '14px'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    }
  }
}));

interface SizeClassesViewProps {
  handleIntermediateChange: Function,
  sizeClassesConfig: SizeClassesConfig,
  setCount: Function,
  setFormValues: Function,
  setBlockNext: Function,
  views: Array<ViewConfig>
}

export default function SizeClassesView({
  handleIntermediateChange,
  sizeClassesConfig,
  setCount,
  setFormValues,
  setBlockNext,
  views
}: SizeClassesViewProps) {
  const classes = useStyles();
  const formikField = mapViewToFormikFields(sizeClassesConfig.id)[0];
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const [fields, setFields] = useState<Array<SelectItemConfig>>([
    {
      value: "Kleingewerbe/Freiberuf",
      preselected: false,
      disabled: true
    },
    {
      value: "Kleinstunternehmen",
      preselected: false,
      disabled: true
    },
    {
      value: "Kleinunternehmen",
      preselected: false,
      disabled: true
    },
    {
      value: "Mittelstand",
      preselected: false,
      disabled: true
    },
    {
      value: "Großunternehmen",
      preselected: false,
      disabled: true
    }
  ]);

  useEffect(() => {
    let mounted = true;
    // Fetch region counts and fill region suggestion list based on searched for values

    (async () => {
      const vertQueries = mapFormValuesToVertQueries(formik.values);
      vertQueries.forEach(q => q.size_classes = []);

      setBlockNext(true);
      const sizeClasses = await fetchCountsOfSizeClasses(vertQueries);
      setBlockNext(false);
      
      let updatedFields: Array<SelectItemConfig> = [];

      if(mounted) {
        for (let field of fields) {
          updatedFields.push({
            value: field.value,
            preselected: field.preselected,
            count: sizeClasses[field.value],
            disabled: false
          });
        }

        setFields(updatedFields);
      };
    })();


    return () => { mounted = false };
  }, []);

  const InfoText = () => (
    <>
      <Typography variant="body1" paragraph style={{ marginTop: '16px' }}>Sie haben jetzt die Möglichkeit, nur bestimmte Unternehmensgrößen auszuwählen. Dadurch können entweder einzelne Unternehmensgrößen ausgeschlossen oder ein Fokus auf diese gelegt werden.</Typography>
      <Typography variant="body1" paragraph><span className={classes.bold}>Die einzelnen Unternehmensgrößen werden nach dem folgenden Schema zugewiesen:</span></Typography>

      <TableContainer className={classes.table}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="body2">Unternehmensgröße</Typography></TableCell>
              <TableCell><Typography variant="body2">Mitarbeiterzahl</Typography></TableCell>
              <TableCell></TableCell>
              <TableCell><Typography variant="body2">Umsatz</Typography></TableCell>
              <TableCell></TableCell>
              <TableCell><Typography variant="body2">Bilanzsumme</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow key={1}>
                <TableCell component="td" scope="row"><Typography variant="body1">Freiberuf / Kleingewerbe</Typography></TableCell>
                <TableCell colSpan={5}><Typography variant="body1">Branchenabhängig oder keine Finanzkennzahlen veröffentlicht</Typography></TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell component="td" scope="row"><Typography variant="body1">Kleinstunternehmen</Typography></TableCell>
                <TableCell><Typography variant="body1">1 - 9</Typography></TableCell>
                <TableCell className={classes.rowSpan}></TableCell>
                <TableCell><Typography variant="body1">bis 2 Mio.</Typography></TableCell>
                <TableCell className={classes.rowSpan}></TableCell>
                <TableCell><Typography variant="body1">bis 2 Mio.</Typography></TableCell>
              </TableRow>
              <TableRow key={3}>
                <TableCell component="td" scope="row"><Typography variant="body1">Kleinunternehmen</Typography></TableCell>
                <TableCell><Typography variant="body1">10 - 49</Typography></TableCell>
                <TableCell className={classes.rowSpan}></TableCell>
                <TableCell><Typography variant="body1">2 - 10 Mio.</Typography></TableCell>
                <TableCell className={classes.rowSpan}></TableCell>
                <TableCell><Typography variant="body1">2 - 10 Mio.</Typography></TableCell>
              </TableRow>
              <TableRow key={4}>
                <TableCell component="td" scope="row"><Typography variant="body1">Mittelständische Unternehmen</Typography></TableCell>
                <TableCell><Typography variant="body1">50 - 249</Typography></TableCell>
                <TableCell className={classes.rowSpan}><Typography className={classes.or} variant="body1">oder</Typography></TableCell>
                <TableCell><Typography variant="body1">10 - 50 Mio.</Typography></TableCell>
                <TableCell className={classes.rowSpan}><Typography className={classes.or} variant="body1">oder</Typography></TableCell>
                <TableCell><Typography variant="body1">10 - 43 Mio.</Typography></TableCell>
              </TableRow>
              <TableRow key={5}>
                <TableCell component="td" scope="row"><Typography variant="body1">Großunternehmen</Typography></TableCell>
                <TableCell><Typography variant="body1">mehr als 250</Typography></TableCell>
                <TableCell className={classes.rowSpan}></TableCell>
                <TableCell><Typography variant="body1">mehr als 50 Mio.</Typography></TableCell>
                <TableCell className={classes.rowSpan}></TableCell>
                <TableCell><Typography variant="body1">mehr als 43 Mio.</Typography></TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="body1" style={{ marginTop: '16px' }}>Hinweis: Sobald eine Firma Jahresabschlüsse/Bilanzen im Bundesanzeiger veröffentlicht, wird sie als Kleinunternehmen (oder bei entsprechenden Finanzkennzahlen als mittelständisches Unternehmen oder Großunternehmen) kategorisiert.</Typography>
    </>
  )

  return (
    <DefaultView viewId={sizeClassesConfig.id} setCount={setCount} setFormValues={setFormValues} views={views} useDefaultCount>
      <Header headerConfig={sizeClassesConfig.header}  infoModal={{
        title: 'Unternehmensgrößen',
        content: <InfoText />,
        linkText: 'Welche Unternehmensgrößen sind die richtigen?'
      }} />
      <CheckboxGroup fieldName={formikField} fields={fields} handleIntermediateChange={handleIntermediateChange} />
      <ErrorMessage padding={true} fieldName={formikField} />
    </DefaultView>
  );
};