import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Icon, List, ListItem, ListItemText, ListSubheader, Typography } from '@material-ui/core';

import levenshtein from '../utils/levenshtein';
import { sortSuggestionListByValue } from '../utils/suggestion-list-utils';
import { getInitialValues, mapViewToFormikFields } from '../utils/form-utils';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';
import { calculateOverallCount } from '../utils/helpers';
import { AdvMark, FormValues, SuggestionValue } from '../data/schema';
import { fetchCountsByPostCode, fetchCountsByRegion, getPostCodeRanges } from '../api/client';
import { allPostCodes } from '../resources/data/all_post_codes';

import CustomSlider from '../form/CustomSlider';
import InfoBox from '../form/InfoBox';
import ErrorMessage from '../form/ErrorMessage';
import Tag from './Tag';

const useStyles = makeStyles((theme) => ({
  tags: {
    marginBottom: theme.padding.sm
  },
  listSubheader: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  filteredRegionsList: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    backgroundColor: '#f2f2f2',
    marginTop: '-24px',
    paddingTop: '24px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: 1
  },
  suggestion: {
    padding: '2px ' + theme.padding.sm
  },
  suggestionText: {
    color: theme.palette.primary.main
  },
  dots: {
    padding: `${theme.padding.xs} ${theme.padding.sm}`,
    fontSize: '14px'
  },
  searchField: (inputFocus) => ({
    boxShadow: inputFocus ? theme.shadows[2] : 'none',
    height: '48px',
    borderRadius: '24px',
    zIndex: 10,
    position: 'relative'
  }),
  inputContainer: {
    padding: '0px ' + theme.padding.sm,
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderLeft: 'none',
    '& span': {
      color: theme.palette.text.disabled
    },
    zIndex: 10,
    backgroundColor: 'white'
  },
  searchTypeContainer: {
    maxWidth: '205px',
    '& p, span': {
      color: 'white',
      fontSize: '16px'
    },
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    borderTopLeftRadius: '24px',
    borderBottomLeftRadius: '24px',
    height: '100%',
    padding: '0px ' + theme.padding.xs,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.dark
    },
    zIndex: 10
  },
  searchTypeList: {
    maxWidth: '205px',
    backgroundColor: theme.palette.grey[200],
    marginTop: '-24px',
    paddingTop: `24px`,
    zIndex: 1,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    '& p, span': {
      fontSize: '16px'
    }
  },
  input: {
    height: '100%',
    width: '100%',
    border: 'none',
    ...theme.typography.body1,
    '&::placeholder': {
      ...theme.typography.body1,
    },
    '&:focus': {
      outline: 'none'
    }
  },
  radiusContainer: {
    marginTop: theme.padding.md
  }
}));

interface PlzTagFieldProps {
  handleIntermediateChange: Function,
  previewSuggestions: number,
  count: number | undefined
}

interface SearchOption {
  id: string,
  icon: string,
  text: string,
  placeholder: string,
  fieldName: string,
  inputFieldName: string
}

export default function PlzTagField({
  handleIntermediateChange,
  previewSuggestions,
  count,
}: PlzTagFieldProps) {
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [blur, setBlur] = useState(true);
  const classes = useStyles(inputFocus);
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const formikFields = mapViewToFormikFields('regionSearch');
  
  const [prevValue, setPrevValue] = useState('');
  const [arrowCounter, setArrowCounter] = useState(-1);
  const [showPlzHint, setShowPlzHint] = useState(false);
  const [rangeLoading, setRangeLoading] = useState(false);
  const [allRegions, setAllRegions] = useState<Array<SuggestionValue>>([]);

  const [allSuggestions, setAllSuggestions] = useState<Array<SuggestionValue>>([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState<Array<SuggestionValue>>([]);

  const countByVerts = calculateOverallCount(formik.values.verticalValues);

  const [searchTypeOpen, setSearchTypeOpen] = useState<boolean>(false);
  const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>(null);
  
  const postCodeInputValueRef = useRef<string>(formik.values.postCodeRangeInput);

  const searchOptions: Array<SearchOption> = [
    {
      id: 'region',
      icon: 'location_city',
      text: 'Bundesland / Stadt',
      placeholder: 'Deutschland, Bundesländer, Städte',
      fieldName: formikFields[0],
      inputFieldName: formikFields[1]
    },
    {
      id: 'plz',
      icon: 'room',
      text: 'Postleitzahlen',
      placeholder: 'z.B. 80333, 80333 - 99998 oder 8 - 9',
      fieldName: formikFields[2],
      inputFieldName: formikFields[3]
    },
    {
      id: 'radius',
      icon: 'track_changes',
      text: 'Umkreis',
      placeholder: 'Postleitzahl',
      fieldName: formikFields[5],
      inputFieldName: formikFields[4]
    }
  ]

  const [selectedOption, setSelectedOption] = useState<SearchOption>(searchOptions[0]);
  const [loading, setLoading] = useState(false);

  const germany: SuggestionValue = {
    category: 'Alle',
    value: 'Deutschland',
    alias: 'Deutschland',
    count: count
  };

  useEffect(() => {
    let mounted = true;

    (async () => {
      setLoading(selectedOption.id === 'region');
      const countsByRegion = await fetchCountsByRegion(formik.values['verticalValues']);
      setLoading(false);
      setAllRegions([
        ...convertStatesValues(countsByRegion["sum"]["states"]),
        ...convertCitiesValues(countsByRegion["sum"]["cities"])
      ]);
    })();

    if (formik.values.postCodes.length > 0) {
      setSelectedOption(searchOptions.filter(so => so.id === 'plz')[0])
    } else if (formik.values.postCodeRange && formik.values.postCodeRangeInput) {
      setSelectedOption(searchOptions.filter(so => so.id === 'radius')[0])
    } else {
      setSelectedOption(searchOptions.filter(so => so.id === 'region')[0]);
    }

    return () => { mounted = false };
  }, [])

  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption.id) {
        case 'plz': {
          setAllSuggestions(allPostCodes);
          break;
        }
        case 'region': {
          setAllSuggestions(allRegions);
          break;
        }
        case 'radius': {
          setAllSuggestions([]);
          break;
        }
      }
    }
  }, [selectedOption, allRegions, allPostCodes]);

  useEffect(() => {
    setFilteredSuggestions(allSuggestions);
  }, [allSuggestions]);

  // update plz ranges
  useEffect(() => {    
    if(formik.values.postCodeRangeInput !== postCodeInputValueRef.current) {
      postCodeInputValueRef.current = formik.values.postCodeInput;

      setShowPlzHint(false);
      formik.setFieldValue(formikFields[5], undefined);
      formik.setFieldValue(formikFields[6], undefined);

      updatePostCodeRanges();
    };  
  }, [formik.values.postCodeRangeInput]);



  useEffect(() => {
    if (inputFocus && !formik.errors[selectedOption.inputFieldName] && filteredSuggestions.length > 0) {
      setSuggestionsOpen(true)
    } else {
      setSuggestionsOpen(false)
    }
  }, [inputFocus, formik.errors[selectedOption.inputFieldName], filteredSuggestions]);

  function updatePostCodeRanges() {
    const value = formik.values.postCodeRangeInput;
    (async () => {
      if (value && value.length === 5) {
        // show errors also if user didn't blur field in between
        formik.setFieldTouched(formikFields[4]);

        setLoading(true);
        const counts = await fetchCountsByPostCode(mapFormValuesToVertQueries(formik.values), value);
        setLoading(false);
        
        if (counts === null) {
          formik.setFieldError(formikFields[4], 'Postleitzahl unbekannt');
        } else if (Object.keys(counts).length === 0) {
          setShowPlzHint(true);
        } else {
          formik.setFieldValue(formikFields[6], mapToAdvMarks(counts));
        }
      }
    })();
  }

  function convertStatesValues(countsByRegion: object): Array<SuggestionValue> {
    let regionList: Array<SuggestionValue> = [];
  
    for (const [key, value] of Object.entries(countsByRegion)) {
      regionList.push({
        category: 'Bundesland',
        value: key,
        alias: key,
        count: value
      })
    }
  
    regionList.push(germany);
  
    return sortSuggestionListByValue(regionList);
  }

  function convertCitiesValues(countsByRegion: object): Array<SuggestionValue> {
    let regionList: Array<SuggestionValue> = [];
  
    for (const [key, value] of Object.entries(countsByRegion)) {
      regionList.push({
        category: 'Stadt',
        value: key,
        alias: key,
        count: value
      })
    }
  
    return sortSuggestionListByValue(regionList);
  }

  function mapToAdvMarks(counts: { [key: string]: number }) {
    let marks: Array<AdvMark> = [];
  
    Object.keys(counts).forEach((key, i) => {
      marks.push({
        value: i,
        origValue: parseInt(key),
        label: `${key} km`,
        count: counts[key]
      });
    });

    if (countByVerts && countByVerts > marks[marks.length - 1].count) {
      marks.push({
        value: marks.length,
        origValue: undefined,
        label: `Alle`,
        count: countByVerts
      })
    }
  
    return marks;
  };

  function filterSuggestionList(filter: string, list: Array<SuggestionValue>): Array<SuggestionValue> {
    return list.filter(item => item.value.toLowerCase().startsWith(filter.toLowerCase()));
  }

  const selectItems = (items: Array<SuggestionValue>) => {
    const currentSelectedValues = formik.values[selectedOption.fieldName];
    const notYetIncluded = items.filter((i1: SuggestionValue) => !currentSelectedValues.map((i2: SuggestionValue) => i2.value).includes(i1.value))

    formik.setFieldValue(selectedOption.fieldName, [
      ...currentSelectedValues,
      ...notYetIncluded
    ]);

    if (selectedOption.id === "region" && items[0].value === "Deutschland") {
      formik.setFieldValue(selectedOption.fieldName, items);
    }

    formik.setFieldValue(selectedOption.inputFieldName, '');
    inputRef.current?.focus();

    setFilteredSuggestions(prev => prev.filter(p => !items.includes(p)))
    //changeInput('');
  }

  const deselectItem = (item: SuggestionValue) => {
    const currentSelectedValues = formik.values[selectedOption.fieldName];
    const newSelectedValues = currentSelectedValues.filter((i: SuggestionValue) => i.value !== item.value);

    formik.setFieldValue(selectedOption.fieldName, newSelectedValues);
  }

  const blurInput = () => {
    // Do not blur if element from suggestion list is clicked -> otherwise click will be overwritten by blur
    if (blur) {
      //setSuggestionsOpen(false);
      formik.setFieldTouched(selectedOption.inputFieldName);
      setInputFocus(false);
    }
  }

  const blurSearchType = () => {
    if (blur) {
      setSearchTypeOpen(false);
    }
  }

  function changeInput (input: string) {
    //if (!suggestionsOpen) setSuggestionsOpen(true);
    formik.setFieldTouched(selectedOption.inputFieldName)
    if (searchTypeOpen) setSearchTypeOpen(false);
    if (!inputFocus) setInputFocus(true);

    if (input.length > 0) {

      if(selectedOption.id === 'plz') {
        clearTimeout(timer);

        // wait 0.5 secs before calling API to allow user to finish typing (and to avoid unnecessary API calls)
        const newTimer = setTimeout((async() => {
          setFilteredSuggestions(await filterPostCodes(input));
        }), 500);

        setTimer(newTimer);
      } else {
        setFilteredSuggestions(filterSuggestions(input));
      }
    }

    if (levenshtein(prevValue, input) > 5) {
      setPrevValue(input);
      handleIntermediateChange(selectedOption.fieldName, input);
    }

    setArrowCounter(-1);
  }

  async function filterPostCodes(input: string) {
    let filteredPostCodes = filteredSuggestions;

    setRangeLoading(true);
    setFilteredSuggestions(filteredSuggestions.filter((sv: SuggestionValue) => sv.category !== 'Postleitzahlenbereich'))

    const response = await getPostCodeRanges(input);
    
    setRangeLoading(false);
    const ranges = await response.json();

    if (ranges.length > 0) {
      const suggestionValues: Array<SuggestionValue> = [];
      const failedRanges = [];

      // only add post code ranges if search result is not only a single post code
      if (!(ranges.length === 1 && ranges[0].pc_cnt === 1)) {
        ranges.forEach((range: any) => {
          if (range.pc_cnt === 0) {
            failedRanges.push(range);
          } else {
            const displayString = `${range.pc_start} - ${range.pc_end} (${range.pc_cnt.toLocaleString('de-DE')} PLZ)`;
    
            suggestionValues.push({
              category: 'Postleitzahlenbereich',
              value: displayString,
              alias: displayString
            })
          }
        });
      }
        
      suggestionValues.concat(filterSuggestions(input));
      filteredPostCodes = suggestionValues.concat(filterSuggestions(input));
    } else {
      filteredPostCodes = filteredSuggestions;
    }
  
    setRangeLoading(false);

    return filteredPostCodes;
  }

  function filterSuggestions(input: string): Array<SuggestionValue> {
    let filteredSuggestions: Array<SuggestionValue> = filterSuggestionList(input, allSuggestions);

    // remove all values that have already been selected
    filteredSuggestions = filteredSuggestions.filter((s: SuggestionValue) => !formik.values[selectedOption.fieldName].map((s: SuggestionValue) => s.value).includes(s.value));

    if (selectedOption.id === "region" && formik.values[selectedOption.fieldName].map((s: SuggestionValue) => s.value).includes("Deutschland")) {
      filteredSuggestions = [];
    }

    return filteredSuggestions;
  }

  function getListEntries() {
    let result: Array<React.ReactNode> = [];
    let listWithSubheaders: { [key: string]: Array<React.ReactNode> } = {};
    let filteredSuggestionsToShow = filteredSuggestions;

    // always add "Deutschland" as first entry in list for "region" selection
    if (selectedOption.id === "region") {
      const item = filteredSuggestionsToShow.filter(v => v !== undefined && v.value === "Deutschland")[0];
      if (item !== undefined) {
        filteredSuggestionsToShow.splice(filteredSuggestionsToShow.indexOf(item), 1);
        filteredSuggestionsToShow.unshift(item);
      }
    }

    if (selectedOption.id === 'plz' && rangeLoading) {
      listWithSubheaders['Postleitzahlenbereich'] = [
        <Grid key='post-code-range' container alignItems='center' className={classes.suggestion} style={{ height: '39px'}}>
          <ClipLoader loading={rangeLoading} size={20} />
        </Grid>
      ]
    }

    filteredSuggestionsToShow.forEach((item, i) => {
      const components = listWithSubheaders[item.category] || [];
      const itemText = item.value + (item.count !== undefined ? ` (${item.count.toLocaleString('de-DE')})` : '');
      const selected = i === arrowCounter;
      const component = (
        <ListItem disabled={loading} selected={selected} className={classes.suggestion} key={`${item.category}-${item.value}`} button onClick={() => selectItems([item])}>
          <ListItemText primary={itemText} className={classes.suggestionText} />
        </ListItem>);

      components.push(component);

      listWithSubheaders[item.category] = components;
    });

    Object.keys(listWithSubheaders).forEach((key, i) => {
      result.push(
        <ListSubheader key={i} className={classes.listSubheader}>{key}</ListSubheader>
      );

      result = result.concat(listWithSubheaders[key].slice(0, previewSuggestions));

      if (listWithSubheaders[key].length > previewSuggestions) {
        result.push(
          <Typography key={"..."} variant="body1" className={classes.dots}>... tippen Sie, um weitere Einträge zu sehen ...</Typography>
        );
      }
    });

    return result;
  }

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case 'ArrowDown':
        setArrowCounter(prev => Math.min(filteredSuggestions.length - 1, prev + 1));
        break;
      case 'ArrowUp':
        setArrowCounter(prev => Math.max(-1, prev - 1));
        break;
      case 'Enter':
        e.preventDefault();

        if (suggestionsOpen) {
          e.stopPropagation();

          if (arrowCounter > -1) {
            selectItems([filteredSuggestions[arrowCounter]]);
          }
        }

        //setSuggestionsOpen(false);
        break;
    }
  }

  function selectSearchTypeOption(option: SearchOption) {
    setSelectedOption(option);
    setSearchTypeOpen(false);
    setBlur(true);
    
    // reset selected values for other search options
    const otherFields = formikFields.filter((field: string) => field !== option.fieldName && field !== option.inputFieldName);
    otherFields.forEach((field: string) => {
      formik.setFieldValue(field, getInitialValues()[field as keyof FormValues]);
    });
  }

  function openSearchTypeDropdown() {
    setSearchTypeOpen(!searchTypeOpen);
    setSuggestionsOpen(false);
  }

  return (
    <div>
      {selectedOption.id !== 'radius' && formik.values[selectedOption.fieldName].length > 0 &&
      <Grid container direction="row" className={classes.tags}>
        {formik.values[selectedOption.fieldName].map((res: SuggestionValue) => (
          <Tag key={res.value} tag={res} deselect={deselectItem} />
        ))}
      </Grid>}

      {/* search field */}
      <Grid container direction="row" wrap="nowrap" className={classes.searchField}>

        {/* search type */}
        <Grid component='button' type='button' container item direction="row" alignItems='center' justify="center" wrap="nowrap" className={classes.searchTypeContainer} onClick={openSearchTypeDropdown} onBlur={blurSearchType}>
          <Grid container item alignItems='center' justify='center' style={{ marginRight: '8px', width: 'fit-content' }}>
            {!loading && <Icon>{selectedOption.icon}</Icon>}
            <ClipLoader color={'#fff'} loading={loading} size={20} />
          </Grid>
          <Typography variant="body1">{selectedOption.text}</Typography>
          <Icon style={{ marginLeft: '8px' }}>{searchTypeOpen ? 'expand_less' : 'expand_more'}</Icon>
        </Grid>

        {/* input */}
        <Grid container item alignItems="center" className={classes.inputContainer} >
          <input 
            name={selectedOption.inputFieldName}
            value={formik.values[selectedOption.inputFieldName]}
            className={`${classes.input} LoNotSensitive data-hj-allow`}
            placeholder={selectedOption.placeholder}
            ref={inputRef}
            onFocus={(e) => changeInput(e.target.value)}
            onChange={(e) => {
              formik.handleChange(e);
              changeInput(e.target.value);
            }}
            onBlur={blurInput}
            onKeyDown={handleKeyDown}
            autoComplete='off'
          />
        </Grid>
      </Grid>

      {/* dropdowns below search field */}
      {searchTypeOpen ? 
      <Grid container direction="column" className={classes.searchTypeList}>
        <List onMouseEnter={() => setBlur(false)} onMouseLeave={() => setBlur(true)}>
          {searchOptions.filter(option => option.id !== selectedOption.id).map((option: SearchOption, i: number) => (
          <ListItem key={`search-option-${i}`} button onClick={() => selectSearchTypeOption(option)}>
            <Icon style={{ marginRight: '8px' }}>{option.icon}</Icon>
            <Typography variant="body1">{option.text}</Typography>
          </ListItem>))}
        </List>
      </Grid>
      :
      null}

      {suggestionsOpen && filteredSuggestions.length > 0 &&
      <List id="suggestion-list-2" className={classes.filteredRegionsList} onMouseEnter={() => setBlur(false)} onMouseLeave={() => setBlur(true)}>
        {getListEntries()}
      </List>}

      {selectedOption.id === 'radius' ?
      <Grid item xs={12}>
        <div>
          {formik.values.postCodeRanges &&
            <div className={classes.radiusContainer}>
              <Typography variant="body2" paragraph>Umkreis wählen</Typography>
              <CustomSlider formikField={formikFields[5]} marks={formik.values.postCodeRanges} />                  
            </div>} 

          {showPlzHint && 
          <InfoBox 
            type="warning" 
            text="Für die gewählte Postleitzahl sind innerhalb des maximal verfügbaren Radius zu wenig Einträge vorhanden. Fahren Sie fort, um keine Einschränkung vorzunehmen, wählen Sie eine andere Postleitzahl oder wählen Sie die Suche nach Bundesländern / Städten."
          />}
        </div>
      </Grid>
      : null}

      <ErrorMessage padding={true} fieldName={selectedOption.inputFieldName} />

    </div>
  );
};