import React, { useEffect } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { FormGroup } from '@material-ui/core';
import CheckboxField from './CheckboxField';

import { SelectItemConfig } from '../data/schema';

interface CheckboxFieldProps {
  fieldName: string,
  fields: Array<SelectItemConfig>,
  handleIntermediateChange: Function
}

export default function CheckboxGroup({ fieldName, fields, handleIntermediateChange }: CheckboxFieldProps) {
  const formik: FormikContextType<FormikValues> = useFormikContext();
  
  useEffect(() => {
    handleIntermediateChange(fieldName, formik.values[fieldName]);
  }, [formik.values[fieldName]]);
  
  return (
    <div>
      <FormGroup>
        {fields.map((field, i) => {
          const count = field.count ? field.count : 0;

          return (
          <CheckboxField
            key={i}
            fieldName={fieldName}
            value={field.value}
            label={`${field.displayValue ? field.displayValue : field.value} (${count.toLocaleString('de-DE')})`}
            preselected={field.preselected}
            disabled={field.disabled}
          />
        )})}
      </FormGroup>
    </div>
  );
}
