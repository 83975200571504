import { SuggestionValue } from "../data/schema";

export function calculateOverallCount(values: Array<SuggestionValue>): number | undefined {
  let fullCount = undefined;

  for (let item of values) {
    if (item.count !== undefined) {
      if(!fullCount) {
        fullCount = item.count
      } else {
        fullCount += item.count;
      }
    }
  }

  return fullCount;
}

export function getExpectedDealSize(dealPrice: number) {
  if (!dealPrice || dealPrice <= 0) {
    return null;
  }

  let pConv = Math.min(Math.max(-0.03726998164317818 * Math.log(dealPrice) + 0.6044334433564411, 0.001), 0.6);
  let expectedSize = Math.min(pConv * dealPrice, 1000.);

  return parseFloat(expectedSize.toFixed(2));
}