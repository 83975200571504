import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import BaseButton from '../buttons/BaseButton';
import FilledButton from '../buttons/FilledButton';

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.padding.lg
  },
  backButton: {
    marginRight: theme.spacing(1),
  }
}));

interface BackNextButtonsProps {
  hideBack?: boolean,
  hideNext?: boolean,
  backButtonText?: string,
  nextButtonText?: string,
  handleBack: any,
  handleNext: any,
  disableNext?: boolean
}

export default function BackNextButtons(props: BackNextButtonsProps) {
  const classes = useStyles();

  const nextOnEnter = (e: KeyboardEvent) => {
    if(e.key === 'Enter' && !props.disableNext && !props.hideNext) {
      // Prevent submit on enter
      e.preventDefault();
      props.handleNext();
    }
  }

  // dependency array for useEffect needs to be empty,
  // otherwise event listener will read old state variables
  // see https://github.com/facebook/react/issues/14699
  useEffect(() => {
    document.addEventListener("keydown", nextOnEnter);
    return () => {
      document.removeEventListener("keydown", nextOnEnter);
    }
  });

  return (
    <Grid container direction="row" justify="space-between" className={classes.buttons}>
      
      <Grid item>
        {!props.hideBack && 
          <BaseButton
            onMouseDown={props.handleBack}
            className={classes.backButton}
          >
            {props.backButtonText || "Zurück"}
          </BaseButton>}
      </Grid>

      <Grid item>
        {!props.hideNext && 
          <FilledButton disabled={props.disableNext} onMouseDown={props.handleNext}>{props.nextButtonText || "Weiter"}</FilledButton>}
      </Grid>

    </Grid>
  );
}