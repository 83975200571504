import { useEffect, useState } from 'react';
import { FormikState, useFormikContext } from 'formik';

import { FormValues, RequiredFieldsConfig, SelectItemConfig, ViewConfig, ReqField } from '../data/schema';
import { fetchCountsOfReqFields } from '../api/client';
import { mapViewToFormikFields } from '../utils/form-utils';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';

import ErrorMessage from '../form/ErrorMessage';
import Header from '../form/Header';
import CheckboxGroup from '../inputs/CheckboxGroup';
import DefaultView from './DefaultView';
import { Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold'
  }
}));

export const reqFields: Array<ReqField> = [
  {
    value: "Adresse",
    displayValue: "Postadresse"
  },
  {
    value: "E-Mail",
    displayValue: "allg. E-Mail"
  },
  {
    value: "Telefon",
    displayValue: "Telefon"
  },
  {
    value: "Fax",
    displayValue: "Fax"
  },
  {
    value: "Anrede",
    displayValue: "Ansprechpartner (Geschäftsführung, Prokura, Inhaberschaft)"
  },
  {
    value: "Webseite",
    displayValue: "Webseite"
  }
]


interface RequiredFieldsViewProps {
  handleIntermediateChange: Function,
  requiredFieldsConfig: RequiredFieldsConfig,
  setCount: Function,
  setFormValues: Function,
  views: Array<ViewConfig>
}

export default function RequiredFieldsView({
  handleIntermediateChange,
  requiredFieldsConfig,
  setCount,
  setFormValues,
  views
}: RequiredFieldsViewProps) {
  const classes = useStyles();
  const formikField = mapViewToFormikFields(requiredFieldsConfig.id)[0];
  const formik: FormikState<FormValues> = useFormikContext();
  const [fields, setFields] = useState<Array<SelectItemConfig>>([
    {
      value: "Adresse",
      displayValue: "Postadresse",
      preselected: true,
      disabled: true
    },
    {
      value: "E-Mail",
      displayValue: "allg. E-Mail",
      preselected: false,
      disabled: true
    },
    {
      value: "Telefon",
      preselected: false,
      disabled: true
    },
    {
      value: "Fax",
      preselected: false,
      disabled: true
    },
    {
      value: "Anrede",
      displayValue: "Ansprechpartner (Geschäftsführung, Prokura, Inhaberschaft)",
      preselected: false,
      disabled: true
    },
    {
      value: "Webseite",
      preselected: false,
      disabled: true
    }
  ]);

  useEffect(() => {
    let mounted = true;
    (async() => {
      const vertQueries = mapFormValuesToVertQueries(formik.values);
      vertQueries.forEach(q => q.req_fields = []);
      const reqFields = await fetchCountsOfReqFields(vertQueries);
      if(mounted) {
        setFields((prev) => {
          let updatedFields: Array<SelectItemConfig> = [];
          for (let field of prev){
            updatedFields.push({
              value: field.value,
              displayValue: field.displayValue,
              preselected: field.preselected,
              count: reqFields[field.value],
              disabled: field.preselected
            });
          }
          return updatedFields;
        })
      }
    })();

    return () => { mounted = false; };
  }, [formik.values['sizeClasses']]);

  const InfoText = () => (
    <>
      <Typography variant="body1" paragraph>Nicht jede Firma hat eine Webseite oder E-Mail-Adresse.</Typography>
      <Typography variant="body1" paragraph>Warum dann eine Liste kaufen, in der die gesuchten Kontaktdetails teilweise fehlen?</Typography>
      <Typography variant="body1" paragraph>Bei Listflix können Sie Ihre Wunschliste so konfigurieren, dass nur Firmen enthalten sind, bei denen das gesuchte Kontaktdetail vorhanden ist.</Typography>
      <Typography variant="body1" paragraph><span className={classes.bold}>Wählen Sie dazu einfach die gesuchten Kontaktdetails per Klick aus.</span></Typography>
      <Typography variant="body1">Die Anzahl der Firmen reduziert sich dann auf die Menge der Firmen mit den gesuchten Kontaktdetails. Der Preis der Liste ergibt sich aus der Menge der enthaltenen Firmen pro Listenbestellung. <Link href="https://listflix.de/preise/" target="_blank">Mehr zu den Preisen</Link>.</Typography>
    </>
  )

  return (
    <DefaultView viewId={requiredFieldsConfig.id} setCount={setCount} setFormValues={setFormValues} views={views} useDefaultCount >
      <Header headerConfig={requiredFieldsConfig.header} infoModal={{
        title: 'Kontaktdetails',
        content: <InfoText />,
        linkText: 'Welche Kontaktdetails sind die richtigen für mich?'
      }} />
      <CheckboxGroup fieldName={formikField} fields={fields} handleIntermediateChange={handleIntermediateChange} />
      <ErrorMessage padding={true} fieldName={formikField} />
    </DefaultView>
  );
};