import { useEffect, useState } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { makeStyles, Slider } from '@material-ui/core';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';
import { AdvMark } from '../data/schema';

const useStyles = makeStyles((theme) => ({
  mark: {
    height: '6px',
    width: '3px',
    marginLeft: '-2px',
    // marginTop: '-2px',
    backgroundColor: 'white', //theme.palette.grey[400],
    opacity: 1,
    '&:last-child': {
      display: 'None'
    }
  },
  markActive: {
    backgroundColor: 'white'
  },
  // round marks
  // mark: {
  //   height: '12px',
  //   width: '12px',
  //   borderRadius: '6px',
  //   marginLeft: '-4px',
  //   marginTop: '-2px',
  //   backgroundColor: theme.palette.grey[300],
  //   opacity: 1
  // },
  // markActive: {
  //   backgroundColor: theme.palette.primary.main
  // },
  markLabel: (size: any) => ({
    color: theme.palette.grey[500],
    fontSize: size > 8 ? size > 10 ? '10px' : '14px' : '16px'
  }),
  markLabelActive: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  track: {
    height: '4px',
    borderRadius: '2px',
    backgroundColor: theme.palette.primary.main
  },
  rail: {
    height: '4px',
    borderRadius: '2px',
    backgroundColor: theme.palette.grey[300],
    opacity: 1
  },
  thumb: {
    height: '24px',
    width: '24px',
    marginTop: '-9px',
    marginLeft: '-10px',
    border: '3px white solid',
    boxShadow: theme.shadows[2]
  },
  rangeContainer: {
    padding: '0px ' + theme.padding.md
  }
}));

interface CustomSliderProps {
  formikField: string,
  marks: Array<AdvMark>,
  showValueLabel?: boolean,
}

export default function CustomSlider({
  formikField,
  marks,
  showValueLabel
}: CustomSliderProps) {
  const classes = useStyles(marks.length);
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    if(marks.length > 0) {
      let initialValue = marks[marks.length - 1].value;
      if(formik.values[formikField] !== undefined) {
        initialValue = marks.filter(m => m.origValue === formik.values[formikField])[0].value
      }
      setSliderValue(initialValue);
    }
  }, [marks]);

  function valueLabelFormat(value: number) {
    const closestMark = findClosestMark(value);
    return `${closestMark.count.toLocaleString('de-DE')}`;
  }

  function findClosestMark(value: number): AdvMark {
    let closestMark = marks[marks.length - 1];
    for (
      let i = 0;
      i <= marks.length;
      i++
    ) {
      let currentMark = marks[i];
      if (value === currentMark.value) {
        return currentMark;
      } else if (value > currentMark.value && value < marks[i + 1].value) {
        if (Math.abs(currentMark.value - value) < marks[i + 1].value - value) {
          closestMark = currentMark;
        } else {
          closestMark = marks[i + 1];
        }
        break;
      }
    }

    return closestMark;
  }

  function handleCommitedChange(e: any, value: number) {
    const closestMark = findClosestMark(value);
    formik.setFieldValue(formikField, closestMark.origValue);
    setSliderValue(closestMark.value);
  }

  return (
    <div className={classes.rangeContainer}>
      {marks.length > 0 &&
        <Slider
          key={`slider-${formikField}`}
          value={sliderValue}
          valueLabelFormat={valueLabelFormat}
          aria-labelledby="discrete-slider-restrict"
          step={0.001}
          valueLabelDisplay={showValueLabel ? "on" : "off"}
          marks={marks}
          min={marks[0].value}
          max={marks[marks.length - 1].value}
          onChange={(e, v: number) => setSliderValue(v)}
          onChangeCommitted={handleCommitedChange}
          classes={{
            mark: classes.mark,
            markActive: classes.markActive,
            markLabel: classes.markLabel,
            markLabelActive: classes.markLabelActive,
            track: classes.track,
            rail: classes.rail,
            thumb: classes.thumb
          }}
        />
      }
    </div>
  );
};