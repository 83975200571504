import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FormikValues, useFormikContext } from 'formik';

import { mapFormValuesToVertQueries, mapVertInfosToOfferPreview } from '../utils/nexus-utils';
import { fetchPrice, fetchVertFrcs } from '../api/client';
import { FormValues, OfferConfig, OfferPreviewItem, VertQuery } from '../data/schema';

import Header from '../form/Header';
import OfferPreviewItemView from '../form/OfferPreviewItemView';

interface OfferViewProps {
  offerConfig: OfferConfig,
  formValues: FormValues,
  count: number | undefined
}

const useStyles = makeStyles((theme) => ({
  successBox: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: theme.padding.sm,
    boxShadow: theme.shadows[3],
    borderRadius: '4px'
  },
  convincedBox: {
    marginTop: theme.padding.sm,
    marginBottom: theme.padding.md,
    padding: theme.padding.sm,
    border: '2px dashed ' + theme.palette.secondary.main,
    borderRadius: '4px'
  },
  arrowIcon: {
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default function OfferView ({
  offerConfig,
  formValues,
  count
}: OfferViewProps) {
  const classes = useStyles();
  const [vertInfos, setVertInfos] = useState<Array<OfferPreviewItem>>([]);
  const [loading, setLoading] = useState(true);
  const { values }: { values: FormikValues } = useFormikContext();

  useEffect(() => {
    let vertFrcPromises: Array<Promise<any>> = [];
    const vertQueries: Array<VertQuery> = mapFormValuesToVertQueries(formValues);

    vertQueries.forEach(v => {
      vertFrcPromises.push(fetchVertFrcs(v));
    });

    (async() => {
      const vertFrcs = await Promise.all(vertFrcPromises);
      let price = {};

      if(count) {
        price = await fetchPrice(count);
      }

      setVertInfos(mapVertInfosToOfferPreview(vertFrcs, price));
      setLoading(false);
    })();

  }, []);

  const salutationText = ['Frau', 'Herr'].includes(values['salutation']) ? `, ${values['salutation']} ${values['lastName']}` : '';

  return (
    <>
      <div className={classes.successBox}>
        <Grid container direction='row' alignItems='center' style={{ marginBottom: '8px' }}>
          <FontAwesomeIcon icon={faRocket} />
          <Typography variant='body2' style={{ marginLeft: '10px' }}>Geschafft!</Typography>
        </Grid>
        <Typography variant='body1'>Ihr Angebot wurde erfolgreich erstellt und wartet auf Sie in Ihrem Posteingang!</Typography>
      </div>

      <div className={classes.convincedBox}>
        <Grid container direction='row' wrap='nowrap' alignItems='center'>
          <Grid item>
            <Typography variant='body2' style={{ marginBottom: '8px' }}>Bereits überzeugt{salutationText}?</Typography>
            <Typography variant='body1'>Sie können Ihre Liste jetzt sofort bestellen und erhalten sie innerhalb von 24 Stunden nach Zahlungseingang per E-Mail.</Typography>
          </Grid>
          <Grid item>
            <ArrowForward color='secondary' className={classes.arrowIcon} />
          </Grid>
        </Grid>
      </div>

      <Header headerConfig={offerConfig.header} />

      <Grid container direction="row" justify='center'>
        <ClipLoader color={'#000'} loading={loading} size={20} />
      </Grid>

      {vertInfos.map((v, i) => (
        <div key={i}>
          <OfferPreviewItemView formValues={formValues} offerPreviewItem={v} pos={i} />
        </div>
      ))}
    </>
  );
};