import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { Grid, Typography, Link, Icon } from '@material-ui/core';

import { ContactConfig, InputFieldConfig } from '../data/schema';

import CheckboxField from '../inputs/CheckboxField';
import CountryDropdown from '../inputs/CountryDropdown';
import InputField from '../inputs/InputField';
import HighlightButton from '../buttons/HighlightButton';
import BaseButton from '../buttons/BaseButton';
import Header from './Header';
import ErrorMessage from './ErrorMessage';
import Benefit from './Benefit';
import { ClipLoader } from 'react-spinners';
import { FaXmark } from 'react-icons/fa6';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  benefits: {
    '& p': {
      fontSize: '14px',
      fontWeight: 'bold'
    },
    '& ul': {
      marginTop: '4px',
      paddingInlineStart: '24px'
    },
    '& li': {
      fontSize: '14px',
      fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif',
    }
  },
  contactSection: {
    margin: theme.padding.sm + ' 0px',
    '& p': {
      fontWeight: 'bold'
    }
  },
  back: {
    marginRight: theme.padding.xs
  },
  dataSecurity: {
    fontSize: '12px'
  }
}));

interface ContactStepProps {
  contactConfig: ContactConfig,
  setFormValues: Function,
  handleBack: Function,
  processing: boolean,
  error?: boolean
}

interface ContactSectionProps {
  title: string,
  children: React.ReactChild | React.ReactChild[]
}

function ContactSection(props: ContactSectionProps) {
  const style = useStyles().contactSection;
  return (
    <div className={style}>
      <Typography variant="body1">{props.title}</Typography>
      <Grid container item xs={12} spacing={2}>
        {props.children}
      </Grid>
    </div>
  )
}

export default function ContactStep({ contactConfig, setFormValues, handleBack, processing, error }: ContactStepProps) {
  const classes = useStyles();
  const formik: FormikContextType<FormikValues> = useFormikContext();

  // const submitButtonText = buyImm ? 'Liste bestellen' : 'Angebot unverbindlich anfordern';
  // const caption = buyImm ? 'Ihre Liste wartet' : contactConfig.header.caption;
  // const headline = buyImm ? 'Bestellen Sie jetzt Ihre Liste!' : contactConfig.header.headline;
  // const header: HeaderConfig = {
  //   caption: caption,
  //   headline: headline
  // }

  // const Confirmation = () => {
  //   if (buyImm) return <Typography variant="body1">Ich akzeptiere die <Link href="https://listflix.de/agb/" target="_blank">allgemeinen Geschäftsbedingungen</Link> und <Link href="https://listflix.de/datenschutz/" target="_blank"> Datenschutzerklärung</Link>.</Typography>;
  //   else return <Typography variant="body1">Ich stimme der <Link href="https://listflix.de/datenschutz/" target="_blank"> Datenschutzerklärung </Link> zu.</Typography>;
  // };

  const fields = [
    {
      "id": "salutation",
      "placeholder": "Anrede*"
    },
    {
      "id": "firstName",
      "placeholder": "Vorname*"
    },
    {
      "id": "lastName",
      "placeholder": "Nachname*"
    },
    {
      "id": "firmName",
      "placeholder": "Firmenname*"
    },
    {
      "id": "street",
      "placeholder": "Straße*"
    },
    {
      "id": "number",
      "placeholder": "Hausnummer*"
    },
    {
      "id": "postCode",
      "placeholder": "Postleitzahl*"
    },
    {
      "id": "city",
      "placeholder": "Ort*"
    },
    {
      "id": "country",
      "placeholder": "Land*"
    },
    {
      "id": "ustid",
      "placeholder": "Umsatzsteuer ID*"
    },
    {
      "id": "email",
      "placeholder": "E-Mail-Adresse*"
    },
    {
      "id": "phone",
      "placeholder": "Telefonnummer"
    }
  ];

  const salutationField = (fields.filter(field => field.id === "salutation")[0] as InputFieldConfig);
  const firstNameField = (fields.filter(field => field.id === "firstName")[0] as InputFieldConfig);
  const lastNameField = (fields.filter(field => field.id === "lastName")[0] as InputFieldConfig);
  const firmNameField = (fields.filter(field => field.id === "firmName")[0] as InputFieldConfig);
  const streetField = (fields.filter(field => field.id === "street")[0] as InputFieldConfig);
  const numberField = (fields.filter(field => field.id === "number")[0] as InputFieldConfig);
  const postCodeField = (fields.filter(field => field.id === "postCode")[0] as InputFieldConfig);
  const cityField = (fields.filter(field => field.id === "city")[0] as InputFieldConfig);
  const phoneField = (fields.filter(field => field.id === "phone")[0] as InputFieldConfig);
  const emailField = (fields.filter(field => field.id === "email")[0] as InputFieldConfig);
  const ustidField = (fields.filter(field => field.id === "ustid")[0] as InputFieldConfig);

  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  return (
    <Grid container direction="column" className={classes.container} spacing={2}>

      <Header headerConfig={contactConfig.header} />

      <ContactSection title="Firma">
        <Grid item xs={12}>
          <InputField key={firmNameField.id} fieldName={firmNameField.id} placeholder={firmNameField.placeholder} blur={true} focusOnLoad={false} />
          <ErrorMessage padding={false} fieldName={firmNameField.id} />
        </Grid>

        <Grid container item direction="row" spacing={2}>
          <Grid item xs={8}>
            <InputField key={streetField.id} fieldName={streetField.id} placeholder={streetField.placeholder} blur={true} focusOnLoad={false} />
            <ErrorMessage padding={false} fieldName={streetField.id} />
          </Grid>
          <Grid item xs={4}>
            <InputField key={numberField.id} fieldName={numberField.id} placeholder={numberField.placeholder} blur={true} focusOnLoad={false} />
            <ErrorMessage padding={false} fieldName={numberField.id} />
          </Grid>
        </Grid>

        <Grid container item direction="row" spacing={2}>
          <Grid item xs={4}>
            <InputField key={postCodeField.id} fieldName={postCodeField.id} placeholder={postCodeField.placeholder} blur={true} focusOnLoad={false} />
            <ErrorMessage padding={false} fieldName={postCodeField.id} />
          </Grid>
          <Grid item xs={8}>
            <InputField key={cityField.id} fieldName={cityField.id} placeholder={cityField.placeholder} blur={true} focusOnLoad={false} />
            <ErrorMessage padding={false} fieldName={cityField.id} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CountryDropdown countries={contactConfig.countries} />
        </Grid>

        {formik.values['country'] !== 'DE' ?
          <Grid item xs={12}>
            <InputField key={ustidField.id} fieldName={ustidField.id} placeholder={ustidField.placeholder} blur={true} focusOnLoad={false} />
            <ErrorMessage padding={false} fieldName={ustidField.id} />
          </Grid>
          : <></>
        }

      </ContactSection>

      <ContactSection title="Ansprechpartner">

        <Grid item xs={12} sm={12} md={12}>
          <InputField key={salutationField.id} fieldName={salutationField.id} placeholder={salutationField.placeholder} blur={true} focusOnLoad={false} />
          <ErrorMessage padding={false} fieldName={salutationField.id} />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <InputField key={firstNameField.id} fieldName={firstNameField.id} placeholder={firstNameField.placeholder} blur={true} focusOnLoad={false} />
          <ErrorMessage padding={false} fieldName={firstNameField.id} />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <InputField key={lastNameField.id} fieldName={lastNameField.id} placeholder={lastNameField.placeholder} blur={true} focusOnLoad={false} />
          <ErrorMessage padding={false} fieldName={lastNameField.id} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField key={emailField.id} fieldName={emailField.id} placeholder={emailField.placeholder} blur={true} focusOnLoad={false} />
          <ErrorMessage padding={false} fieldName={emailField.id} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField key={phoneField.id} fieldName={phoneField.id} placeholder={phoneField.placeholder} blur={true} focusOnLoad={false} />
          <ErrorMessage padding={false} fieldName={phoneField.id} />
        </Grid>
      </ContactSection>

      <Grid item style={{ paddingBottom: '0px' }}>
        <div>
          <Grid container direction="row" alignItems="center" wrap='nowrap'>
            <Grid item>
              <CheckboxField
                fieldName="abo"
                value="Ich interessiere mich für regelmäßige Updates der Liste."
                label=""
                preselected={false}
                disabled={false}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.dataSecurity} style={{ fontWeight: 'bold' }}>Ich interessiere mich für regelmäßige Updates der Liste.</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item>
        <div>
          <Grid container direction="row" alignItems="center" wrap='nowrap'>
            <Grid item>
              <CheckboxField
                fieldName="dataSecurityConsent"
                value="Ich stimme den AGB und der Datenschutzerklärung zu"
                label=""
                preselected={false}
                disabled={false}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.dataSecurity}>Ich akzeptiere die <Link href="https://listflix.de/agb/" target="_blank">AGB</Link> und bestätige, dass ich die <Link href="https://listflix.de/datenschutz/" target="_blank">Datenschutzerklärung</Link> zur Kenntnis genommen habe und mit der Verarbeitung meiner personenbezogenen Daten durch Listflix zum Zwecke der Angebotserstellung und Produktinformation einverstanden bin.</Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item style={{ marginTop: '8px' }}>
        {processing ?
        <Grid container direction='row' justify='center' alignItems='center' style={{ marginTop: '16px', marginBottom: '16px'}}>
          <ClipLoader color={'#000'} loading={processing} size={20} />
          <Typography variant='body1' style={{ marginLeft: '8px', fontWeight: 'bold' }} color='primary'>Angebot wird erstellt und versandt...</Typography>
        </Grid>
        : error ?
        <Grid container direction='row' justify='center' alignItems='center' style={{ marginTop: '16px', marginBottom: '16px'}} wrap="nowrap">
          <FaXmark color={'#c41818'} size={20} style={{ marginRight: '8px' }} />
          <Typography variant='body1' style={{ marginLeft: '8px', fontWeight: 'bold', color: '#c41818' }}>Leider ist ein Fehler aufgetreten. Bitte wenden Sie sich direkt an: <Link style={{ fontWeight: 'bold', color: '#c41818', textDecoration: 'underline' }} href="mailto:anfrage@listflix.de?subject=&amp;body=">anfrage@listflix.de</Link></Typography>
        </Grid>
        :
        <HighlightButton
          id="submit-button"
          style={{width: '100%'}}
          type="submit"
          disabled={formik.isSubmitting || formik.values["dataSecurityConsent"].length === 0 || !formik.isValid}
        >
          Angebot unverbindlich anfordern
        </HighlightButton>
        }
      </Grid>

      <Grid item container direction="row">
        <BaseButton onClick={handleBack}><Icon className={classes.back}>arrow_back</Icon>Zurück</BaseButton>
      </Grid>

      <Grid item container direction="row">
        <Benefit iconType="watch_later" text="Flixe Lieferung" />
        <Benefit iconType="manage_accounts" text="Individuelle Listen" />
        <Benefit iconType="check_circle_outline" text="Qualitätsgarantie" />
      </Grid>

    </Grid>
  );
}
