import { Link, Section } from "@react-email/components";

export default function Unsubscribe () {
  return (
    <Section className="w-full text-center py-[8px]">
      <Link href="mailto:anfrage@listflix.de?subject=Unsubscribe&amp;body=Bitte senden Sie mir keine weiteren Angebote an diese E-Mail Adresse." className="text-[12px] underline text-listflix-green-main">Unsubscribe</Link>
      <span className="px-2 text-listflix-green-main text-[12px]">|</span>
      <Link href="https://listflix.de/agb/"className="text-[12px] underline text-listflix-green-main">Allgemeine Geschäftsbedingungen</Link>
    </Section>
  )
}