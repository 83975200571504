import { Column, Row, Text } from "@react-email/components";
import SquareIcon from "./SquareIcon";
import { twMerge } from "tailwind-merge";

type Position = {
  text: string,
  absolute: string,
  percentage: string
}

function PositionDetail({ headline, positions, className }: { headline: string, positions: Position[], className?: string }) {
  return (
    <Column className={twMerge(className)}>
      {/* Header */}
      <Row>
        <Column className="w-[30px]">
          <SquareIcon character={headline[0]} />
        </Column>
        <Column className="w-[8px]" />
        <Column>
          <span className='font-bold text-[14px]'>{headline}:</span>
        </Column>
      </Row>

      {/* Body */}
      <Row className="pt-[8px]">
        <Column>
          <Text className="mt-0 mb-0">
            {positions.map((pos, i) => (
              <Text key={`pos-${i}`} className="text-[14px] mt-0 mb-0"><span className="font-bold text-[14px]">{pos.absolute}</span> mit {pos.text} ({pos.percentage})</Text>
            ))}
          </Text>
        </Column>
      </Row>

    </Column>
  )
}

export function BasisdatenDetails({ pos, className }: { pos: any, className?: string }) {
  return (
    <PositionDetail headline='Basisdaten' className={twMerge(className)} positions={[
      {
        text: 'Firmennamen',
        absolute: pos.com_t,
        percentage: pos.com_pc
      },
      {
        text: 'Branche',
        absolute: pos.industry_t,
        percentage: pos.industry_pc
      },
      {
        text: 'Unternehmensgegenstand',
        absolute: pos.subject_t,
        percentage: pos.subject_pc
      }
    ]}/>
  )
}

export function AnsprechpartnerDetails({ pos, className }: { pos: any, className?: string }) {
  return (
    <PositionDetail headline='Ansprechpartner' className={twMerge(className)} positions={[
      {
        text: 'Geschäftsführung',
        absolute: pos.executive_t,
        percentage: pos.executive_pc
      },
      {
        text: 'Prokura',
        absolute: pos.proxy_t,
        percentage: pos.proxy_pc
      },
      {
        text: 'Inhaber',
        absolute: pos.owner_t,
        percentage: pos.owner_pc
      }
    ]}/>
  )
}

export function KontaktdatenDetails({ pos, className }: { pos: any, className?: string }) {
  return (
    <PositionDetail headline='Kontaktdaten' className={twMerge(className)} positions={[
      {
        text: 'Postadresse',
        absolute: pos.postal_t,
        percentage: pos.postal_pc
      },
      {
        text: 'allg. Telefon',
        absolute: pos.phone_t,
        percentage: pos.phone_pc
      },
      {
        text: 'Fax',
        absolute: pos.fax_t,
        percentage: pos.fax_pc
      },
      {
        text: 'Webseite',
        absolute: pos.homepage_t,
        percentage: pos.homepage_pc
      },
      {
        text: 'allg. E-Mail',
        absolute: pos.email_t,
        percentage: pos.email_pc
      }
    ]}/>
  )
}

export function FinanzdatenDetails({ pos, className }: { pos: any, className?: string }) {
  return (
    <PositionDetail headline='Finanzdaten' className={twMerge(className)} positions={[
      {
        text: 'Firmengrößenklasse',
        absolute: pos.size_class_t,
        percentage: pos.size_class_pc
      },
      {
        text: 'Bilanzsumme',
        absolute: pos.balance_sheet_total_t,
        percentage: pos.balance_sheet_total_pc
      },
      {
        text: 'Umsatz',
        absolute: pos.revenue_t,
        percentage: pos.revenue_pc
      },
      {
        text: 'Betriebsergebnis',
        absolute: pos.profit_t,
        percentage: pos.profit_pc
      },
      {
        text: 'Mitarbeiterzahl',
        absolute: pos.employees_no_t,
        percentage: pos.employees_no_pc
      }
    ]}/>
  )
} 