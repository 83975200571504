import { SuggestionValue, VerticalCount } from '../data/schema';

function prepare(value: string) {
  return value.toLowerCase();
}

function sortByAlias(a: SuggestionValue, b: SuggestionValue) {
  const aliasA = prepare(a.alias);
  const aliasB = prepare(b.alias);
  if (aliasA < aliasB) return -1;
  if (aliasA > aliasB) return 1;
  return 0;
}

function sortByValue(a: SuggestionValue, b: SuggestionValue) {
  const valueA = prepare(a.value);
  const valueB = prepare(b.value);
  if (valueA < valueB) return -1;
  if (valueA > valueB) return 1;
  return 0;
}

export function sortSuggestionListByAlias(list: Array<SuggestionValue>) {
  return list.sort(sortByAlias);
}

export function sortSuggestionListByValue(list: Array<SuggestionValue>) {
  return list.sort(sortByValue);
}

export function addCountsToSuggestionList(list: Array<SuggestionValue>, verticalCounts: Array<VerticalCount>) {
  const verticalDict = transFormToDict(verticalCounts);

  for(let item of list){
    if(item.vert_id && verticalDict[item.vert_id]){
      item.count = verticalDict[item.vert_id].count;
      item.value = verticalDict[item.vert_id].nameShort;
    } else {
      item.count = undefined;
    }
  }

  return list;
}

function transFormToDict(verticalCounts: Array<VerticalCount>){
  let dict:any = {};

  for(let item of verticalCounts){
    dict[item.vert_id] = {
      count: item.com_cnt,
      nameShort: item.name_short
    }
  }

  return dict;
}

export function filterSuggestionList(filter: string, list: Array<SuggestionValue>): Array<SuggestionValue> {
  let currentList = sortSuggestionListByAlias(list.slice());
  let keepSearching = true;
  let result: Array<SuggestionValue> = [];
  let start = 0; 

  filter = prepare(filter);

  while (keepSearching) {
    let mid = Math.floor(currentList.length / 2);
    let alias = prepare(currentList[mid].alias);

    if (alias.startsWith(filter)) {
      start = mid;
      keepSearching = false;
    } else if (alias < filter && currentList.length > 1) {
      currentList.splice(0, mid);
    } else if (alias > filter && currentList.length > 1) {
      currentList.splice(mid, currentList.length - mid);
    } else {
      return [];
    }
  }

  // Search list upwards
  for (
    let i = start;
    i >= 0 && prepare(currentList[i].alias).startsWith(filter);
    i--
  ) {    
    if (!result.map(v => v.value).includes(currentList[i].value)){
      result.push(currentList[i]);
    }
  }

  
  // Search list downwards
  for (
    let i = start + 1;
    i < currentList.length && prepare(currentList[i].alias).startsWith(filter);
    i++
  ) {
      if (!result.map(v => v.value).includes(currentList[i].value)){
        result.push(currentList[i]);
      }
  }
  
  return sortSuggestionListByValue(result);
}