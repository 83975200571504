import React from 'react';
import { makeStyles } from '@material-ui/core/styles'; 
import BaseButton from './BaseButton';

const useStyles = makeStyles(theme => ({
  filledButton: {
    color: 'white'
  }

}));

export default function FilledButton(props: any) {
  const classes = useStyles();
  const { className, ...otherProps } = props;
  const buttonProps = {
      className,
      ...otherProps,
      color: 'primary',
      variant: 'contained',
      disableElevation: true
  }

  return <BaseButton {...buttonProps} className={`${classes.filledButton} ${className}`}>{props.children}</BaseButton>
}