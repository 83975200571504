import {
  Text,
  Row,
  Column,
} from '@react-email/components';
import { twMerge } from 'tailwind-merge';
import RoundIcon from './RoundIcon';

export function Advantage1({ className }: { className?: string }) {
  return (
    <Column className={twMerge(className)}>
      {/* Vorteil Header */}
      <Row>
        <Column className="w-[30px]">
          <RoundIcon character="1" />
        </Column>
        <Column className="w-[8px]" />
        <Column>
          <span className='font-bold text-[14px]'>Schneller Neukunden finden</span>
        </Column>
      </Row>

      {/* Vorteil Body */}
      <Row className="pt-[8px]">
        <Column>
          <Text className="mt-0 mb-0 text-[14px]">
            Suchen Sie nicht lange selbst nach Neukunden, sondern sparen Sie sich das.
          </Text>
        </Column>
      </Row>

    </Column>
  )
}

export function Advantage2({ className }: { className?: string }) {
  return (
    <Column className={twMerge(className)}>
      {/* Vorteil Header */}
      <Row>
        <Column className="w-[30px]">
          <RoundIcon character="2" />
        </Column>
        <Column className="w-[8px]" />
        <Column>
          <span className='font-bold text-[14px]'>E-Mail-Adressvalidierung</span>
        </Column>
      </Row>

      {/* Vorteil Body */}
      <Row className="pt-[8px]">
        <Column>
          <Text className="mt-0 mb-0 text-[14px]">
            Kein Ärger mehr mit unzähligen, nicht zustellbaren E-Mails.
          </Text>
        </Column>
      </Row>

    </Column>
  )
}

export function Advantage3({ className }: { className?: string }) {
  return (
    <Column className={twMerge(className)}>
      {/* Vorteil Header */}
      <Row>
        <Column className="w-[30px]">
          <RoundIcon character="3" />
        </Column>
        <Column className="w-[8px]" />
        <Column>
          <span className='font-bold text-[14px]'>Listflix-Qualitätsgarantie</span>
        </Column>
      </Row>

      {/* Vorteil Body */}
      <Row className="pt-[8px]">
        <Column>
          <Text className="mt-0 mb-0 text-[14px]">
            Wir garantieren die Qualität unserer Listen. Dadurch können Sie - falls nötig - fehlerhafte Daten umtauschen oder zurückgeben.
          </Text>
        </Column>
      </Row>
    </Column>
  )
}

export function Advantage4({ className }: { className?: string }) {
  return (
    <Column className={twMerge(className)}>
      {/* Vorteil Header */}
      <Row>
        <Column className="w-[30px]">
          <RoundIcon character="4" />
        </Column>
        <Column className="w-[8px]" />
        <Column>
          <span className='font-bold text-[14px]'>Hochwertige Quellen</span>
        </Column>
      </Row>

      {/* Vorteil Body */}
      <Row className="pt-[8px]">
        <Column>
          <Text className="mt-0 mb-0 text-[14px]">
            Listflix nutzt nur qualitativ hochwertige Quellen und überprüft diese vollautomatisch rund um die Uhr auf Aktualität.
          </Text>
        </Column>
      </Row>

    </Column>
  )
}