import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Dialog, DialogContent, DialogTitle, IconButton, Link } from '@material-ui/core';
import { HeaderConfig } from '../data/schema';
import { Close, Info } from '@material-ui/icons';
import InfoHover from './InfoHover';

const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: theme.padding.md,
  },
  text: {
    marginTop: theme.spacing(2)
  },
  // needs to be relative to make width of info box work relative to width of the container
  headerContainer: {
    position: 'relative'
  },
  iconRoot: {
    fontSize: '30px',
    marginLeft: theme.padding.xs
  },
  dialog: {
    maxWidth: '1200px',
    [theme.breakpoints.down('xs')]: {
      margin: theme.padding.xs
    }
  },
  dialogTitle: {
    padding: theme.padding.xs
  },
  dialogContent: {
    padding: theme.padding.lg,
    paddingTop: '0px',
    '& h1': {
      marginBottom: theme.padding.sm,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.padding.sm
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.padding.xs
    }
  },
  infoLink: {
    marginTop: theme.padding.xs,
    '& p': {
      marginLeft: theme.padding.xs,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      }
    }
  }
}));

interface InfoHoverProps {
  text: Array<string>
}

interface InfoModalProps {
  title: string,
  content: ReactElement,
  linkText: string
}

interface HeaderProps {
  headerConfig: HeaderConfig,
  infoHover?: InfoHoverProps,
  infoModal?: InfoModalProps
}

export default function Header({ headerConfig, infoHover, infoModal }: HeaderProps) {
  const classes = useStyles();
  const { caption, headline, text, info } = headerConfig;
  const [showInfo, setShowInfo] = useState(false);

  function handleClose () {
    setShowInfo(false);
  }

  function handleClick() {
    setShowInfo(!showInfo);
  }

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headline}>
        {caption && <Typography variant="caption">{caption}</Typography>}
        <Grid container direction="row" wrap="nowrap" alignItems={'center'}>
          <Typography variant="h1">{headline}</Typography>

          {/* show info icon for info hover */}
          {infoHover &&
          <Info 
            onMouseEnter={() => setShowInfo(true)}
            // also handle click for mobile
            onClick={handleClick}
            onMouseLeave={() => setShowInfo(false)}
            color={'primary'}
            classes={{
              root: classes.iconRoot
            }}
          />}
        </Grid>

        {text && <Typography variant="body1" className={classes.text}>{text}</Typography>}
        
        {/* show link for info modal */}
        {infoModal && 
          <Grid container direction="row" wrap="nowrap" alignItems="center" className={classes.infoLink}>
            <Info color='primary' />
            <Typography variant="body1" onClick={() => setShowInfo(true)}>{infoModal.linkText}</Typography>
          </Grid>
        }
      </div>

      {infoHover && showInfo && <InfoHover show={showInfo} text={infoHover.text} />}

      {infoModal && showInfo &&
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={showInfo} classes={{paper: classes.dialog}}>
          <Grid container direction='row' justify='flex-end' className={classes.dialogTitle} alignItems='center'>
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>

          <DialogContent classes={{root: classes.dialogContent}}>
            <Typography variant="h1">{infoModal.title}</Typography>
            {infoModal.content}
          </DialogContent>
        </Dialog>
      }
    </div>
  );
}
