import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText, ListSubheader, Typography } from '@material-ui/core';

import levenshtein from '../utils/levenshtein';
import { SuggestionValue } from '../data/schema';

import Tag from './Tag';
import SearchField from './SearchField';


const useStyles = makeStyles((theme) => ({
  tags: {
    marginBottom: theme.padding.sm
  },
  listSubheader: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    backgroundColor: '#f2f2f2',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    fontFamily: '"UniNeueRegular", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  suggestions: {
    maxHeight: '500px',
    overflow: 'auto',
    backgroundColor: '#f2f2f2',
    marginTop: '-24px',
    paddingTop: '24px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px'
  },
  suggestion: {
    padding: '2px ' + theme.padding.sm
  },
  suggestionText: {
    color: theme.palette.primary.main
  },
  dots: {
    padding: `${theme.padding.xs} ${theme.padding.sm}`,
    fontSize: '14px'
  }
}));

interface TagFieldProps {
  fieldName: string,
  inputFieldName: string,
  suggestionList: Array<SuggestionValue>,
  filterSuggestionList: Function,
  allowShowAll: boolean,
  placeholder: string,
  handleIntermediateChange: Function,
  focusOnLoad: boolean,
  openOnFocus: boolean,
  previewSuggestions: number,
  loading: boolean
}

export default function TagField({
  fieldName,
  inputFieldName,
  suggestionList,
  filterSuggestionList,
  allowShowAll,
  placeholder,
  handleIntermediateChange,
  focusOnLoad,
  openOnFocus,
  previewSuggestions,
  loading
}: TagFieldProps) {

  const classes = useStyles();
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const valueRef = useRef<FormikValues>();

  const [suggestions, setSuggestions] = useState(suggestionList);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [blur, setBlur] = useState(true);
  const [prevValue, setPrevValue] = useState('');
  const [arrowCounter, setArrowCounter] = useState(-1);
  const [showAll, setShowAll] = useState(false);

  // updates to formik value need to be stored in permanent ref in order for them to be visible when component unmounts
  // otherwise, function for unmounting will be registered with values as they are on mount of component -> value would be empty
  useEffect(() => {
    formik.validateField(fieldName);
    valueRef.current = formik.values;
  }, [formik.values]);

  useEffect(() => {
    if(suggestionList.length > 0) {
      setSuggestions(suggestionList);
    }
  }, [suggestionList]);

  const selectItem = (item: SuggestionValue) => {
    const currentSelectedValues = formik.values[fieldName];

    // check if entered custom value matches one of the suggestions
    const suggestion = suggestions.filter(v => v.value.toLowerCase() === item.value.toLowerCase())[0];
    const selectedItem = suggestion ? suggestion : item;

    if (!currentSelectedValues.map((i: SuggestionValue) => i.value).includes(item.value)) {
      formik.setFieldValue(fieldName, [
        ...currentSelectedValues,
        { ...selectedItem }
      ]);
    }

    if (fieldName === "regionValues" && item.value === "Deutschland") {
      formik.setFieldValue(fieldName, [item]);
    }

    setShowSuggestions(false);
    setBlur(true);
    formik.setFieldValue(inputFieldName, '');
  }

  const deselectItem = (item: SuggestionValue) => {
    const currentSelectedValues = formik.values[fieldName];
    const newSelectedValues = currentSelectedValues.filter((i: SuggestionValue) => i.value !== item.value);

    formik.setFieldValue(fieldName, newSelectedValues);
  }

  const blurInput = () => {
    // Do not blur if element from suggestion list is clicked -> otherwise click will be overwritten by blur
    if (blur) {
      setShowSuggestions(false);
      setShowAll(false);
      formik.setFieldTouched(fieldName);
    }
  }

  const changeInput = (event: BaseSyntheticEvent) => {
    const input = event.target.value;
    formik.setFieldValue(inputFieldName, input);

    if(input.length >= 2 || (event.type === 'focus' && openOnFocus)) {
      setSuggestions(filterSuggestions(input));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

    if (levenshtein(prevValue, input) > 5) {
      setPrevValue(input);
      handleIntermediateChange(fieldName, input);
    }

    setArrowCounter(-1);
  }

  function filterSuggestions(input: string): Array<SuggestionValue> {
    let filteredSuggestions: Array<SuggestionValue> = [];

    filteredSuggestions = filterSuggestionList(input, suggestionList);

    // remove all suggestions that have already been selected
    filteredSuggestions = filteredSuggestions.filter((s: SuggestionValue) => !formik.values[fieldName].map((s: SuggestionValue) => s.value).includes(s.value));

    if (fieldName === "regionValues" && formik.values[fieldName].map((s: SuggestionValue) => s.value).includes("Deutschland")) {
      filteredSuggestions = [];
    }

    return filteredSuggestions;
  }

  function handleShowAll() {
    setShowAll(true);
    setBlur(false);
  }

  function getListEntries() {
    let result: Array<React.ReactNode> = [];
    let listWithSubheaders: { [key: string]: Array<React.ReactNode> } = {};
    let suggestionsToShow = suggestions;

    // always add "Deutschland" as first entry in list for "region" selection
    if (fieldName === "regionValues") {
      const item = suggestionsToShow.filter(v => v !== undefined && v.value === "Deutschland")[0];
      if (item !== undefined) {
        suggestionsToShow.splice(suggestionsToShow.indexOf(item), 1);
        suggestionsToShow.unshift(item);
      }
    }

    suggestionsToShow.forEach((item, i) => {
      const components = listWithSubheaders[item.category] || [];
      const itemText = item.value + (item.count !== undefined ? ` (${item.count.toLocaleString('de-DE')})` : '');
      const selected = i === arrowCounter;
      const component = (
        <ListItem disabled={loading} selected={selected} className={classes.suggestion} key={`${item.category}-${item.value}`} button onClick={() => selectItem(item)}>
          <ListItemText primary={itemText} className={classes.suggestionText} />
        </ListItem>);

      components.push(component);

      listWithSubheaders[item.category] = components;
    });

    Object.keys(listWithSubheaders).forEach((key, i) => {
      result.push(
        <ListSubheader key={i} className={classes.listSubheader}>{key}</ListSubheader>
      );

      result = result.concat(listWithSubheaders[key].slice(0, previewSuggestions));

      if (listWithSubheaders[key].length > previewSuggestions) {
        result.push(
          <Typography key={"..."} variant="body1" className={classes.dots}>... tippen Sie, um weitere Einträge zu sehen ...</Typography>
        );
      }

      // if (allowShowAll && showAll) {
      //   result = result.concat(listWithSubheaders[key]);
      // } else if (allowShowAll && !showAll) {
      //   result = result.concat(listWithSubheaders[key].slice(0, previewSuggestions));

      //   if (listWithSubheaders[key].length > previewSuggestions) {
      //     result.push(
      //       <ListItem selected={false} className={classes.suggestion} key={'show-all'} button onClick={handleShowAll}>
      //         <ListItemText primary={'Alle anzeigen...'} className={classes.suggestionText} />
      //       </ListItem>
      //     )
      //   }
      // } else {
      //   result = result.concat(listWithSubheaders[key].slice(0, previewSuggestions));
      //   result.push(<Typography key={"..."} variant="body1" className={classes.dots}>... tippen Sie, um weitere Einträge zu sehen ...</Typography>)
      // }
    });

    return result;
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        setArrowCounter(prev => Math.min(suggestions.length - 1, prev + 1));
        break;
      case 'ArrowUp':
        setArrowCounter(prev => Math.max(-1, prev - 1));
        break;
      case 'Enter':
        e.preventDefault();

        if (showSuggestions) {
          e.stopPropagation();

          if (arrowCounter > -1) {
            selectItem(suggestions[arrowCounter]);
          }
        }

        setShowSuggestions(false);
        break;
    }
  }

  return (
    <div>
      {formik.values[fieldName].length > 0 &&
        <Grid container direction="row" className={classes.tags}>
          {formik.values[fieldName].map((res: SuggestionValue) => (
            <Tag key={res.value} tag={res} deselect={deselectItem} />
          ))}
        </Grid>}

      <SearchField
        name={inputFieldName}
        value={formik.values[inputFieldName]}
        onBlur={blurInput}
        onChange={changeInput}
        onKeyDown={handleKeyDown}
        onFocus={changeInput}
        placeholder={placeholder}
        autoComplete='off'
        focusOnLoad={focusOnLoad}
        loading={showSuggestions && loading}
      />

      {showSuggestions && suggestions.length > 0 &&
        <List id="suggestion-list-1" className={classes.suggestions} onMouseEnter={() => setBlur(false)} onMouseLeave={() => setBlur(true)}>
          {getListEntries()}
        </List>}
    </div>
  );
};