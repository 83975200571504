import { makeStyles } from '@material-ui/core/styles'; 
import { Grid, Icon, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  benefit: {
    '& .material-icons': { // adjust size of icon through .material-icons class. Otherwise it will be overwritten by elementor.
      fontSize: '64px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '48px'
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.padding.sm
      }
    }
  }
}));

interface BenefitProps {
  iconType: string,
  text: string
}

export default function Benefit({ iconType, text }: BenefitProps) {
  const classes = useStyles();

  return (
    <Grid container item direction="column" alignItems="center" className={classes.benefit} xs={12} sm={4}>
      <Icon color='primary'>{iconType}</Icon>
      <Typography variant="body2">{text}</Typography>
    </Grid>
  )
}