import {
  Body,
  Section,
  Tailwind,
  Head,
  Html,
  Text,
  Row,
  Column
} from '@react-email/components';
import { EmailValues } from '../../data/schema';
import { Advantage1, Advantage2, Advantage3, Advantage4 } from './components/Advantages';
import { defaultValues } from './data/offer-default-vaules';
import HighlightButton from './components/HighlightButton';
import { H2 } from './components/Typography';
import DefaultContainer from './components/DefaultContainer';
import Footer from './components/Footer';
import Unsubscribe from './components/Unsubscribe';
import Contact from './components/Contact';
import OfferOrderContent from './components/OfferOrderContent';
import Header from './components/Header';
import { twConfig } from './data/tailwind-config';

interface ListflixOfferEmailProps {
  values: EmailValues
}

/*
  #FECB18	<-  PRIMÄR GELB
  #29AD7D	<- PRIMÄR GRÜN
  #F58634	<- SEKUNDÄR Orange
  #e66e16		<- SEKUNDÄR Orange (mehr Kontrast)
  #208A64 Dunkelgrün
*/

export default function ListflixOfferEmail ({ values }: ListflixOfferEmailProps) {
  if (!values) { 
    values = defaultValues
  }

  function BuyNow() {
    return (
      <Section className="bg-gray-100 rounded-sm p-[32px] text-center shadow-md border-solid border-listflix-yellow-main border-4">
        <Text className="mb-[16px] mt-[0px] text-center font-bold">Jetzt bestellen und Sie erhalten Ihre Listflix-Liste in weniger als 24h {values.prepayment ? 'nach Zahlungseingang ' : ''}(Mo-Fr). Sie können per Überweisung, Paypal oder Kreditkarte bezahlen (Vorkasse).</Text>
        <HighlightButton link={values.acceptLink}>Diese Liste bestellen</HighlightButton>
      </Section>
    )
  }

  return (
    <Tailwind
      config={twConfig}
    >
      <Html>
        <Head />
        <Body>
          {/* wrap in section because Outlook does not accept background color of body for some reason */}
          <Section className="bg-gray-100 mx-auto my-[0px] font-email">
            {/* Header */}
            <Header />
          
            <DefaultContainer>

              {/* Anrede */}
              <Section className="px-[8px] xl:px-[32px]">
                <H2 className="text-listflix-green-main mt-[0px]">
                  Hallo {values.salutation} {values.lastName},
                </H2>
                <Text className="text-[14px]">
                  vielen Dank für Ihre Angebotsanfrage! Um <span className="font-bold">schneller Neukunden zu finden</span>, sollte niemand Firmenlisten selbst recherchieren müssen.
                </Text>
                <Text className="text-[14px] mb-[0px]">
                  Bestimmt haben auch Sie <span className="font-bold">keine Zeit</span>, um stundenlang in Verzeichnissen nach Neukunden zu suchen. Mit dem Kauf einer <span className="font-bold">Listflix Liste</span> haben unsere Kunden schon <span className="font-bold">über 30 Jahre an Recherchezeit eingespart</span>. Warum nicht auch Sie?
                </Text>
              </Section>

              {/* Angebot */}
              <OfferOrderContent values={values} isOffer />              

              {/* Jetzt kaufen */}
              <BuyNow />

              {/* Ihre Vorteile bei Listflix */}
              <Section className="py-[16px]">
                <Row>
                  <Column className="px-[8px] xl:px-[32px]">
                    <H2 className="text-gray-900">Ihre Vorteile bei Listflix</H2>
                  </Column>
                </Row>

                <Row>
                  <Column className="h-[4px] bg-listflix-yellow-main rounded-full w-full" />
                </Row>

                {/* --------- Advantages small screen start ----------- */}
                <div id="not-mso-if-start"></div>
                <Row className="sm:hidden w-full">
                  <Advantage1 className='px-[8px] pt-[32px]' />
                </Row>

                <Row className="sm:hidden w-full">
                  <Advantage2 className="px-[8px] pt-[16px]" />
                </Row>

                <Row className="sm:hidden w-full">
                  <Advantage3 className="px-[8px] pt-[16px]" />
                </Row>

                <Row className="sm:hidden w-full">
                  <Advantage4 className='px-[8px] pt-[16px]' />
                </Row>
                <div id="end-if"></div>
                {/* --------- Advantages small screen end ----------- */}

                {/* --------- Advantages large screen start ----------- */}
                <Row className="hidden sm:block px-[8px] xl:px-[32px] pt-[32px] pb-[16px]">
                  {/* Vorteil 1 */}
                  <Advantage1 className="w-1/2" />

                  <Column className="min-w-[16px]" />

                  {/* Vorteil 2 */}
                  <Advantage2 className="w-1/2" />
                </Row>

                <Row className="hidden sm:block px-[8px] xl:px-[32px] pt-[16px]">
                  {/* Vorteil 3 */}
                  <Advantage3 className="w-1/2" />                  

                  <Column className="min-w-[16px]" />

                  {/* Vorteil 4 */}
                  <Advantage4 className="w-1/2" />
                  
                </Row>
                {/* --------- Advantages large screen end ----------- */}

              </Section>

              {/* Ohne Ihre Listflix Liste */}
              <Section>
                <Row>
                  <Column className="px-[8px] xl:px-[32px]">
                    <H2 className="text-red-700">Ohne Ihre Listflix Liste</H2>
                  </Column>
                </Row>

                <Row>
                  <Column className="h-[4px] bg-red-700 rounded-full w-full" />
                </Row>

                <ul style={{paddingInlineStart: '48px'}}>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">fehlt Ihnen Zeit für wirklich wichtige Dinge.</Text></li>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">verlieren Sie Kundenpotenziale, da die manuelle Suche der technischen Datenerhebung klar unterlegen ist.</Text></li>
                </ul>
              </Section>

              {/* Mit Ihrer Listflix Liste */}
              <Section className='pb-[32px]'>
                <Row>
                  <Column className="px-[8px] xl:px-[32px]">
                    <H2 className="text-listflix-green-main">Mit Ihrer Listflix Liste</H2>
                  </Column>
                </Row>

                <Row>
                  <Column className="h-[4px] bg-listflix-green-main rounded-full w-full" />
                </Row>

                <ul style={{paddingInlineStart: '48px'}}>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">finden Sie schneller Neukunden.</Text></li>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">gewinnen Sie Zeit für wirklich wichtige Dinge.</Text></li>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">bekommen Sie eine Liste mit Informationen aus mehreren, geprüften Quellen.</Text></li>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">erhalten Sie eine dauerhafte Nutzungslizenz Ihrer Liste.</Text></li>
                  <li><Text className="mt-[0px] mb-[0px] text-[14px]">profitieren Sie von einer Qualitätsgarantie inklusive Umtauschoption.</Text></li>
                </ul>
              </Section>

              {/* Jetzt kaufen 2 */}
              <BuyNow />

              {/* Kontakt */}
              <Contact />
            </DefaultContainer>

            {/* Footer */}
            <Footer />

            {/* unsubscribe */}
            <Unsubscribe />

          </Section>
        </Body>
      </Html>
    </Tailwind>
  )
}