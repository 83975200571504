import { makeStyles } from '@material-ui/core/styles';
import { Grid, Icon, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  box: (props: InfoBoxProps) => ({
    marginTop: theme.padding.md,
    padding: theme.padding.sm,
    backgroundColor: props.type === 'warning' ? 'rgba(254, 203, 24, 0.2)' : theme.palette.grey[100],
    borderRadius: '4px',
    fontSize: '14px'
  }),
  icon: (props: InfoBoxProps) => ({
    marginRight: theme.padding.sm,
    color: props.type === 'warning' ? 'rgb(254, 203, 24)' : theme.palette.primary.main,
  }),
  iconRoot: {
    fontSize: '30px'
  }
}));

interface InfoBoxProps {
  type: string,
  text: string
}

// info box current not in use
export default function InfoBox(props: InfoBoxProps) {
  const classes = useStyles(props);

  return (
    <Grid container direction="row" className={classes.box} wrap="nowrap" alignItems='center'>
      <Grid item className={classes.icon}>
        <Icon classes={{
          root: classes.iconRoot
        }}>{props.type === 'warning' ? 'warning' : 'info'}</Icon>
      </Grid>
      <Grid item>
        <Typography variant="body1">{props.text}</Typography>
      </Grid>
    </Grid>
  )

}