import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { FormControl, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CountryValue } from '../data/schema';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    width: '100%'
  }
}));

interface CountryDropdownProps {
  countries: Array<CountryValue>
}

export default function CountryDropdown({ countries }: CountryDropdownProps) {
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const classes = useStyles();

  return (
    <FormControl classes={{root: classes.dropdown}}>
      <Select
        native
        value={formik.values['country']}
        name='country'
        onChange={(e) => {
          formik.setFieldValue('country', e.target.value)
        }}
      >
        {
          countries.map((country, i) => (
            <option key={i} value={country.iso2}>{country.text}</option>
          ))
        }
      </Select>
    </FormControl>
  );
}
