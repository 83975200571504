import { useEffect, useRef, useState } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

import { AdvMark, RegionSearchConfig, SuggestionValue, ViewConfig } from '../data/schema';
import { fetchCountsByPostCode, fetchCountsByRegion, fetchGlobalCount } from '../api/client';
import { sortSuggestionListByValue } from '../utils/suggestion-list-utils';
import { mapViewToFormikFields } from '../utils/form-utils';
import { mapFormValuesToVertQueries } from '../utils/nexus-utils';
import { calculateOverallCount } from '../utils/helpers';

import ErrorMessage from '../form/ErrorMessage';
import Header from '../form/Header';
import TagField from '../inputs/TagField';
import CustomSlider from '../form/CustomSlider';
import DefaultView from './DefaultView';
import InfoBox from '../form/InfoBox';
import SearchField from '../inputs/SearchField';
import PlzTagField from '../inputs/PlzTagField';


const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    marginBottom: theme.padding.md,
    backgroundColor: theme.palette.grey[200],
    width: 'auto',
    borderRadius: '4px'
  },
  toggleButton: {
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'normal',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(28, 121, 87, 0.2)'
    }
  },
  activeToggle: {
    fontWeight: 'bold',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    borderRadius: '4px',
    borderTopRightRadius: '4px',
    border: '2px solid ' + theme.palette.primary.main,
    boxShadow: theme.shadows[2]
  },
  radiusContainer: {
    marginTop: theme.padding.md
  },
  plzInput: {
    width: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  bold: {
    fontWeight: 'bold'
  }
}));

interface RegionSearchViewProps {
  handleIntermediateChange: Function,
  regionSearchConfig: RegionSearchConfig,
  count: number | undefined,
  setCount: Function,
  setFormValues: Function,
  views: Array<ViewConfig>
}

export default function RegionSearchView({
  handleIntermediateChange,
  regionSearchConfig,
  count,
  setCount,
  setFormValues,
  views
}: RegionSearchViewProps) {
  const formik: FormikContextType<FormikValues> = useFormikContext();
  const classes = useStyles();
  const countByVerts = calculateOverallCount(formik.values.verticalValues);

  // set count depending on which fields are selected (which view is shown -> region or plz selection)
  useEffect(() => {
    if(formik.values.regionValues.length > 0) {
      const count = calculateOverallCount(formik.values.regionValues);
      setCount(count);
    } else if(formik.values.postCodeRange) {
      // set to max value (countByVerts) if "Alle" is selected
      if(formik.values.postCodeRanges.length > 0 && formik.values.postCodeRange === formik.values.postCodeRanges[formik.values.postCodeRanges.length - 1].origValue) {
        setCount(countByVerts);
      } else if (formik.values.postCodeRanges) {
        const count = formik.values.postCodeRanges.filter((m: AdvMark) => m.origValue === formik.values.postCodeRange)[0].count;
        setCount(count);
      }
    } else if (formik.values.postCodes.length > 0) {
      (async() => {
        const count = await fetchGlobalCount(mapFormValuesToVertQueries(formik.values));
        setCount(count);
      })();
    } else {
      setCount(countByVerts);
    }
  }, [formik.values.postCodeRange, formik.values.postCodeRanges, formik.values.regionValues, formik.values.postCodes]);

  const InfoText = () => (
    <>
      <Typography variant="body1" paragraph>Die richtige Region ergibt sich aus Ihrem Vertriebsgebiet. Wählen Sie die Regionen, in denen Sie Neukunden finden möchten.</Typography>
      <Typography variant="body1" paragraph><span className={classes.bold}>Sie haben folgende regionale Filtermöglichkeiten:</span></Typography>
      <ul>
        <li><Typography variant="body1"><span className={classes.bold}>Deutschland:</span> Genau richtig für alle, die deutschlandweit Kunden suchen.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Bundesländer:</span> Setzen Sie regionale Schwerpunkte mit der Auswahl nach Bundesland.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Top-30 Städte:</span> Wählen Sie aus den 30 größten Städten in Deutschland.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Postleitzahlen:</span> Grenzen Sie Ihre Zielregion mithilfe von Postleitzahlenbereichen wie bspw. 80000-90000 oder einzelnen Postleitzahlen genau ein.</Typography></li>
        <li><Typography variant="body1"><span className={classes.bold}>Umkreis:</span> Mit dem Umkreisfilter definieren Sie ganz einfach einen Umkreis um Ihre Wunsch-Postleitzahl.</Typography></li>

      </ul>
      <Typography variant="body1" paragraph>Bei Listflix bekommen Sie ausschließlich Listen für Deutschland.</Typography>
    </>
  )

  return (
    <DefaultView viewId={regionSearchConfig.id} setCount={setCount} setFormValues={setFormValues} views={views} >
      <Header 
        headerConfig={regionSearchConfig.header}
        infoModal={{
          title: 'Region',
          content: <InfoText />,
          linkText: 'Welche Region ist die richtige für mich?'
        }}
      />
      <PlzTagField
        key={regionSearchConfig.id}
        handleIntermediateChange={handleIntermediateChange}
        previewSuggestions={17}
        count={count}
      />

    </DefaultView>
  );
};